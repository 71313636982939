import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { AddNumField, options } from "helper/helper";
import { toast } from "react-toastify";
import { isAdminLoading } from "./adminSlice";

export const getAdminList = createAsyncThunk(
  "admin/get-admin-list",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      const response = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/user/user-list/?page=${data.page}&limit=${data.limit}&sort=${data.sort ? data.sort : ""}&search=${data.search ? data.search : ""}&filter[role]=2&filter[gender]=${data.gender ? data.gender : ""}`,
        data: data?.send,
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.status === 200) {
        if (response?.data?.data?.length > 0) {
          const res = await AddNumField(
            response?.data?.data?.[0],
            data?.page * data?.limit
          );
          return fulfillWithValue(res);
        } else {
          const modPage = Math.max(data.page - 1, 0);
          const responseAgain = await axios({
            method: "GET",
            url: `${process.env.REACT_APP_AUTH_URL}/admin/user/user-list/?page=${data.page}&limit=${data.limit}&sort=${data.sort ? data.sort : ""}&search=${data.search ? data.search : ""}&filter[role]=2&filter[gender]=${data.gender ? data.gender : ""}`,
          });
          if (responseAgain.status === 200) {
            const resAgain = await AddNumField(
              responseAgain?.data,
              modPage * data?.limit
            );
            return fulfillWithValue(resAgain);
          } else {
            // toast.error(responseAgain?.data?.message, options);
            return rejectWithValue();
          }
        }
      } else {
        // toast.error(response?.data?.message, options);
        return rejectWithValue();
      }
    } catch (err) {
      return rejectWithValue();
    }
  }
);

export const addAdmin = createAsyncThunk(
  "admin/add-admin",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      dispatch(isAdminLoading(true));
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/user/add-user`,
        data: data,
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.status === 200) {
        dispatch(isAdminLoading(false));
        toast.success(response?.data?.message, options);
        return response?.data?.status;
      } else {
        dispatch(isAdminLoading(false));
        toast.error(response?.data?.message, options);
      }
    } catch (err) {
      dispatch(isAdminLoading(false));
      toast.error(err, options);
    }
  }
);

export const getAdminDetail = createAsyncThunk(
  "admin/get-admin-detail",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      dispatch(isAdminLoading(true))
      const response = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/user/user-detail/${data.id}`,
      });
      
      if (response.status === 200) {
        dispatch(isAdminLoading(false))
        return fulfillWithValue(response?.data);
      } else {
        dispatch(isAdminLoading(false))
        toast.error(response?.data?.message, options);
        return rejectWithValue();
      }
    } catch (err) {
      dispatch(isAdminLoading(false))
      toast.error(err, options);
      return rejectWithValue();
    }
  }
);

export const updateAdmin = createAsyncThunk(
  "admin/update-admin",
  async (data, { dispatch }) => {
    try {
      dispatch(isAdminLoading(true));
      const response = await axios({
        method: "PUT",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/user/update-detail/${data?.id}`,
        data: data?.send,
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.status === 200) {
        dispatch(isAdminLoading(false));
        toast.success(response?.data?.message, options);

        return response?.data?.status;
      } else {
        dispatch(isAdminLoading(false));
        toast.error(response?.data?.message, options);
      }
    } catch (err) {
      dispatch(isAdminLoading(false));
      toast.error(err, options);
    }
  }
);

export const deleteAdminAsync = createAsyncThunk(
  "admin/delete-admin-account",
  async (data, { dispatch }) => {
    try {
      dispatch(isAdminLoading(true));
      const response = await axios({
        method: "DELETE",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/user/delete-account`,
        data: data?.send,
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.status === 200) {
        dispatch(isAdminLoading(false));
        dispatch(
          getAdminList({
            page: data?.page,
            limit: data.limit,
            search: data.search,
            sort: data.sort,
          })
        );
        toast.success(response?.data?.message, options);
        return response?.data?.status;
      } else {
        dispatch(isAdminLoading(false));
        toast.error(response?.data?.message, options);
      }
    } catch (err) {
      dispatch(isAdminLoading(false));
      toast.error(err, options);
    }
  }
);

export const adminActivateDeactivate = createAsyncThunk(
  "admin/activate-deactivate",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      dispatch(isAdminLoading(true));
      const response = await axios({
        method: "PUT",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/user/manage-account/${data?.id}`,
        data: data?.send,
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.status === 200) {
        dispatch(isAdminLoading(false));
        toast.success(response?.data?.message, options)

        return response?.data?.status;
      } else {
        dispatch(isAdminLoading(false));
        toast.error(response?.data?.message, options);
      }
    } catch (err) {
      dispatch(isAdminLoading(false));
      toast.error(err, options);
    }
  }
);
