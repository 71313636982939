/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import ConformationPopup from "components/ui/ConformationPopup";
import SearchField from "components/ui/SearchField";
import useDisableSkelaton from "hooks/useDisableSkelaton";
import useFetchList from "hooks/useFetchList";
import useDeleteContent from "hooks/useDeleteContent";
import ModuleLayout from "layouts/ModuleLayout";
import { setPageDetails } from "modules/auth/redux/authSlice";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { getBreadcrumbName } from "helper/helper";
import { useNavigate } from "react-router-dom";
import DropDownBox from "components/DropDownBox";
import ReviewTable from "../component/ReviewTable";
import { getReviewList } from "../redux/reviewApi";

const Reviews = ({ setTitle }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [rating, setRating] = useState()

    useEffect(() => {
        setTitle("Review Management");
    }, [setTitle]);

    const { reviewList, metaData, reviewLoading } = useSelector(
        (state) => state.review,
        shallowEqual
    );

    const [active, setActive] = useState("review");
    const [mobileSidebar, setMobileSidebar] = useState(false);
    const [minimizedSidebar, setMinimizedSidebar] = useState(false);
    const { contentLoading } = useDisableSkelaton(reviewLoading);

    const {
        content,
        alertBox,
        setAlertBox,
        selectAllRows,
        collectAllRows,
        setSelectAllRows,
        setCollectAllRows,
        deleteFunction,
    } = useDeleteContent(reviewList, "id");

    const {
        privileges,
        limit,
        setLimit,
        sort,
        setSort,
        search,
        setSearch,
        btnNo,
        setBtnNo,
        tableIndex,
        setTableIndex,
    } = useFetchList(
        getReviewList,
        metaData,
        selectAllRows,
        setSelectAllRows,
        setCollectAllRows
    );

    useEffect(() => {
        dispatch(setPageDetails({ page: metaData?.page, search }));
    }, [dispatch, metaData, search]);

    const listContent = reviewList?.map((item, i) => {
        const { id, num, image, name, rating, ratedBy } = item;
        const similarItems = [
            { style: "name", val: image?.localUrl, tag: "image" },
            { style: "name", val: name },
            { style: "name", val: `${rating} (${ratedBy})`, tag: "rating" },
        ];
        return [id, num, image, name, similarItems];
    });

    const TABLEDATA = {
        loadingState: contentLoading,
        skalatonDetails: { col: 4, rows: limit },
        list: reviewList,
        listHeading: ["Product Image", "Product", "Rating"],
        listContent,
        selectAllRows,
        setSelectAllRows,
        collectAllRows,
        setCollectAllRows,
        deleteFunction,
        sliceName: "question",
        PagAPI: getReviewList,
        search,
        sort,
        limit,
        setLimit,
        btnNo,
        setBtnNo,
        tableIndex,
        setTableIndex,
        privileges,
        hasAccess: "reviewManagement",
        navigateTo: (params) => {
            const user = reviewList.find((order) => order.id === params);
            const breadcrumbName = getBreadcrumbName(user);
            navigate(`/reviews/${params}`, {
                state: { slug: params, title: breadcrumbName },
            });
        },
        metaData,
    };

    useEffect(() => {
        const data = {
            page: 0,
            limit: 0,
            sort: sort,
            rating: rating
        };
        dispatch(getReviewList(data));
    }, [sort, rating]);

    return (
        <ModuleLayout
            minimizedSidebar={minimizedSidebar}
            setMinimizedSidebar={setMinimizedSidebar}
            mobileSidebar={mobileSidebar}
            setMobileSidebar={setMobileSidebar}
            active={active}
            setActive={setActive}
            content={content}
            alertBox={alertBox}
        >
            <header className="list-header">
                <div className="list-title">
                    <h2>Review Management</h2>
                    {/* <div className="flex-center gap-1">
                        {Object?.values(privileges["reviewManagement"])?.includes(
                            "delete"
                        ) && selectAllRows ? (
                            <button
                                className="btn-secondary flex-center gap-1"
                                onClick={() => {
                                    deleteFunction("all Questions");
                                }}
                            >
                                <i className="fa-light fa-trash"></i>
                                {`Delete ${collectAllRows.length > 1
                                    ? "All " + collectAllRows.length
                                    : ""
                                    }`}
                            </button>
                        ) : null}
                    </div> */}
                </div>
            </header>
            <div className="list-container custom-box">
                <div className="flex-between">
                    <div className="flex gap-1">
                        <DropDownBox
                            options={[
                                { label: "Highest", value: "desc" },
                                { label: "Lowest", value: "asc" },
                            ]}
                            dropDownTitle="Sort by review"
                            animateDropDownTitle
                            customSetter={setSort}
                            customDropBoxStyles={{
                                opacity: 0.6,
                                color: "#262626",
                                padding: "0.6rem",
                                width: "10rem",
                                border: "1px solid var(--color-neutral-92)",
                            }}
                            resetButton
                        />
                    </div>
                    <div className="flex gap-1">
                        <div className="search-wrapper" style={{ width: "5rem" }}>
                            <input
                                type="number"
                                name="rating"
                                id="rating"
                                onChange={(e) => setRating(e.target.value)}
                                max={5}
                                min={0}
                                className="input"
                                style={{ paddingRight: ".6rem" }}
                                placeholder="Rating"
                            />
                        </div>
                        <SearchField value={search} setValue={setSearch} />
                    </div>

                </div>
                <ReviewTable {...TABLEDATA} />
            </div>

            {alertBox && (
                <ConformationPopup
                    customState={alertBox}
                    customSetter={setAlertBox}
                    //   api={deleteQuestion}
                    popUpData={content}
                    customFunction={() => {
                        setSelectAllRows(false);
                        setCollectAllRows([]);
                    }}
                    toastMessage={"Question removed successfully"}
                />
            )}
        </ModuleLayout>
    );
};

export default Reviews;
