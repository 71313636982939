import { createAsyncThunk } from "@reduxjs/toolkit";
import { AddNumField, options } from "helper/helper";
import { toggleUserLoading } from "./userSlice";
import { toast } from "react-toastify";
import axios from "axios";

export const getUserList = createAsyncThunk(
  "user/get-user-list",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {

    try {
      const response = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/user/user-list/?page=${data?.page}&limit=${data?.limit}&sort=${data?.sort ? data?.sort : ""}&search=${data?.search ? data?.search : ""}&filter[role]=3&filter[gender]=${data?.gender ? data?.gender : ""}&filter[premium]=${data?.premium ? data?.premium : ""}`,
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.status === 200) {
        if (response?.data?.data?.length > 0) {
          const res = await AddNumField(
            response?.data?.data?.[0],
            data?.page * data?.limit
          );
          return fulfillWithValue(res);
        } else {
          const modPage = Math.max(data.page - 1, 0);
          const responseAgain = await axios({
            method: "GET",
            url: `${process.env.REACT_APP_AUTH_URL}/admin/user/user-list/?page=${data?.page}&limit=${data?.limit}&sort=${data?.sort ? data?.sort : ""}&search=${data?.search ? data?.search : ""}&filter[role]=3&filter[gender]=${data?.gender ? data?.gender : ""}&filter[premium]=${data?.premium ? data?.premium : ""}`,
          });
          if (responseAgain.status === 200) {
            const resAgain = await AddNumField(
              responseAgain?.data,
              modPage * data?.limit
            );
            return fulfillWithValue(resAgain);
          } else {
            toast.error(responseAgain?.data?.message, options);
            return rejectWithValue();
          }
        }
      } else {
        return rejectWithValue();
      }
    } catch (err) {
      return rejectWithValue();
    }
  }
);

export const getUserDetail = createAsyncThunk(
  "user/get-user-detail",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      const response = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/user/user-detail/${data}`,
      });
      if (response.status === 200) {
        return fulfillWithValue(response?.data);
      } else {
        toast.error(response?.data?.message, options);
        return rejectWithValue();
      }
    } catch (err) {
      toast.error(err, options);
      return rejectWithValue();
    }
  }
);

export const updateUser = createAsyncThunk(
  "user/update-user-detail",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      dispatch(toggleUserLoading(true));
      const response = await axios({
        method: "PUT",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/user/update-detail/${data?.id}`,
        data: data?.send,
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.status === 200) {
        dispatch(toggleUserLoading(false));
        toast.success(response?.data?.message, options);

        return response?.data?.status;
      } else {
        dispatch(toggleUserLoading(false));
        toast.error(response?.data?.message, options);
      }
    } catch (err) {
      dispatch(toggleUserLoading(false));
      toast.error(err, options);
    }
  }
);

export const deleteUser = createAsyncThunk(
  "user/delete-user-account",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    
    try {
      dispatch(toggleUserLoading(true));
      const response = await axios({
        method: "DELETE",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/user/delete-account`,
        data: data?.send,
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.status === 200) {
        dispatch(toggleUserLoading(false));
        dispatch(
          getUserList({
            page: data?.page,
            limit: data.limit,
            search: data.search,
            sort: data.sort,
          })
        );
        toast.success(response?.data?.message, options);
        return response?.data?.status;
      } else {
        dispatch(toggleUserLoading(false));
        toast.error(response?.data?.message, options);
      }
    } catch (err) {
      dispatch(toggleUserLoading(false));
      toast.error(err, options);
    }
  }
);

export const userActivateDeactivate = createAsyncThunk(
  "user/activate-deactivate",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    
    try {
      dispatch(toggleUserLoading(true));
      const response = await axios({
        method: "PUT",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/user/manage-account/${data?.id}`,
        data: data?.send,
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.status === 200) {
        dispatch(toggleUserLoading(false));
        toast.success(response?.data?.message, options)
        return response?.data?.status;
      } else {
        dispatch(toggleUserLoading(false));
        toast.error(response?.data?.message, options);
      }
    } catch (err) {
      dispatch(toggleUserLoading(false));
      toast.error(err, options);
    }
  }
);

export const getReferralList = createAsyncThunk(
  "user/get-referral-list",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      const response = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/user/referral/referral-list/?page=${data?.page}&limit=${data?.limit}&sort=${data?.sort ? data?.sort : "desc"}&search=${data?.search ? data?.search : ""}&filter[userId]=${data.userId ? data.userId : ""}&filter[isCompleted]=${data?.isCompleted ? data?.isCompleted : ""}`,
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.status === 200) {
        if (response?.data?.data?.length > 0) {
          const res = await AddNumField(
            response?.data?.data?.[0],
            data?.page * data?.limit
          );
          return fulfillWithValue(res);
        } else {
          const modPage = Math.max(data.page - 1, 0);
          const responseAgain = await axios({
            method: "GET",
            url: `${process.env.REACT_APP_AUTH_URL}/admin/user/referral/referral-list/?page=${data?.page}&limit=${data?.limit}&sort=${data?.sort ? data?.sort : "desc"}&search=${data?.search ? data?.search : ""}&filter[userId]=${data.userId ? data.userId : ""}&filter[isCompleted]=${data?.isCompleted ? data?.isCompleted : ""}`,
          });
          if (responseAgain.status === 200) {
            const resAgain = await AddNumField(
              responseAgain?.data,
              modPage * data?.limit
            );
            return fulfillWithValue(resAgain);
          } else {
            toast.error(responseAgain?.data?.message, options);
            return rejectWithValue();
          }
        }
      } else {
        return rejectWithValue();
      }
    } catch (err) {
      return rejectWithValue();
    }
  }
);

export const getPointsTransaction = createAsyncThunk(
  "user/point-transaction",
  async (data, { fulfillWithValue, rejectWithValue, dispatch }) => {
    try {
      const response = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/user/point/point-list/?page=${data?.page}&limit=${data?.limit}&sort=${data?.sort ? data?.sort : "desc"}&search=${data?.search ? data?.search : ""}&filter[userId]=${data.userId ? data.userId : ""}&filter[pointType]=${data?.pointType ? data?.pointType : ""}&filter[transactionType]=${data?.transactionType ? data?.transactionType : ""}`,
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.status === 200) {
        return fulfillWithValue(response?.data?.data)
      } else {
        return rejectWithValue();
      }
    } catch (err) {
      return rejectWithValue();
    }
  }
)
