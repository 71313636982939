import Pagination from "components/Pagination";
import TableSkelaton from "components/skelaton/TableSkelaton";
import Checkbox from "components/ui/Checkbox";
import NotFound from "components/ui/NotFound";
import React from "react";

const UserTable = (props) => {
  const {
    loadingState,
    skalatonDetails,
    listHeading,
    listContent,
    selectAllRows,
    selectedRows,
    setSelectedRows,
    toggleSelectAll,
    toggleRowSelection,
    deleteFunction,
    sliceName,
    search,
    PagAPI,
    sort,
    limit,
    setLimit,
    btnNo,
    setBtnNo,
    tableIndex,
    setTableIndex,
    privileges,
    hasAccess,
    navigateTo,
    metaData,
    setAlertBox2,
    setPopupDataTwo,
    setPopupCustomWarning,
    customIcon
  } = props;
  const styles = {
    borderRadius: selectAllRows ? { borderRadius: 0 } : null,
    dangerBackground: (val) =>
      selectedRows?.includes(val)
        ? {
          background: "#FEFBFB",
        }
        : null,
  };

  return (
    <>
      {loadingState ? (
        <TableSkelaton col={skalatonDetails.col} row={skalatonDetails.rows} />
      ) : listContent?.length > 0 ? (
        <>
          <div className="table_container">
            <div className="table_content">
              <table className="main_table">
                <thead className="tb_head">
                  <tr className="tb_row">
                    <th className="tbh_name" style={styles.borderRadius}>
                      {Object.values(privileges[hasAccess]).includes(
                        "delete"
                      ) && (
                          <Checkbox
                            name="selectAll"
                            checked={selectAllRows}
                            onChange={toggleSelectAll}
                          />
                        )}
                    </th>
                    {listHeading?.map((tbh, i) => (
                      <th className="tbh_name" key={i}>
                        {tbh}
                      </th>
                    ))}
                    {Object.values(privileges[hasAccess]).includes(
                      "write" || "delete"
                    ) && (
                        <th
                          className="tbh_name text-center"
                          style={styles.borderRadius}
                        >
                          Action
                        </th>
                      )}
                  </tr>
                </thead>

                <tbody className="tb_body">
                  {listContent.map((content, index) => {
                    return (
                      <tr
                        key={index}
                        className="tb_row"
                        style={styles.dangerBackground(content?.[0])}
                      >
                        <td className="tb_data">
                          {selectedRows ? (
                            <Checkbox
                              index={index}
                              name={`checkbox${index}`}
                              id={`checkbox${index}`}
                              checked={selectedRows?.includes(content?.[0])}
                              onChange={() => toggleRowSelection(content?.[0])}
                            />
                          ) : (
                            <div style={{ paddingLeft: "0.5rem" }}>
                              {content?.[1] + "."}
                            </div>
                          )}
                        </td>

                        {content?.[3].map((simVal, i) => (
                          <td className="tb_data" key={i}>
                            <div className={simVal.style}>{simVal.val}</div>
                          </td>
                        ))}

                        {Object.values(privileges[hasAccess]).includes(
                          "write"
                        ) && (
                            <td className="tb_data">
                              <div className="actions">
                                {Object.values(privileges[hasAccess]).includes(
                                  "write"
                                ) && (
                                    <button
                                      className="icon-btn view"
                                      onClick={() => navigateTo(content?.[0])}
                                      title="View Details"
                                    >
                                      <i className="fa-regular fa-eye"></i>
                                    </button>
                                  )}

                                {Object.values(privileges[hasAccess]).includes(
                                  "write"
                                ) && (
                                    <button
                                      title={`${content?.[4] ? "Disable" : "Enable"}`}
                                      className={`icon-btn ${content?.[4] ? "disable" : "enable"}`}
                                      onClick={() => {
                                        setPopupDataTwo({
                                          data: {
                                            id: content?.[0],
                                            send: {
                                              status: !content?.[4],
                                            },
                                          },
                                          title: "User status changed",
                                        });
                                        setAlertBox2(true);
                                        setPopupCustomWarning(
                                          !content?.[4]
                                            ? "Activate the User?"
                                            : "Are you sure?"
                                        );
                                      }}
                                    >
                                      {
                                        customIcon ? <i className={!content?.[4] ? customIcon?.enable : customIcon?.disable}></i> :
                                          <i
                                            className={`fa-duotone fa-solid ${!content?.[4] ? "fa-user-check" : "fa-user-xmark"}  medium`}
                                          ></i>
                                      }
                                    </button>
                                  )}
                                {Object.values(privileges[hasAccess]).includes(
                                  "delete"
                                ) && (
                                    <button
                                      title="Delete"
                                      className="icon-btn delete"
                                      onClick={() => {
                                        deleteFunction(content?.[0], content?.[2]);
                                      }}
                                    >
                                      <i className="fa-regular fa-trash"></i>
                                    </button>
                                  )}
                              </div>
                            </td>
                          )}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>

          {metaData?.total >= 5 && (
            <Pagination
              sliceName={sliceName}
              search={search}
              api={PagAPI}
              sort={sort}
              limit={limit}
              setLimit={setLimit}
              tableIndex={tableIndex}
              setTableIndex={setTableIndex}
              btnNo={btnNo}
              setBtnNo={setBtnNo}
              callbackFunction={() => setSelectedRows(new Set())}
            />
          )}
        </>
      ) : (
        <NotFound />
      )}
    </>
  );
};

export default UserTable;
