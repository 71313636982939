/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";

const DropDownBox = ({
  dropDownTitle,
  options,
  defaultValue,
  size,
  showSearchBar,
  customSetter,
  customFormikLabel,
  customDropBoxStyles,
  disabled, // ? boolean
  customTextStyle,
  animateDropDownTitle,
  animateDropDownTitleStyle, //? provide the object with 2 object {labelDown:{style},labelUp{style}}
  incomingValue, // ? provide incoming string value
  resetButton, // ? boolean
  callCustomFunction, // ? formik.setValue
  customValueForCustomFunction, // ? value for formik.setValue
  isPhoneCode,
  listApi,
  apiData = {}, //? provide object or any data that you want to send with the request
  dispatch,
  listMenu, // for listing up or down
  listOfKeyValue, //? provide a object containing labelKey and valueKey ex-> {labelKey:"productName",valueKey:"productId"}
}) => {
  const [showMenu, setShowMenu] = useState(false);
  const [addStyle, setAddStyle] = useState(false);
  const [menuOptions, setMenuOptions] = useState(options);

  const [dropDownValue, setDropDownValue] = useState(defaultValue);
  
  const [dropDownValueTwo, setDropDownValueTwo] = useState("");

  const handleClick = (e) => {
    setAddStyle(!addStyle);
    // formik.setFieldValue("search", "")
    chatBoxVisibility();
  };

  function chatBoxVisibility() {
    if (showMenu) {
      const styleTimer = setTimeout(() => {
        setShowMenu(false);
        clearTimeout(styleTimer);
      }, 200);
    } else {
      setShowMenu(true);
    }
  }
  // async function apiCall() {
  //   const response = await dispatch(listApi(apiData)) // ? return array containing value

  //   if (options.length === 0) {
  //     const options = response?.payload?.map(item => {
  //       return { label: item.name, value: item._id }
  //     })

  //     // if (menuOptions.length === 0) {
  //     //   setMenuOptions(options)
  //     //   setCheck(false)
  //     // }
  //   }
  // }

  useEffect(() => {
    if (dropDownValueTwo || dropDownValue === "All") {
      if (callCustomFunction) {
        callCustomFunction(dropDownValueTwo, customValueForCustomFunction);
      } else if (customFormikLabel) {
        customSetter(customFormikLabel, dropDownValueTwo);
      } else {
        customSetter(dropDownValueTwo);
      }
      if (dropDownValue === "All") {
        setDropDownValue("");
      }
    }
  }, [dropDownValueTwo]);

  const [historyIncomingValue, setHistoryIncomingValue] = useState("");

  useEffect(() => {
    if (
      (historyIncomingValue && historyIncomingValue !== incomingValue) ||
      (!dropDownValueTwo && incomingValue)
    ) {
      options?.forEach((item) => {
        const { label, value } = item;
        if (value === incomingValue) {
          setHistoryIncomingValue(value);
          setDropDownValueTwo(value);
          setDropDownValue( label);
        }
      });
    }

  }, [options, incomingValue]);

  // useEffect(() => {
  //   if (!dropDownValueTwo) {
  //     options?.forEach((item) => {
  //       const { label, value } = item;

  //       if (value === incomingValue) {
  //         setDropDownValueTwo(value);
  //         // setDropDownValue(label);
  //         setDropDownValue(
  //           isPhoneCode
  //             ? `${label?.split(" ")?.[0]} ${label?.split(" ")?.[1]}`
  //             : label
  //         );
  //         // setMenuOptions(options);
  //       }
  //     });
  //   }
  // }, [options, incomingValue]);

  // useEffect(() => {
  //   setDropDownValue(defaultValue);
  // }, [defaultValue]);

  // useEffect(() => {
  //   if (options.length === 0 && dispatch && listApi) {
  //     apiCall()
  //   }
  // }, [menuOptions])

  return (
    <div
      className={
        "drop-down-main" +
        (size === "small"
          ? " drop-down-main-small"
          : size === "medium"
            ? " drop-down-main-medium"
            : size === "mini"
              ? " drop-down-main-mini"
              : " drop-down-main-large")
      }
    >
      {dropDownTitle && (
        <div
          className={
            "drop-down-title" +
            (animateDropDownTitle
              ? dropDownValueTwo || showMenu
                ? " animateDropDownLabel animateDropDownLabelUp"
                : " animateDropDownLabel"
              : "")
          }
          style={
            animateDropDownTitle
              ? dropDownValueTwo || showMenu
                ? { ...animateDropDownTitleStyle?.labelUp, padding: "0" }
                : { ...animateDropDownTitleStyle?.labelDown, padding: "0" }
              : {}
          }
        >
          <span>{dropDownTitle ? dropDownTitle : ""}</span>
        </div>
      )}
      <div
        className={
          "drop-down-selector" +
          (!disabled && disabled !== undefined
            ? ""
            : menuOptions?.length > 4 ||
              (showSearchBar && menuOptions?.length > 3)
              ? " show-drop-scroll"
              : " hide-drop-scroll")
        }
      >
        <div
          className={
            (!disabled && disabled !== undefined
              ? " direct disabledDropBox"
              : "direct") + (animateDropDownTitle ? " fixedHeight" : " ")
          }
          onClick={() => {
            if (disabled || disabled === undefined) {
              handleClick();
            }
          }}
          style={customDropBoxStyles ? customDropBoxStyles : {}}
        >
          <div
            className="default_value"
            style={
              customTextStyle && dropDownValue === defaultValue
                ? customTextStyle
                : {}
            }
            dangerouslySetInnerHTML={{__html: isPhoneCode ? `${dropDownValue?.split("+")?.[0]} +${dropDownValue?.split("+")?.[1]?.split(" ")?.[0]}` : dropDownValue}}
          >
            {/* {dropDownValue} */}
          </div>
          {/* <span
            className={`material-symbols-outlined drop-arrow ${
              !disabled && disabled !== undefined
                ? "disableDropDown"
                : addStyle
                  ? "up-arrow"
                  : ""
            }`}
            style={
              customTextStyle && dropDownValue === defaultValue
                ? customTextStyle
                : {}
            }
          >
            south
          </span> */}
          <i
            className={`fa-solid fa-caret-down  drop-arrow ${!disabled && disabled !== undefined
              ? "disableDropDown"
              : addStyle
                ? "up-arrow"
                : ""
              }`}
            style={
              customTextStyle && dropDownValue === defaultValue
                ? customTextStyle
                : {}
            }
          ></i>
        </div>
        {showMenu && (
          <DropDownMenu
            disabled={disabled}
            addStyle={addStyle}
            showSearchBar={showSearchBar}
            dropDownValueTwo={dropDownValueTwo}
            resetButton={resetButton}
            menuOptions={menuOptions}
            setDropDownValue={setDropDownValue}
            setDropDownValueTwo={setDropDownValueTwo}
            options={options}
            setMenuOptions={setMenuOptions}
            showMenu={showMenu}
            handleClick={handleClick}
            dispatch={dispatch}
            listApi={listApi}
            listMenu={listMenu}
            isPhoneCode={isPhoneCode}
          />
        )}
      </div>
    </div>
  );
};

export default DropDownBox;

const DropDownMenu = ({
  options,
  disabled,
  addStyle,
  showSearchBar,
  dropDownValueTwo,
  resetButton,
  menuOptions,
  setDropDownValue,
  setDropDownValueTwo,
  setMenuOptions,
  showMenu,
  handleClick,
  listApi,
  dispatch,
  listMenu,
  isPhoneCode,
}) => {
  const [search, setSearch] = useState("");

  const handleSearch = (e) => {
    // setSearch(e.target.value.trim())
    // formik.setFieldValue("search", e.target.value.toLowerCase())
    setSearch(e.target.value);
  };

  useEffect(() => {
    if (showSearchBar) {
      const arr = [];
      options?.forEach((item) => {
        if (
          item.label &&
          item.label.toLowerCase().includes(search.trim().toLowerCase())
        ) {
          arr.push(item);
        }
      });

      setMenuOptions(arr);
    }
  }, [search]);

  const [globalClick, setGlobalClick] = useState(false);
  const menuRef = useRef();

  useEffect(() => {
    const handleGlobalClick = (event) => {
      if (menuRef?.current && !menuRef?.current?.contains(event.target))
        handleClick();
    };

    document.addEventListener("click", handleGlobalClick);
    return () => {
      document.removeEventListener("click", handleGlobalClick);
    };
  }, [menuRef]);

  useEffect(() => {
    if (showMenu) {
      setGlobalClick(true);
    }
  }, []);

  useEffect(() => {
    if (options) {
      setMenuOptions(options);
    }
  }, [options]);

  return (
    <>
      {!disabled && disabled !== undefined ? (
        ""
      ) : showMenu ? (
        <div
          className={
            addStyle
              ? `drop-down-menu ${listMenu === "up" && "list-up"}`
              : "drop-down-menu hide_drop-down-menu"
          }
          ref={showMenu && globalClick ? menuRef : null}
          style={showSearchBar && { overflow: "hidden" }}
        >
          {showSearchBar ? (
            <div className="drop-down-search-bar">
              <input
                type="text"
                placeholder="search here..."
                name="search"
                value={search}
                onChange={handleSearch}
              />
            </div>
          ) : null}

          {resetButton && dropDownValueTwo && !search ? (
            <div
              className={"drop-down-item"}
              onClick={() => {
                setDropDownValue("All");
                setDropDownValueTwo("");
                handleClick();
              }}
            >
              <span>All</span>
            </div>
          ) : null}

          {menuOptions?.length > 0 ? (
            <div
              style={
                showSearchBar && {
                  maxHeight: "calc(10rem - 50px)",
                  overflowY: "auto",
                }
              }
            >
              {menuOptions?.map(({ label, value }, index) => (
                <div
                  key={index}
                  className={
                    "drop-down-item" +
                    (dropDownValueTwo === value ? " selectedDropBox" : "")
                  }
                  onClick={() => {
                    setDropDownValue(
                      isPhoneCode
                        ? `${label?.split(" ")?.[0]} ${label?.split(" ")?.[1]}`
                        : label
                    );
                    setDropDownValueTwo(value);
                    handleClick();
                  }}
                  dangerouslySetInnerHTML={{
                    __html: label
                  }}
                >
                  {/* <span>
                    {isPhoneCode
                      ? `${label?.split(" ")?.[0]} ${label?.split(" ")?.[1]}`
                      : label}
                  </span> */}
                </div>
              ))}
            </div>
          ) : (
            <div className="drop-down-item">
              <span>No Data Found</span>
            </div>
          )}
        </div>
      ) : null}
    </>
  );
};
