/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import DropDownBox from "./DropDownBox";

const Pagination = ({
  sliceName,
  tableIndex,
  setTableIndex,
  api,
  sort,
  search,
  limit,
  setLimit,
  btnNo,
  setBtnNo,
  send,
  callbackFunction,
  metaDataName = "metaData",
  defaultRows = 5,
}) => {
  const dispatch = useDispatch();
  const { [metaDataName]: metaData } = useSelector((store) => store[sliceName]);

  const pageList = new Array(metaData?.totalPages).fill(undefined);
  const { pageDetails } = useSelector((state) => state.auth, shallowEqual);

  const handleChange = (no) => {
    // setTableIndex(index);
    if (no > 0 && no <= pageList.length) {
      setTableIndex(no - 1);
    }
    if (no > 1 && no < pageList.length - 1) {
      if (no < btnNo[1]) {
        setBtnNo([btnNo[0] - 1, btnNo[1] - 1, btnNo[2] - 1]);
      } else if (no > btnNo[1]) {
        setBtnNo([btnNo[0] + 1, btnNo[1] + 1, btnNo[2] + 1]);
      }
    }
  };

  const handleLastBtn = () => {
    setTableIndex(pageList.length - 1);
    setBtnNo([pageList.length - 3, pageList.length - 2, pageList.length - 1]);
    dispatch(
      api({
        page: pageList.length - 1,
        limit,
        search,
        sort: sort,
        send: send ? send : {},
      })
    );
    if (callbackFunction) {
      callbackFunction();
    }
  };

  const handleFirstBtn = () => {
    setTableIndex(0);
    setBtnNo([1, 2, 3]);
    dispatch(
      api({
        page: 0,
        limit,
        sort: sort,
        search,
        send: send ? send : {},
      })
    );
    if (callbackFunction) {
      callbackFunction();
    }
  };

  const handlePageChange = (value) => {
    if (value !== metaData.page) {
      dispatch(
        api({
          page: value,
          limit,
          sort: sort,
          search,
          send: send ? send : {},
        })
      );
      if (callbackFunction) {
        callbackFunction();
      }
    }
  };

  useEffect(() => {
    if (
      (pageDetails.page === 0 || Object.keys(pageDetails).length === 0) &&
      tableIndex <= 0
    ) {
      setTableIndex(0);
    } else {
      if (tableIndex !== metaData?.page) {
        setTableIndex(metaData?.page);
      }
    }
  }, [pageDetails]);

  const currentPage = metaData?.page + 1;
  const totalEntries = metaData?.total;

  const startIndex = (currentPage - 1) * limit + 1;
  const endIndex = Math.min(currentPage * limit, totalEntries);
  const displayLine = `Showing ${startIndex} to ${endIndex} of ${totalEntries} entries`;

  return (
    <div className="pagination-container">
      <div className="flex-center gap-2">
        <DropDownBox
          options={[
            { label: `${defaultRows}`, value: defaultRows },
            {
              label: `${defaultRows * 2}`,
              value: defaultRows * 2,
            },
            {
              label: `${defaultRows * 3}`,
              value: defaultRows * 3,
            },
          ]}
          defaultValue="Rows"
          listMenu={limit > 3 ? "" : "up"}
          customSetter={setLimit}
          customDropBoxStyles={{
            minWidth: "2.2rem",
            padding: ".5rem .8rem",
            borderRadius: "0.188rem",
            border: "0.113rem solid var(--color-neutral-92)",
          }}
          customTextStyle={{
            color: "var(--color-neutral-50)",
            // opacity: "0.7",
          }}
        // incomingValue={5}
        />
        <p className="entries">{displayLine}</p>
      </div>

      <div
        className={`pagination-box flex-center ${metaData?.total < limit ? "hide" : ""}`}
      >
        <button
          className={`pagBTN ${!metaData?.hasPrevPage ? "disabled-btn" : ""}`}
          onClick={() => {
            if (tableIndex > 0) {
              handlePageChange(tableIndex - 1);
            }
            handleChange(tableIndex);
          }}
          disabled={!metaData?.hasPrevPage}
        >
          <span className="small">Previous</span>
        </button>
        {new Array(metaData?.totalPages).fill(undefined).length <= 3 ? (
          new Array(metaData?.totalPages)
            .fill(undefined)
            ?.map((item, index) => {
              return (
                <button
                  key={index}
                  className={index === tableIndex ? "pagBTN active" : "pagBTN"}
                  onClick={() => {
                    setTableIndex(index);
                    handlePageChange(index);
                  }}
                >
                  {index + 1}
                </button>
              );
            })
        ) : new Array(metaData?.totalPages).fill(undefined).length > 3 &&
          tableIndex + 1 <
          new Array(metaData?.totalPages).fill(undefined).length - 1 ? (
          <>
            {btnNo?.map((item, i) => {
              return (
                <button
                  key={i}
                  className={
                    item === tableIndex + 1 ? "pagBTN active" : "pagBTN"
                  }
                  onClick={() => {
                    handleChange(item);
                    handlePageChange(item - 1);
                  }}
                >
                  {item}
                </button>
              );
            })}{" "}
            <span className="page-separation">...</span>
            <button
              className={
                tableIndex ===
                  new Array(metaData?.totalPages).fill(undefined).length - 1
                  ? "pagBTN active"
                  : "pagBTN"
              }
              onClick={handleLastBtn}
            >
              {new Array(metaData?.totalPages).fill(undefined).length}
            </button>
          </>
        ) : (
          <>
            <button
              className={tableIndex === 0 ? "pagBTN active" : "pagBTN"}
              onClick={handleFirstBtn}
            >
              1
            </button>{" "}
            <span className="page-separation">...</span>
            {[
              new Array(metaData?.totalPages).fill(undefined).length - 2,
              new Array(metaData?.totalPages).fill(undefined).length - 1,
              new Array(metaData?.totalPages).fill(undefined).length,
            ]?.map((item, i) => {
              return (
                <button
                  key={i}
                  className={
                    item === tableIndex + 1 ? "pagBTN active" : "pagBTN"
                  }
                  onClick={() => {
                    handleChange(item);
                    handlePageChange(item - 1);
                  }}
                >
                  {item}
                </button>
              );
            })}{" "}
          </>
        )}
        <button
          className={`pagBTN ${!metaData?.hasNextPage ? "disabled-btn" : ""}`}
          onClick={() => {
            if (
              tableIndex <
              new Array(metaData?.totalPages).fill(undefined).length - 1
            ) {
              handlePageChange(tableIndex + 1);
              handleChange(tableIndex + 2);
            }
          }}
          disabled={!metaData?.hasNextPage}
        >
          <span className="small">Next</span>
        </button>
      </div>
    </div>
  );
};

export default Pagination;
