import React, { useEffect, useState } from "react";
import ModuleLayout from "layouts/ModuleLayout";
import UserHeader from "../components/UserHeader";
import { Outlet } from "react-router-dom";
// import { shallowEqual, useSelector } from "react-redux";
// import Loader from "components/ui/Loader";

const UserLayout = ({ setTitle }) => {
  useEffect(() => {
    setTitle("User's Details");
  }, [setTitle]);

  const [mobileSidebar, setMobileSidebar] = useState(false);
  const [active, setActive] = useState("users");
  const [minimizedSidebar, setMinimizedSidebar] = useState(false);
  // const { userLoading } = useSelector((state) => state.user, shallowEqual);

  return (
    <ModuleLayout
      minimizedSidebar={minimizedSidebar}
      setMinimizedSidebar={setMinimizedSidebar}
      mobileSidebar={mobileSidebar}
      setMobileSidebar={setMobileSidebar}
      active={active}
      setActive={setActive}
    >
      <div className="details-wrapper">
        <UserHeader />
        <Outlet />
      </div>

      {/* <Loader isLoading={userLoading} /> */}
    </ModuleLayout>
  );
};

export default UserLayout;
