import { createSlice } from "@reduxjs/toolkit";
import { getReviewDetail, getReviewList } from "./reviewApi";

const reviewSlice = createSlice({
  name: "review",
  initialState: {
    reviewLoading: false,
    reviewList: [],
    metaData: {},
    reviewDetail: {},
    listMetaData: {},
    dataList: []
  },
  reducers: {
    isReviewLoading: (state, action) =>
    (state = {
      ...state,
      reviewLoading: action.payload,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(getReviewList.pending, (state, action) => {
      state.reviewLoading = true;
    });
    builder.addCase(getReviewList.fulfilled, (state, action) => {

      state.reviewLoading = false;
      state.reviewList = action?.payload?.data;
      state.metaData = action?.payload?.metadata[0];
    });
    builder.addCase(getReviewList.rejected, (state, action) => {
      state.reviewLoading = false;
      state.reviewList = [];
    });
    builder.addCase(getReviewDetail.pending, (state, action) => {
      state.reviewLoading = true;
    });
    builder.addCase(getReviewDetail.fulfilled, (state, action) => {
      state.reviewLoading = false;
      state.reviewDetail = action.payload?.data?.productInfo;
      state.dataList = action.payload?.data?.reviewsList?.data
      state.listMetaData = action.payload?.data?.reviewsList?.metadata?.[0]
    });
    builder.addCase(getReviewDetail.rejected, (state, action) => {
      state.reviewLoading = false;
    });
  },
});

export const { isReviewLoading } = reviewSlice.actions;
export default reviewSlice.reducer;
