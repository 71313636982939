/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import DropDownBox from "components/DropDownBox";
import InputField from "components/ui/InputField";
import SubmitButton from "components/ui/SubmitButton";
import ModuleLayout from "layouts/ModuleLayout";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { getCategoryList } from "modules/catalogue/categories/redux/categoryApi";
import { getBrandList } from "modules/catalogue/brands/redux/brandsApi";
import { convertToWebP, maxDate, minDate } from "helper/helper";
import {
  addProduct,
  getProductDetail,
  updateProductDetail,
} from "../redux/productApi";
import { useFormik } from "formik";
import * as Yup from "yup";

const ManageProduct = ({ setTitle }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { product_id } = useParams();

  const [catOpts, setCatOpts] = useState([]);
  const [brandOpts, setBrandOpts] = useState([]);
  const [countryOpts, setCountryOpts] = useState();
  const [currencyOpts, setCurrencyOpts] = useState();

  const { countryList } = useSelector((state) => state.profile, shallowEqual);

  const { productDetail } = useSelector(
    (state) => state.products,
    shallowEqual
  );

  const { brandList } = useSelector((state) => state.CatBrand, shallowEqual);
  const { categoryList } = useSelector(
    (state) => state.CatCategory,
    shallowEqual
  );

  const unixToDate = (unixTimestamp) => {
    const date = new Date(unixTimestamp);
    const formattedDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`;
    return formattedDate;
  };

  useEffect(() => {
    if (categoryList?.length > 0) {
      const cc = categoryList.map((item) => {
        const label = `${item?.name}`;
        const value = item.id;
        const key = item.id;
        return { label, value, key };
      });
      setCatOpts(cc);
    }
  }, [categoryList]);

  useEffect(() => {
    if (brandList?.length > 0) {
      const cc = brandList.map((item) => {
        const label = `${item?.name}`;
        const value = item.id;
        const key = item.id;
        return { label, value, key };
      });
      setBrandOpts(cc);
    }
  }, [brandList]);

  useEffect(() => {
    if (countryList?.length > 0) {
      const cc = countryList.map((item) => {
        const label = `${item?.name}`;
        const value = item.name;
        const key = item.dialCode;
        return { label, value, key };
      });
      setCountryOpts(cc);
      const cr = countryList.map((item) => {
        const label = `${item?.currency.code} - ${item?.name}`;
        const value = item?.currency.code;
        const key = item.dialCode;
        return { label, value, key };
      });
      setCurrencyOpts(cr);
    }
  }, [countryList]);

  useEffect(() => {
    if (product_id === "add-new") {
      setTitle("Product Management | Add Inventory");
    } else {
      setTitle("Product Management | Update Inventory");
    }
  }, [setTitle]);

  const [active, setActive] = useState("products");
  const [mobileSidebar, setMobileSidebar] = useState(false);
  const [minimizedSidebar, setMinimizedSidebar] = useState(false);
  const [productImgs, setProductImgs] = useState([]);
  const [imageZoom, setImageZoom] = useState();
  const [activeInputs, setActiveInputs] = useState(true);
  const [selectedCountry, setSelectedCountry] = useState({});

  const [hideBox, setHideBox] = useState({
    general: true,
    price: false,
    images: false,
    publish: false,
    dimension: false,
    shipping: false,
    country: false,
    specification: false,
  });

  const handleRemoveImage = (index) => {
    const newImages = [...productImgs];
    newImages.splice(index, 1);
    formik.setFieldValue("images", newImages);
    setProductImgs(newImages);
  };

  useEffect(() => {
    const data = {
      filter: "product",
      limit: 0,
      page: 0,
      search: "",
      sort: "desc",
    };
    dispatch(getCategoryList(data));
  }, []);

  useEffect(() => {
    const data = {
      page: 0,
      limit: 0,
      sort: "desc",
      search: "",
    };
    dispatch(getBrandList(data));
  }, []);

  const initialValues = {
    name: "",
    category: "",
    brand: "",
    hsn: "",
    description: "",
    manufactured_date: "",
    weight: "",
    length: "",
    breadth: "",
    height: "",
    country: "",
    currencyCode: "",
    countrySymb: "",
    quantity: "",
    taxType: "",
    taxValue: "",
    mrp: "",
    pricetype: "",
    price: "",
    shipCharge: "",
    packCharge: "",
    images: [],
    specification: [{ key: "", val: "" }],
    returnPeriod: 0,
  };

  const productSchema = Yup.object({
    name: Yup.string()
      .trim()
      .min(3, "Name must be at least 3 characters long")
      .max(80, "First name must be less then 80 characters")
      .required("Name is required"),
    category: Yup.string().required("Category is required"),
    brand: Yup.string().required("Brand is required"),
    description: Yup.string()
      .trim()
      .min(3, "Description must be at least 3 characters long")
      // .max(80, "Description must be less then 80 characters")
      // .matches(/^[A-Za-z ]+$/, "Please enter a valid name")
      .required("Description is required"),
    hsn: Yup.string().min(6, "HSN Code should be min 6 number"),
    manufactured_date: Yup.date()
      .required("Manufactured date is required"),
      // .min("01-01-0001", "Min date 01-01-0001")
      // .max("12-31-9999", "Max date 12-31-9999"),
    height: Yup.number().required("Height is required").max(1000, "Max 1000 cm is allowed"),
    weight: Yup.number().required("Weight is required").max(1000, "Max 1000 kg is allowed"),
    length: Yup.number().required("Length is required").max(1000, "Max 1000 cm is allowed"),
    breadth: Yup.number().required("Breadth is required").max(1000, "Max 1000 cm is allowed"),
    country: Yup.string().required("Please select Origin Country"),
    currencyCode: Yup.string().required("Currency Code is required"),
    countrySymb: Yup.string().required("Currency Symbol is required"),
    quantity: Yup.number().required("Quantity is required"),
    taxType: Yup.string().required("Please select Tax type"),
    taxValue: Yup.string().required("Please select Tax Value"),
    mrp: Yup.string().required("Mrp is required"),
    // price: Yup.number().required("Price is required"),
    price: Yup.number()
      .required("Price is required")
      .test(
        "price-smaller-than-or-equal-to-mrp",
        "Price should be smaller than or equal to MRP",
        function (value) {
          return value <= this.parent.mrp;
        }
      ),
    pricetype: Yup.string().required(),
    shipCharge: Yup.number().max(1000, "Max 1000 is allowed"),
    packCharge: Yup.number().max(1000, "Max 1000 is allowed"),
    returnPeriod: Yup.number()
      .required("Return Period is required")
      .min(0, "Min 0 day"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: productSchema,
    onSubmit: async (values) => {
      const transformedData = values.specification.map((obj) => ({
        [obj.key]: obj.val,
      }));
      const json = JSON.stringify(transformedData);
      const fotmData = new FormData();
      fotmData.append("product_name", values.name.trim());
      fotmData.append("description", values.description.trim());
      fotmData.append("category_id", values.category);
      fotmData.append("brand_id", values.brand);
      fotmData.append("hsn_code", values.hsn);
      fotmData.append(
        "manufactured_date",
        new Date(values.manufactured_date).toISOString()
      );
      fotmData.append("product_weight", values.weight);
      fotmData.append("product_length", values.length);
      fotmData.append("product_breadth", values.breadth);
      fotmData.append("product_height", values.height);
      fotmData.append("product_quantity", values.quantity);
      fotmData.append("origin_country", values.country);
      fotmData.append("currency_code", values.currencyCode);
      fotmData.append("currency_symbol", values.countrySymb);
      fotmData.append("tax_type", values.taxType);
      fotmData.append("tax_value", values.taxValue);
      fotmData.append("tax_included", values.pricetype);
      fotmData.append("mrp", values.mrp);
      fotmData.append("selling_price", values.price);
      fotmData.append("shipping_charge", values.shipCharge);
      fotmData.append("packaging_charge", values.packCharge);
      fotmData.append("return_period", values.returnPeriod);
      values.images?.forEach((blob, index) => {
        fotmData.append("images", blob);
      });
      fotmData.append("specification[]", json);

      if (product_id === "add-new") {
        const response = await dispatch(addProduct(fotmData));
        if (response?.payload) {
          navigate(-1);
        }
      } else {
        const response = await dispatch(
          updateProductDetail({ send: fotmData, id: product_id })
        );
        if (response?.payload) {
          navigate(-1);
        }
      }
    },
  });

  const fetchImages = async (images) => {
    if (images) {
      const files = await Promise.all(
        images.map(async (image) => {
          const response = await fetch(image.localUrl);
          const blob = await response.blob();
          return new File([blob], image.localUrl, { type: blob.type });
        })
      );
      formik.setFieldValue("images", files);
      setProductImgs(files);
    }
  };

  const uploadImgs = async (event) => {
    const files = event.target.files;

    if (files.length > 0) {
      const convertedFiles = await Promise.all(
        Array.from(files).map(async (file) => {
          return await convertToWebP(file);
        })
      );
      formik.setFieldValue("images", [
        ...formik.values.images,
        ...convertedFiles,
      ]);
      setProductImgs([...formik.values.images, ...convertedFiles]);
    }
  };

  useEffect(() => {
    if (product_id !== "add-new") {
      setActiveInputs(false);
      dispatch(getProductDetail({ id: product_id }));
    }
  }, []);

  useEffect(() => {
    (async () => {
      if (product_id !== "add-new" && productDetail) {
        formik.setValues({
          name: productDetail?.name,
          category: productDetail?.category?.id,
          brand: productDetail?.brand?.id,
          hsn: productDetail?.HSN,
          description: productDetail?.description,
          manufactured_date: unixToDate(productDetail?.manufacturedDate),
          weight: productDetail?.weight?.value,
          length: productDetail?.measurement?.length?.value,
          breadth: productDetail?.measurement?.breadth?.value,
          height: productDetail?.measurement?.height?.value,
          country: productDetail?.origin,
          quantity: productDetail?.quantity,
          taxType: productDetail?.tax?.type,
          taxValue: productDetail?.tax?.value,
          mrp: productDetail?.mrp,
          pricetype: String(productDetail?.taxIncluded),
          price: productDetail?.sellingPrice,
          shipCharge: productDetail?.additionalCharge?.shipping,
          packCharge: productDetail?.additionalCharge?.packaging,
          specification: productDetail?.specification?.map((obj) => {
            const key = Object.keys(obj)[0];
            const val = obj[key];
            return { key, val };
          }),
          currencyCode: productDetail?.currency?.code,
          countrySymb: selectedCountry?.currency?.symbol,
          returnPeriod: productDetail?.returnPeriod,
        });
        fetchImages(productDetail?.images);
      }
    })();
  }, [activeInputs, productDetail]);

  const ActivePage =
    product_id === "add-new" ? "Add Product" : "Update Product";

  const addMoreFields = () => {
    formik.setValues({
      ...formik.values,
      specification: [...formik.values.specification, { key: "", val: "" }],
    });
  };

  const removeField = (index) => {
    const newSpecification = [...formik.values.specification];
    newSpecification.splice(index, 1);
    formik.setValues({ ...formik.values, specification: newSpecification });
  };

  useEffect(() => {
    if (formik.values.currencyCode) {
      const data = countryList.filter(
        (ele) => ele?.currency?.code === formik.values.currencyCode
      );
      setSelectedCountry(data?.[0]);
    }
  }, [formik.values.currencyCode]);

  useEffect(() => {
    if (selectedCountry) {
      formik.setValues({
        ...formik.values,
        countrySymb: selectedCountry?.currency?.symbol,
      });
    }
  }, [selectedCountry]);

  const InputProps = {
    className: "form-input",
    onChange: formik.handleChange,
    onBlur: formik.handleBlur,
    disabled: !activeInputs,
  };

  return (
    <ModuleLayout
      minimizedSidebar={minimizedSidebar}
      setMinimizedSidebar={setMinimizedSidebar}
      mobileSidebar={mobileSidebar}
      setMobileSidebar={setMobileSidebar}
      active={active}
      setActive={setActive}
    >
      <div className="list-container custom-box">
        <div className="tb_controls">
          <h2>Product Management | {ActivePage}</h2>
          {product_id !== "add-new" && (
            <div className="flex gap-2">
              {!activeInputs ? (
                <>
                  <button
                    type="button"
                    onClick={() => navigate(-1)}
                    className="btn-outlined cancel"
                  >
                    Back
                  </button>
                  <button
                    type="button"
                    onClick={() => setActiveInputs(!activeInputs)}
                    className="btn-primary"
                  >
                    Update
                  </button>
                </>
              ) : (
                <>
                  <button
                    type="button"
                    onClick={() => setActiveInputs(!activeInputs)}
                    className="btn-outlined tertiary"
                  >
                    Abort
                  </button>
                  <SubmitButton
                    className="btn-primary"
                    type="submit"
                    onClick={() => formik.handleSubmit()}
                    disabled={formik.isSubmitting}
                  >
                    Submit
                  </SubmitButton>
                </>
              )}
            </div>
          )}

          {product_id === "add-new" && (
            <div className="flex-center gap-2">
              <button
                type="button"
                className="btn-outlined cancel"
                onClick={() => navigate(-1)}
              >
                Cancel
              </button>

              <SubmitButton
                className="btn-primary"
                type="submit"
                onClick={() => formik.handleSubmit()}
                isLoading={formik.isSubmitting}
              >
                Submit
              </SubmitButton>
            </div>
          )}
        </div>
        <main className="main">
          <div className="auth-form-wrapper">
            <form className="form" onSubmit={formik.handleSubmit}>
              {/* general detail */}
              <div className="general-detail">
                <div
                  className="detail-header"
                  onClick={() => {
                    setHideBox({
                      ...hideBox,
                      general: !hideBox.general,
                    });
                  }}
                >
                  <h3>Product Information</h3>

                  <div>
                    <div className="sidebar-icon">
                      {hideBox.general ? (
                        <i className="fa-regular fa-chevron-down medium"></i>
                      ) : (
                        <i className="fa-regular fa-chevron-right medium"></i>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  className={hideBox.general ? "flex flex-col gap-2" : "hide"}
                >
                  <div className="form-control">
                    <label className="form-label">Product Name</label>
                    <div className="form-input-box">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-input"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        disabled={!activeInputs}
                        onInput={(e) =>
                          (e.target.value = e.target.value.replace(
                            /(?:^|\s)\S/g,
                            (match) => match.toUpperCase()
                          ))
                        }
                      />
                    </div>
                    {formik.errors.name && formik.touched.name ? (
                      <span className="err">{formik.errors.name}</span>
                    ) : null}
                  </div>
                  <div className="form-control">
                    <label className="form-label">Description</label>
                    <div className="form-input-box">
                      <textarea
                        id="description"
                        name="description"
                        className="form-input"
                        rows={3}
                        value={formik.values.description}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        disabled={!activeInputs}
                        onInput={(e) =>
                          (e.target.value = e.target.value.replace(
                            /^./,
                            (match) => match.toUpperCase()
                          ))
                        }
                      />
                    </div>
                    {formik.errors.description && formik.touched.description ? (
                      <span className="err">{formik.errors.description}</span>
                    ) : null}
                  </div>
                  <div className="form-col-3">
                    <div>
                      <div className="form-control">
                        <label className="form-label">Categories</label>
                        <div className="form-input-box">
                          <DropDownBox
                            options={catOpts}
                            defaultValue="Select Category"
                            animateDropDownTitle
                            customSetter={formik.setFieldValue}
                            customFormikLabel="category"
                            customDropBoxStyles={{
                              border: "0.063rem solid #d2d6da",
                              borderRadius: "0.5rem",
                              color: "#262626",
                            }}
                            customTextStyle={{
                              color: "#454545",
                              opacity: "0.7",
                            }}
                            incomingValue={formik.values.category}
                            disabled={activeInputs}
                          />
                        </div>
                      </div>
                      {formik.errors.category && formik.touched.category ? (
                        <span className="err">{formik.errors.category}</span>
                      ) : null}
                    </div>
                    <div>
                      <div className="form-control">
                        <label className="form-label">Brand</label>
                        <div className="form-input-box">
                          <DropDownBox
                            options={brandOpts}
                            defaultValue="Select Brand"
                            animateDropDownTitle
                            customSetter={formik.setFieldValue}
                            customFormikLabel="brand"
                            customDropBoxStyles={{
                              border: "0.063rem solid #d2d6da",
                              borderRadius: "0.5rem",
                              color: "#262626",
                            }}
                            customTextStyle={{
                              color: "#454545",
                              opacity: "0.7",
                            }}
                            incomingValue={formik.values.brand}
                            disabled={activeInputs}
                          />
                        </div>
                      </div>
                      {formik.errors.brand && formik.touched.brand ? (
                        <span className="err">{formik.errors.brand}</span>
                      ) : null}
                    </div>
                    <div>
                      <div className="form-control">
                        <label className="form-label">HSN Code</label>
                        <div className="form-input-box">
                          <InputField
                            type="text"
                            id="hsn"
                            name="hsn"
                            className="form-input"
                            value={formik.values.hsn}
                            disabled={!activeInputs}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            onInput={(e) => {
                              e.target.value = e.target.value.replace(
                                /\D/g,
                                ""
                              );
                            }}
                          />
                        </div>
                      </div>
                      {formik.errors.hsn && formik.touched.hsn ? (
                        <span className="err">{formik.errors.hsn}</span>
                      ) : null}
                    </div>
                  </div>
                  <div className="form-col-2">
                    <div className="form-control">
                      <label className="form-label">Manufactured Date</label>
                      <div className="form-input-box">
                        <InputField
                          type="date"
                          id="manufactured_date"
                          name="manufactured_date"
                          className="form-input"
                          value={formik.values.manufactured_date}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          disabled={!activeInputs}
                          max={maxDate}
                          // min={minDate}
                        />
                      </div>
                      {formik.errors.manufactured_date &&
                      formik.touched.manufactured_date ? (
                        <span className="err">
                          {formik.errors.manufactured_date}
                        </span>
                      ) : null}
                    </div>
                    <div className="form-control">
                      <label className="form-label">
                        Return Period (in Days)
                      </label>
                      <div className="form-input-box">
                        <input
                          type="number"
                          id="returnPeriod"
                          name="returnPeriod"
                          className="form-input"
                          value={formik.values.returnPeriod}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          disabled={!activeInputs}
                          onInput={(e) => {
                            let inputValue = e.target.value;
                            e.target.value = e.target.value.replace(
                              /[^\d\.]/g,
                              ""
                            );
                            e.target.value = inputValue
                              .slice(0, 2)
                              .replace(/\D/g, "");
                          }}
                        />
                      </div>
                      {formik.errors.returnPeriod &&
                      formik.touched.returnPeriod ? (
                        <span className="err">
                          {formik.errors.returnPeriod}
                        </span>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>

              {/* dimension detail */}
              <div className="general-detail">
                <div
                  className="detail-header"
                  onClick={() => {
                    setHideBox({
                      ...hideBox,
                      dimension: !hideBox.dimension,
                    });
                  }}
                >
                  <h3>Dimensions</h3>

                  <div>
                    <div className="sidebar-icon">
                      {hideBox.dimension ? (
                        <i className="fa-regular fa-chevron-down medium"></i>
                      ) : (
                        <i className="fa-regular fa-chevron-right medium"></i>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  className={hideBox.dimension ? "flex flex-col gap-2" : "hide"}
                >
                  <div className="form-col-2">
                    <div className="form-control">
                      <label className="form-label">Weight (in kg)</label>
                      <div className="form-input-box">
                        <input
                          type="number"
                          id="weight"
                          name="weight"
                          className="form-input"
                          disabled={!activeInputs}
                          value={formik.values.weight}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          min={0}
                          // max={1000}
                          // onInput={(e) => {
                          //   let inputValue = e.target.value;
                          //   // e.target.value = e.target.value.replace(
                          //   //   /[^\d\.]/g,
                          //   //   ""
                          //   // );
                          //   e.target.value = inputValue
                          //     .slice(0, 4)
                          //     .replace(/\D/g, "");
                          // }}
                        />
                      </div>
                      {formik.errors.weight && formik.touched.weight ? (
                        <span className="err">{formik.errors.weight}</span>
                      ) : null}
                    </div>
                    <div className="form-control">
                      <label className="form-label">Length (in cm)</label>
                      <div className="form-input-box">
                        <input
                          type="number"
                          id="length"
                          name="length"
                          className="form-input"
                          value={formik.values.length}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          min={0}
                          // onInput={(e) => {
                          //   let inputValue = e.target.value;
                          //   e.target.value = e.target.value.replace(
                          //     /[^\d\.]/g,
                          //     ""
                          //   );
                          //   e.target.value = inputValue
                          //     .slice(0, 4)
                          //     .replace(/\D/g, "");
                          // }}
                          // max={1000}
                          disabled={!activeInputs}
                        />
                      </div>
                      {formik.errors.length && formik.touched.length ? (
                        <span className="err">{formik.errors.length}</span>
                      ) : null}
                    </div>
                    <div className="form-control">
                      <label className="form-label">Breadth (in cm)</label>
                      <div className="form-input-box">
                        <input
                          type="number"
                          id="breadth"
                          name="breadth"
                          className="form-input"
                          value={formik.values.breadth}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          min={0}
                          // onInput={(e) => {
                          //   let inputValue = e.target.value;
                          //   e.target.value = e.target.value.replace(
                          //     /[^\d\.]/g,
                          //     ""
                          //   );
                          //   e.target.value = inputValue
                          //     .slice(0, 4)
                          //     .replace(/\D/g, "");
                          // }}
                          disabled={!activeInputs}
                        />
                      </div>
                      {formik.errors.breadth && formik.touched.breadth ? (
                        <span className="err">{formik.errors.breadth}</span>
                      ) : null}
                    </div>
                    <div className="form-control">
                      <label className="form-label">Height (in cm)</label>
                      <div className="form-input-box">
                        <input
                          type="number"
                          id="height"
                          name="height"
                          className="form-input"
                          value={formik.values.height}
                          min={0}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          // onInput={(e) => {
                          //   let inputValue = e.target.value;
                          //   e.target.value = e.target.value.replace(
                          //     /[^\d\.]/g,
                          //     ""
                          //   );
                          //   e.target.value = inputValue
                          //     .slice(0, 4)
                          //     .replace(/\D/g, "");
                          // }}
                          disabled={!activeInputs}
                        />
                      </div>
                      {formik.errors.height && formik.touched.height ? (
                        <span className="err">{formik.errors.height}</span>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>

              {/* country Detail  */}
              <div className="general-detail">
                <div
                  className="detail-header"
                  onClick={() => {
                    setHideBox({
                      ...hideBox,
                      country: !hideBox.country,
                    });
                  }}
                >
                  <h3>Country & Currency</h3>

                  <div>
                    <div className="sidebar-icon">
                      {hideBox.country ? (
                        <i className="fa-regular fa-chevron-down medium"></i>
                      ) : (
                        <i className="fa-regular fa-chevron-right medium"></i>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  className={hideBox.country ? "flex flex-col gap-2" : "hide"}
                >
                  <div className="form-col-3">
                    <div>
                      <div className="form-control">
                        <label className="form-label">Origin Country</label>
                        <div className="form-input-box">
                          <DropDownBox
                            disabled={activeInputs}
                            options={countryOpts}
                            defaultValue="Select Country"
                            animateDropDownTitle
                            customSetter={formik.setFieldValue}
                            customFormikLabel="country"
                            customDropBoxStyles={{
                              border: "0.063rem solid #d2d6da",
                              borderRadius: "0.5rem",
                              color: "#262626",
                            }}
                            customTextStyle={{
                              color: "#454545",
                              opacity: "0.7",
                            }}
                            incomingValue={formik.values.country}
                            showSearchBar
                          />
                        </div>
                      </div>
                      {formik.errors.country && formik.touched.country ? (
                        <span className="err">{formik.errors.country}</span>
                      ) : null}
                    </div>
                    <div>
                      <div className="form-control">
                        <label className="form-label">Select Currency</label>
                        <div className="form-input-box">
                          <DropDownBox
                            disabled={activeInputs}
                            options={currencyOpts}
                            defaultValue="Select Currency"
                            animateDropDownTitle
                            customSetter={formik.setFieldValue}
                            customFormikLabel="currencyCode"
                            customDropBoxStyles={{
                              border: "0.063rem solid #d2d6da",
                              borderRadius: "0.5rem",
                              color: "#262626",
                            }}
                            customTextStyle={{
                              color: "#454545",
                              opacity: "0.7",
                            }}
                            incomingValue={formik.values.currencyCode}
                            showSearchBar
                          />
                        </div>
                      </div>
                      {formik.errors.currencyCode &&
                      formik.touched.currencyCode ? (
                        <span className="err">
                          {formik.errors.currencyCode}
                        </span>
                      ) : null}
                    </div>
                    <div>
                      <div className="form-control">
                        <label className="form-label">Currency Symbol</label>
                        <div className="form-input-box">
                          <InputField
                            type="text"
                            id="countrySymb"
                            name="countrySymb"
                            className="form-input"
                            value={formik.values.countrySymb}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            disabled
                          />
                        </div>
                      </div>
                      {formik.errors.countrySymb &&
                      formik.touched.countrySymb ? (
                        <span className="err">{formik.errors.countrySymb}</span>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>

              {/* stock detail */}
              <div className="general-detail">
                <div
                  className="detail-header"
                  onClick={() => {
                    setHideBox({
                      ...hideBox,
                      price: !hideBox.price,
                    });
                  }}
                >
                  <h3>Price & Stocks</h3>

                  <div>
                    <div className="sidebar-icon">
                      {hideBox.price ? (
                        <i className="fa-regular fa-chevron-down medium"></i>
                      ) : (
                        <i className="fa-regular fa-chevron-right medium"></i>
                      )}
                    </div>
                  </div>
                </div>
                <div className={hideBox.price ? "flex flex-col gap-2" : "hide"}>
                  <div className="form-col-3">
                    <div className="form-control">
                      <label className="form-label">Quantity</label>
                      <div className="form-input-box">
                        <InputField
                          type="text"
                          id="quantity"
                          name="quantity"
                          className="form-input"
                          value={formik.values.quantity}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          disabled={!activeInputs}
                          onInput={(e) => {
                            let inputValue = e.target.value;
                            e.target.value = e.target.value.replace(
                              /[^\d\.]/g,
                              ""
                            );
                            e.target.value = inputValue
                              .slice(0, 6)
                              .replace(/\D/g, "");
                          }}
                        />
                      </div>
                      {formik.errors.quantity && formik.touched.quantity ? (
                        <span className="err">{formik.errors.quantity}</span>
                      ) : null}
                    </div>

                    <div className="form-control">
                      <label className="form-label">Tax Type</label>
                      <div className="form-input-box">
                        <DropDownBox
                          options={[
                            { label: "GST", value: "GST" },
                            { label: "VAT", value: "VAT" },
                          ]}
                          defaultValue="Select Tax Type"
                          animateDropDownTitle
                          customSetter={formik.setFieldValue}
                          customFormikLabel="taxType"
                          customDropBoxStyles={{
                            border: "0.063rem solid #d2d6da",
                            borderRadius: "0.5rem",
                            color: "#262626",
                          }}
                          customTextStyle={{
                            color: "#454545",
                            opacity: "0.7",
                          }}
                          incomingValue={formik.values.taxType}
                          disabled={activeInputs}
                        />
                      </div>
                      {formik.errors.taxType && formik.touched.taxType ? (
                        <span className="err">{formik.errors.taxType}</span>
                      ) : null}
                    </div>

                    <div className="form-control">
                      <label className="form-label">Tax Value</label>
                      <div className="form-input-box">
                        <DropDownBox
                          options={[
                            { label: "15 %", value: 15 },
                            { label: "12 %", value: 12 },
                            { label: "10 %", value: 10 },
                            { label: "5 %", value: 5 },
                          ]}
                          defaultValue="Select Tax Value"
                          animateDropDownTitle
                          customSetter={formik.setFieldValue}
                          customFormikLabel="taxValue"
                          customDropBoxStyles={{
                            border: "0.063rem solid #d2d6da",
                            borderRadius: "0.5rem",
                            color: "#262626",
                          }}
                          customTextStyle={{
                            color: "#454545",
                            opacity: "0.7",
                          }}
                          incomingValue={formik.values.taxValue}
                          disabled={activeInputs}
                        />
                      </div>
                      {formik.errors.taxValue && formik.touched.taxValue ? (
                        <span className="err">{formik.errors.taxValue}</span>
                      ) : null}
                    </div>
                  </div>

                  <div className="form-col-3">
                    <div>
                      <div className="form-control">
                        <label className="form-label">MRP</label>
                        <div className="form-input-box">
                          <InputField
                            type="number"
                            id="mrp"
                            name="mrp"
                            className="form-input"
                            value={formik.values.mrp}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            // onInput={(e) => {
                            //   let inputValue = e.target.value;
                            //   e.target.value = e.target.value.replace(
                            //     /[^\d\.]/g,
                            //     ""
                            //   );
                            //   e.target.value = inputValue
                            //     .slice(0, 6)
                            //     .replace(/\D/g, "");
                            // }}
                            disabled={!activeInputs}
                            min={0}
                            max={99999}
                          />
                        </div>
                      </div>
                      {formik.errors.mrp && formik.touched.mrp ? (
                        <span className="err">{formik.errors.mrp}</span>
                      ) : null}
                    </div>

                    <div>
                      <div className="form-control">
                        <label className="form-label">Selling Price</label>
                        <div className="form-input-box">
                          <InputField
                            type="number"
                            id="price"
                            name="price"
                            className="form-input"
                            value={formik.values.price}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            // onInput={(e) => {
                            //   let inputValue = e.target.value;
                            //   e.target.value = e.target.value.replace(
                            //     /[^\d\.]/g,
                            //     ""
                            //   );
                            //   e.target.value = inputValue
                            //     .slice(0, 6)
                            //     .replace(/\D/g, "");
                            // }}
                            disabled={!activeInputs}
                            min={0}
                            max={99999}
                          />
                        </div>
                      </div>
                      {formik.errors.price && formik.touched.price ? (
                        <span className="err">{formik.errors.price}</span>
                      ) : null}
                    </div>

                    <div>
                      <div className="form-control">
                        <label className="flex gap-1 form-label">
                          Tax Included
                        </label>
                        <div className="form-input-box">
                          <DropDownBox
                            options={[
                              { label: "Yes", value: "true" },
                              { label: "No", value: "false" },
                            ]}
                            defaultValue="Is Tax Included"
                            animateDropDownTitle
                            customSetter={formik.setFieldValue}
                            customFormikLabel="pricetype"
                            customDropBoxStyles={{
                              border: "0.063rem solid #d2d6da",
                              borderRadius: "0.5rem",
                              color: "#262626",
                            }}
                            customTextStyle={{
                              color: "#454545",
                              opacity: "0.7",
                            }}
                            incomingValue={formik.values.pricetype}
                            disabled={activeInputs}
                          />
                        </div>
                      </div>
                      {formik.errors.pricetype && formik.touched.pricetype ? (
                        <span className="err">{formik.errors.pricetype}</span>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>

              {/* shipping detail */}
              <div className="general-detail">
                <div
                  className="detail-header"
                  onClick={() => {
                    setHideBox({
                      ...hideBox,
                      shipping: !hideBox.shipping,
                    });
                  }}
                >
                  <h3>Shipping</h3>

                  <div>
                    <div className="sidebar-icon">
                      {hideBox.shipping ? (
                        <i className="fa-regular fa-chevron-down medium"></i>
                      ) : (
                        <i className="fa-regular fa-chevron-right medium"></i>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  className={hideBox.shipping ? "flex flex-col gap-2" : "hide"}
                >
                  <div className="form-col-2">
                    <div>
                      <div className="form-control">
                        <label className="form-label">
                          Shipping Charge (in{" "}
                          {selectedCountry?.currency?.symbol || "Rs"})
                        </label>
                        <div className="form-input-box">
                          <input
                            type="number"
                            id="shipCharge"
                            name="shipCharge"
                            className="form-input"
                            value={formik.values.shipCharge}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            // onInput={(e) => {
                            //   e.target.value = e.target.value
                            //     .replace(/[^\d\.]/g, "")
                            //     .slice(0, 6);
                            // }}
                            disabled={!activeInputs}
                          />
                        </div>
                      </div>
                      {formik.errors.shipCharge && formik.touched.shipCharge ? (
                        <span className="err">{formik.errors.shipCharge}</span>
                      ) : null}
                    </div>
                    <div>
                      <div className="form-control">
                        <label className="form-label">
                          Packaging Charge (in{" "}
                          {selectedCountry?.currency?.symbol || "Rs"})
                        </label>
                        <div className="form-input-box">
                          <input
                            type="number"
                            id="packCharge"
                            name="packCharge"
                            className="form-input"
                            value={formik.values.packCharge}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            // onInput={(e) => {
                            //   e.target.value = e.target.value
                            //     .replace(/[^\d\.]/g, "")
                            //     .slice(0, 6);
                            // }}
                            disabled={!activeInputs}
                          />
                        </div>
                      </div>
                      {formik.errors.packCharge && formik.touched.packCharge ? (
                        <span className="err">{formik.errors.packCharge}</span>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>

              {/* specification */}
              <div className="general-detail">
                <div
                  className="detail-header"
                  onClick={() => {
                    setHideBox({
                      ...hideBox,
                      specification: !hideBox.specification,
                    });
                  }}
                >
                  <h3>Specification</h3>

                  <div>
                    <div className="sidebar-icon">
                      {hideBox.specification ? (
                        <i className="fa-regular fa-chevron-down medium"></i>
                      ) : (
                        <i className="fa-regular fa-chevron-right medium"></i>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  className={
                    hideBox.specification ? "flex flex-col gap-2" : "hide"
                  }
                >
                  <div>
                    {formik.values.specification?.map((spec, index) => (
                      <div
                        key={index}
                        className="flex gap-1"
                        style={{ alignItems: "end", marginBottom: "1rem" }}
                      >
                        <div className="form-col-2" style={{ width: "100%" }}>
                          <div className="form-control">
                            <label className="form-label">Key</label>
                            <div className="form-input-box">
                              <input
                                type="text"
                                id={`key-${index}`}
                                name={`specification[${index}].key`}
                                className="form-input"
                                value={spec.key}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                disabled={!activeInputs}
                                onInput={(e) =>
                                  (e.target.value = e.target.value.replace(
                                    /^./,
                                    (match) => match.toUpperCase()
                                  ))
                                }
                              />
                            </div>
                          </div>
                          <div className="form-control">
                            <label className="form-label">Value</label>
                            <div className="form-input-box">
                              <InputField
                                type="text"
                                id={`value-${index}`}
                                name={`specification[${index}].val`}
                                className="form-input"
                                value={spec.val}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                disabled={!activeInputs}
                                onInput={(e) =>
                                  (e.target.value = e.target.value.replace(
                                    /^./,
                                    (match) => match.toUpperCase()
                                  ))
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <button
                          type="button"
                          onClick={() => removeField(index)}
                          disabled={
                            formik.values.specification.length < 2 ||
                            !activeInputs
                          }
                        >
                          <i className="fa-light fa-trash"></i>
                        </button>
                      </div>
                    ))}
                    <div style={{ flexShrink: 0 }}>
                      <button
                        type="button"
                        className="btn-outlined small"
                        onClick={() => addMoreFields()}
                        disabled={!activeInputs}
                      >
                        Add More
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              {/* product Image */}
              <div className="general-detail">
                <div
                  className="detail-header"
                  onClick={() => {
                    setHideBox({
                      ...hideBox,
                      images: !hideBox.images,
                    });
                  }}
                >
                  <h3>Gallery</h3>

                  <div>
                    <div className="sidebar-icon">
                      {hideBox.images ? (
                        <i className="fa-regular fa-chevron-down medium"></i>
                      ) : (
                        <i className="fa-regular fa-chevron-right medium"></i>
                      )}
                    </div>
                  </div>
                </div>
                <div className={hideBox.images ? "images" : "hide"}>
                  <label htmlFor="images" className="upload-img flex-center">
                    <div className="text-center">
                      <i className="fa-regular fa-plus"></i>
                      <p>Add Images</p>
                    </div>
                    <input
                      type="file"
                      name="images"
                      id="images"
                      className="hide"
                      accept=".jpg,.png,.jpeg,.webp"
                      onChange={(event) => uploadImgs(event)}
                      onBlur={formik.handleBlur}
                      multiple
                      disabled={!activeInputs}
                    />
                  </label>

                  {productImgs.map((img, i) => (
                    <div
                      className="product-image"
                      onClick={() => setImageZoom(img)}
                      key={i}
                    >
                      <img
                        src={URL.createObjectURL(img)}
                        alt={`product-images-${i}`}
                      />
                      <button
                        className="flex-center"
                        type="button"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleRemoveImage(i);
                        }}
                        disabled={!activeInputs}
                      >
                        <i className="fa-regular fa-x small"></i>
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            </form>
          </div>
        </main>
      </div>
      {imageZoom && (
        <div id="confirm-box-wrapper">
          <div className="image-container flex-center">
            <div className="event-card show_delete-component-fadeIn-animation">
              <button className="closespan" onClick={() => setImageZoom(false)}>
                <i className="fa-regular fa-x small"></i>
              </button>
              <div className="preview-image">
                <img src={URL.createObjectURL(imageZoom)} alt="" />
              </div>
            </div>
          </div>
        </div>
      )}
    </ModuleLayout>
  );
};

export default ManageProduct;
