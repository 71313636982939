/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import CustomTable from "components/CustomTable";
import ConformationPopup from "components/ui/ConformationPopup";
import SearchField from "components/ui/SearchField";
import ModuleLayout from "layouts/ModuleLayout";
import { setPageDetails } from "modules/auth/redux/authSlice";
import { getBreadcrumbName } from "helper/helper";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { getProductList, deleteProduct } from "../redux/productApi";
import useDisableSkelaton from "hooks/useDisableSkelaton";
import useFetchList from "hooks/useFetchList";
import DropDownBox from "components/DropDownBox";
import { getCategoryList } from "modules/catalogue/categories/redux/categoryApi";
import { getBrandList } from "modules/catalogue/brands/redux/brandsApi";
import { clearProductDetail } from "../redux/productSlice";
import useSelectRows from "hooks/useSelectRows";

const Products = ({ setTitle }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    setTitle("Product Management");
  }, [setTitle]);

  useEffect(() => {
    dispatch(
      getCategoryList({
        page: "0",
        limit: "0",
        search: "",
        sort: "desc",
        filter: "product",
      })
    );
    dispatch(getBrandList({ page: "0", limit: "0", search: "", sort: "desc" }));
  }, [dispatch]);

  const { productList, metaData, productLoading } = useSelector(
    (state) => state.products,
    shallowEqual
  );
  const { categoryList } = useSelector(
    (state) => state.CatCategory,
    shallowEqual
  );

  const { brandList } = useSelector((state) => state.CatBrand, shallowEqual);

  const [active, setActive] = useState("product");
  const [mobileSidebar, setMobileSidebar] = useState(false);
  const [minimizedSidebar, setMinimizedSidebar] = useState(false);
  const [catList, setCatList] = useState([]);
  const [brdList, setBrdList] = useState([]);
  const [brand, setBrand] = useState("");
  const [category, setCategory] = useState("");

  const { contentLoading } = useDisableSkelaton(productLoading);

  useEffect(() => {
    dispatch(setPageDetails({ page: metaData?.page, search }));
  }, [metaData]);

  useEffect(() => {
    if (categoryList?.length > 0) {
      const list = categoryList.map((category) => ({
        label: category?.name,
        value: category?.id,
      }));
      setCatList(list);
    }
  }, [categoryList]);

  useEffect(() => {
    if (brandList?.length > 0) {
      const list = brandList.map((brand) => ({
        label: brand?.name,
        value: brand?.id,
      }));
      setBrdList(list);
    }
  }, [brandList]);

  const {
    content,
    alertBox,
    setAlertBox,
    selectAllRows,
    selectedRows,
    setSelectedRows,
    toggleSelectAll,
    toggleRowSelection,
    deleteFunction,
  } = useSelectRows(productList, "product_id");

  const {
    privileges,
    limit,
    setLimit,
    sort,
    setSort,
    search,
    setSearch,
    btnNo,
    setBtnNo,
    tableIndex,
    setTableIndex,
  } = useFetchList(getProductList, metaData, selectAllRows, setSelectedRows);

  const listContent = productList.map((item, i) => {
    const { id, num, name, discount, SKU, category, brand } = item;
    const similarItems = [
      { style: "name", val: name },
      { style: "", val: SKU },
      { style: "", val: `${discount}%` },
      { style: "", val: `${category?.name}` },
      { style: "", val: `${brand?.name}` },
    ];
    return [id, num, name, null, similarItems];
  });

  const TABLEDATA = {
    loadingState: contentLoading,
    skalatonDetails: { col: 4, rows: limit },
    listHeading: ["Product", "SKU", "Discount", "Category", "Brand"],
    listContent,
    selectedRows,
    selectAllRows,
    setSelectedRows,
    toggleSelectAll,
    toggleRowSelection,
    deleteFunction,
    sliceName: "products",
    search,
    PagAPI: getProductList,
    sort,
    limit,
    setLimit,
    btnNo,
    setBtnNo,
    tableIndex,
    setTableIndex,
    privileges,
    hasAccess: "productManagement",
    // navigateTo: update,
    navigateTo: (params) => {
      const user = productList.find((question) => question.id === params);
      const breadcrumbName = getBreadcrumbName(user);
      navigate(`/products/${params}`, {
        state: { slug: params, title: breadcrumbName },
      });
    },
    metaData,
  };

  useEffect(() => {
    dispatch(
      getProductList({
        page: 0,
        limit: limit,
        search: search,
        sort: sort,
        category: category,
        brand: brand,
      })
    );
    return () => dispatch(clearProductDetail());
  }, [dispatch, category, brand]);

  return (
    <ModuleLayout
      minimizedSidebar={minimizedSidebar}
      setMinimizedSidebar={setMinimizedSidebar}
      mobileSidebar={mobileSidebar}
      setMobileSidebar={setMobileSidebar}
      active={active}
      setActive={setActive}
      content={content}
      alertBox={alertBox}
    >
      <header className="list-header">
        <div className="list-title">
          <h2>Product Management</h2>
          <div className="flex-center gap-1">
            {Object.values(privileges["productManagement"]).includes(
              "write"
            ) && (
              <button
                className="btn-primary flex-center gap-1"
                onClick={() => navigate("/products/add-new")}
              >
                <i className="fa-solid fa-feather"></i> Add Product
              </button>
            )}

            {Object?.values(privileges["productManagement"])?.includes(
              "delete"
            ) && selectedRows.length !== 0 ? (
              <button
                className="btn-secondary flex-center gap-1"
                onClick={() => {
                  deleteFunction("all glossaries");
                }}
              >
                <i className="fa-light fa-trash"></i>
                {`Delete ${selectedRows.length}`}
              </button>
            ) : null}
          </div>
        </div>
      </header>

      <div className="list-container custom-box">
        <div className="tb_controls">
          <div className="flex-center gap-1">
            <DropDownBox
              options={[
                { label: "Newest", value: "desc" },
                { label: "Oldest", value: "asc" },
              ]}
              size="small"
              dropDownTitle="Sort"
              animateDropDownTitle
              customSetter={setSort}
              customDropBoxStyles={{
                opacity: 0.6,
                color: "#262626",
                padding: "0.6rem",
                border: "1px solid var(--color-neutral-92)",
              }}
            />

            <DropDownBox
              options={brdList}
              size="small"
              dropDownTitle="Brand"
              animateDropDownTitle
              customSetter={setBrand}
              customDropBoxStyles={{
                opacity: 0.6,
                color: "#262626",
                padding: "0.6rem",
                border: "1px solid var(--color-neutral-92)",
              }}
              resetButton
            />

            <DropDownBox
              showSearchBar
              options={JSON.parse(JSON.stringify(catList))}
              size="small"
              dropDownTitle="Category"
              animateDropDownTitle
              customSetter={setCategory}
              customDropBoxStyles={{
                color: "#262626",
                opacity: 0.6,
                fontSize: "0.9rem",
                // width: "15rem",
                padding: "0.6rem",
                border: "1px solid var(--color-neutral-92)",
              }}
              resetButton
            />
          </div>
          <SearchField value={search} setValue={setSearch} />
        </div>
        <CustomTable {...TABLEDATA} />
      </div>

      {alertBox && (
        <ConformationPopup
          customState={alertBox}
          customSetter={setAlertBox}
          api={deleteProduct}
          popUpData={content}
          customFunction={() => setSelectedRows(new Set())}
          toastMessage={"Product removed successfully"}
        />
      )}
    </ModuleLayout>
  );
};

export default Products;
