import React, { useRef, createRef, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ClipLoader } from "react-spinners";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setIsVerify } from "modules/auth/redux/authSlice";
import { send2faOtp, verify2faOtp } from "modules/auth/redux/authApi";

const Otp = ({ setTitle }) => {
  const { userEmail, authLoading, phone, isoCode } = useSelector(
    (state) => state.auth,
    shallowEqual
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [seconds, setSeconds] = useState(30);
  const [showMessage, setShowMessage] = useState(false);
  useEffect(() => {
    let timerInterval;
    if (seconds > 0) {
      timerInterval = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);
    } else {
      clearInterval(timerInterval);
      setShowMessage(true);
    }

    return () => {
      clearInterval(timerInterval);
    };
  }, [seconds]);
  useEffect(() => {
    setTitle("OTP");
    inputRefs.current[0].current.focus();
  }, [setTitle]);

  const initialValues = {
    Ip_1: "",
    Ip_2: "",
    Ip_3: "",
    Ip_4: "",
    Ip_5: "",
    Ip_6: "",
  };

  const otpSchema = Yup.object().shape({
    Ip_1: Yup.string()
      .required("Please Enter OTP")
      .matches(/^[0-9]+$/, "Invalid OTP (only numbers allowed)")
      .min(0, "Invalid OTP")
      .max(9, "Invalid OTP"),
    Ip_2: Yup.string()
      .required("Please Enter Otp")
      .matches(/^[0-9]+$/, "Invalid OTP (only numbers allowed)")
      .min(0, "Invalid OTP")
      .max(9, "Invalid OTP"),
    Ip_3: Yup.string()
      .required("Please Enter Otp")
      .matches(/^[0-9]+$/, "Invalid OTP (only numbers allowed)")
      .min(0, "Invalid OTP")
      .max(9, "Invalid OTP"),
    Ip_4: Yup.string()
      .required("Please Enter Otp")
      .matches(/^[0-9]+$/, "Invalid OTP (only numbers allowed)")
      .min(0, "Invalid OTP")
      .max(9, "Invalid OTP"),
    Ip_5: Yup.string()
      .required("Please Enter Otp")
      .matches(/^[0-9]+$/, "Invalid OTP (only numbers allowed)")
      .min(0, "Invalid OTP")
      .max(9, "Invalid OTP"),
    Ip_6: Yup.string()
      .required("Please Enter Otp")
      .matches(/^[0-9]+$/, "Invalid OTP (only numbers allowed)")
      .min(0, "Invalid OTP")
      .max(9, "Invalid OTP"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: otpSchema,
    onSubmit: async (values, {resetForm}) => {
      let OTP = `${values.Ip_1}${values.Ip_2}${values.Ip_3}${values.Ip_4}${values.Ip_5}${values.Ip_6}`;
      let data = {
        email: userEmail,
        otp: Number(OTP),
        phone: `${phone}`,
        iso_code: isoCode,
      };

      const response = await dispatch(verify2faOtp(data));
      resetForm()
      if (response.payload) {
        dispatch(setIsVerify(true));
        navigate("/");
      }
    },
  });
  const handleOTP = async () => {
    const res = await dispatch(send2faOtp({
      email: userEmail,
      iso_code: isoCode,
      phone: `${phone}`
    }));
    if (res?.payload) {
      setSeconds(30);
      setShowMessage(false);
    }

  };
  const inputRefs = useRef([
    createRef(),
    createRef(),
    createRef(),
    createRef(),
    createRef(),
    createRef(),
  ]);

  const focusNextInput = (index) => {
    if (inputRefs.current[index + 1]) {
      inputRefs.current[index + 1].current.focus();
    }
  };

  const focusPreviousInput = (index) => {
    if (inputRefs.current[index - 1]) {
      inputRefs.current[index - 1].current.focus();
    }
  };

  const handleInputChange = (index, value) => {
    formik.setFieldValue(`Ip_${index + 1}`, value);
    if (value !== "") {
      focusNextInput(index);
    }
  };

  const handleBackspace = (index, event) => {
    if (event.key === "Backspace" && formik.values[`Ip_${index + 1}`] === "") {
      focusPreviousInput(index);
    }
  };

  const handleInputKeyDown = (index, event) => {
    handleBackspace(index, event);
  };

  let shouldDisplayError = Object.keys(formik.errors).some(
    (fieldName) => formik.touched[fieldName] && formik.errors[fieldName]
  );

  return (
    <>
      <div className="sign_in-body">
        <div className="sign_in-content">
          <div className="s-content-body">
            <div className="s-form-container">
              <div className="form-header2">
                <div className="lock-icon">
                  <i className="fa-light fa-lock-keyhole"></i>
                </div>
                <h3 className="f-heading2">2-Step Verification</h3>
              </div>
              <div className="s-form-body">
                <form
                  noValidate="novalidate"
                  onSubmit={formik.handleSubmit}
                  autoComplete="off"
                >
                  <div className="register_details">
                    <div className="rg_One">
                      <div className="otp_Box2">
                        <div className="otp_Input_Box">
                          <span className="Input_Span">
                            <input
                              type="text"
                              value={formik.values.Ip_1}
                              onChange={(e) =>
                                handleInputChange(
                                  0,
                                  e.target.value.trim()
                                )
                              }
                              onInput={(e) => {
                                e.target.value = e.target.value.replace(
                                  /[^0-9]/g,
                                  ""
                                );
                              }}
                              onPaste={(e) => {
                                e.preventDefault();
                              }}
                              onCopy={(e) => {
                                e.preventDefault();
                              }}
                              onBlur={formik.handleBlur}
                              name={`Ip_1`}
                              onKeyDown={(e) => handleInputKeyDown(0, e)}
                              ref={inputRefs.current[0]}
                              min={0}
                              max={9}
                              maxLength={1}
                            />
                          </span>
                          <p>-</p>
                          <span className="Input_Span">
                            <input
                              type="text"
                              value={formik.values.Ip_2}
                              onChange={(e) =>
                                handleInputChange(
                                  1,
                                  e.target.value.trim()
                                )
                              }
                              onInput={(e) => {
                                e.target.value = e.target.value.replace(
                                  /[^0-9]/g,
                                  ""
                                );
                              }}
                              onPaste={(e) => {
                                e.preventDefault();
                              }}
                              onCopy={(e) => {
                                e.preventDefault();
                              }}
                              onBlur={formik.handleBlur}
                              name={`Ip_2`}
                              onKeyDown={(e) => handleInputKeyDown(1, e)}
                              ref={inputRefs.current[1]}
                              min={0}
                              max={9}
                              maxLength={1}
                            />
                          </span>
                          <p>-</p>
                          <span className="Input_Span">
                            <input
                              type="text"
                              value={formik.values.Ip_3}
                              onChange={(e) =>
                                handleInputChange(
                                  2,
                                  e.target.value.trim()
                                )
                              }
                              onInput={(e) => {
                                e.target.value = e.target.value.replace(
                                  /[^0-9]/g,
                                  ""
                                );
                              }}
                              onPaste={(e) => {
                                e.preventDefault();
                              }}
                              onCopy={(e) => {
                                e.preventDefault();
                              }}
                              onBlur={formik.handleBlur}
                              name={`Ip_3`}
                              onKeyDown={(e) => handleInputKeyDown(2, e)}
                              ref={inputRefs.current[2]}
                              min={0}
                              max={9}
                              maxLength={1}
                            />
                          </span>
                          <p>-</p>
                          <span className="Input_Span">
                            <input
                              type="text"
                              value={formik.values.Ip_4}
                              onChange={(e) =>
                                handleInputChange(
                                  3,
                                  e.target.value.trim()
                                )
                              }
                              onInput={(e) => {
                                e.target.value = e.target.value.replace(
                                  /[^0-9]/g,
                                  ""
                                );
                              }}
                              onPaste={(e) => {
                                e.preventDefault();
                              }}
                              onCopy={(e) => {
                                e.preventDefault();
                              }}
                              onBlur={formik.handleBlur}
                              name={`Ip_4`}
                              onKeyDown={(e) => handleInputKeyDown(3, e)}
                              ref={inputRefs.current[3]}
                              min={0}
                              max={9}
                              maxLength={1}
                            />
                          </span>
                          <p>-</p>
                          <span className="Input_Span">
                            <input
                              type="text"
                              value={formik.values.Ip_5}
                              onChange={(e) =>
                                handleInputChange(
                                  4,
                                  e.target.value.trim()
                                )
                              }
                              onInput={(e) => {
                                e.target.value = e.target.value.replace(
                                  /[^0-9]/g,
                                  ""
                                );
                              }}
                              onPaste={(e) => {
                                e.preventDefault();
                              }}
                              onCopy={(e) => {
                                e.preventDefault();
                              }}
                              onBlur={formik.handleBlur}
                              name={`Ip_5`}
                              onKeyDown={(e) => handleInputKeyDown(4, e)}
                              ref={inputRefs.current[4]}
                              min={0}
                              max={9}
                              maxLength={1}
                            />
                          </span>
                          <p>-</p>
                          <span className="Input_Span">
                            <input
                              type="text"
                              value={formik.values.Ip_6}
                              onChange={(e) =>
                                handleInputChange(
                                  5,
                                  e.target.value.trim()
                                )
                              }
                              onInput={(e) => {
                                e.target.value = e.target.value.replace(
                                  /[^0-9]/g,
                                  ""
                                );
                              }}
                              onPaste={(e) => {
                                e.preventDefault();
                              }}
                              onCopy={(e) => {
                                e.preventDefault();
                              }}
                              onBlur={formik.handleBlur}
                              name={`Ip_6`}
                              onKeyDown={(e) => handleInputKeyDown(5, e)}
                              ref={inputRefs.current[5]}
                              min={0}
                              max={9}
                              maxLength={1}
                            />
                          </span>
                        </div>
                        <p className="err text-center">
                          {shouldDisplayError && "Enter OTP properly"}
                        </p>
                      </div>
                    </div>
                    <div className="mt-1 flex-center">
                      {authLoading ? (
                        <span className="btn-primary">
                          <ClipLoader
                            // color={color}
                            loading={true}
                            // cssOverride={override}
                            size={20}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                          />
                        </span>
                      ) : (
                        <button className="btn-primary" type="submit">
                          Verify OTP
                        </button>
                      )}

                    </div>
                  </div>
                </form>
                <div className="sf-footer mt-1">
                  Haven't received it?{" "}
                  {!showMessage ? (
                    <span
                      style={{ cursor: "not-allowed" }}
                      className="navigate"
                    >
                      {seconds}
                    </span>
                  ) : (
                    <span className="navigate" onClick={handleOTP}>
                      Resend a new code
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Otp;
