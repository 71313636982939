import { createSlice } from "@reduxjs/toolkit";
import { getIPaddressAsync, login, logout, logoutAllDevices } from "./authApi"

const authSlice = createSlice({
  name: "auth",
  initialState: {
    authLoading: false,
    token: null,
    maintenance: false,
    maintenanceData: {},
    role: null,
    is_2fa: false,
    is_verify: false,
    privileges: {},
    userEmail: null,
    phone: "",
    latitude: "",
    longitude: "",
    ip: "",
    pageDetails: {},
    isoCode: ""
  },
  reducers: {
    isAuthToggle: (state, { payload }) => {
      state.authLoading = payload;
    },
    maintenanceUpdate: (state, { payload }) =>
    (state = {
      ...state,
      maintenance: payload?.status,
      maintenanceData: payload,
    }),
    setUserEmail: (state, { payload }) =>
      (state = { ...state, userEmail: payload?.userEmail }),
    setUserPhone: (state, { payload }) =>
      (state = { ...state, userPhone: payload?.userPhone }),
    setIsVerify: (state, { payload }) =>
      (state = { ...state, is_verify: payload }),
    setLongitude: (state, { payload }) =>
      (state = { ...state, longitude: payload }),
    setLatitude: (state, { payload }) =>
      (state = { ...state, latitude: payload }),
    setIp: (state, action) =>
    (state = {
      ...state,
      ip: action.payload,
    }),
    setPageDetails: (state, { payload }) => {
      state.pageDetails = payload;
    },
    defaultLogout: (state) => {
      state.authLoading = false;
      state.token = null;
      state.role = null;
      state.is_2fa = false;
      state.is_verify = false;
      state.privileges = {};
    },
  },
  extraReducers: (builder) => {
    // Login reducers-------------------------
    builder.addCase(login.pending, (state, action) => {
      state.authLoading = true;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      state.authLoading = false;
      state.token = action.payload?.data?.token;
      state.role = action.payload?.data?.data?.role;
      state.is_2fa = action.payload?.data?.data?.is_2FA;
      // state.is_verify = action.payload?.data??.is_verified;
      state.privileges = action.payload?.data?.data?.privileges;
      state.userEmail = action.payload?.data?.data?.email;
      state.phone = action.payload?.data?.data?.phone;
      state.isoCode = action.payload?.data?.data?.isoCode;
    });
    builder.addCase(login.rejected, (state, action) => {
      state.authLoading = false;
    });

    // Logout reducers-------------------------
    builder.addCase(logout.pending, (state, action) => {
      state.authLoading = true;
    });
    builder.addCase(logout.fulfilled, (state, action) => {
      state.authLoading = false;
      state.token = null;
      state.role = null;
      state.is_2fa = false;
      state.is_verify = false;
      state.privileges = {};
      state.userEmail = null;
      state.phone = "";
      state.pageDetails = {};
    });
    builder.addCase(logout.rejected, (state, action) => {
      state.authLoading = false;
    });

    // logout all device--------------------------------
    builder.addCase(logoutAllDevices.pending, (state, action) => {
      state.authLoading = true;
    });
    builder.addCase(logoutAllDevices.fulfilled, (state, action) => {
      state.authLoading = false;
      state.token = null;
      state.role = null;
      state.is_2fa = false;
      state.is_verify = false;
      state.privileges = {};
      state.userEmail = null;
      state.phone = "";
      state.pageDetails = {};
    });
    builder.addCase(logoutAllDevices.rejected, (state, action) => {
      state.authLoading = false;
    });

    // IP-Address reducers-------------------------
    builder.addCase(getIPaddressAsync.pending, (state, action) => {
      state.ip = "";
      state.latitude = "";
      state.longitude = "";
    });
    builder.addCase(getIPaddressAsync.fulfilled, (state, action) => {
      state.ip = action.payload.ip;
      state.latitude = action.payload.latitude;
      state.longitude = action.payload.longitude;
    });
    builder.addCase(getIPaddressAsync.rejected, (state, action) => {
      state.ip = "";
      state.latitude = "";
      state.longitude = "";
    });
  },
});

export const {
  isAuthToggle,
  setUserEmail,
  setIsVerify,
  setLatitude,
  setLongitude,
  setIp,
  defaultLogout,
  setUserPhone,
  setPageDetails,
} = authSlice.actions;
export default authSlice.reducer;
