/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useSelector, shallowEqual } from "react-redux";
import Maintenance from "../modules/common/Maintenance";
import { CommonRoutes } from "./role/CommonRoutes";
import { AuthRoutes } from "./role/AuthRoutes";
import { MainRoutes } from "./role/MainRoutes";
import Otp from "modules/common/Otp";

const RootRoutes = () => {
  const [title, setTitle] = useState("PEAK72 | Admin");
  const [maintenanceStatus, setMaintenanceStatus] = useState(false);
  document.title = title;
  const { maintenance, role, token, privileges, is_2fa, is_verify } = useSelector((state) => state.auth, shallowEqual);
  const module = role === 1 || role === 2 ? MainRoutes : [];
  const renderModules = () => {
    return module?.map((obj, index) => {
      if (
        obj.position === "head" ||
        obj.position === "parent" ||
        obj.position === "child"
      ) {
        const hasPrivilege = privileges.hasOwnProperty(obj.privilegeTag);
        const hasReadPrivilege =
          hasPrivilege &&
          Object.values(privileges[obj.privilegeTag]).includes("read");
        const hasReadWritePrivilege =
          hasPrivilege &&
          Object.values(privileges[obj.privilegeTag]).includes(
            "read" && "write"
          );

        if (obj?.children) {
          return (
            <Route
              key={index}
              path={obj.path}
              element={
                token &&
                (obj.position === "head" ? (
                  <obj.component setTitle={setTitle} />
                ) : hasReadPrivilege ? (
                  obj.position === "parent" ? (
                    hasReadPrivilege ? (
                      <obj.component setTitle={setTitle} />
                    ) : (
                      <Navigate to="/" />
                    )
                  ) : hasReadWritePrivilege ? (
                    <obj.component setTitle={setTitle} />
                  ) : (
                    <Navigate to="/" />
                  )
                ) : (
                  <Navigate to="/" />
                ))
              }
            >
              {obj.children.map((childObj, i) => {
                return (
                  <Route
                    key={i}
                    path={childObj.path}
                    element={<childObj.component />}
                  />
                );
              })}
            </Route>
          );
        } else {
          return (
            <Route
              key={index}
              path={obj.path}
              element={
                token &&
                (obj.position === "head" ? (
                  <obj.component setTitle={setTitle} />
                ) : hasPrivilege ? (
                  obj.position === "parent" ? (
                    hasReadPrivilege ? (
                      <obj.component setTitle={setTitle} />
                    ) : (
                      <Navigate to="/" />
                    )
                  ) : hasReadWritePrivilege ? (
                    <obj.component setTitle={setTitle} />
                  ) : (
                    <Navigate to="/" />
                  )
                ) : (
                  <Navigate to="/" />
                ))
              }
            />
          );
        }
      }
    });
  };

  useEffect(() => {
    if (maintenance) {
      setMaintenanceStatus(maintenance);
    }
  }, [maintenance]);

  return (
    <>
      {maintenanceStatus ? (
        <Routes>
          <Route path="*" element={<Maintenance setTitle={setTitle} />} />
        </Routes>
      )
        : token && is_2fa !== is_verify ? (
          <Routes>
            <Route path="*" element={<Navigate to="/verify" />} />
            <Route path="/verify" element={<Otp setTitle={setTitle} />} />
          </Routes>
        ) : (
          <Routes>
            {/* ----------------------------------COMMON ROUTES----------------------------------------- */}

            {CommonRoutes?.map((obj, index) => {
              return (
                <Route
                  key={index}
                  path={obj.path}
                  element={<obj.component setTitle={setTitle} />}
                />
              );
            })}

            {/* ----------------------------------ROUTES ACCESSIBLE JUST FOR AUTH ----------------------------------------- */}

            {!token && <Route path="/" element={<Navigate to="/login" />} />}

            {AuthRoutes?.map((obj, index) => {
              return (
                <Route
                  key={index}
                  path={obj.path}
                  element={
                    !token ? (
                      <obj.component setTitle={setTitle} />
                    ) : (
                      <Navigate to={"/"} />
                    )
                  }
                />
              );
            })}

            {/* ----------------------------------Role Based Routing----------------------------------------- */}

            {token && renderModules()}
          </Routes>
        )}
    </>
  );
};

export default RootRoutes;
