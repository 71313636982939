// import Pagination from "components/Pagination";
import TableSkelaton from "components/skelaton/TableSkelaton";
import NotFound from "./ui/NotFound";

const Table = (props) => {
  const { loadingState, skalatonDetails, listHeading, listContent } = props;

  return (
    <>
      {loadingState ? (
        <TableSkelaton col={skalatonDetails.col} row={skalatonDetails.rows} />
      ) : listContent?.length > 0 ? (
        <>
          <div className="table_container">
            <div className="table_content">
              <table className="main_table text-left">
                <thead className="tb_head">
                  <tr className="tb_row">
                    {listHeading?.map((tbh, i) => (
                      <th className="tbh_name neutral" key={i}>
                        {tbh}
                      </th>
                    ))}
                  </tr>
                </thead>

                <tbody className="tb_body">
                  {listContent.map((content, index) => {
                    return (
                      <tr key={index} className="tb_row">
                        {content?.[1].map((simVal, i) => (
                          <td className="tb_data" key={i}>
                            <div className={`${simVal.style}`}>
                              {simVal.image && (
                                <img src={simVal.image} alt="logo" />
                              )}
                              {simVal.val}
                            </div>
                          </td>
                        ))}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>

          {/* {metaData?.total >= 5 && (
            <Pagination
              sliceName={sliceName}
              api={PagAPI}
              sort={sort}
              limit={limit}
              setLimit={setLimit}
              tableIndex={tableIndex}
              setTableIndex={setTableIndex}
              btnNo={btnNo}
              setBtnNo={setBtnNo}
            />
          )} */}
        </>
      ) : (
        <NotFound />
      )}
    </>
  );
};

export default Table;
