import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { AddNumField, options } from "helper/helper";
import { isQuestionLoading } from "./questionSlice";

// Levels list thunk ----------
export const getLevelList = createAsyncThunk(
  "catalogue/get-level-list",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/catalogue/module/level-list?page=${data.page}&limit=${data.limit}&sort=${data?.sort ? data?.sort : "desc"}&search=${data.search}`,
      });

      if (response.status === 200) {
        if (response?.data?.data?.length > 0) {
          const res = await AddNumField(
            response?.data,
            data?.page * data?.limit
          );

          return fulfillWithValue(res);
        } else {
          const modPage = Math.max(data.page - 1, 0);
          const responseAgain = await axios({
            method: "POST",
            url: `${process.env.REACT_APP_AUTH_URL}/admin/catalogue/module/level-list/?page=${data.page}&limit=${data.limit}&sort=${data?.sort ? data?.sort : "desc"}&search=${data.search}`,
          });
          if (responseAgain.status === 200) {
            const resAgain = await AddNumField(
              responseAgain?.data,
              modPage * data?.limit
            );
            return fulfillWithValue(resAgain);
          } else {
            toast.error(responseAgain?.data?.message, options);
            return rejectWithValue();
          }
        }
      } else {
        // toast.error(response?.data?.message, options);
        return rejectWithValue();
      }
    } catch (err) {
      return rejectWithValue();
    }
  }
);

export const getQuestionList = createAsyncThunk(
  "catalogue/get-question-list",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      dispatch(isQuestionLoading(true));
      const response = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/catalogue/question/question-list?page=${data.page}&limit=${data.limit}&sort=${data?.sort ? data?.sort : "desc"}&search=${data.search ? data.search : ""}&filter[type]=${data?.type ? data.type : ""}&filter[level]=${data?.level ? data.level : ""}&filter[survey]=${data?.survey ? data.survey : ""}`,
      });

      if (response.status === 200) {
        if (response?.data?.data?.length > 0) {
          const res = await AddNumField(
            response?.data?.data?.[0],
            data?.page * data?.limit
          );

          dispatch(isQuestionLoading(false));
          return fulfillWithValue(res);
        } else {
          const modPage = Math.max(data.page - 1, 0);
          const responseAgain = await axios({
            method: "GET",
            url: `${process.env.REACT_APP_AUTH_URL}/admin/catalogue/question/question-list?page=${data.page}&limit=${data.limit}&sort=${data?.sort ? data?.sort : "desc"}&search=${data?.search ? data.search : ""}&filter[type]=${data?.type ? data.type : ""}&filter[level]=${data?.level ? data.level : ""}&filter[survey]=${data?.survey ? data.survey : ""}`,
          });
          if (responseAgain.status === 200) {
            const resAgain = await AddNumField(
              responseAgain?.data?.data?.[0],
              modPage * data?.limit
            );
            dispatch(isQuestionLoading(false));
            return fulfillWithValue(resAgain);
          } else {
            dispatch(isQuestionLoading(false));
            toast.error(responseAgain?.data?.message, options);
            return rejectWithValue();
          }
        }
      } else {
        dispatch(isQuestionLoading(false));
        // toast.error(response?.data?.message, options);
        return rejectWithValue();
      }
    } catch (err) {
      return rejectWithValue();
    }
  }
);

export const getAllQues = createAsyncThunk(
  "catalogue/get-all-questions",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      dispatch(isQuestionLoading(true));
      const response = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/catalogue/question/question-list?page=${data.page}&limit=${data.limit}&sort=${data?.sort ? data?.sort : "desc"}&search=${data.search ? data.search : ""}&filter[type]=${data?.type ? data.type : ""}&filter[level]=${data?.level ? data.level : ""}`,
      });

      if (response.status === 200) {
        if (response?.data?.data?.length > 0) {
          const res = await AddNumField(
            response?.data?.data?.[0],
            data?.page * data?.limit
          );

          dispatch(isQuestionLoading(false));
          return fulfillWithValue(res);
        } else {
          const modPage = Math.max(data.page - 1, 0);
          const responseAgain = await axios({
            method: "GET",
            url: `${process.env.REACT_APP_AUTH_URL}/admin/catalogue/question/question-list?page=${data.page}&limit=${data.limit}&sort=${data?.sort ? data?.sort : "desc"}&search=${data?.search ? data.search : ""}&filter[type]=${data?.type ? data.type : ""}&filter[level]=${data?.level ? data.level : ""}`,
          });
          if (responseAgain.status === 200) {
            const resAgain = await AddNumField(
              responseAgain?.data?.data?.[0],
              modPage * data?.limit
            );
            dispatch(isQuestionLoading(false));
            return fulfillWithValue(resAgain);
          } else {
            dispatch(isQuestionLoading(false));
            toast.error(responseAgain?.data?.message, options);
            return rejectWithValue();
          }
        }
      } else {
        dispatch(isQuestionLoading(false));
        // toast.error(response?.data?.message, options);
        return rejectWithValue();
      }
    } catch (error) {
      return rejectWithValue();
    }
  }
);

export const addQuestion = createAsyncThunk(
  "catalogue/add-question",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      dispatch(isQuestionLoading(true));
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/catalogue/question/add-question`,
        data: data,
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.status === 200) {
        dispatch(isQuestionLoading(false));
        dispatch(
          getQuestionList({
            page: 0,
            limit: 5,
            search: "",
          })
        );
        toast.success(response?.data?.message, options);
        return response?.data?.status;
      } else {
        dispatch(isQuestionLoading(false));
        toast.error(response?.data?.message, options);
      }
    } catch (err) {
      dispatch(isQuestionLoading(false));
      toast.error(err, options);
    }
  }
);

export const addBulkQuestionAsync = createAsyncThunk(
  "catalogue/add-bulk-question",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    dispatch(isQuestionLoading(true));
    try {
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/catalogue/question/question-upload-bulk`,
        headers: {
          "Content-Type": "multipart/form-data",
        },

        data: data,
      });

      if (response.status === 200) {
        dispatch(isQuestionLoading(false));
        dispatch(
          getQuestionList({
            page: 0,
            limit: 6,
            search: "",
          })
        );
        toast.success(response?.data?.message, options);
        return response?.data?.status;
      } else {
        dispatch(isQuestionLoading(false));
        toast.error(response?.data?.message, options);
      }
    } catch (err) {
      dispatch(isQuestionLoading(false));
      toast.error(err, options);
    }
  }
);

export const getQuestionDetail = createAsyncThunk(
  "catalogue/get-question-detail",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      const response = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/catalogue/question/question-detail/${data?.id}`,
      });
      if (response.status === 200) {
        return fulfillWithValue(response?.data);
      } else {
        toast.error(response?.data?.message, options);
        return rejectWithValue();
      }
    } catch (err) {
      toast.error(err, options);
      return rejectWithValue();
    }
  }
);

export const updateQuestionDetail = createAsyncThunk(
  "catalogue/update-question",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      dispatch(isQuestionLoading(true));
      const response = await axios({
        method: "PUT",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/catalogue/question/update-question/${data?.id}`,
        data: data?.send,
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.status === 200) {
        dispatch(isQuestionLoading(false));
        dispatch(
          getQuestionList({
            page: 0,
            limit: 6,
            search: "",
          })
        );
        toast.success(response?.data?.message, options);
        return response?.data;
      } else {
        dispatch(isQuestionLoading(false));
        toast.error(response?.data?.message, options);
      }
    } catch (err) {
      dispatch(isQuestionLoading(false));
      toast.error(err, options);
    }
  }
);

export const deleteQuestion = createAsyncThunk(
  "catalogue/delete-question",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      dispatch(isQuestionLoading(true));
      const response = await axios({
        method: "DELETE",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/catalogue/question/delete-question`,
        data: data?.send,
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.status === 200) {
        dispatch(isQuestionLoading(false));
        dispatch(
          getQuestionList({
            page: data?.page,
            limit: data.limit,
            search: data.search,
            sort: data.sort,
          })
        );
        toast.success(response?.data?.message, options);
        return response?.data?.status;
      } else {
        dispatch(isQuestionLoading(false));
        toast.error(response?.data?.message, options);
      }
    } catch (err) {
      dispatch(isQuestionLoading(false));
      toast.error(err, options);
    }
  }
);
