import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Maintenance = ({ setTitle }) => {
  useEffect(() => {
    setTitle("Maintenance");
  }, [setTitle]);

  const navigate = useNavigate();

  return (
    <div className="container">
      <div className="app-container">
        <main className="maintenance-container flex-center">
          <figure className="maintenance-bg">
            <img
              src={require("../../assets/icons/maintenance-bg.svg").default}
              alt="maintenance"
            />
          </figure>
          <header className="maintenance-text">
            <h1 className="maintenance-title">Maintenance Mode!!!</h1>
            <h2 className="maintenance-subtitle">
              Website is Under Construction. Check back later!
            </h2>
          </header>

          <button onClick={() => navigate("/")} className="btn-primary">
            Go Back to Home
          </button>
        </main>
      </div>
    </div>
  );
};

export default Maintenance;
