import { createSlice } from "@reduxjs/toolkit";
import { getAdminList, getAdminDetail } from "./adminApi";

const adminSlice = createSlice({
  name: "admin",
  initialState: {
    adminLoading: false,
    adminList: [],
    metaData: [],
    adminDetail: {},
  },
  reducers: {
    isAdminLoading: (state, action) =>
    (state = {
      ...state,
      adminLoading: action.payload,
    }),
  },
  extraReducers: (builder) => {
    // getAdminList reducers-------------------------
    builder.addCase(getAdminList.pending, (state, action) => {
      state.adminLoading = true;
    });
    builder.addCase(getAdminList.fulfilled, (state, action) => {

      state.adminLoading = false;
      state.adminList = action?.payload?.data;
      state.metaData = action?.payload?.metadata[0];
    });
    builder.addCase(getAdminList.rejected, (state, action) => {
      state.adminLoading = false;
      state.adminList = [];
    });
    // getAdminDetail reducers-------------------------
    builder.addCase(getAdminDetail.pending, (state, action) => {
      state.adminLoading = true;
      state.adminDetail = {}
    });
    builder.addCase(getAdminDetail.fulfilled, (state, action) => {
      state.adminLoading = false;
      state.adminDetail = action.payload.data;
    });
    builder.addCase(getAdminDetail.rejected, (state, action) => {
      state.adminLoading = false;
      state.adminDetail = {}
    });
  },
});

export const { isAdminLoading } = adminSlice.actions;
export default adminSlice.reducer;
