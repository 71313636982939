import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { AddNumField, options } from "helper/helper";
import { toast } from "react-toastify";
import { isProductLoading } from "./productSlice";

export const getProductList = createAsyncThunk(
  "product/get-product-list",
  async (
    { page, limit, search, sort = "desc", category = "", brand = "" },
    { rejectWithValue, fulfillWithValue, dispatch }
  ) => {
    try {
      const response = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/product/product-list/?page=${page}&limit=${limit}&sort=${sort}&search=${search}&filter[categoryId]=${category}&filter[brandId]=${brand}`,
      });
      if (response.status === 200) {
        if (response?.data?.data?.length > 0) {
          const res = await AddNumField(
            response?.data?.data?.[0],
            page * limit
          );
          return fulfillWithValue(res);
        } else {
          const modPage = Math.max(page - 1, 0);
          const responseAgain = await axios({
            method: "GET",
            url: `${process.env.REACT_APP_AUTH_URL}/admin/product/product-list/?page=${page}&limit=${limit}&sort=${sort}&search=${search}&filter[categoryId]=${category}&filter[brandId]=${brand}`,
          });
          if (responseAgain.status === 200) {
            const resAgain = await AddNumField(
              responseAgain?.data?.data?.[0],
              modPage * limit
            );
            return fulfillWithValue(resAgain);
          } else {
            toast.error(responseAgain?.data?.message, options);
            return rejectWithValue();
          }
        }
      } else {
        // toast.error(response?.data?.message, options);
        return rejectWithValue();
      }
    } catch (err) {
      return rejectWithValue();
    }
  }
);

export const addProduct = createAsyncThunk(
  "product/add-product",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      dispatch(isProductLoading(true));
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/product/add-product`,
        data: data,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.status === 200) {
        dispatch(isProductLoading(false));
        toast.success(response?.data?.message, options);
        return response?.data?.status;
      } else {
        dispatch(isProductLoading(false));
        toast.error(response?.data?.message, options);
      }
    } catch (err) {
      dispatch(isProductLoading(false));
      toast.error(err, options);
    }
  }
);

export const getProductDetail = createAsyncThunk(
  "product/get-product-detail",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      const response = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/product/product-detail/${data?.id}`,
      });
      if (response.status === 200) {
        return fulfillWithValue(response?.data);
      } else {
        toast.error(response?.data?.message, options);
        return rejectWithValue();
      }
    } catch (err) {
      toast.error(err, options);
      return rejectWithValue();
    }
  }
);

export const updateProductDetail = createAsyncThunk(
  "product/update-product",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      dispatch(isProductLoading(true));
      const response = await axios({
        method: "PUT",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/product/update-product/${data?.id}`,
        data: data?.send,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (response.status === 200) {
        dispatch(isProductLoading(false));
        toast.success(response?.data?.message, options);

        return response?.data?.status;
      } else {
        dispatch(isProductLoading(false));
        toast.error(response?.data?.message, options);
      }
    } catch (err) {
      dispatch(isProductLoading(false));
      toast.error(err, options);
    }
  }
);

export const deleteProduct = createAsyncThunk(
  "product/delete-product",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      dispatch(isProductLoading(true));
      const response = await axios({
        method: "DELETE",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/product/delete-product`,
        data: data?.send,
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.status === 200) {
        dispatch(isProductLoading(false));
        dispatch(
          getProductList({
            page: data?.page,
            limit: data.limit,
            search: data.search,
            sort: data.sort,
          })
        );
        // toast.success(response?.data?.message, options);
        return response?.data?.status;
      } else {
        dispatch(isProductLoading(false));
        // toast.error(response?.data?.message, options);
      }
    } catch (err) {
      dispatch(isProductLoading(false));
      toast.error(err, options);
    }
  }
);
