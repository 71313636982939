import { createSlice } from "@reduxjs/toolkit";
import { getDashboardDetail, getLeaderboardList } from "./dashboardApi";

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    dashboardLoading: false,
    dashboardDetail: {},
    leaderboardList: []
  },
  reducers: {
    isDashboardLoading: (state, { payload }) => {
      (state = {
        ...state,
        dashboardLoading: payload,
      })
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getDashboardDetail.pending, (state, action) => {
      state.dashboardLoading = true;
    });
    builder.addCase(getDashboardDetail.fulfilled, (state, action) => {
      state.dashboardLoading = false;
      state.dashboardDetail = action?.payload?.data;
    });
    builder.addCase(getDashboardDetail.rejected, (state, action) => {
      state.dashboardLoading = false;
    });

    builder.addCase(getLeaderboardList.pending, (state, action) => {
      state.dashboardLoading = true;
    });
    builder.addCase(getLeaderboardList.fulfilled, (state, action) => {
      state.dashboardLoading = false;
      state.leaderboardList = action?.payload?.data;
    });
    builder.addCase(getLeaderboardList.rejected, (state, action) => {
      state.dashboardLoading = false;
    });
  }
});

export const { isDashboardLoading } = dashboardSlice.actions

export default dashboardSlice.reducer;
