import { createSlice } from "@reduxjs/toolkit";
import { getAddressDetail, getAddressList } from "./addressApi";

const addressSlice = createSlice({
    name: "address",
    initialState: {
        addressLoading: false,
        addressList: [],
        addressDetail: {}
    },
    reducers: {
        isAddressLoading: (state, action) =>
        (state = {
            ...state,
            addressLoading: action.payload,
        }),
    },
    extraReducers: (builder) => {
        builder.addCase(getAddressList.pending, (state, action) => {
            state.addressLoading = true;
        });
        builder.addCase(getAddressList.fulfilled, (state, action) => {

            state.addressLoading = false;
            state.addressList = action.payload?.data;
        });
        builder.addCase(getAddressList.rejected, (state, action) => {
            state.addressLoading = false;
            state.addressList = [];
        });

        builder.addCase(getAddressDetail.pending, (state, action) => {
            state.addressLoading = true;
            state.addressDetail = {}
        });
        builder.addCase(getAddressDetail.fulfilled, (state, action) => {

            state.addressLoading = false;
            state.addressDetail = action.payload?.data;
        });
        builder.addCase(getAddressDetail.rejected, (state, action) => {
            state.addressLoading = false;
            state.addressDetail = {}
        });
    }
})

export const { isAddressLoading } = addressSlice.actions;
export default addressSlice.reducer;