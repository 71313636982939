import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { AddNumField, options } from "helper/helper";
import { isSkillLoading } from "./skillSlice";

export const getSkillList = createAsyncThunk(
  "catalogue/get-skill-list",
  async (data, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/catalogue/skill/skill-list?page=${data.page}&limit=${data.limit}&sort=${data?.sort ? data?.sort : "desc"}&search=${data.search}&filter[categoryId]=${data?.filter ? data?.filter : ""}`,
      });

      if (response.status === 200) {
        if (response?.data?.data?.length > 0) {
          const res = await AddNumField(
            response?.data,
            data?.page * data?.limit
          );

          return fulfillWithValue(res?.data?.[0]);
        } else {
          const modPage = Math.max(data.page - 1, 0);
          const responseAgain = await axios({
            method: "GET",
            url: `${process.env.REACT_APP_AUTH_URL}/admin/catalogue/skill/skill-list?page=${data.page}&limit=${data.limit}&sort=${data?.sort ? data?.sort : "desc"}&search=${data.search}&filter[categoryId]=${data?.filter ? data?.filter : ""}`,
          });
          if (responseAgain.status === 200) {
            const resAgain = await AddNumField(
              responseAgain?.data,
              modPage * data?.limit
            );
            return fulfillWithValue(resAgain?.data?.[0]);
          } else {
            // toast.error(responseAgain?.data?.message, options);
            return rejectWithValue();
          }
        }
      } else {
        // toast.error(response?.data?.message, options);
        return rejectWithValue();
      }
    } catch (err) {
      return rejectWithValue();
    }
  }
);

export const addSkill = createAsyncThunk(
  "catalogue/add-skill",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      dispatch(isSkillLoading(true));
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/catalogue/skill/add-skill`,
        data: data,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (response.status === 200) {
        dispatch(isSkillLoading(false));
        dispatch(
          getSkillList({
            page: 0,
            limit: 6,
            search: "",
          })
        );
        toast.success(response?.data?.message, options);
        return response?.data?.status;
      } else {
        dispatch(isSkillLoading(false));
        toast.error(response?.data?.message, options);
      }
    } catch (err) {
      dispatch(isSkillLoading(false));
      toast.error(err, options);
    }
  }
);

export const addBulkSkillAsync = createAsyncThunk(
  "catalogue/add-bulk-skill",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    dispatch(isSkillLoading(true));
    try {
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/catalogue/skill/skill-upload-bulk`,
        headers: {
          "Content-Type": "multipart/form-data",
        },

        data: data,
      });

      if (response.status === 200) {
        dispatch(isSkillLoading(false));
        dispatch(
          getSkillList({
            page: 0,
            limit: 6,
            search: "",
          })
        );
        toast.success(response?.data?.message, options);
        return response?.data?.status;
      } else {
        dispatch(isSkillLoading(false));
        toast.error(response?.data?.message, options);
      }
    } catch (err) {
      dispatch(isSkillLoading(false));
      toast.error(err, options);
    }
  }
);

export const deleteSkillAsync = createAsyncThunk(
  "tookit/delete-skill",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      dispatch(isSkillLoading(true));
      const response = await axios({
        method: "DELETE",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/catalogue/skill/delete-skill`,
        data: data?.send,
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.status === 200) {
        dispatch(isSkillLoading(false));
        dispatch(
          getSkillList({
            page: data?.page,
            limit: data.limit,
            search: data.search,
            sort: data.sort,
          })
        );
        toast.success(response?.data?.message, options);
        return response?.data?.status;
      } else {
        dispatch(isSkillLoading(false));
        toast.error(response?.data?.message, options);
      }
    } catch (err) {
      dispatch(isSkillLoading(false));
      toast.error(err, options);
    }
  }
);

export const getSkillDetail = createAsyncThunk(
  "catalogue/get-skill-detail",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      const response = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/catalogue/skill/skill-detail/${data?.id}`,
      });
      if (response.status === 200) {
        return fulfillWithValue(response?.data);
      } else {
        toast.error(response?.data?.message, options);
        return rejectWithValue();
      }
    } catch (err) {
      toast.error(err, options);
      return rejectWithValue();
    }
  }
);

export const updateSkillDetail = createAsyncThunk(
  "catalogue/update-skill",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      dispatch(isSkillLoading(true));
      const response = await axios({
        method: "PUT",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/catalogue/skill/update-skill/${data?.id}`,
        data: data?.send,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (response.status === 200) {
        dispatch(isSkillLoading(false));
        dispatch(
          getSkillList({
            page: 0,
            limit: 6,
            search: "",
            filter: "",
          })
        );
        toast.success(response?.data?.message, options);
        return response?.data;
      } else {
        dispatch(isSkillLoading(false));
        toast.error(response?.data?.message, options);
      }
    } catch (err) {
      dispatch(isSkillLoading(false));
      toast.error(err, options);
    }
  }
);
