import { createSlice } from "@reduxjs/toolkit";
import { getGlossaryDetail, getGlossaryList } from "./glossaryApi";

const glossarySlice = createSlice({
  name: "glossary",
  initialState: {
    glossaryLoading: false,
    glossaryList: [],
    metaData: [],
    glossaryDetail: {},
  },
  reducers: {
    isGlossaryLoading: (state, action) =>
      (state = {
        ...state,
        glossaryLoading: action.payload,
      }),
    clearGlossaryDetails: (state) => {
      state.glossaryDetail = {};
    },
  },
  extraReducers: (builder) => {
    // getGlossaryList reducers-------------------------
    builder.addCase(getGlossaryList.pending, (state, action) => {
      state.glossaryLoading = true;
    });
    builder.addCase(getGlossaryList.fulfilled, (state, action) => {
      state.glossaryLoading = false;
      state.glossaryList = action.payload.data;
      state.metaData = action.payload.metadata[0];
    });
    builder.addCase(getGlossaryList.rejected, (state, action) => {
      state.glossaryLoading = false;
      state.glossaryList = [];
    });
    // getGlossaryDetail reducers-------------------------
    builder.addCase(getGlossaryDetail.pending, (state, action) => {
      state.glossaryLoading = true;
    });
    builder.addCase(getGlossaryDetail.fulfilled, (state, action) => {
      state.glossaryLoading = false;
      state.glossaryDetail = action.payload.data;
    });
    builder.addCase(getGlossaryDetail.rejected, (state, action) => {
      state.glossaryLoading = false;
    });
  },
});

export const { isGlossaryLoading, clearGlossaryDetails } =
  glossarySlice.actions;
export default glossarySlice.reducer;
