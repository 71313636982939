/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import CustomTable from "components/CustomTable";
import ConformationPopup from "components/ui/ConformationPopup";
import SearchField from "components/ui/SearchField";
import useDisableSkelaton from "hooks/useDisableSkelaton";
import useFetchList from "hooks/useFetchList";
import ModuleLayout from "layouts/ModuleLayout";
import { setPageDetails } from "modules/auth/redux/authSlice";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { unixTimeToReadableFormat } from "helper/helper";
import { useNavigate } from "react-router-dom";
import DropDownBox from "components/DropDownBox";
import { useFormik } from "formik";
import { getOrderList } from "../redux/orderApi";
import { SortItems } from "helper/placeholder-data";
import useSelectRows from "hooks/useSelectRows";

const OrderList = ({ setTitle }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    setTitle("Order Management");
  }, [setTitle]);

  const { orderList, metaData, orderLoading } = useSelector(
    (state) => state.order,
    shallowEqual
  );
  const [active, setActive] = useState("order");
  const [mobileSidebar, setMobileSidebar] = useState(false);
  const [minimizedSidebar, setMinimizedSidebar] = useState(false);
  const { contentLoading } = useDisableSkelaton(orderLoading);

  const {
    content,
    alertBox,
    setAlertBox,
    selectAllRows,
    selectedRows,
    setSelectedRows,
    toggleSelectAll,
    toggleRowSelection,
    deleteFunction,
  } = useSelectRows(orderList, "id");

  const {
    privileges,
    limit,
    setLimit,
    sort,
    setSort,
    search,
    setSearch,
    btnNo,
    setBtnNo,
    tableIndex,
    setTableIndex,
  } = useFetchList(getOrderList, metaData, selectAllRows, setSelectedRows);

  useEffect(() => {
    dispatch(setPageDetails({ page: metaData?.page, search }));
  }, [dispatch, metaData, search]);

  const listContent = orderList?.map((item, i) => {
    const {
      id,
      num,
      orderId,
      items,
      currency,
      netAmount,
      placedBy,
      orderDate,
      status,
      paymentStatus,
    } = item;
    const crDt = unixTimeToReadableFormat(orderDate);
    const similarItems = [
      { style: "name", val: orderId },
      { style: "name", val: items },
      { style: "name", val: `${currency?.symbol} ${netAmount}` },
      {
        style: "",
        val: `${placedBy?.name?.firstName} ${placedBy?.name?.lastName}`,
      },
      { style: "", val: crDt },
      { style: "", val: status?.value },
      { style: "", val: paymentStatus },
    ];
    return [id, num, orderId, netAmount, similarItems];
  });

  const TABLEDATA = {
    loadingState: contentLoading,
    skalatonDetails: { col: 4, rows: limit },
    list: orderList,
    listHeading: [
      "Order ID",
      "Items",
      "Amount",
      "Order By",
      "Order Date",
      "Order Status",
      "Payment Status",
    ],
    listContent,
    // selectedRows,
    // selectAllRows,
    // setSelectedRows,
    // toggleSelectAll,
    // toggleRowSelection,
    deleteFunction,
    sliceName: "question",
    PagAPI: getOrderList,
    search,
    sort,
    limit,
    setLimit,
    btnNo,
    setBtnNo,
    tableIndex,
    setTableIndex,
    privileges,
    hasAccess: "orderManagement",
    navigateTo: (params) => {
      const order = orderList.find((order) => order.id === params);
      navigate(`/orders/${params}`, {
        state: { slug: params, title: order?.orderId },
      });
    },
    metaData,
    disableDelete: true,
  };

  const formik = useFormik({
    initialValues: {
      status: "",
      paymentStatus: "",
    },
  });

  useEffect(() => {
    const data = {
      page: 0,
      limit: limit,
      sort: sort,
      status: formik.values.status,
      paymentStatus: formik.values.paymentStatus,
    };
    dispatch(getOrderList(data));
  }, [formik.values, sort]);

  return (
    <ModuleLayout
      minimizedSidebar={minimizedSidebar}
      setMinimizedSidebar={setMinimizedSidebar}
      mobileSidebar={mobileSidebar}
      setMobileSidebar={setMobileSidebar}
      active={active}
      setActive={setActive}
      content={content}
      alertBox={alertBox}
    >
      <header className="list-header">
        <div className="list-title">
          <h2>Order Management</h2>
          {/* <div className="flex-center gap-1">
            {Object?.values(privileges["orderManagement"])?.includes(
              "delete"
            ) && selectedRows.length !== 0 ? (
              <button
                className="btn-secondary flex-center gap-1"
                onClick={() => {
                  deleteFunction("all Questions");
                }}
              >
                <i className="fa-light fa-trash"></i>
                {`Delete ${selectedRows.length}`}
              </button>
            ) : null}
          </div> */}
        </div>
      </header>
      <div className="list-container custom-box">
        <div className="flex-between gap-1">
          <div className="flex gap-1">
            <DropDownBox
              options={SortItems}
              size="large"
              dropDownTitle="Sort"
              animateDropDownTitle
              customSetter={setSort}
              customDropBoxStyles={{
                opacity: 0.6,
                color: "#262626",
                padding: "0.6rem",
                border: "1px solid var(--color-neutral-92)",
                width: "6rem",
              }}
            />
            <DropDownBox
              options={[
                { label: "Open", value: "Open" },
                { label: "Pending", value: "Pending" },
                { label: "On Hold", value: "On Hold" },
                {
                  label: "Awaiting Fulfillment",
                  value: "Awaiting Fulfillment",
                },
                { label: "Awaiting Shipment", value: "Awaiting Shipment" },
                { label: "Shipped", value: "Shipped" },
                { label: "In Transit", value: "In Transit" },
                { label: "Out For Delivery", value: "Out For Delivery" },
                { label: "Completed", value: "Completed" },
                { label: "Cancelled", value: "Cancelled" },
                { label: "Returned", value: "Returned" },
                { label: "Refund", value: "Refund" },
              ]}
              dropDownTitle="Select Status"
              animateDropDownTitle
              size="large"
              customSetter={formik.setFieldValue}
              customFormikLabel="status"
              customDropBoxStyles={{
                opacity: 0.6,
                color: "#262626",
                padding: "0.6rem",
                width: "9rem",
                border: "1px solid var(--color-neutral-92)",
              }}
              resetButton
            />
            <DropDownBox
              options={[
                { label: "Paid", value: "Paid" },
                { label: "Pending", value: "Pending" },
                { label: "Failed", value: "Failed" },
              ]}
              dropDownTitle="Payment Status"
              animateDropDownTitle
              size="large"
              customSetter={formik.setFieldValue}
              customFormikLabel="paymentStatus"
              customDropBoxStyles={{
                opacity: 0.6,
                color: "#262626",
                padding: "0.6rem",
                width: "10rem",
                border: "1px solid var(--color-neutral-92)",
              }}
              resetButton
            />
          </div>

          <SearchField value={search} setValue={setSearch} />
        </div>
        <CustomTable {...TABLEDATA} />
      </div>

      {alertBox && (
        <ConformationPopup
          customState={alertBox}
          customSetter={setAlertBox}
          //   api={deleteQuestion}
          popUpData={content}
          customFunction={() => setSelectedRows(new Set())}
          toastMessage={"Question removed successfully"}
        />
      )}
    </ModuleLayout>
  );
};

export default OrderList;
