import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  manageEmailNotification,
  managePushNotification,
  manageSmsNotification,
} from "../redux/profileApi";

const Notifications = () => {
  const { profileDetail } = useSelector((state) => state.profile, shallowEqual);

  const dispatch = useDispatch();
  const [ischeckpush, setischeckpush] = useState(true);
  const [ischeckemail, setischeckemail] = useState(true);
  const [ischecksms, setischecksms] = useState(true);

  let timeoutId = null;
  const handlePushToggle = () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(async () => {
      const data = {
        is_notification: !ischeckpush,
      };
      const response = await dispatch(managePushNotification(data));
      if (response?.payload) {
        setischeckpush(!ischeckpush);
      }
    }, 200);
  };

  // const handlePushToggle = async () => {
  //   const data = {
  //     is_notification: !ischeckpush,
  //   };
  //   const response = await dispatch(managePushNotification(data));
  //   if (response?.payload) {
  //     setischeckpush(!ischeckpush);
  //   }
  // };
  const handleEmailToggle = () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(async () => {
      const data = {
        is_notification: !ischeckemail,
      };
      const response = await dispatch(manageEmailNotification(data));
      if (response?.payload) {
        setischeckemail(!ischeckemail);
      }
    }, 200);
  };

  // const handleEmailToggle = async () => {
  //   const data = {
  //     is_notification: !ischeckemail,
  //   };
  //   const response = await dispatch(manageEmailNotification(data));
  //   if (response?.payload) {
  //     setischeckemail(!ischeckemail);
  //   }
  // };

  const handleSMSToggle = () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(async () => {
      const data = {
        is_notification: !ischecksms,
      };
      const response = await dispatch(manageSmsNotification(data));
      if (response?.payload) {
        setischecksms(!ischecksms);
      }
    }, 200);
  };

  // const handleSMSToggle = async () => {
  //   const data = {
  //     is_notification: !ischecksms,
  //   };
  //   const response = await dispatch(manageSmsNotification(data));
  //   if (response?.payload) {
  //     setischecksms(!ischecksms);
  //   }
  // };

  useEffect(() => {
    if (profileDetail) {
      setischeckpush(profileDetail?.notification?.pushNotification);
      setischeckemail(profileDetail?.notification?.emailNotification);
      setischecksms(profileDetail?.notification?.messageNotification);
    }
  }, [profileDetail]);

  return (
    <div className="profile-details">
      <div className="header-wrapper">
        <h2 className="title">Manage Notification</h2>
      </div>
      <div className="flex flex-col gap-2" style={{ padding: "1rem" }}>
        <div className="flex-between">
          <p>Push Notification</p>
          <div>
            <input
              type="checkbox"
              name="push"
              id="push"
              style={{ display: "none" }}
              className="cc-inp"
              checked={ischeckpush}
              onChange={handlePushToggle}
            />
            <label htmlFor="push">
              <div className="custom-checkbox">
                <div className="check-circle"></div>
              </div>
            </label>
          </div>
        </div>

        <div className="flex-between">
          <p>Email Notification</p>
          <div>
            <input
              type="checkbox"
              name="email"
              id="email"
              style={{ display: "none" }}
              className="cc-inp"
              checked={ischeckemail}
              onChange={handleEmailToggle}
            />
            <label htmlFor="email">
              <div className="custom-checkbox">
                <div className="check-circle"></div>
              </div>
            </label>
          </div>
        </div>

        <div className="flex-between">
          <p>Message Notification</p>
          <div>
            <input
              type="checkbox"
              name="msg"
              id="msg"
              style={{ display: "none" }}
              className="cc-inp"
              checked={ischecksms}
              onChange={handleSMSToggle}
            />
            <label htmlFor="msg">
              <div className="custom-checkbox">
                <div className="check-circle"></div>
              </div>
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notifications;
