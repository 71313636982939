/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { addPolicy, getPolicyDetail, updatePolicy } from "../redux/policiesApi";
import { resetPolicyDetail } from "../redux/policiesSlice";
import { toolbarOptions } from "helper/placeholder-data";
import Loader from "components/ui/Loader";
import SubmitButton from "components/ui/SubmitButton";
import InputField from "components/ui/InputField";
import ModuleLayout from "layouts/ModuleLayout";
import ReactQuill from "react-quill";
import { useFormik } from "formik";
import * as Yup from "yup";

const ManagePolicy = ({ setTitle }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { slug } = useParams();
  const pathName = useLocation().pathname;

  const { policyDetail, policyLoading } = useSelector(
    (state) => state.cmsPolicy,
    shallowEqual
  );


  // Set up page title ----------------------------
  useEffect(() => {
    if (pathName.includes("add-new")) {
      setTitle("Content Management | Add Policy");
    } else if (slug) {
      setTitle("Content Management | Update Policy");
    }
  }, [setTitle]);

  const [mobileSidebar, setMobileSidebar] = useState(false);
  const [active, setActive] = useState(() => "cmsPages");
  const [minimizedSidebar, setMinimizedSidebar] = useState(false);
  const [activeInputs, setActiveInputs] = useState(true);

  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
    },
    validationSchema: Yup.object({
      title: Yup.string()
        .trim()
        .min(3, "Title must be at least 3 characters long")
        .max(80, "Title must be less then 80 characters")
        // .matches(/^[A-Za-z ]+$/, "Please enter a valid name")
        .required("Title is required"),
      description: Yup.string()
        .trim()
        .min(3, "Description must be at least 3 characters long")
        .required("Description should not be empty"),
    }),
    onSubmit: async (values, { resetForm }) => {
      const data = {
        title: values.title
          .trim()
          .split(/[\s,\t,\n]+/)
          .join(" "),
        body: values.description.trim(),
      };

      if (pathName.includes("add-new")) {
        const response = await dispatch(addPolicy(data));
        if (response?.payload) {
          navigate(-1);
        }
      } else if (slug?.length > 0) {
        const response = await dispatch(
          updatePolicy({ send: data, slug: slug })
        );
        if (response?.payload) {
          navigate(-1);
        }
      }
      resetForm();
    },
  });

  // setting form values if policydetails & slug are there
  useEffect(() => {
    if (slug?.length > 0) {
      dispatch(getPolicyDetail({ slug }));
    }
    return () => {
      dispatch(resetPolicyDetail());
    };
  }, [dispatch]);

  useEffect(() => {
    if (slug?.length > 0 && policyDetail) {
      formik.setValues({
        title: policyDetail?.title,
        description: policyDetail?.body,
      });
    }
    return () => {
      formik.resetForm();
    };
  }, [activeInputs, policyDetail]);

  // Set Page Heading ----------------------------
  const ActivePage = pathName.includes("add-new")
    ? "Add Policy"
    : "Update Policy";

  return (
    <ModuleLayout
      minimizedSidebar={minimizedSidebar}
      setMinimizedSidebar={setMinimizedSidebar}
      mobileSidebar={mobileSidebar}
      setMobileSidebar={setMobileSidebar}
      active={active}
      setActive={setActive}
    >
      {/* --- LOADER --- */}
      <Loader isLoading={policyLoading} />

      {/* ----- MAIN FORM ----- */}
      <div className="form-header">
        <h2>Content Management | {ActivePage}</h2>

        {/* ------// BUTTONS //-------- */}
        {slug?.length > 0 && (
          <div className="form-control">
            {activeInputs ? (
              <>
                <button
                  type="button"
                  onClick={() => navigate("/content/pages")}
                  className="btn-outlined cancel"
                >
                  Back
                </button>
                <button
                  type="button"
                  onClick={() => setActiveInputs(false)}
                  className="btn-primary"
                >
                  Update
                </button>
              </>
            ) : (
              <>
                <button
                  type="button"
                  onClick={() => setActiveInputs(true)}
                  className="btn-outlined tertiary"
                >
                  Abort
                </button>
                <SubmitButton
                  className="btn-primary"
                  type="submit"
                  onClick={formik.handleSubmit}
                  disabled={formik.isSubmitting}
                >
                  Submit
                </SubmitButton>
              </>
            )}
          </div>
        )}

        {pathName.includes("add-new") && (
          <div className="form-control">
            <button
              type="button"
              onClick={() => navigate(-1)}
              className="btn-outlined cancel"
            >
              Back
            </button>
            <SubmitButton
              className="btn-primary"
              type="submit"
              onClick={formik.handleSubmit}
              disabled={formik.isSubmitting}
            >
              Submit
            </SubmitButton>
          </div>
        )}
      </div>
      <div className="form-wrapper custom-box">
        <div className="auth-form-wrapper">
          <form className="form" onSubmit={formik.handleSubmit}>
            <div className="form-control">
              <label className="form-label">Title</label>
              <div className="form-input-box">
                <InputField
                  type="text"
                  id="title"
                  name="title"
                  placeholder="Enter page title here..."
                  value={formik.values.title || ""}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  onInput={(e) =>
                  (e.target.value = e.target.value
                    // .replace(/[^A-Za-z ]/gi, "")
                    .replace(/(?:^|\s)\S/g, (match) => match.toUpperCase()))
                  }
                  disabled={(slug && activeInputs) || (policyDetail?.slug === "terms-condition" || policyDetail?.slug === "privacy-policy")}
                  maxLength={80}
                />
              </div>
              {formik.errors.title && formik.touched.title && (
                <span className="err">{formik.errors.title}</span>
              )}
            </div>

            <div className="form-control">
              <label className="form-label">Description</label>
              <div
                className={`form-input-box ${slug && activeInputs ? "disabled-text-editor" : ""}`}
              >
                <div className={`text-editor`}>
                  <ReactQuill
                    theme="snow"
                    modules={toolbarOptions}
                    value={formik.values.description || ""}
                    onChange={(ev) => formik.setFieldValue("description", ev)}
                    readOnly={slug && activeInputs}
                  />
                </div>
              </div>
              {formik.errors.description && formik.touched.description && (
                <span className="err">{formik.errors.description}</span>
              )}
            </div>
          </form>
        </div>
      </div>
    </ModuleLayout>
  );
};

export default ManagePolicy;
