import { createSlice } from "@reduxjs/toolkit";
import { getPointsTransaction, getReferralList, getUserDetail, getUserList } from "./userApi";

const userSlice = createSlice({
  name: "user",
  initialState: {
    userLoading: false,
    userList: [],
    metaData: [],
    userDetail: {},
    referralList: [],
    referralListMeta: {},
    pointsInfo: {},
    pointsTransactionList: [],
    pointsTransactionMetaData: {}
  },
  reducers: {
    toggleUserLoading: (state, action) =>
    (state = {
      ...state,
      userLoading: action.payload,
    }),
    resetUserDetails: (state, action) => {
      state.userDetail = [];
    },
  },
  extraReducers: (builder) => {
    // getUserList reducers-------------------------
    builder.addCase(getUserList.pending, (state, action) => {
      state.userLoading = true;
    });
    builder.addCase(getUserList.fulfilled, (state, action) => {
      state.userLoading = false;
      state.userList = action?.payload?.data;
      state.metaData = action?.payload?.metadata[0];
    });
    builder.addCase(getUserList.rejected, (state, action) => {
      state.userLoading = false;
      state.userList = [];
    });
    // getUserDetail reducers-------------------------
    builder.addCase(getUserDetail.pending, (state, action) => {
      state.userLoading = true;
    });
    builder.addCase(getUserDetail.fulfilled, (state, action) => {
      state.userLoading = false;
      state.userDetail = action?.payload?.data;
    });
    builder.addCase(getUserDetail.rejected, (state, action) => {
      state.userLoading = false;
    });
    // get User Referal List reducers-------------------------
    builder.addCase(getReferralList.pending, (state, action) => {
      state.userLoading = true;
    });
    builder.addCase(getReferralList.fulfilled, (state, action) => {
      state.userLoading = false;
      state.referralList = action?.payload?.data;
      state.referralListMeta = action?.payload?.metadata[0];
    });
    builder.addCase(getReferralList.rejected, (state, action) => {
      state.userLoading = false;
      state.referralList = [];
      state.referralListMeta = {}
    });
    // points tranction 
    builder.addCase(getPointsTransaction.pending, (state, action) => {
      state.userLoading = true;
    });
    builder.addCase(getPointsTransaction.fulfilled, (state, action) => {
      state.userLoading = false;
      state.pointsInfo = action?.payload?.points
      state.pointsTransactionList = action?.payload?.transaction?.[0]?.data;
      state.pointsTransactionMetaData = action?.payload?.transaction?.[0]?.metadata?.[0];
    });
    builder.addCase(getPointsTransaction.rejected, (state, action) => {
      state.userLoading = false;
      state.pointsInfo = {}
      state.pointsTransactionList = [];
      state.pointsTransactionMetaData = {}
    });
  },
});

export const { toggleUserLoading, resetUserDetails } = userSlice.actions;
export default userSlice.reducer;
