/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useState } from "react";
import DropDownBox from "components/DropDownBox";
import SubmitButton from "components/ui/SubmitButton";
import InputField from "components/ui/InputField";
import ModuleLayout from "layouts/ModuleLayout";
import { toolbarOptions } from "helper/placeholder-data";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  addTemplate,
  getTemplateDetail,
  updateTemplate,
} from "../redux/templateApi";
import ReactQuill from "react-quill";
import { useFormik } from "formik";
import * as Yup from "yup";
import { resetTemplateDetail } from "../redux/templateSlice";
import Loader from "components/ui/Loader";

const ManageTemplate = ({ setTitle }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { slug } = useParams();
  const pathName = useLocation().pathname;

  const { templateDetail, templateLoading } = useSelector(
    (state) => state.cmsTemplate,
    shallowEqual
  );

  useEffect(() => {
    if (slug) {
      setTitle("Content Management | Update Template");
    } else {
      setTitle("Content Management | Add Template");
    }
  }, [setTitle]);

  const [mobileSidebar, setMobileSidebar] = useState(false);
  const [active, setActive] = useState(() => "cmsTemplates");
  const [minimizedSidebar, setMinimizedSidebar] = useState(false);
  const [activeInputs, setActiveInputs] = useState(true);
  const [previewMail, setPreviewMail] = useState(false);
  const [prevScreen, setPrevScreen] = useState("desktop");

  const initialValues = {
    title: "",
    type: "",
    description: `<h1>Place Title Here</h1>
                            <p>Sometimes you just want to send a simple HTML email with a simple design and clear
                                call to action. This is it.</p>
                            <a href="http://localhost:3000" target="_blank" className="cta">Call To
                                Action</a>
                            <p>This is a really simple email template. It's sole purpose is to get the recipient to
                                click the button with no distractions.</p>
                            <p>Good luck! Hope it works.</p>`,
    email_subject: "",
  };

  const templateSchema = Yup.object({
    title: Yup.string()
      .trim()
      .min(3, "Title must be at least 3 characters long")
      .max(80, "Title must be less then 80 characters")
      .matches(/^[A-Za-z ]+$/, "Please enter a valid name")
      .required("Title is required"),
    description: Yup.string()
      .trim()
      .min(3, "Description must be at least 3 characters long")
      .required("Description should not be empty"),
    type: Yup.string().required("Type is required"),
    email_subject: Yup.string().when("type", {
      is: "email",
      then: () =>
        Yup.string()
          .min(3, "Subject must be at least 3 characters long")
          .max(80, "Subject must be less then 80 characters")
          // .matches(/^[A-Za-z ]+$/, "Please enter a valid name")
          .required("Subject should not be empty"),
    }),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: templateSchema,
    onSubmit: async (values, { resetForm }) => {
      let data = {
        title: values.title
          .trim()
          .split(/[\s,\t,\n]+/)
          .join(" "),
        type: values.type,
        body: values.description.trim(),
        // body: emailVar
      };

      if (values.type === "email") {
        data = { ...data, subject: values.email_subject };
      }

      if (pathName.includes("add-new")) {
        const response = await dispatch(addTemplate(data));
        if (response?.payload) {
          navigate(-1);
        }
      } else if (slug?.length > 0) {
        const response = await dispatch(updateTemplate({ send: data, slug }));
        if (response?.payload) {
          navigate(-1);
        }
      }
      resetForm();
    },
  });

  useEffect(() => {
    if (slug) {
      dispatch(getTemplateDetail({ slug }));
    }
    return () => {
      dispatch(resetTemplateDetail());
    };
  }, [dispatch]);

  useEffect(() => {
    if (slug?.length > 0 && templateDetail) {
      formik.setValues({
        title: templateDetail?.title,
        description: templateDetail?.body,
        type: templateDetail?.type,
        email_subject:
          templateDetail?.type === "email" && templateDetail?.subject,
      });
    }
    return () => formik.resetForm();
  }, [activeInputs, templateDetail]);

  const ActivePage = pathName.includes("add-new")
    ? "Add Template"
    : "Update Template";

  // Email Template html code ---------
  const emailVar = `<!doctype html>
    <html lang="en" xmlns="http://www.w3.org/1999/xhtml" xmlns:o="urn:schemas-microsoft-com:office:office">
    
    <head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
        <meta name="x-apple-disable-message-reformatting">
        <link rel="preconnect" href="https://fonts.googleapis.com">
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
        <link href="https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap"
            rel="stylesheet">
        <title>Email</title>
        <style media="all" type="text/css">
            * {
                box-sizing: border-box;
            }
    
            body {
                margin: 0;
                background-color: #F6F5F2;
                font-family: "Noto Sans", Helvetica, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif;
                -webkit-font-smoothing: antialiased;
                -ms-text-size-adjust: 100%;
                -webkit-text-size-adjust: 100%;
                font-size: 16px;
                line-height: 1.5;
                color: #2e2c2c;
            }
    
            .container {
                width: 100%;
                height: 100%;
                overflow-x: hidden;
                overflow-y: auto;
                background-color: #F6F5F2;
            }
    
            .container .table-wrapper {
                max-width: 600px;
                margin: 20px auto;
            }
    
            table {
                width: 100%;
                border-collapse: separate;
                /* client specific spaces */
                mso-table-lspace: 0pt;
                mso-table-rspace: 0pt;
            }
    
            img {
                width: 100%;
                max-width: 250px;
                vertical-align: middle;
            }
    
            /* --------->> header, footer & main <<-------- */
    
            .main {
                background: #F0EBE3;
                border: 1px solid #eaebed;
                border-radius: 16px;
                padding: 20px;
                width: 100%;
            }
    
            .footer td,
            .footer p,
            .footer span,
            .footer a {
                color: #6e6f70;
                font-size: 16px;
                text-align: center;
            }
    
            /* --------->> typography <<-------- */
    
            p {
                margin: 0;
                font-weight: normal;
                margin-bottom: 16px;
            }
    
            /* --------->> button styles <<-------- */
            a {
                text-decoration: none;
                transition: all 200ms linear;
            }
    
            a:hover {
                text-decoration: underline;
            }
    
            td.main a {
                background-color: #BB9AB1 !important;
                border-radius: 4px;
                color: white;
                display: inline-block;
                font-size: 16px;
                font-weight: bold;
                margin-bottom: 24px;
                padding: 12px 24px;
                text-transform: capitalize;
                text-decoration: none;
            }
    
            td.main a:hover {
                background-color: #987D9A !important;
            }
    
            tfoot.footer tr td.social-buttons a {
                text-decoration: none;
                min-width: 96px;
                min-height: 38px;
                display: inline-block;
                color: #6b6565;
                font-size: small;
                padding: 8px 10px;
                background: #47446a;
                border-radius: 4px;
                /* border: 1px solid #69656b30; */
                color: white;
            }
    
            /* ----------->> Other Utilities <<-------- */
            .align-center {
                text-align: center;
            }
    
            .align-right {
                text-align: right;
            }
    
            .align-left {
                text-align: left;
            }
    
    
            /* --------->> Mobile responsive <<-------- */
    
            @media only screen and (max-width: 600px) {
    
                .main p,
                .main td,
                .main span {
                    font-size: 16px !important;
                }
    
                .table-wrapper {
                    width: 90% !important;
                }
    
                .main {
                    border-left-width: 0 !important;
                    border-radius: 0 !important;
                    border-right-width: 0 !important;
                }
    
                td.main a {
                    font-size: 16px !important;
                    max-width: 100% !important;
                    width: 100% !important;
                }
            }
    
            /* -------------------------------------
        PRESERVE THESE STYLES IN THE HEAD
    ------------------------------------- */
    
            @media all {
                .apple-link a {
                    color: inherit !important;
                    font-family: inherit !important;
                    font-size: inherit !important;
                    font-weight: inherit !important;
                    line-height: inherit !important;
                    text-decoration: none !important;
                }
            }
        </style>
    </head>
    
    <body>
        <div class="container">
            <div class="table-wrapper">
                <table role="presentation" border="0" cellpadding="0" cellspacing="0" class="body">
                    <!-- START HEADER -->
                    <thead>
                        <tr>
                            <th style="padding-bottom: 20px;">
                                <a href="http://localhost:3000/" target="_blank">
                                     <img src="https://raw.githubusercontent.com/cuneiform-vikas/email-temp/main/images/peak72.png"
                                         alt="" style="mix-blend-mode: darken; object-fit: contain;">
                                </a>
                            </th>
                        </tr>
                    </thead>
    
                    <!-- END HEADER -->
    
                    <!-- START CENTERED WHITE CONTAINER -->
                    <tbody>
                        <tr>
                            <td class="main ql-editor">
                           ${formik.values.description}
                            </td>
                        </tr>
                    </tbody>
    
                    <!-- END CENTERED WHITE CONTAINER -->
    
                    <!-- START FOOTER -->
                    <tfoot class="footer">
                        <tr>
                            <td class="social-buttons" style="display: inline-block; width: 100%; padding:20px;">
                                <a href="https://localhost:3000" target="_blank" style="margin-right: 5px;">
                                    <img src="https://raw.githubusercontent.com/cuneiform-vikas/email-temp/main/icons/apple-512.png"
                                        style="height: 20px; width: 20px; object-fit: contain;" alt="Apple">&nbsp;iOS
                                </a>
                                <a href="https://localhost:3000" target="_blank" style="margin-left: 5px;">
                                    <img src="https://raw.githubusercontent.com/cuneiform-vikas/email-temp/main/icons/android-512.png"
                                        style="height: 20px; width: 20px; object-fit: contain;" alt="Android">
                                    </i>&nbsp;Android
                                </a>
                            </td>
                        </tr>
                        <tr>
                            <td class="content-block">
                                <span class="apple-link">
                                    Copyright © 2024 Peak72 Finance | All Rights Reserved.
                                </span>
                                <br>
                                Don't like these emails?
                                <a href="http://htmlemail.io/blog">Unsubscribe</a>.
                            </td>
                        </tr>
                    </tfoot>
    
                    <!-- END FOOTER -->
                </table>
            </div>
    
        </div>
    </body>
    
    </html>`;

  return (
    <ModuleLayout
      minimizedSidebar={minimizedSidebar}
      setMinimizedSidebar={setMinimizedSidebar}
      mobileSidebar={mobileSidebar}
      setMobileSidebar={setMobileSidebar}
      active={active}
      setActive={setActive}
    >
      <Loader isLoading={templateLoading} />
      <div className="form-wrapper custom-box">
        <div className="form-header" style={{ paddingBottom: "1rem" }}>
          <h2>Content Management | {ActivePage}</h2>
          {/* ------// BUTTONS //-------- */}
          {slug?.length > 0 && (
            <div className="form-control">
              {activeInputs ? (
                <>
                  <button
                    type="button"
                    onClick={() => navigate("/content/templates")}
                    className="btn-outlined cancel"
                  >
                    Back
                  </button>
                  <button
                    type="button"
                    onClick={() => setActiveInputs(false)}
                    className="btn-primary"
                  >
                    Update
                  </button>
                </>
              ) : (
                <>
                  <button
                    type="button"
                    onClick={() => setActiveInputs(true)}
                    className="btn-outlined tertiary"
                  >
                    Abort
                  </button>
                  <SubmitButton
                    className="btn-primary"
                    type="submit"
                    onClick={formik.handleSubmit}
                    disabled={formik.isSubmitting}
                  >
                    Submit
                  </SubmitButton>

                  {formik.values.type === "email" && (
                    <button
                      className="btn-sucess"
                      onClick={() => setPreviewMail(!previewMail)}
                    >
                      {previewMail ? "Write" : "Preview"}
                    </button>
                  )}
                </>
              )}
            </div>
          )}

          {pathName.includes("add-new") && (
            <div className="form-control">
              <button
                type="button"
                onClick={() => navigate(-1)}
                className="btn-outlined cancel"
              >
                Back
              </button>
              <SubmitButton
                className="btn-primary"
                type="submit"
                onClick={formik.handleSubmit}
                disabled={formik.isSubmitting}
              >
                Submit
              </SubmitButton>
              {formik.values.type === "email" && (
                <button
                  className="btn-sucess"
                  onClick={() => setPreviewMail(!previewMail)}
                >
                  {previewMail ? "Write" : "Preview"}
                </button>
              )}
            </div>
          )}
        </div>

        {/* ------- SWITCH PREVIEW SCREEN & FORM SCREEN ------- */}
        {!previewMail ? (
          <div className="auth-form-wrapper">
            <form
              className="form"
              onSubmit={formik.handleSubmit}
              // noValidate="novalidate"
            >
              <div className="form-col-2">
                <div className="form-control">
                  <label className="form-label">Title</label>
                  <div className="form-input-box">
                    <InputField
                      type="text"
                      id="title"
                      name="title"
                      placeholder="Enter page title here..."
                      value={formik.values.title || ""}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      onInput={(e) =>
                        (e.target.value = e.target.value
                          .replace(/[^A-Za-z ]/gi, "")
                          .replace(/(?:^|\s)\S/g, (match) =>
                            match.toUpperCase()
                          ))
                      }
                      disabled={slug && activeInputs}
                      maxLength={80}
                    />
                  </div>
                  {formik.errors.title && formik.touched.title && (
                    <span className="err">{formik.errors.title}</span>
                  )}
                </div>

                <div className="form-control">
                  <label className="form-label">Type</label>
                  <div className="form-input-box">
                    <DropDownBox
                      options={[
                        { label: "Email", value: "email" },
                        { label: "Message", value: "message" },
                        {
                          label: "Notification",
                          value: "notification",
                        },
                      ]}
                      size="large"
                      defaultValue="Select Type"
                      customSetter={formik.setFieldValue}
                      customFormikLabel="type"
                      customDropBoxStyles={{
                        border: "0.063rem solid #d2d6da",
                        borderRadius: "0.5rem",
                        color: "#262626",
                        fontSize: "0.857rem",
                      }}
                      customTextStyle={{
                        color: "#454545",
                      }}
                      incomingValue={slug && templateDetail?.type}
                      disabled={slug && !activeInputs}
                    />
                  </div>
                  {formik.errors.type && formik.touched.type ? (
                    <span className="err">{formik.errors.type}</span>
                  ) : null}
                </div>
              </div>

              {formik.values.type === "email" && (
                <div className="form-control">
                  <label className="form-label">Subject</label>
                  <div className="form-input-box">
                    <InputField
                      type="text"
                      id="email_subject"
                      name="email_subject"
                      value={formik.values.email_subject || ""}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder="Enter email subject here..."
                      onInput={(e) =>
                        (e.target.value = e.target.value
                          .replace(/[^A-Za-z ]/gi, "")
                          .replace(/(?:^|\s)\S/g, (match) =>
                            match.toUpperCase()
                          ))
                      }
                      maxLength={80}
                      disabled={slug && activeInputs}
                    />
                  </div>
                  {formik.errors.email_subject &&
                    formik.touched.email_subject && (
                      <span className="err">{formik.errors.email_subject}</span>
                    )}
                </div>
              )}

              <div className="form-control">
                <label className="form-label">Description</label>
                <div
                  className={`form-input-box ${slug && activeInputs ? "disabled-text-editor" : ""}`}
                >
                  <div className={`text-editor`}>
                    <ReactQuill
                      theme="snow"
                      modules={toolbarOptions}
                      value={formik.values.description || ""}
                      onChange={(ev) => formik.setFieldValue("description", ev)}
                      readOnly={slug && activeInputs}
                      placeholder="Enter description here"
                    />
                  </div>
                </div>
                {formik.errors.description && formik.touched.description && (
                  <span className="err">{formik.errors.description}</span>
                )}
              </div>
            </form>
          </div>
        ) : (
          <div className="email-preview-box">
            <div className="toggle-button">
              <span
                className={`${prevScreen === "desktop" ? "active" : ""}`}
                onClick={() => setPrevScreen("desktop")}
              >
                <i className="fa-light fa-desktop"></i>
              </span>
              <span
                className={`${prevScreen === "mobile" ? "active" : ""}`}
                onClick={() => setPrevScreen("mobile")}
              >
                <i className="fa-regular fa-mobile"></i>
              </span>
            </div>
            <iframe
              title="email template"
              className={`email-template ${prevScreen === "mobile" ? "mobile-view" : ""} `}
              allowFullScreen
              loading="lazy"
              srcDoc={emailVar}
            ></iframe>
            {/* <div
              className={`email-template ${prevScreen === "mobile" ? "mobile-view" : ""} `}
              dangerouslySetInnerHTML={{ __html: emailVar }}
            ></div> */}
          </div>
        )}
      </div>
    </ModuleLayout>
  );
};

export default ManageTemplate;
