/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import CustomTable from "components/CustomTable";
import ConformationPopup from "components/ui/ConformationPopup";
import SearchField from "components/ui/SearchField";
import useDisableSkelaton from "hooks/useDisableSkelaton";
import useFetchList from "hooks/useFetchList";
import ModuleLayout from "layouts/ModuleLayout";
import { levels, questionTypes, SortItems } from "helper/placeholder-data";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { addBulkQuestionAsync, deleteQuestion, getQuestionList } from "../redux/questionApi";
import { getBreadcrumbName, unixTimeToReadableFormat } from "helper/helper";
import { useNavigate } from "react-router-dom";
import DropDownBox from "components/DropDownBox";
import { getSkillList } from "modules/catalogue/skills/redux/skillApi";
import { useFormik } from "formik";
import useSelectRows from "hooks/useSelectRows";
import BulkUpload from "components/BulkUpload";
import { questionSheet } from "assets/files";

const Questions = ({ setTitle }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    setTitle("Question Management");
  }, [setTitle]);

  const { questionList, metaData, questionLoading } = useSelector(
    (state) => state.question,
    shallowEqual
  );

  const [active, setActive] = useState("question");
  const [mobileSidebar, setMobileSidebar] = useState(false);
  const [minimizedSidebar, setMinimizedSidebar] = useState(false);
  const { contentLoading } = useDisableSkelaton(questionLoading);
  const [popUp, setPopup] = useState(false)
  
  const [addStyle, setAddStyle] = useState(true);

  const {
    content,
    alertBox,
    setAlertBox,
    selectAllRows,
    selectedRows,
    setSelectedRows,
    toggleSelectAll,
    toggleRowSelection,
    deleteFunction,
  } = useSelectRows(questionList, "question_id");

  const {
    privileges,
    limit,
    setLimit,
    sort,
    setSort,
    search,
    setSearch,
    btnNo,
    setBtnNo,
    tableIndex,
    setTableIndex,
  } = useFetchList(getQuestionList, metaData, selectAllRows, setSelectedRows);

  const listContent = questionList.map((item, i) => {
    const { id, num, title, slug, level, type, createdAt } = item;
    const crDt = unixTimeToReadableFormat(createdAt);
    const similarItems = [
      { style: "que-title", val: title },
      { style: "", val: level },
      { style: "", val: type },
      { style: "", val: crDt },
    ];
    return [id, num, title, slug, similarItems];
  });

  const TABLEDATA = {
    loadingState: contentLoading,
    skalatonDetails: { col: 4, rows: limit },
    list: questionList,
    listHeading: ["question", "Level", "Type", "Created At"],
    listContent,
    selectedRows,
    selectAllRows,
    setSelectedRows,
    toggleSelectAll,
    toggleRowSelection,
    deleteFunction,
    sliceName: "question",
    PagAPI: getQuestionList,
    search,
    sort,
    limit,
    setLimit,
    btnNo,
    setBtnNo,
    tableIndex,
    setTableIndex,
    privileges,
    hasAccess: "questionManagement",
    navigateTo: (params) => {
      const user = questionList.find((question) => question.id === params);
      const breadcrumbName = getBreadcrumbName(user);
      navigate(`/catalogue/question-management/${params}`, {
        state: { slug: params, title: breadcrumbName },
      });
    },
    metaData,
  };

  const formik = useFormik({
    initialValues: {
      type: "",
      level: "",
      isSurvey: "",
    },
  });

  useEffect(() => {
    dispatch(
      getSkillList({
        page: 0,
        limit: 0,
        search: "",
        filter: "",
      })
    );
  }, [formik.values.skills]);

  useEffect(() => {
    const data = {
      page: 0,
      limit: limit,
      sort: sort,
      type: formik.values.type,
      level: formik.values.level,
      survey: formik.values.isSurvey,
    };
    dispatch(getQuestionList(data));
  }, [formik.values, sort]);

  const handleClick = () => {
    setAddStyle((prev) => !prev);
    chatBoxVisibility()
  };

  function chatBoxVisibility() {
    if (popUp) {
      const styleTimer = setTimeout(() => {
        setPopup(false);
        document
          .getElementsByClassName("container")[0]
          .classList.remove("popUp-container");
        clearTimeout(styleTimer);
      }, 200);
    }
  }

  return (
    <ModuleLayout
      minimizedSidebar={minimizedSidebar}
      setMinimizedSidebar={setMinimizedSidebar}
      mobileSidebar={mobileSidebar}
      setMobileSidebar={setMobileSidebar}
      active={active}
      setActive={setActive}
      content={content}
      alertBox={alertBox}
    >
      <header className="list-header">
        <div className="list-title">
          <h2>Question Management</h2>
          <div className="flex-center gap-1">
            {Object.values(privileges["questionManagement"]).includes(
              "write"
            ) && (
                <div className="flex gap-1">
                  <button
                    className="btn-primary flex-center gap-1"
                    onClick={() =>
                      navigate("/catalogue/question-management/add-question")
                    }
                  >
                    <i className="fa-solid fa-feather"></i> Add Question
                  </button>

                  <button
                    className="btn-outlined flex-center gap-1"
                    onClick={() => setPopup(true)}
                  >
                    <i className="fa-solid fa-feather"></i> Add Multiple Question
                  </button>
                </div>
              )}

            {Object?.values(privileges["questionManagement"])?.includes(
              "delete"
            ) && selectedRows.length !== 0 ? (
              <button
                className="btn-secondary flex-center gap-1"
                onClick={() => {
                  deleteFunction("all Questions");
                }}
              >
                <i className="fa-light fa-trash"></i>
                {`Delete ${selectedRows.length}`}
              </button>
            ) : null}
          </div>
        </div>
      </header>
      <div className="list-container custom-box">
        <div className="flex-between">
          <div className="flex gap-1">
            <DropDownBox
              options={SortItems}
              dropDownTitle="Sort"
              animateDropDownTitle
              customSetter={setSort}
              customDropBoxStyles={{
                opacity: 0.6,
                color: "#262626",
                padding: "0.6rem",
                width: "9rem",
                border: "1px solid var(--color-neutral-92)",
              }}
            />
            <DropDownBox
              options={questionTypes}
              dropDownTitle="Select Type"
              animateDropDownTitle
              customSetter={formik.setFieldValue}
              customFormikLabel="type"
              customDropBoxStyles={{
                opacity: 0.6,
                color: "#262626",
                padding: "0.6rem",
                width: "9rem",
                border: "1px solid var(--color-neutral-92)",
              }}
              resetButton
            />
            <DropDownBox
              options={levels}
              dropDownTitle="Select Level"
              animateDropDownTitle
              customSetter={formik.setFieldValue}
              customFormikLabel="level"
              customDropBoxStyles={{
                opacity: 0.6,
                color: "#262626",
                fontSize: "0.9rem",
                padding: "0.6rem",
                width: "9rem",
                border: "1px solid var(--color-neutral-92)",
              }}
              resetButton
            />
            <DropDownBox
              options={[
                { label: "Survey", value: "true" },
                { label: "Quiz", value: "false" },
              ]}
              dropDownTitle="Select Quiz"
              animateDropDownTitle
              customSetter={formik.setFieldValue}
              customFormikLabel="isSurvey"
              customDropBoxStyles={{
                opacity: 0.6,
                color: "#262626",
                fontSize: "0.9rem",
                padding: "0.6rem",
                width: "9rem",
                border: "1px solid var(--color-neutral-92)",
              }}
              resetButton
            />
          </div>

          <SearchField value={search} setValue={setSearch} />
        </div>
        <CustomTable {...TABLEDATA} />
      </div>

      {alertBox && (
        <ConformationPopup
          customState={alertBox}
          customSetter={setAlertBox}
          api={deleteQuestion}
          popUpData={content}
          customFunction={() => setSelectedRows(new Set())}
        // toastMessage={"Question removed successfully"}
        />
      )}
      {
        popUp &&
        <div id="confirm-box-wrapper">
          <div
            className={
              "popup-wrapper" +
              (addStyle
                ? " show_delete-component-fadeIn-animation"
                : " hide_delete-component-fadeOut-animation")
            }
          >
            <div
              className={`popup-content w-32`}
            >
              <div className="popup-header">
                <h2>Add Multiple Questions</h2>
                <button className="close flex-center" onClick={() => setPopup(false)}>
                  <i className="fa-sharp fa-regular fa-xmark-large"></i>
                </button>
              </div>
              <BulkUpload
                fetchApi={addBulkQuestionAsync}
                sheet={questionSheet}
                templateName={"Question Template"}
                handleClick={handleClick}
              />
            </div>
          </div>
        </div>
      }
    </ModuleLayout>
  );
};

export default Questions;
