/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import CustomTable from "components/CustomTable";
import ConformationPopup from "components/ui/ConformationPopup";
import SearchField from "components/ui/SearchField";
import useDisableSkelaton from "hooks/useDisableSkelaton";
import useFetchList from "hooks/useFetchList";
import useDeleteContent from "hooks/useDeleteContent";
import ModuleLayout from "layouts/ModuleLayout";
import { setPageDetails } from "modules/auth/redux/authSlice";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { getBreadcrumbName, unixTimeToReadableFormat } from "helper/helper";
import { useNavigate } from "react-router-dom";
import DropDownBox from "components/DropDownBox";
import { useFormik } from "formik";
import { getOrderList } from "modules/orders/redux/orderApi";

const Shipment = ({ setTitle }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        setTitle("Order Management");
    }, [setTitle]);

    const { orderList, metaData, orderLoading } = useSelector(
        (state) => state.order,
        shallowEqual
    );

    const [active, setActive] = useState("shipment");
    const [mobileSidebar, setMobileSidebar] = useState(false);
    const [minimizedSidebar, setMinimizedSidebar] = useState(false);
    const { contentLoading } = useDisableSkelaton(orderLoading);

    const {
        content,
        alertBox,
        setAlertBox,
        selectAllRows,
        collectAllRows,
        setSelectAllRows,
        setCollectAllRows,
        deleteFunction,
    } = useDeleteContent(orderList, "_id");

    const {
        privileges,
        limit,
        setLimit,
        sort,
        setSort,
        search,
        setSearch,
        btnNo,
        setBtnNo,
        tableIndex,
        setTableIndex,
    } = useFetchList(
        getOrderList,
        metaData,
        selectAllRows,
        setSelectAllRows,
        setCollectAllRows
    );

    useEffect(() => {
        dispatch(setPageDetails({ page: metaData?.page, search }));
    }, [dispatch, metaData, search]);

    const listContent = orderList?.map((item, i) => {
        const { _id, num, orderId, currency, netAmount, createdAt } = item;
        const crDt = unixTimeToReadableFormat(createdAt);
        const similarItems = [
            { style: "name", val: orderId },
            { style: "name", val: `${currency?.symbol} ${netAmount}` },
            { style: "name", val: crDt },
            { style: "", val: crDt },
            { style: "name", val: `Awaiting Shipment` },
            { style: "", val: `Pending` },
        ];
        return [_id, num, orderId, netAmount, similarItems];
    });

    const TABLEDATA = {
        loadingState: contentLoading,
        skalatonDetails: { col: 4, rows: limit },
        list: orderList,
        listHeading: ["Order ID", "Amount", "Shipping Date", "Order Date", "Order Status", "Payment Status"],
        listContent,
        selectAllRows,
        setSelectAllRows,
        collectAllRows,
        setCollectAllRows,
        deleteFunction,
        sliceName: "question",
        PagAPI: getOrderList,
        search,
        sort,
        limit,
        setLimit,
        btnNo,
        setBtnNo,
        tableIndex,
        setTableIndex,
        privileges,
        hasAccess: "orderManagement",
        navigateTo: (params) => {
            const user = orderList.find((order) => order._id === params);
            const breadcrumbName = getBreadcrumbName(user);
              navigate(`/shipment/${params}`, {
                state: { slug: params, title: breadcrumbName },
              });
        },
        metaData,
    };

    const formik = useFormik({
        initialValues: {
            status: "",
            level: "",
        },
    });

    useEffect(() => {
        const data = {
            page: 0,
            limit: limit,
            sort: sort,
            status: formik.values.status,
        };
        dispatch(getOrderList(data));
    }, [formik.values, sort]);

    return (
        <ModuleLayout
            minimizedSidebar={minimizedSidebar}
            setMinimizedSidebar={setMinimizedSidebar}
            mobileSidebar={mobileSidebar}
            setMobileSidebar={setMobileSidebar}
            active={active}
            setActive={setActive}
            content={content}
            alertBox={alertBox}
        >
            <header className="list-header">
                <div className="list-title">
                    <h2>Shipment Management</h2>
                    <div className="flex-center gap-1">
                        {Object?.values(privileges["questionManagement"])?.includes(
                            "delete"
                        ) && selectAllRows ? (
                            <button
                                className="btn-secondary flex-center gap-1"
                                onClick={() => {
                                    deleteFunction("all Questions");
                                }}
                            >
                                <i className="fa-light fa-trash"></i>
                                {`Delete ${collectAllRows.length > 1
                                        ? "All " + collectAllRows.length
                                        : ""
                                    }`}
                            </button>
                        ) : null}
                    </div>
                </div>
            </header>
            <div className="list-container custom-box">
                <div className="flex-between">
                    <div className="flex gap-1">
                        <DropDownBox
                            options={[
                                { label: "Newest", value: "asc" },
                                { label: "Oldest", value: "desc" },
                            ]}
                            dropDownTitle="Sort"
                            animateDropDownTitle
                            customSetter={setSort}
                            customDropBoxStyles={{
                                opacity: 0.6,
                                color: "#262626",
                                padding: "0.6rem",
                                width: "9rem",
                                border: "1px solid var(--color-neutral-92)",
                            }}
                        />
                        <DropDownBox
                            options={[
                                { label: "Open", value: "open" },
                                { label: "Pending", value: "pending" },
                                { label: "Processing", value: "processing" },
                                { label: "Completed", value: "completed" },
                            ]}
                            dropDownTitle="Select Status"
                            animateDropDownTitle
                            customSetter={formik.setFieldValue}
                            customFormikLabel="status"
                            customDropBoxStyles={{
                                opacity: 0.6,
                                color: "#262626",
                                padding: "0.6rem",
                                width: "9rem",
                                border: "1px solid var(--color-neutral-92)",
                            }}
                            resetButton
                        />
                    </div>

                    <SearchField value={search} setValue={setSearch} />
                </div>
                <CustomTable {...TABLEDATA} />
            </div>

            {alertBox && (
                <ConformationPopup
                    customState={alertBox}
                    customSetter={setAlertBox}
                    //   api={deleteQuestion}
                    popUpData={content}
                    customFunction={() => {
                        setSelectAllRows(false);
                        setCollectAllRows([]);
                    }}
                    toastMessage={"Question removed successfully"}
                />
            )}
        </ModuleLayout>
    );
};

export default Shipment;
