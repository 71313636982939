/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useState } from "react";
import ModuleLayout from "layouts/ModuleLayout";
import DropDownBox from "components/DropDownBox";
import Loader from "components/ui/Loader";
import SubmitButton from "components/ui/SubmitButton";
import { uploadImage, urlToObject } from "helper/helper";
import QueListPopup from "./QueListPopup";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { addQuiz, getQuizDetail, updateQuizDetail } from "../redux/quizApi";
import { getSkillList } from "modules/catalogue/skills/redux/skillApi";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { getAllQues } from "modules/catalogue/questions/redux/questionApi";
import { clearQuizDetail } from "../redux/quizSlice";

const QuizManager = ({ setTitle }) => {
  const [quePopup, setQuePopup] = useState(false);
  const [active, setActive] = useState("quizes");
  const [mobileSidebar, setMobileSidebar] = useState(false);
  const [minimizedSidebar, setMinimizedSidebar] = useState(false);
  const [activeInputs, setActiveInputs] = useState(true);
  const [quizImage, setQuizImage] = useState(null);
  const [selQues, setSelQues] = useState([]);
  const [qfList, setQfList] = useState([]);

  const { allQues } = useSelector((state) => state.question, shallowEqual);
  const { skillList } = useSelector((state) => state.CatSkill, shallowEqual);
  const { quizLoading, quizDetail } = useSelector(
    (state) => state.quiz,
    shallowEqual
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { quiz_id } = useParams();

  useEffect(() => {
    setTitle("Manage Quizzes");

    dispatch(
      getSkillList({
        page: 0,
        limit: 0,
        search: "",
        filter: "",
      })
    );

    dispatch(
      getAllQues({
        page: 0,
        limit: 0,
        type: "",
        level: "",
        search: "",
      })
    );
  }, [setTitle, dispatch]);

  useEffect(() => {
    if (quiz_id !== "add-quiz") {
      dispatch(getQuizDetail(quiz_id));
    }

    return () => dispatch(clearQuizDetail());
  }, [dispatch, quiz_id]);

  const skills = skillList.map((skill) => ({
    label: skill.name,
    value: skill.id,
  }));

  const initialValues = {
    file: null,
    skill_id: "",
    quiz_name: "",
    quiz_type: "skill",
    quiz_level: "",
    description: [{ title: "", description: "" }],
    questions: [],
  };

  const validationSchema = Yup.object({
    file: Yup.mixed().required("Quiz image is required"),
    skill_id: Yup.string().required("Select a skill type"),
    quiz_name: Yup.string()
      .required("Quiz title is required")
      .max(50, "Quiz title must not exceed 50 characters"),
    quiz_type: Yup.string().required("Quiz type is required"),
    quiz_level: Yup.string().required("Quiz level is required"),
    description: Yup.array().required("Description is required for the quiz"),
    questions: Yup.array().test(
      "at-least-one-question",
      "Add at least one question",
      (questions) => questions?.length > 0
    ),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      const data = {
        send: {
          image: values.file,
          skill_id: values.skill_id,
          quiz_name: values.quiz_name,
          quiz_type: values.quiz_type,
          "description[]": JSON.stringify(values.description),
          quiz_level: values.quiz_level,
          question_id: values.questions,
        },
        id: quiz_id,
      };

      if (quiz_id === "add-quiz") {
        const res = await dispatch(addQuiz(data.send));
        if (res?.payload) {
          resetForm();
          navigate(-1);
        }
      } else {
        const res = await dispatch(updateQuizDetail(data));
        if (res?.payload) {
          resetForm();
          navigate(-1);
        }
      }
    },
  });

  useEffect(() => {
    if (quiz_id !== "add-quiz" && quizDetail) {
      (async () => {
        const image =
          quizDetail?.image?.localUrl || quizDetail?.image?.bucketUrl;
        const imageObject = await urlToObject(image);
        formik.setValues({
          file: imageObject,
          skill_id: quizDetail?.skillId,
          quiz_name: quizDetail?.name,
          quiz_type: quizDetail?.type,
          quiz_level: quizDetail?.level,
          description: quizDetail?.description,
          questions: quizDetail?.question?.map((ele) => ele.questionId),
        });
        setQuizImage(image);
      })();
      setSelQues(quizDetail?.question?.map((ele) => ele.questionId));
    }
  }, [quizDetail, quiz_id]);

  useEffect(() => {
    formik.setFieldValue("questions", selQues);
  }, [selQues, quizDetail]);

  useEffect(() => {
    if (formik?.values?.file) {
      const img = URL.createObjectURL(formik?.values?.file);
      setQuizImage(img);
    }
  }, [formik?.values?.file]);

  const addMoreFields = () => {
    formik.setValues({
      ...formik.values,
      description: [
        ...formik.values.description,
        { title: "", description: "" },
      ],
    });
  };

  useEffect(() => {
    const filteredQuestions = allQues.filter((item) =>
      selQues?.includes(item.id.toString())
    );
    setQfList(filteredQuestions);
  }, [selQues]);

  const removeField = (index) => {
    const newDesc = [...formik.values.description];
    newDesc.splice(index, 1);
    formik.setValues({ ...formik.values, description: newDesc });
  };

  const InputProps = {
    className: "form-input",
    onBlur: formik.handleBlur,
    onChange: formik.handleChange,
    onInput: (e) =>
      (e.target.value = e.target.value.replace(/^./, (match) =>
        match.toUpperCase()
      )), // Capitalize the first letter of the input
    disabled: quiz_id !== "add-quiz" ? activeInputs : false,
  };

  return (
    <ModuleLayout
      minimizedSidebar={minimizedSidebar}
      setMinimizedSidebar={setMinimizedSidebar}
      setMobileSidebar={setMobileSidebar}
      mobileSidebar={mobileSidebar}
      setActive={setActive}
      active={active}
    >
      <Loader isLoading={quizLoading} />

      <div className="list-container custom-box">
        <div className="form-header">
          <h2>Quiz Management</h2>

          {quiz_id !== "add-quiz" && (
            <div className="flex gap-2">
              {activeInputs ? (
                <>
                  <button
                    type="button"
                    onClick={() => navigate(-1)}
                    className="btn-outlined cancel"
                  >
                    Back
                  </button>
                  <button
                    type="button"
                    onClick={() => setActiveInputs(false)}
                    className="btn-primary"
                  >
                    Update
                  </button>
                </>
              ) : (
                <>
                  <button
                    type="button"
                    onClick={() => setActiveInputs(true)}
                    className="btn-outlined tertiary"
                  >
                    Abort
                  </button>
                  <SubmitButton
                    className="btn-primary"
                    type="submit"
                    onClick={formik.handleSubmit}
                    disabled={formik.isSubmitting}
                  >
                    Submit
                  </SubmitButton>
                </>
              )}
            </div>
          )}

          {quiz_id === "add-quiz" && (
            <div className="flex gap-1">
              <button
                type="button"
                onClick={() => navigate(-1)}
                className="btn-outlined cancel"
              >
                Back
              </button>
              <button
                className="btn-primary"
                type="submit"
                onClick={() => formik.handleSubmit()}
                disabled={formik.isSubmitting}
              >
                Submit
              </button>
            </div>
          )}
        </div>

        <main className="main">
          <div className="auth-form-wrapper">
            <form>
              <div className="genral-quiz-info">
                <div className="quiz-image">
                  <label htmlFor="file" className="image-label">
                    {quizImage ? (
                      <img
                        src={quizImage}
                        alt="quiz"
                        crossOrigin="anonymous"
                        style={{
                          width: "100%",
                          height: "100%",
                          maxHeight: "235px",
                          objectFit: "cover",
                        }}
                      />
                    ) : (
                      <div className="dummy-img flex-center">
                        <i className="fa-duotone fa-solid fa-image dummy-img__icon"></i>
                      </div>
                    )}
                    <input
                      id="file"
                      name="file"
                      type="file"
                      className="form-input"
                      accept=".jpg, .jpeg, .png, .gif, .webp"
                      onChange={(e) => uploadImage(e, formik.setFieldValue)}
                      onBlur={formik.handleBlur}
                      disabled={quiz_id !== "add-quiz" ? activeInputs : false}
                      hidden
                    />
                  </label>
                  {formik.errors.file && formik.touched.file && (
                    <span className="err">{formik.errors.file}</span>
                  )}
                </div>

                <div className="quiz-general">
                  <div className="form-control">
                    <label className="form-label">Quiz Title</label>
                    <div className="form-input-box">
                      <input
                        type="text"
                        id="quiz_name"
                        name="quiz_name"
                        value={formik.values.quiz_name}
                        {...InputProps}
                        placeholder="Enter quiz title here"
                        maxLength={51}
                      />
                    </div>
                    {formik.errors.quiz_name && formik.touched.quiz_name ? (
                      <span className="err">{formik.errors.quiz_name}</span>
                    ) : null}
                  </div>

                  <div className="form-control">
                    <label className="form-label">Quiz Level</label>
                    <div className="form-input-box">
                      <DropDownBox
                        options={[
                          { label: "Level 1", value: "easy" },
                          { label: "Level 2", value: "medium" },
                          { label: "Level 3", value: "hard" },
                          { label: "Level 4", value: "expert" },
                          { label: "Level 5", value: "master" },
                          { label: "Level 6", value: "extreme" },
                        ]}
                        defaultValue="Select Level"
                        customSetter={formik.setFieldValue}
                        customFormikLabel="quiz_level"
                        customDropBoxStyles={{
                          border: "1px solid #e6e6e6",
                          borderRadius: "0.5rem",
                          color: "#454545",
                        }}
                        customTextStyle={{
                          color: "#212229",
                          opacity: "0.7",
                        }}
                        incomingValue={formik.values.quiz_level}
                        disabled={quiz_id !== "add-quiz" ? !activeInputs : true}
                      />
                    </div>
                    {formik.errors.quiz_level && formik.touched.quiz_level ? (
                      <span className="err">{formik.errors.quiz_level}</span>
                    ) : null}
                  </div>

                  <div className="form-control">
                    <label className="form-label">In which skill?</label>
                    <div className="form-input-box">
                      <DropDownBox
                        options={skills}
                        defaultValue="Select Skill"
                        customSetter={formik.setFieldValue}
                        customFormikLabel="skill_id"
                        customDropBoxStyles={{
                          border: "1px solid #e6e6e6",
                          borderRadius: "0.5rem",
                          color: "#454545",
                        }}
                        customTextStyle={{
                          color: "#212229",
                          opacity: "0.7",
                        }}
                        incomingValue={formik.values.skill_id}
                        disabled={quiz_id !== "add-quiz" ? !activeInputs : true}
                      />
                    </div>
                    {formik.errors.skill_id && formik.touched.skill_id ? (
                      <span className="err">{formik.errors.skill_id}</span>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="form-control mt-2">
                <label className="form-label">Add Pre Description</label>
                <div className="form-input-box">
                  {formik?.values?.description &&
                    formik?.values?.description.map((desc, index) => (
                      <div
                        key={index}
                        className="flex gap-1"
                        style={{ alignItems: "end" }}
                      >
                        <div
                          className="form-col-2  mt-1"
                          style={{ width: "100%" }}
                        >
                          <div className="form-control">
                            <label className="form-label">Title</label>
                            <div className="form-input-box">
                              <input
                                type="text"
                                id={`key-${index}`}
                                name={`description[${index}].title`}
                                value={desc.title}
                                {...InputProps}
                                placeholder="Enter pre description title here"
                                maxLength={81}
                              />
                            </div>
                          </div>
                          <div className="form-control">
                            <label className="form-label">Description</label>
                            <div className="form-input-box">
                              <input
                                type="text"
                                id={`value-${index}`}
                                name={`description[${index}].description`}
                                value={desc.description}
                                {...InputProps}
                                placeholder="Enter pre description title here"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="flex-center gap-2">
                          <button
                            type="button"
                            className="icon-btn delete"
                            style={{ flexShrink: 0 }}
                            onClick={() => removeField(index)}
                            disabled={formik?.values?.description.length < 2}
                          >
                            <i className="fa-solid fa-file-xmark medium"></i>
                          </button>
                          <button
                            type="button"
                            className="icon-btn view"
                            onClick={() => addMoreFields()}
                            disabled={
                              quiz_id !== "add-quiz" ? activeInputs : false
                            }
                          >
                            <i className="fa-thin fa-file-plus medium"></i>
                          </button>
                        </div>
                      </div>
                    ))}
                </div>
              </div>

              <div className="flex-between mt-2">
                <h3>Selected Questions</h3>
                <button
                  className="btn-outlined"
                  type="button"
                  onClick={() => setQuePopup(!quePopup)}
                  disabled={quiz_id !== "add-quiz" ? activeInputs : false}
                >
                  Add Questions
                </button>
              </div>

              {formik.errors.questions && formik.touched.questions && (
                <span className="err">{formik.errors.questions}</span>
              )}

              {qfList.length > 0 && (
                <div className="table_content mt-1">
                  <table className="main_table" style={{ textAlign: "left" }}>
                    <thead className="tb_head">
                      <tr className="tb_row">
                        <th className="tbh_name">Title</th>
                        <th className="tbh_name">Type</th>
                        <th className="tbh_name">Level</th>
                      </tr>
                    </thead>

                    <tbody className="tb_body">
                      {qfList.map((content, index) => {
                        return (
                          <tr key={index} className="tb_row">
                            <td className="tb_data">{content.title}</td>
                            <td className="tb_data">{content.type}</td>
                            <td className="tb_data">{content.level}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              )}
              {/* : (
                 <p className="w-full text-center" style={{ padding: "1rem" }}>
                  No data found <i className="fa-light fa-file-excel"></i>
               </p>
             )} */}
            </form>
          </div>
        </main>
      </div>
      {quePopup && (
        <QueListPopup
          quePopup={quePopup}
          setQuePopup={setQuePopup}
          setSelQues={setSelQues}
          selQues={selQues}
          quizLevel={formik.values.quiz_level}
        />
      )}
    </ModuleLayout>
  );
};

export default QuizManager;
