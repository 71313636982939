import { createSlice } from "@reduxjs/toolkit";
import { getCountryList, getProfileDetails } from "./profileApi";

const profileSlice = createSlice({
  name: "profile",
  initialState: {
    profileLoading: false,
    profileDetail: [],
    countryList: []
  },
  reducers: {
    isProfileToggle: (state, action) => {
      state.profileLoading = action.payload;
    },
    setProfileDetail: (state, action) => {
      
      state.profileDetail = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getProfileDetails.pending, (state) => {
      state.profileLoading = true;
    });
    builder.addCase(getProfileDetails.fulfilled, (state, { payload }) => {
      
      state.profileLoading = false;
      state.profileDetail = payload?.data;
    });
    builder.addCase(getProfileDetails.rejected, (state) => {
      state.profileLoading = false;
    });

    builder.addCase(getCountryList.pending, (state) => {
      state.profileLoading = true;
    });
    builder.addCase(getCountryList.fulfilled, (state, { payload }) => {
      state.profileLoading = false;
      state.countryList = payload?.data;
    });
    builder.addCase(getCountryList.rejected, (state) => {
      state.profileLoading = false;
    });
  },
});

export const { isProfileToggle, setProfileDetail } = profileSlice.actions;
export default profileSlice.reducer;
