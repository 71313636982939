/* eslint-disable react-hooks/exhaustive-deps */
import ModuleLayout from 'layouts/ModuleLayout'
import React, { useEffect, useState } from 'react'
import General from '../component/General';
import Payment from '../component/Payment';
import Notes from '../component/Notes';
import { useParams } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getOrderDetail, updateOrderStatus } from '../redux/orderApi';
import { profilePic } from 'assets/images';
import { unixTimeToReadableFormat } from 'helper/helper';
import ConformationPopup from 'components/ui/ConformationPopup';
import DropDownBox from 'components/DropDownBox';

export default function OrderDetail() {
    const { id } = useParams()
    const dispatch = useDispatch()
    const { orderDetail } = useSelector(state => state.order, shallowEqual)
    const [active, setActive] = useState("order");
    const [mobileSidebar, setMobileSidebar] = useState(false);
    const [minimizedSidebar, setMinimizedSidebar] = useState(false);
    const [activeTab, setActiveTab] = useState("general")
    const [condition, setCondition] = useState(0)
    const [alertBox, setAlertBox] = useState(false)
    const [popUpData, setPopUpData] = useState()
    const [odStatus, setOdStatus] = useState("")
    console.log('odStatus: ', odStatus);

    const orderStatus = {
        "Open": 0,
        "Pending": 1,
        "On Hold": 0,
        "Awaiting Fulfillment": 2,
        "Awaiting Shipment": 2,
        "Shipped": 3,
        "Partially Shipped": 3,
        "In Transit": 3,
        "Out For Delivery": 3,
        "Completed": 4,
        "Partially Completed": 4,
        "Cancelled": 5,
        "Returned": 6,
        "Refund": 7,
    }

    useEffect(() => {
        if (id) {
            dispatch(getOrderDetail({ id: id }))
        }
    }, [id])

    const manageOrderStatus = (status) => {
        const data = {
            id: id,
            send: {
                status: status
            }
        }
        dispatch(updateOrderStatus(data))
    }

    useEffect(() => {
        const sts = Object.values(orderStatus)[Object.keys(orderStatus).indexOf(orderDetail?.status?.value)]
        setCondition(sts)
        setOdStatus((orderDetail?.status?.value)?.toLowerCase())
    }, [orderDetail])

    const handleCancel = async () => {
        const data = {
            id: id,
            send: {
                status: "cancel"
            }
        }
        setPopUpData({ title: "", data })
        setAlertBox(true);
    };

    return (
        <ModuleLayout
            minimizedSidebar={minimizedSidebar}
            setMinimizedSidebar={setMinimizedSidebar}
            mobileSidebar={mobileSidebar}
            setMobileSidebar={setMobileSidebar}
            active={active}
            setActive={setActive}
        >
            <div className="list-container custom-box">
                <div className="tb_controls">
                    <h2>Order Management </h2>
                </div>
            </div>
            <main>
                <div>
                    <div className="custom-box">
                        <div className="flex-between">
                            <div className='flex-center gap-1'>
                                <div className="flex-center gap-1" style={{ borderRight: "1px solid black", paddingRight: "1rem" }}>
                                    <img src={orderDetail?.placedBy?.photo?.bucketUrl ? orderDetail?.placedBy?.photo?.bucketUrl : profilePic} alt="" width={40} />
                                    <h3 className='mb-0'>{`${orderDetail?.placedBy?.name?.firstName} ${orderDetail?.placedBy?.name?.lastName}`}</h3>
                                </div>
                                <div>
                                    <h3 className='mb-0 order-id'>Order ID : {orderDetail?.orderId} </h3>
                                    {orderDetail?.orderDate && <p className='order-id'>Order Date : {unixTimeToReadableFormat(orderDetail?.orderDate)} </p>}
                                </div>
                            </div>
                            <div className="flex gap-1">
                                {
                                    condition < 1 ?
                                        <>
                                            <button className='btn-outlined' onClick={handleCancel} type="button">Cancel</button>
                                            <button disabled={orderDetail?.status?.value === "On Hold"} className='btn-outlined' type="button" onClick={() => manageOrderStatus("onHold")}>Hold</button>
                                            <button className='btn-primary' type="button" onClick={() => manageOrderStatus("accept")} >Accept Order</button>
                                        </> :
                                        <>
                                            <DropDownBox
                                                dropDownTitle={"Change Order Status"}
                                                options={[
                                                    { label: "Shipped", value: "shipped" },
                                                    { label: "Completed", value: "completed" },
                                                    { label: "Cancelled", value: "cancelled" },
                                                    { label: "Returned", value: "returned" },
                                                    { label: "Refund", value: "refund" },
                                                ]}
                                                customSetter={setOdStatus}
                                                // value={odStatus}
                                                incomingValue={odStatus}
                                                animateDropDownTitle
                                                size={"medium"}
                                            />
                                            <button className='btn-primary' type="button" disabled={odStatus === ""} onClick={() => manageOrderStatus(odStatus)}>Submit</button>
                                        </>
                                    // :
                                    // <div
                                    //     style={{ color: condition === 4 || condition === 7 ? "green" : (condition === 5 ? "red" : "black"), fontWeight: "500" }}
                                    // >
                                    //     Order {orderDetail?.status?.value}
                                    // </div>)
                                }
                            </div>
                        </div>
                    </div>
                    <div className="flex gap-1 od-tab">
                        <button type="button" className={`odt-btn ${activeTab === "general" && "active"}`} onClick={() => setActiveTab("general")}>General</button>
                        <button type="button" className={`odt-btn ${activeTab === "payments" && "active"}`} onClick={() => setActiveTab("payments")}>Payments</button>
                        <button type="button" className={`odt-btn ${activeTab === "notes" && "active"}`} onClick={() => setActiveTab("notes")}>Notes</button>
                    </div>
                </div>

                {/* {
                    activeTab === "general" && condition !== 5 &&
                    <div className="sticky-status OD-status">
                        <span
                            className="status first"
                            style={{ background: condition >= 1 ? "#4BB543" : "#D9D9D9" }}
                        >
                            <span>{condition <= 1 ? orderDetail?.status?.value : "Pending"}</span>
                            <span
                                className="arrow-right"
                                style={{ background: condition >= 1 ? "#4BB543" : "#D9D9D9" }}
                            ></span>
                        </span>
                        <span
                            className="status"
                            style={{
                                background:
                                    condition >= 2
                                        ? "#4BB543"
                                        : condition === 1
                                            ? "#D9D9D9"
                                            : "#D9D9D9",
                            }}
                        >
                            <span className="arrow-left"></span>
                            <span>Processing</span>
                            <span
                                className="arrow-right"
                                style={{
                                    background:
                                        condition >= 2
                                            ? "#4BB543"
                                            : condition === 1
                                                ? "#D9D9D9"
                                                : "#D9D9D9",
                                }}
                            ></span>
                        </span>
                        <span
                            className="status"
                            style={{
                                background:
                                    condition >= 3
                                        ? "#4BB543"
                                        : condition === 1
                                            ? "#D9D9D9"
                                            : "#D9D9D9",
                            }}
                        >
                            {" "}
                            <span className="arrow-left"></span>
                            <span>Shipped</span>
                            <span
                                className="arrow-right"
                                style={{
                                    background:
                                        condition >= 3
                                            ? "#4BB543"
                                            : condition === 1
                                                ? "#D9D9D9"
                                                : "#D9D9D9",
                                }}
                            ></span>
                        </span>
                        <span
                            className="status last"
                            style={{
                                background:
                                    condition === 4
                                        ? "#4BB543"
                                        : condition === 3
                                            ? "#D9D9D9"
                                            : "#D9D9D9",
                            }}
                        >
                            {" "}
                            <span className="arrow-left"></span>
                            <span>Completed</span>
                        </span>
                    </div>
                } */}

                <div className="main-ODetais">
                    {activeTab === "general" && <General detail={orderDetail} />}
                    {activeTab === "payments" && <Payment payment={orderDetail?.payment} transaction={orderDetail?.transaction} currencySymbol={orderDetail?.currency?.symbol} />}
                    {activeTab === "notes" && <Notes detail={orderDetail} />}
                </div>

                {alertBox && (
                    <ConformationPopup
                        customState={alertBox}
                        customSetter={setAlertBox}
                        mainTitle="Really want to cancel this order?"
                        subTitle=""
                        api={updateOrderStatus}
                        popupType='Cancel Order'
                        cancelText='Abort'
                        popUpData={popUpData}
                    />
                )}
            </main>
        </ModuleLayout>
    )
}
