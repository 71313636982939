/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
// eslint-disable-next-line
import Table from "components/Table";
import { friendsList } from "helper/placeholder-data";
import { unixTimeToReadableFormat } from "helper/helper";
import { walnut } from "assets/icons";
import { getReferralList } from "../redux/userApi";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import TableSkelaton from "components/skelaton/TableSkelaton";
import NotFound from "components/ui/NotFound";
import SeprPagination from "components/ui/SeprPagination";

const UserFriends = () => {
  const dispatch = useDispatch()
  // eslint-disable-next-line
  const [limit, setLimit] = useState(5)
  // eslint-disable-next-line
  const [page, setPage] = useState(0)

  const listContent = friendsList.map((obj, i) => {
    const { _id, user_name, avatar, email_id, reg_date, referral_pt, status } =
      obj;
    const valArr = [
      _id,
      [
        { style: "tb_data_name", val: user_name, image: avatar },
        { style: "f-email", val: email_id },
        { style: "date", val: unixTimeToReadableFormat(reg_date) },
        { style: "tb_data_walnut", val: referral_pt, image: walnut },
        { style: `tb_data_membership ${status}`, val: status },
      ],
    ];
    return valArr;
  });
  // eslint-disable-next-line
  const table_props = {
    loadingState: false,
    skalatonDetails: { col: 5, rows: 5 },
    listHeading: [
      "Players",
      "Email ID",
      "Registration Date",
      "Referral Poins",
      "Status",
    ],
    listContent,
  };
  const { referralList, referralListMeta, userLoading, userDetail } = useSelector(
    (state) => state.user,
    shallowEqual
  );


  useEffect(() => {
    if (userDetail?.id) {
      const data = {
        userId: userDetail?.id,
        page: page,
        limit: limit,
      }
      dispatch(getReferralList(data))
    }
  }, [userDetail, limit, page])

  return (
    <div className="custom-box">
      <h3>Referral Friends {referralListMeta?.total }</h3>
      {
        userLoading ?
          <TableSkelaton col={3} row={5} /> :
          referralList?.length > 0 ?
            <div>
              <div className="table_container">
                <div className="table_content">
                  <table className="main_table text-left">
                    <thead className="tb_head">
                      <tr className="tb_row">
                        <th className="tbh_name neutral">Sr No.</th>
                        <th className="tbh_name neutral">Player Name</th>
                      </tr>
                    </thead>
                    <tbody className="tb_body">
                      {referralList?.map((player) =>
                        <tr className="tb_row" key={player?.id}>
                          <td className="tb_data">{player.num}</td>
                          <td className="tb_data">{player.name?.firstName} {player.name?.lastName}</td>
                        </tr>)}
                    </tbody>
                  </table>
                </div>
              </div>

              <SeprPagination
                metaData={referralListMeta}
                setRecords={setLimit}
                handlePageChange={setPage}
                records={limit}
                isLimitBox
              />
            </div> : <NotFound />
      }
    </div>
  );
};

export default UserFriends;
