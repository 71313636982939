/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { uploadImage, urlToObject } from "helper/helper";
import SubmitButton from "components/ui/SubmitButton";
import BulkUpload from "components/BulkUpload";
import { skillsSheet } from "assets/files";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  addBulkBrandAsync,
  addBrand,
  getBrandDetail,
  updateBrandDetail,
} from "../redux/brandsApi";
import { clearBrandDetail } from "../redux/brandsSlice";

const ManageBrand = ({ popup, setPopup, setBtnNo, setTableIndex }) => {
  
  const [addStyle, setAddStyle] = useState(true);
  const [activeInputs, setActiveInputs] = useState(true);
  const [categoryImg, setCategoryImg] = useState(null);

  const dispatch = useDispatch();
  const { brandDetail } = useSelector((state) => state.CatBrand, shallowEqual);
  

  const BrandImage =
    brandDetail?.image?.bucketUrl || brandDetail?.image?.localUrl;

  useEffect(() => {
    if (popup.type === "update-brand") {
      dispatch(getBrandDetail({ id: popup.id }));
    }

    return () => dispatch(clearBrandDetail());
  }, [dispatch, popup.id]);

  const formik = useFormik({
    initialValues: {
      name: "",
      file: null,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .trim()
        .min(3, "Brand name must be at least 3 characters long")
        .max(80, "Brand name must be less then 80 characters")
        // .matches(/^[A-Za-z ]+$/, "Please enter a valid name")
        .required("Brand name should not be empty"),
      // file: Yup.mixed().required("Please upload image first"),
      file: Yup.mixed().required("Brand image is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      const data = {
        send: {
          image: values.file,
          brand_name: values.name.trim(),
        },
        id: popup.id,
      };
      if (popup.type === "add-brand") {
        const response = await dispatch(addBrand(data?.send));
        if (response?.payload) {
          setPopup({ show: false, type: "", id: "" });
          setTableIndex(0);
          setBtnNo([1, 2, 3]);
          resetForm();
        }
      }

      if (popup.type === "update-brand") {
        const response = await dispatch(updateBrandDetail(data));
        if (response?.payload) {
          setPopup({ show: false, type: "", id: "" });
          resetForm();
        }
      }
    },
  });

  useEffect(() => {
    if (formik.values.file) {
      const img = URL.createObjectURL(formik.values.file);
      setCategoryImg(img);
    }
  }, [formik.values.file]);

  useEffect(() => {
    if (popup.type === "update-brand" && brandDetail) {
      (async () => {
        const imageObject = BrandImage && (await urlToObject(BrandImage));
        formik.setValues({
          name: brandDetail?.name,
          file: imageObject,
        });
        setCategoryImg(BrandImage);
      })();
    }
  }, [brandDetail, popup.id]);

  useEffect(() => {
    document
      .getElementsByClassName("container")[0]
      .classList.add("popUp-container");
  }, []);

  const handleClick = () => {
    if (activeInputs === false) {
      setActiveInputs(true);
    } else {
      setAddStyle(!addStyle);
      chatBoxVisibility();
    }
  };

  function chatBoxVisibility() {
    if (popup.show) {
      const styleTimer = setTimeout(() => {
        setPopup({ ...popup, show: !popup.show });
        document
          .getElementsByClassName("container")[0]
          .classList.remove("popUp-container");
        clearTimeout(styleTimer);
      }, 200);
    }
  }

  return (
    <div id="confirm-box-wrapper">
      <div
        className={
          "popup-wrapper" +
          (addStyle
            ? " show_delete-component-fadeIn-animation"
            : " hide_delete-component-fadeOut-animation")
        }
      >
        <div
          className={`popup-content ${popup.type === "add-multiple-brand" ? "w-32" : ""}`}
        >
          <div className="popup-header">
            <h2>{popup.type.replaceAll("-", " ")}</h2>

            <button className="close flex-center" onClick={() => handleClick()}>
              <i className="fa-sharp fa-regular fa-xmark-large"></i>
            </button>
          </div>

          {popup.type === "add-brand" || popup.type === "update-brand" ? (
            <div className="pc-wrapper">
              <div className="auth-form-wrapper">
                <form
                  className="form"
                  onSubmit={formik.handleSubmit}
                  // noValidate="novalidate"
                >
                  <div className="form-control">
                    <label htmlFor="file" className="form-label">
                      {categoryImg ? (
                        <div
                          className="update-image-wrapper flex-center"
                          style={
                            activeInputs ? { cursor: "not-allowed" } : null
                          }
                        >
                          <img
                            src={categoryImg}
                            alt="brand"
                            crossOrigin="anonymous"
                          />
                        </div>
                      ) : (
                        <div className="flex-center gap-2">
                          <div className="dummy-img flex-center">
                            <i className="fa-duotone fa-solid fa-image dummy-img__icon"></i>
                          </div>
                          <span className="profChange_label">Add Icon</span>
                        </div>
                      )}
                      <input
                        id="file"
                        name="file"
                        type="file"
                        className="form-input"
                        accept=".jpg, .jpeg, .png, .gif, .webp"
                        onChange={(e) => uploadImage(e, formik.setFieldValue)}
                        onBlur={formik.handleBlur}
                        disabled={
                          popup.type === "update-brand" ? activeInputs : false
                        }
                        hidden
                      />
                    </label>
                    {formik.errors.file && formik.touched.file && (
                      <span className="err">{formik.errors.file}</span>
                    )}
                  </div>

                  <div className="form-control">
                    <label className="form-label">Brand Name</label>
                    <div className="form-input-box">
                      <input
                        id="name"
                        name="name"
                        type="text"
                        placeholder="Please enter brand here..."
                        value={formik.values.name || ""}
                        className="form-input"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        // onKeyDown: (e) => e.key === " " && e.preventDefault(),
                        onInput={(e) =>
                          (e.target.value = e.target.value
                            // .replace(/[^A-Za-z ]/gi, "")
                            .replace(/(?:^|\s)\S/g, (match) =>
                              match.toUpperCase()
                            ))
                        }
                        disabled={
                          popup.type === "update-brand" ? activeInputs : false
                        }
                        maxLength={80}
                      />
                    </div>
                    {formik.errors.name && formik.touched.name && (
                      <span className="err">{formik.errors.name}</span>
                    )}
                  </div>

                  <div className="flex-center">
                    {popup.type === "update-brand" && activeInputs === true ? (
                      <button
                        className="btn-outlined flex-center"
                        onClick={() => setActiveInputs(!activeInputs)}
                      >
                        Edit Brand
                      </button>
                    ) : (
                      <SubmitButton
                        size="fit-content"
                        className="btn-primary"
                        type="submit"
                        isLoading={formik.isSubmitting}
                      >
                        Submit
                      </SubmitButton>
                    )}
                  </div>
                </form>
              </div>
            </div>
          ) : null}
          {popup.type === "add-multiple-brand" && (
            <BulkUpload
              sheet={skillsSheet}
              fetchApi={addBulkBrandAsync}
              handleClick={handleClick}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ManageBrand;
