/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import { getUserDetail } from "../redux/userApi";
import { resetUserDetails } from "../redux/userSlice";
import { App_User_Tab_NavList } from "helper/placeholder-data";
import { premium } from "assets/icons";
// eslint-disable-next-line
import { unixTimeToReadableFormat } from "helper/helper";
import { profilePic2 } from "assets/images";

const UserHeader = () => {
  const dispatch = useDispatch();
  const { user_id } = useParams();
  const { pathname } = useLocation();

  useEffect(() => {
    dispatch(getUserDetail(user_id));
    return () => {
      dispatch(resetUserDetails());
    };
  }, [dispatch]);

  const { userDetail } = useSelector((state) => state.user, shallowEqual);

  return (
    <div className="details-wrapper-header">
      <div className="profile-header">
        <div className="profile-header__primary">
          <div className="left flex-center gap-2">
            <img
              src={userDetail?.photo?.localUrl || profilePic2}
              className="profile-picture"
              alt="profile"
              crossOrigin="anonymous"
            />
            <div className="flex flex-col gap-2">
              <div>
                <h3>
                  {`${userDetail?.name?.firstName} ${userDetail?.name?.lastName}`}
                </h3>
                <a href={`mailto:${userDetail?.email?.value}`}>
                  {userDetail?.email?.value}
                </a>
              </div>

              <div className="flex gap-2">
                <address className="flex-center gap-1">
                  <i
                    className="fa-regular fa-location-dot"
                    style={{ color: "var(--color-neutral)" }}
                  ></i>
                  <span>{userDetail?.address || "India"}</span>
                </address>
                {/* <p className="flex-center gap-1">
                  <i className="fa-regular fa-calendar-day"></i>{" "}
                  <span>
                    {`Joined ${unixTimeToReadableFormat(userDetail?.createdAt)}`}
                  </span>
                </p> */}
              </div>
            </div>
          </div>

          {userDetail?.is_premium && (
            <div className="right flex-center gap-1">
              <img src={premium} alt="" />
              <p>3 Month Premium Subscription</p>
            </div>
          )}
        </div>
        {/* <div className="profile-header__btr">
          <div className="left flex-center gap-1">
            <div className="text-center">
              <h2>{userDetail?.badges || 0}</h2>
              <p>Badges</p>
            </div>
            <hr className="points-divider" />
            <div className="text-center">
              <h2>{userDetail?.trophy || 0}</h2>
              <p>Trophy</p>
            </div>
            <hr className="points-divider" />
            <div className="text-center">
              <h2>{userDetail?.referers || 0}</h2>
              <p>Referrals</p>
            </div>
          </div>
          <div className="right flex-center gap-1">
            <div className="text-center">
              <h2>{userDetail?.streaks || 0}</h2>
              <p>Strikes</p>
            </div>
            <hr className="points-divider" />

            <div className="text-center">
              <h2>{userDetail?.lifes || 0}</h2>
              <p>Life's</p>
            </div>
            <hr className="points-divider" />

            <div className="text-center">
              <h2>
                {userDetail?.walnuts > 1000
                  ? `${userDetail?.walnuts / 1000}K`
                  : userDetail?.walnuts}
                {userDetail?.walnuts || 0}
              </h2>
              <p>Walnuts Points</p>
            </div>
            <hr className="points-divider" />
            <div className="text-center">
              <h2>
                {userDetail?.xp_points > 1000
                  ? `${userDetail?.xp_points / 1000}K`
                  : userDetail?.xp_points}
                {userDetail?.xp_points || 0}
              </h2>
              <p>Experience Points</p>
            </div>
          </div>
        </div> */}
      </div>

      <div className="profile-footer">
        <ul className="list">
          {App_User_Tab_NavList.map((nav, i) => (
            <li
              key={i}
              className={`${pathname === nav.link(user_id) ? "active" : ""}`}
            >
              <Link
                className="user-nav-tab"
                to={nav.link(user_id)}
                state={{
                  title: `${userDetail?.name?.firstName} ${userDetail?.name?.lastName}`,
                  slug: `${user_id}`,
                }}
              >
                <i className={nav.icon}></i>
                <span>{nav.title}</span>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default UserHeader;
