import { createSlice } from "@reduxjs/toolkit";
import { getCategoryDetail, getCategoryList } from "./categoryApi";

const categorySlice = createSlice({
  name: "CatCategory",
  initialState: {
    categoryLoading: false,
    categoryList: [],
    metaData: [],
    categoryDetail: {},
  },
  reducers: {
    isCategoryLoading: (state, action) =>
      (state = {
        ...state,
        categoryLoading: action.payload,
      }),
    clearCategoryDetail: (state, action) => {
      state.categoryDetail = {};
    },
  },
  extraReducers: (builder) => {
    // getCategoryList reducers-------------------------
    builder.addCase(getCategoryList.pending, (state, action) => {
      state.categoryLoading = true;
    });
    builder.addCase(getCategoryList.fulfilled, (state, action) => {
      state.categoryLoading = false;
      state.categoryList = action?.payload?.data;
      state.metaData = action?.payload?.metadata[0];
    });
    builder.addCase(getCategoryList.rejected, (state, action) => {
      state.categoryLoading = false;
      state.categoryList = [];
    });
    // getGlossaryDetail reducers-------------------------
    builder.addCase(getCategoryDetail.pending, (state, action) => {
      state.categoryLoading = true;
    });
    builder.addCase(getCategoryDetail.fulfilled, (state, action) => {
      state.categoryLoading = false;
      state.categoryDetail = action.payload.data;
    });
    builder.addCase(getCategoryDetail.rejected, (state, action) => {
      state.categoryLoading = false;
    });
  },
});

export const { isCategoryLoading, clearCategoryDetail } = categorySlice.actions;
export default categorySlice.reducer;
