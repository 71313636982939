/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import SubmitButton from "components/ui/SubmitButton";
import ModuleLayout from "layouts/ModuleLayout";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import InputField from "components/ui/InputField";
import DropDownBox from "components/DropDownBox";
import * as Yup from "yup";
import {
  addQuestion,
  getQuestionDetail,
  updateQuestionDetail,
} from "../redux/questionApi";
import { clearQuestionDetail } from "../redux/questionSlice";

const QueManager = ({ setTitle }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { question_id } = useParams();

  useEffect(() => {
    setTitle("Manage Questions");
  }, [setTitle]);

  const [active, setActive] = useState("question");
  const [mobileSidebar, setMobileSidebar] = useState(false);
  const [minimizedSidebar, setMinimizedSidebar] = useState(false);
  const [activeInputs, setActiveInputs] = useState(false);
  const { questionDetail } = useSelector(
    (state) => state.question,
    shallowEqual
  );

  const initialValues = {
    questionTitle: "",
    desc: "",
    questionType: "",
    questionLevel: "",
    option_is_shuffle: false,
    options: [{ value: "" }],
    answerType: "",
    hint: "",
    answerDescription: "",
    points: "",
    duration: "",
    correctAns: [],
    isSurvey: false,
  };

  const validationSchema = Yup.object({
    questionTitle: Yup.string().required("Question title is required"),
    desc: Yup.string(),
    questionType: Yup.string().required("Select question type"),
    questionLevel: Yup.string().required("Select question level"),
    option_is_shuffle: Yup.boolean(),
    isSurvey: Yup.boolean(),
    points: Yup.string().required("Points are required"),
    duration: Yup.string().required("Duration is required"),
    options: Yup.array().when("questionType", {
      is: (value) => ["multipleChoice", "checkbox", "dropdown"].includes(value),
      then: () =>
        Yup.array()
          .required("Options are required")
          .min(4, "Minimum four options are required")
          .of(
            Yup.object().shape({
              value: Yup.string().trim().required("Option value is required"),
            })
          ),
    }),
    answerDescription: Yup.string(),
    correctAns: Yup.array().when("questionType", {
      is: (value) => ["multipleChoice", "checkbox", "dropdown"].includes(value),
      then: () =>
        Yup.array()
          .required("Provide atleast one correct option")
          .min(1, "Provide atleast one correct option"),
    }),
    hint: Yup.string(),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      const data = {
        send: {
          question_title: values.questionTitle,
          description: values.desc,
          question_type: values.questionType,
          question_level: values.questionLevel,
          option_is_shuffle: values.option_is_shuffle,
          options: values.options,
          answer_description: values.answerDescription,
          hint: values.hint,
          points: values.points,
          duration: values.points,
          is_survey:
            values.questionType === "shortAnswer" ? true : values.isSurvey,
          answer: values.correctAns.map((item) => {
            return { value: item };
          }),
        },
        id: question_id,
      };
      if (question_id === "add-question") {
        const res = await dispatch(addQuestion(data.send));
        if (res?.payload) {
          resetForm();
          navigate(-1);
        }
      } else {
        const res = await dispatch(updateQuestionDetail(data));
        if (res?.payload) {
          resetForm();
          navigate(-1);
        }
      }
    },
  });

  useEffect(() => {
    if (question_id !== "add-question") {
      dispatch(getQuestionDetail({ id: question_id }));
    }

    return () => clearQuestionDetail();
  }, [question_id]);

  useEffect(() => {
    if (question_id !== "add-question") {
      formik.setValues({
        ...formik.values,
        questionTitle: questionDetail?.title,
        desc: questionDetail?.description,
        questionType: questionDetail?.type,
        questionLevel: questionDetail?.level,
        option_is_shuffle: questionDetail?.option?.isShuffle,
        options: questionDetail?.option?.options,
        answerType: questionDetail?.answer?.type,
        hint: questionDetail?.hint,
        answerDescription: questionDetail?.answer?.description,
        points: questionDetail?.points,
        duration: questionDetail?.duration,
        isSurvey: questionDetail?.survey,
        correctAns: questionDetail?.answer?.answers?.map((ans) => ans.value),
      });
    }
  }, [questionDetail, question_id]);

  useEffect(() => {
    if (question_id === "add-question") {
      setActiveInputs(true);
    }
  }, [question_id]);

  const addMoreOptions = () => {
    formik.setValues({
      ...formik.values,
      options: [...formik.values.options, { value: "" }],
    });
  };

  const removeField = (index) => {
    const removedValue = formik.values.options[index].value;

    const newOptions = [...formik.values.options];
    newOptions.splice(index, 1);

    const newCorrectAns = formik.values.correctAns.filter(
      (val) => val !== removedValue
    );

    formik.setValues({
      ...formik.values,
      options: newOptions,
      correctAns: newCorrectAns,
    });
  };

  return (
    <ModuleLayout
      minimizedSidebar={minimizedSidebar}
      setMinimizedSidebar={setMinimizedSidebar}
      mobileSidebar={mobileSidebar}
      setMobileSidebar={setMobileSidebar}
      active={active}
      setActive={setActive}
    >
      <div className="list-container custom-box">
        <div className="form-header">
          <h2>Question Management</h2>

          {question_id !== "add-question" && (
            <div className="flex gap-2">
              {!activeInputs ? (
                <>
                  <button
                    type="button"
                    onClick={() => navigate(-1)}
                    className="btn-outlined cancel"
                  >
                    Back
                  </button>
                  <button
                    type="button"
                    onClick={() => setActiveInputs(true)}
                    className="btn-primary"
                  >
                    Update
                  </button>
                </>
              ) : (
                <>
                  <button
                    type="button"
                    onClick={() => setActiveInputs(false)}
                    className="btn-outlined tertiary"
                  >
                    Abort
                  </button>
                  <SubmitButton
                    className="btn-primary"
                    type="submit"
                    onClick={formik.handleSubmit}
                    disabled={formik.isSubmitting}
                  >
                    Submit
                  </SubmitButton>
                </>
              )}
            </div>
          )}

          {question_id === "add-question" && (
            <div className="flex gap-1">
              <button
                type="button"
                onClick={() => navigate(-1)}
                className="btn-outlined cancel"
              >
                Back
              </button>
              <SubmitButton
                className="btn-primary"
                type="submit"
                onClick={formik.handleSubmit}
                disabled={formik.isSubmitting}
              >
                Submit
              </SubmitButton>
            </div>
          )}
        </div>
        <main className="main">
          <div className="auth-form-wrapper">
            <form>
              <div className="form-control">
                <label className="form-label">Question Title</label>
                <div className="form-input-box">
                  <input
                    className="form-input"
                    type="text"
                    id="questionTitle"
                    name="questionTitle"
                    value={formik.values.questionTitle}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    disabled={!activeInputs}
                    onInput={(e) =>
                    (e.target.value = e.target.value.replace(/^./, (match) =>
                      match.toUpperCase()
                    ))
                    }
                  />
                </div>
                {formik.errors.questionTitle && formik.touched.questionTitle ? (
                  <span className="err">{formik.errors.questionTitle}</span>
                ) : null}
              </div>
              <div className="form-control mt-1">
                <label className="form-label">Question Description</label>
                <div className="form-input-box">
                  <textarea
                    id="desc"
                    name="desc"
                    className="form-input"
                    value={formik.values.desc}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    disabled={!activeInputs}
                    onInput={(e) =>
                    (e.target.value = e.target.value.replace(/^./, (match) =>
                      match.toUpperCase()
                    ))
                    }
                  />
                </div>
                {formik.errors.desc && formik.touched.desc ? (
                  <span className="err">{formik.errors.desc}</span>
                ) : null}
              </div>
              <div className="form-col-2 mt-1">
                <div>
                  <div className="form-control">
                    <label className="form-label">Question Type</label>
                    <div className="form-input-box">
                      <DropDownBox
                        options={[
                          // { label: "Short Answer", value: "shortAnswer" },
                          { label: "Multiple Choice", value: "multipleChoice" },
                          { label: "Checkbox", value: "checkbox" },
                          { label: "Dropdown", value: "dropdown" },
                        ]}
                        defaultValue="Select Question Type "
                        customSetter={formik.setFieldValue}
                        customFormikLabel="questionType"
                        customDropBoxStyles={{
                          border: "1px solid #e6e6e6",
                          borderRadius: "0.5rem",
                          color: "#454545",
                        }}
                        customTextStyle={{
                          color: "#212229",
                          opacity: "0.7",
                        }}
                        incomingValue={formik.values.questionType}
                        disabled={activeInputs}
                      />
                    </div>
                  </div>
                  {formik.errors.questionType && formik.touched.questionType ? (
                    <span className="err">{formik.errors.questionType}</span>
                  ) : null}
                </div>
                <div>
                  <div className="form-control">
                    <label className="form-label">Question Level</label>
                    <div className="form-input-box">
                      <DropDownBox
                        options={[
                          { label: "Level 1", value: "easy" },
                          { label: "Level 2", value: "medium" },
                          { label: "Level 3", value: "hard" },
                          { label: "Level 4", value: "expert" },
                          { label: "Level 5", value: "master" },
                          { label: "Level 6", value: "extreme" },
                        ]}
                        defaultValue="Select Question Level "
                        disabled={activeInputs}
                        customSetter={formik.setFieldValue}
                        customFormikLabel="questionLevel"
                        customDropBoxStyles={{
                          border: "1px solid #e6e6e6",
                          borderRadius: "0.5rem",
                          color: "#454545",
                        }}
                        customTextStyle={{
                          color: "#212229",
                          opacity: "0.7",
                        }}
                        incomingValue={formik.values.questionLevel}
                      />
                    </div>
                  </div>
                  {formik.errors.questionLevel &&
                    formik.touched.questionLevel ? (
                    <span className="err">{formik.errors.questionLevel}</span>
                  ) : null}
                </div>
              </div>
              <div
                className={`form-col-${formik.values.questionType === "shortAnswer" ? 1 : 2} mt-1`}
              >
                {formik.values.questionType !== "shortAnswer" && (
                  <div className="flex gap-2 check-opts">
                    <p>Do you want to suffle option?</p>
                    <input
                      type="checkbox"
                      name="option_is_shuffle"
                      id="option_is_shuffle"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      checked={formik.values.option_is_shuffle}
                      style={{ display: "none" }}
                      className="cc-inp"
                      disabled={!activeInputs}
                      onInput={(e) =>
                      (e.target.value = e.target.value.replace(
                        /^./,
                        (match) => match.toUpperCase()
                      ))
                      }
                    />
                    <label htmlFor="option_is_shuffle">
                      <div className="custom-checkbox">
                        <div className="check-circle"></div>
                      </div>
                    </label>
                  </div>
                )}
                <div
                  style={
                    formik.values.questionType === "shortAnswer"
                      ? { opacity: ".5", userSelect: "none" }
                      : null
                  }
                  className="flex gap-2 check-opts"
                >
                  <p>Is this question for survey?</p>
                  <input
                    type="checkbox"
                    name="isSurvey"
                    id="isSurvey"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    checked={
                      formik.values.questionType === "shortAnswer" ||
                      formik.values.isSurvey
                    }
                    style={{ display: "none" }}
                    className="cc-inp"
                    disabled={
                      formik.values.questionType === "shortAnswer" ||
                      !activeInputs
                    }
                  />
                  <label htmlFor="isSurvey">
                    <div className="custom-checkbox">
                      <div className="check-circle"></div>
                    </div>
                  </label>
                </div>
              </div>
              {formik.values.questionType &&
                formik.values.questionType !== "shortAnswer" && (
                  <div className="form-control mt-1">
                    <div className="flex-between">
                      <p className="form-label">Add Options</p>
                      <div style={{ flexShrink: 0 }}>
                        <button
                          type="button"
                          className="btn-outlined"
                          onClick={() => addMoreOptions()}
                          disabled={!activeInputs}
                        >
                          Add more
                        </button>
                      </div>
                    </div>
                    {formik.values.options?.map((opts, index) => (
                      <div key={index}>
                        <p
                          className="form-label"
                          style={{ paddingLeft: "0px", marginTop: "10px" }}
                        >
                          {`Option ${index + 1} `}
                          {typeof formik.values.options != "string" &&
                            (formik.errors.options?.[index] &&
                              formik.touched.options?.[index] ? (
                              <span className="err">
                                {formik.errors.options?.[index]?.value}
                              </span>
                            ) : null)}
                        </p>
                        <div
                          className="flex"
                          style={{ marginTop: "5px", alignItems: "center" }}
                        >
                          <div style={{ display: "flex", marginRight: "10px" }}>
                            <label
                              htmlFor={`checkbox${index}`}
                              className="TD_CheckBox_LB"
                            >
                              <input
                                name="correctAns"
                                id={`checkbox${index}`}
                                type={
                                  formik.values.questionType === "checkbox"
                                    ? "checkbox"
                                    : "radio"
                                }
                                className="checkbox-animated"
                                checked={formik.values.correctAns.includes(
                                  opts.value
                                )}
                                onInput={(e) =>
                                (e.target.value = e.target.value.replace(
                                  /^./,
                                  (match) => match.toUpperCase()
                                ))
                                }
                                onChange={(e) => {
                                  if (
                                    formik.values.questionType === "checkbox"
                                  ) {
                                    if (e.target.checked) {
                                      formik.setFieldValue("correctAns", [
                                        ...formik.values.correctAns,
                                        opts.value,
                                      ]);
                                    } else {
                                      formik.setFieldValue(
                                        "correctAns",
                                        formik.values.correctAns.filter(
                                          (val) => val !== opts.value
                                        )
                                      );
                                    }
                                  } else {
                                    if (e.target.checked) {
                                      formik.setFieldValue("correctAns", [
                                        opts.value,
                                      ]);
                                    }
                                  }
                                  formik.handleChange("correctAns");
                                }}
                              />
                              <div className="checkmark"></div>
                            </label>
                          </div>
                          <div style={{ width: "100%" }}>
                            <div className="form-input-box">
                              <input
                                type="text"
                                id={`key-${index}`}
                                name={`options[${index}].value`}
                                min={1}
                                className="form-input"
                                value={opts.value}
                                onChange={(e) => {
                                  const newValue = e.target.value;
                                  const oldValue = opts.value;
                                  if (
                                    formik.values.correctAns.includes(oldValue)
                                  ) {
                                    const updatedCorrectAns =
                                      formik.values.correctAns.map((val) =>
                                        val === oldValue ? newValue : val
                                      );
                                    formik.setFieldValue(
                                      "correctAns",
                                      updatedCorrectAns
                                    );
                                  }
                                  formik.handleChange(e);
                                }}
                                onInput={(e) =>
                                (e.target.value = e.target.value.replace(
                                  /^./,
                                  (match) => match.toUpperCase()
                                ))
                                }
                                onBlur={formik.handleBlur}
                                disabled={!activeInputs}
                              />
                            </div>
                          </div>
                          <button
                            type="button"
                            onClick={() => removeField(index)}
                            disabled={formik.values.options.length < 2}
                          >
                            <i className="fa-light fa-trash"></i>
                          </button>
                        </div>
                      </div>
                    ))}
                    {typeof formik.errors.options === "string" && (
                      <span className="err">{formik.errors.options}</span>
                    )}
                  </div>
                )}
              {formik.errors.correctAns ? (
                <span className="err">{formik.errors.correctAns}</span>
              ) : null}
              <div className="form-col-2 mt-1">
                <div>
                  <div className="form-control">
                    <label className="form-label">Answer Points (Wallnut points)</label>
                    <div className="form-input-box">
                      <InputField
                        type="text"
                        id="points"
                        name="points"
                        placeholder="Enter walnut points"
                        value={formik.values.points}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        onInput={(e) => {
                          let inputValue = e.target.value;
                          e.target.value = inputValue
                            .slice(0, 3)
                            .replace(/\D/g, "");
                          (e.target.value = e.target.value.replace(/\D/g, ""))
                        }

                        }
                        disabled={!activeInputs}
                      />
                    </div>
                  </div>
                  {formik.errors.points && formik.touched.points ? (
                    <span className="err">{formik.errors.points}</span>
                  ) : null}
                </div>
                <div>
                  <div className="form-control">
                    <label className="form-label">Duration (in ms)</label>
                    <div className="form-input-box">
                      <input
                        type="text"
                        id="duration"
                        name="duration"
                        className="form-input"
                        value={formik.values.duration}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        onInput={(e) => {
                          let inputValue = e.target.value;
                          e.target.value = inputValue
                            .slice(0, 10)
                            .replace(/\D/g, "");
                          (e.target.value = e.target.value.replace(/\D/g, ""))
                        }}
                        placeholder="in ms"
                        disabled={!activeInputs}
                      />
                    </div>
                  </div>
                  {formik.errors.duration && formik.touched.duration ? (
                    <span className="err">{formik.errors.duration}</span>
                  ) : null}
                </div>
              </div>
              <div className="form-control mt-1">
                <label className="form-label">Answer Description</label>
                <div className="form-input-box">
                  <textarea
                    type="text"
                    id="answerDescription"
                    name="answerDescription"
                    className="form-input"
                    value={formik.values.answerDescription}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    disabled={!activeInputs}
                    onInput={(e) =>
                    (e.target.value = e.target.value.replace(/^./, (match) =>
                      match.toUpperCase()
                    ))
                    }
                  />
                </div>
                {formik.errors.answerDescription &&
                  formik.touched.answerDescription ? (
                  <span className="err">{formik.errors.answerDescription}</span>
                ) : null}
              </div>
              <div className="form-control mt-1">
                <label className="form-label">Answer Hint</label>
                <div className="form-input-box">
                  <InputField
                    type="text"
                    id="hint"
                    name="hint"
                    value={formik.values.hint}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    disabled={!activeInputs}
                    onInput={(e) =>
                    (e.target.value = e.target.value.replace(/^./, (match) =>
                      match.toUpperCase()
                    ))
                    }
                  />
                </div>
                {formik.errors.hint && formik.touched.hint ? (
                  <span className="err">{formik.errors.hint}</span>
                ) : null}
              </div>
            </form>
          </div>
        </main>
      </div>
    </ModuleLayout>
  );
};

export default QueManager;
