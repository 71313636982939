import { unixTimeToReadableFormat } from "helper/helper";
import React from "react";

const Payment = ({ payment, transaction, currencySymbol }) => {

  return (
    <div className="payment-main">
      <div className="pm-top">
        <div className="pm-div">
          <div className="pmtop">
            <span className="title">Payment Method</span>
            {/* <div className="eDiv">Edit</div> */}
          </div>
          <div className="pm-content">
            <span className="val">{payment?.paymentMethod}</span>
          </div>
        </div>
        <div className="pm-div">
          <div className="pmtop">
            <span className="title">Payment Status</span>
            {/* <div className="eDiv">Edit</div> */}
          </div>
          <div className="pm-content">
            <span className={`status-div ${payment?.status?.toLowerCase()}`}>{payment?.status}</span>
          </div>
        </div>
      </div>
      <div className="pm-bottom">
        <div className="pm-div" style={{ width: "100%" }}>
          <div className="pmtop">
            <span className="title">Transaction History</span>
          </div>
          <div className="pm-content">
            <div className="pmTable">
              <table className="pmTable-main">
                <thead>
                  <tr>
                    <th className="pmt-head">Type</th>
                    <th className="pmt-head">Amount</th>
                    <th className="pmt-head">Payment Date</th>
                    <th className="pmt-head">Payment Mode</th>
                    <th className="pmt-head">Payment Method</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    transaction?.map((ele) =>
                      <tr>
                        <th className="pmt-data"><div className={`status-div ${ele.status?.toLowerCase()}`}>{ele.status}</div></th>
                        <th className="pmt-data">{`${currencySymbol} ${ele.amount}`}</th>
                        <th className="pmt-data">{ele.paymentDate ? unixTimeToReadableFormat(ele.paymentDate) : "-"}</th>
                        <th className="pmt-data">{ele.paymentMode}</th>
                        <th className="pmt-data">{ele.paymentMethod}</th>
                      </tr>
                    )
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Payment;
