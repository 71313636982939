import { createSlice } from "@reduxjs/toolkit";
import { getTemplateDetail, getTemplateList } from "./templateApi";

const templateSlice = createSlice({
  name: "cmsTemplate",
  initialState: {
    templateLoading: false,
    templateList: [],
    templateMetaData: [],
    templateDetail: [],
  },
  reducers: {
    isTemplateLoading: (state, action) =>
      (state = {
        ...state,
        templateLoading: action.payload,
      }),
    // to reset template details
    resetTemplateDetail: (state) => {
      state.policyDetail = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTemplateList.pending, (state, action) => {
      state.templateLoading = true;
    });
    builder.addCase(getTemplateList.fulfilled, (state, action) => {
      state.templateLoading = false;
      state.templateList = action?.payload?.data;
      state.metaData = action?.payload?.metadata?.[0];
    });
    builder.addCase(getTemplateList.rejected, (state, action) => {
      state.templateLoading = false;
      state.templateList = [];
    });
    builder.addCase(getTemplateDetail.pending, (state, action) => {
      state.templateLoading = true;
    });
    builder.addCase(getTemplateDetail.fulfilled, (state, action) => {
      state.templateLoading = false;
      state.templateDetail = action.payload.data;
    });
    builder.addCase(getTemplateDetail.rejected, (state, action) => {
      state.templateLoading = false;
    });
  },
});

export const { isTemplateLoading, resetTemplateDetail } = templateSlice.actions;
export default templateSlice.reducer;
