import { createSlice } from "@reduxjs/toolkit";
import { getProductList, getProductDetail } from "./productApi";

const productSlice = createSlice({
  name: "product",
  initialState: {
    productLoading: false,
    productList: [],
    metaData: [],
    productDetail: {},
  },
  reducers: {
    isProductLoading: (state, action) =>
      (state = {
        ...state,
        productLoading: action.payload,
      }),
    clearProductDetail: (state) => (state = { ...state, productDetail: {} }),
  },
  extraReducers: (builder) => {
    // getProductList reducers-------------------------
    builder.addCase(getProductList.pending, (state) => {
      state.productLoading = true;
    });
    builder.addCase(getProductList.fulfilled, (state, action) => {
      state.productLoading = false;
      state.productList = action.payload.data;
      state.metaData = action.payload.metadata[0];
    });
    builder.addCase(getProductList.rejected, (state) => {
      state.productLoading = false;
      state.productList = [];
    });
    // getProductDetail reducers-------------------------
    builder.addCase(getProductDetail.pending, (state, action) => {
      state.productLoading = true;
    });
    builder.addCase(getProductDetail.fulfilled, (state, action) => {
      state.productLoading = false;
      state.productDetail = action.payload.data;
    });
    builder.addCase(getProductDetail.rejected, (state, action) => {
      state.productLoading = false;
    });
  },
});

export const { isProductLoading, clearProductDetail } = productSlice.actions;
export default productSlice.reducer;
