import { createAsyncThunk } from "@reduxjs/toolkit";
import { AddNumField, options } from "helper/helper";
import { isQuizLoading } from "./quizSlice";
import { toast } from "react-toastify";
import axios from "axios";

export const getQuizList = createAsyncThunk(
  "quiz/get-quiz-list",
  async (
    {
      page = 0,
      limit = 5,
      sort = "desc",
      search,
      type = "",
      level = "",
      skillId = "",
    },
    { rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/catalogue/quiz/quiz-list/?page=${page}&limit=${limit}&sort=${sort}&search=${search}&filter[type]=${type}&filter[level]=${level}&filter[skillId]=${skillId}`,
      });

      if (response.status === 200) {
        if (response?.data?.data?.length > 0) {
          const res = await AddNumField(
            response?.data?.data?.[0],
            page * limit
          );
          return fulfillWithValue(res);
        } else {
          const modPage = Math.max(page - 1, 0);
          const responseAgain = await axios({
            method: "GET",
            url: `${process.env.REACT_APP_AUTH_URL}/admin/catalogue/quiz/quiz-list/?page=${page}&limit=${limit}&sort=${sort}&search=${search}&filter[type]=${type}&filter[level]=${level}&filter[skillId]=${skillId}`,
          });
          if (responseAgain.status === 200) {
            const resAgain = await AddNumField(
              responseAgain?.data,
              modPage * limit
            );
            return fulfillWithValue(resAgain);
          } else {
            toast.error(responseAgain?.data?.message, options);
            return rejectWithValue();
          }
        }
      } else {
        // toast.error(response?.data?.message, options);
        return rejectWithValue();
      }
    } catch (err) {
      return rejectWithValue();
    }
  }
);

export const addQuiz = createAsyncThunk(
  "quiz/add-quiz",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      dispatch(isQuizLoading(true));
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/catalogue/quiz/add-quiz`,
        data: data,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (response.status === 200) {
        dispatch(isQuizLoading(false));
        dispatch(
          getQuizList({
            page: 0,
            limit: 5,
            sort: "desc",
            search: "",
          })
        );
        toast.success(response?.data?.message, options);
        return response?.data?.status;
      } else {
        dispatch(isQuizLoading(false));
        toast.error(response?.data?.message, options);
      }
    } catch (err) {
      dispatch(isQuizLoading(false));
      toast.error(err, options);
    }
  }
);

export const addBulkQuizAsync = createAsyncThunk(
  "quiz/add-bulk-quiz",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    dispatch(isQuizLoading(true));
    try {
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/quiz/add-quiz-bulk`,
        headers: {
          "Content-Type": "multipart/form-data",
        },

        data: data?.send,
      });

      if (response.status === 200) {
        dispatch(isQuizLoading(false));
        dispatch(
          getQuizList({
            page: 0,
            limit: 5,
            search: "",
            sort: "",
          })
        );
        toast.success(response?.data?.message, options);
        return response?.data?.status;
      } else {
        dispatch(isQuizLoading(false));
        toast.error(response?.data?.message, options);
      }
    } catch (err) {
      dispatch(isQuizLoading(false));
      toast.error(err, options);
    }
  }
);

export const deleteQuiz = createAsyncThunk(
  "quiz/delete-quiz",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      dispatch(isQuizLoading(true));
      const response = await axios({
        method: "DELETE",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/catalogue/quiz/delete-quiz`,
        data: data?.send,
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.status === 200) {
        dispatch(isQuizLoading(false));
        dispatch(
          getQuizList({
            page: data?.page,
            limit: data.limit,
            search: data.search,
            sort: data.sort,
          })
        );
        // toast.success(response?.data?.message, options);
        return response?.data?.status;
      } else {
        dispatch(isQuizLoading(false));
        // toast.error(response?.data?.message, options);
      }
    } catch (err) {
      dispatch(isQuizLoading(false));
      toast.error(err, options);
    }
  }
);

export const getQuizDetail = createAsyncThunk(
  "quiz/get-quiz-detail",
  async (quizId, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      const response = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/catalogue/quiz/quiz-detail/${quizId}`,
      });
      
      if (response.status === 200) {
        return fulfillWithValue(response?.data);
      } else {
        toast.error(response?.data?.message, options);
        return rejectWithValue();
      }
    } catch (err) {
      toast.error(err, options);
      return rejectWithValue();
    }
  }
);

export const updateQuizDetail = createAsyncThunk(
  "quiz/update-quiz",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      dispatch(isQuizLoading(true));
      const response = await axios({
        method: "PUT",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/catalogue/quiz/update-quiz/${data?.id}`,
        data: data?.send,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (response.status === 200) {
        dispatch(isQuizLoading(false));
        dispatch(
          getQuizList({
            page: 0,
            limit: data?.limit,
            search: "",
            sort: "desc",
          })
        );
        toast.success(response?.data?.message, options);
        return response?.data?.status;
      } else {
        dispatch(isQuizLoading(false));
        toast.error(response?.data?.message, options);
      }
    } catch (err) {
      dispatch(isQuizLoading(false));
      toast.error(err, options);
    }
  }
);
