/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import ModuleLayout from "layouts/ModuleLayout";
import DropDownBox from "components/DropDownBox";
import { Chart } from "react-google-charts";
import { DashLeadBoard, GeoChartdata } from "helper/placeholder-data";
import LeadBoardTable from "../components/LeadBoardTable";
import Cards from "../components/Cards";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getDashboardDetail } from "../redux/dashboardApi";
import { nodata } from "assets/icons";

const Dashboard = ({ setTitle }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    setTitle("Admin | Dashboard");
  }, [setTitle]);

  const [mobileSidebar, setMobileSidebar] = useState(false);
  const [active, setActive] = useState("dashboard");
  const [minimizedSidebar, setMinimizedSidebar] = useState(false);
  const [year, setYear] = useState("");
  const [week, setWeek] = useState("");

  const { dashboardLoading, dashboardDetail } = useSelector(
    (state) => state.dashboard,
    shallowEqual
  );

  const { privileges } = useSelector((state) => state.auth, shallowEqual);

  useEffect(() => {
    const chartWidth = "100%";
    document.querySelectorAll(".chart-wrapper").forEach((chart) => {
      chart.style.width = chartWidth;
    });
  }, [minimizedSidebar]);

  useEffect(() => {
    dispatch(getDashboardDetail());
  }, []);

  return (
    <ModuleLayout
      active={active}
      setActive={setActive}
      minimizedSidebar={minimizedSidebar}
      setMinimizedSidebar={setMinimizedSidebar}
      mobileSidebar={mobileSidebar}
      setMobileSidebar={setMobileSidebar}
    >
      <div className="dashboard-container">
        {/*--------// Cards //---------  */}
        <div className="dashboard-card-box">
          {privileges.hasOwnProperty("userManagement") &&
            Object?.values(privileges["userManagement"])?.includes("read") && (
              <Cards
                isLoading={dashboardLoading}
                icon="fa-solid fa-user"
                title="Total Users"
                value={dashboardDetail?.users?.total || 0}
              />
            )}

          {privileges.hasOwnProperty("skillManagement") &&
            Object?.values(privileges["skillManagement"])?.includes("read") && (
              <Cards
                icon="fa-solid fa-layer-group"
                title="Total Skills"
                isLoading={dashboardLoading}
                value={dashboardDetail?.skills?.total || 0}
              />
            )}

          {privileges.hasOwnProperty("productManagement") &&
            Object?.values(privileges["productManagement"])?.includes(
              "read"
            ) && (
              <Cards
                icon={"fa-sharp-duotone fa-solid fa-box"}
                title={"Total Products"}
                isLoading={dashboardLoading}
                value={dashboardDetail?.products?.total || 0}
              />
            )}

          {privileges.hasOwnProperty("orderManagement") &&
            Object?.values(privileges["orderManagement"])?.includes("read") && (
              <Cards
                icon={"fa-solid fa-box-open"}
                title={"Total Orders"}
                isLoading={dashboardLoading}
                value={dashboardDetail?.orders?.total || 0}
              />
            )}
        </div>

        {/*--------// Charts & Table //---------  */}
        <div className="dash-content">
          {privileges.hasOwnProperty("userManagement") &&
            Object?.values(privileges["userManagement"])?.includes("read") && (
              <div className="user-statistics custom-box">
                <div>
                  <h2>Users Statistics</h2>
                  <p>
                    <b>Total Users:</b> {dashboardDetail?.users?.total || 0}
                  </p>
                </div>
                <div className="user-charts">
                  {dashboardDetail?.users?.gender ? (
                    <Chart
                      width="100%"
                      // height="250px"
                      chartType="PieChart"
                      data={dashboardDetail?.users?.gender}
                      options={{
                        title: "Gender Ratio",
                        width: 280,
                        colors: ["#0076BB", "#E86973"],
                      }}
                    />
                  ) : (
                    <div>
                      <h1>Gender Ratio</h1>
                      <p>No Data</p>
                    </div>
                  )}

                  {dashboardDetail?.users?.status ? (
                    <Chart
                      width="100%"
                      // height="250px"
                      chartType="PieChart"
                      data={dashboardDetail?.users?.status}
                      options={{
                        title: "User Status",
                        width: 280,
                        pieHole: 0.4,
                        is3D: false,
                        colors: ["#238F51", "#D0202E"],
                      }}
                    />
                  ) : (
                    <div>
                      <h1>User Status</h1>
                      <p>No Data</p>
                    </div>
                  )}

                  {dashboardDetail?.users?.subscription ? (
                    <Chart
                      width="100%"
                      // height="250px"
                      chartType="PieChart"
                      data={dashboardDetail?.users?.subscription}
                      options={{
                        title: "User Subscription",
                        width: 280,
                        is3D: false,
                        colors: ["#ec8f6e", "#f3b49f"],
                      }}
                    />
                  ) : (
                    <div>
                      <h1>User Subscription</h1>
                      <p>No Data</p>
                    </div>
                  )}
                </div>
              </div>
            )}

          {privileges.hasOwnProperty("orderManagement") &&
            Object?.values(privileges["orderManagement"])?.includes("read") && (
              <div className="statistics custom-box">
                <div className="statistics-header">
                  <h2>Orders</h2>
                  <DropDownBox
                    options={[
                      { label: "2024", value: "2024" },
                      { label: "2023", value: "2023" },
                      { label: "2022", value: "2022" },
                    ]}
                    size="small"
                    animateDropDownTitle
                    dropDownTitle="Year"
                    customSetter={setYear}
                    customDropBoxStyles={{
                      border: "0.063rem solid #F5F5F5",
                      borderRadius: "0.5rem",
                      color: "#454545",
                    }}
                    customTextStyle={{
                      color: "#252525",
                      opacity: "0.7",
                    }}
                    resetButton
                  />
                </div>

                <div className="statistics-body">
                  <div
                    className="chart-wrapper"
                    key={minimizedSidebar ? "minimized" : "maximized"}
                  >
                    {dashboardDetail?.orders?.status ? (
                      <Chart
                        chartType="BarChart"
                        width="100%"
                        height="400px"
                        data={dashboardDetail?.orders?.status}
                        options={{
                          title: `Total Orders: ${dashboardDetail?.orders?.total || 0}`,
                          chartArea: { width: "50%" },
                          hAxis: {
                            title: "Total",
                            minValue: 0,
                          },
                          vAxis: {
                            title: "Status",
                          },
                        }}
                      />
                    ) : (
                      <div className="flex-center">
                        <div>
                          <img src={nodata} alt="no-data" width={300} />
                          <h2 className="text-center">No Data Found</h2>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}

          <div className="skills-products-wrapper">
            {privileges.hasOwnProperty("skillManagement") &&
              Object?.values(privileges["skillManagement"])?.includes(
                "read"
              ) && (
                <div className="custom-box flex-1">
                  <h2>Skills</h2>
                  {dashboardDetail?.skills ? (
                    <Chart
                      width="100%"
                      // height="250px"
                      chartType="PieChart"
                      data={dashboardDetail?.skills?.status}
                      options={{
                        // title: "Skills status",
                        width: 280,
                        is3D: false,
                        colors: ["#ffc154", "#47b39c"],
                      }}
                    />
                  ) : (
                    <div>
                      {/* <h1>Skills</h1> */}
                      <h1>No Data</h1>
                    </div>
                  )}
                </div>
              )}

            {privileges.hasOwnProperty("productManagement") &&
              Object?.values(privileges["productManagement"])?.includes(
                "read"
              ) && (
                <div className="custom-box flex-1">
                  <h2>Products</h2>
                  <p className="mt-1">
                    Total Products: {dashboardDetail?.products?.total || 0}
                  </p>
                  <p className="mt-1">
                    Out Of Stock: {dashboardDetail?.products?.outOfStock || 0}
                  </p>
                </div>
              )}
          </div>

          <div className="list custom-box">
            <div
              className="statistics-header"
              style={{ paddingBottom: "1rem" }}
            >
              <h2>Lead Board List</h2>
              {/* <DropDownBox
                options={[
                  { label: "1st Week", value: "1" },
                  { label: "2nd Week", value: "2" },
                  { label: "3rd Week", value: "3" },
                ]}
                size="small"
                animateDropDownTitle
                dropDownTitle="Week"
                customSetter={setWeek}
                customDropBoxStyles={{
                  border: "0.063rem solid #F5F5F5",
                  borderRadius: "0.5rem",
                  color: "#454545",
                }}
                customTextStyle={{
                  color: "#252525",
                  opacity: "0.7",
                }}
                resetButton
              /> */}
            </div>
            <LeadBoardTable />
          </div>
        </div>
      </div>
    </ModuleLayout>
  );
};

export default Dashboard;
