import React, { useEffect, useState } from "react";
import GeneralDetails from "../components/GeneralDetails";
import ChangePass from "../components/ChangePass";
import SideNav from "../components/SideNav";
import ModuleLayout from "layouts/ModuleLayout";
import Notifications from "../components/Notifications";
import Authentication from "../components/Authentication";
import DeleteAcc from "../components/DeleteAcc";
import LogoutEverywhere from "../components/LogoutEverywhere";

const Profile = ({ setTitle }) => {
  useEffect(() => {
    setTitle("My Profile");
  }, [setTitle]);

  const [active, setActive] = useState("profile");
  const [selectedTab, setSelectedTab] = useState("general");
  const [mobileSidebar, setMobileSidebar] = useState(false);
  const [minimizedSidebar, setMinimizedSidebar] = useState(false);

  const TABS = {
    general: <GeneralDetails />,
    changePass: <ChangePass />,
    deactivate: <DeleteAcc />,
    notification: <Notifications />,
    authentication: <Authentication />,
    logout: <LogoutEverywhere />
  };

  return (
    <ModuleLayout
      minimizedSidebar={minimizedSidebar}
      setMinimizedSidebar={setMinimizedSidebar}
      mobileSidebar={mobileSidebar}
      setMobileSidebar={setMobileSidebar}
      active={active}
      setActive={setActive}
    >
      <div className="profile-wrapper">
        <h1 className="heading">Account Settings</h1>

        <div className="details">
          <SideNav selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
          {TABS[selectedTab]}
        </div>
      </div>
    </ModuleLayout>
  );
};

export default Profile;
