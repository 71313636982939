import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { AddNumField, options } from "helper/helper";
import { isCategoryLoading } from "./categorySlice";

export const getCategoryList = createAsyncThunk(
  "catalogue/get-category-list",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    
    try {
      const response = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/catalogue/category/category-list?page=${data?.page}&limit=${data?.limit}&sort=${data?.sort ? data?.sort : "desc"}&search=${data.search}&filter[type]=${data?.filter ? data?.filter : ""}`,
      });

      if (response.status === 200) {
        if (response?.data?.data?.length > 0) {
          const res = await AddNumField(
            response?.data?.data?.[0],
            data?.page * data?.limit
          );

          return fulfillWithValue(res);
        } else {
          const modPage = Math.max(data.page - 1, 0);
          const responseAgain = await axios({
            method: "GET",
            url: `${process.env.REACT_APP_AUTH_URL}/admin/catalogue/category/category-list?page=${data?.page}&limit=${data?.limit}&sort=${data?.sort ? data?.sort : "desc"}&search=${data.search}&filter[type]=${data?.filter ? data?.filter : ""}`,
          });
          if (responseAgain.status === 200) {
            const resAgain = await AddNumField(
              responseAgain?.data,
              modPage * data?.limit
            );
            return fulfillWithValue(resAgain);
          } else {
            toast.error(responseAgain?.data?.message, options);
            return rejectWithValue();
          }
        }
      } else {
        // toast.error(response?.data?.message, options);
        return rejectWithValue();
      }
    } catch (err) {
      return rejectWithValue();
    }
  }
);

export const addCategory = createAsyncThunk(
  "catalogue/add-category",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      dispatch(isCategoryLoading(true));
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/catalogue/category/add-category`,
        data: data,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (response.status === 200) {
        dispatch(isCategoryLoading(false));
        dispatch(
          getCategoryList({
            page: 0,
            limit: 6,
            search: "",
            sort: "desc",
            filter: "",
          })
        );
        toast.success(response?.data?.message, options);
        return response?.data?.status;
      } else {
        dispatch(isCategoryLoading(false));
        toast.error(response?.data?.message, options);
      }
    } catch (err) {
      dispatch(isCategoryLoading(false));
      toast.error(err, options);
    }
  }
);

export const getCategoryDetail = createAsyncThunk(
  "catalogue/get-category-detail",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      const response = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/catalogue/category/category-detail/${data?.id}`,
      });
      if (response.status === 200) {
        return fulfillWithValue(response?.data);
      } else {
        toast.error(response?.data?.message, options);
        return rejectWithValue();
      }
    } catch (err) {
      toast.error(err, options);
      return rejectWithValue();
    }
  }
);

export const updateCategoryDetail = createAsyncThunk(
  "catalogue/update-category",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      dispatch(isCategoryLoading(true));
      const response = await axios({
        method: "PUT",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/catalogue/category/update-category/${data?.id}`,
        data: data?.send,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.status === 200) {
        dispatch(isCategoryLoading(false));
        dispatch(
          getCategoryList({
            page: 0,
            limit: 6,
            search: "",
            sort: "desc",
            filter: "",
          })
        );
        toast.success(response?.data?.message, options);
        return response?.data;
      } else {
        dispatch(isCategoryLoading(false));
        toast.error(response?.data?.message, options);
      }
    } catch (err) {
      dispatch(isCategoryLoading(false));
      toast.error(err, options);
    }
  }
);

export const deleteCategoryAsync = createAsyncThunk(
  "catalogue/delete-category",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      dispatch(isCategoryLoading(true));
      const response = await axios({
        method: "DELETE",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/catalogue/category/delete-category`,
        data: data?.send,
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.status === 200) {
        dispatch(isCategoryLoading(false));
        dispatch(
          getCategoryList({
            page: data?.page,
            limit: data.limit,
            search: data.search,
            sort: data.sort,
          })
        );
        // toast.success(response?.data?.message, options);
        return response?.data?.status;
      } else {
        dispatch(isCategoryLoading(false));
        // toast.error(response?.data?.message, options);
      }
    } catch (err) {
      dispatch(isCategoryLoading(false));
      toast.error(err, options);
    }
  }
);

export const addBulkCategoryAsync = createAsyncThunk(
  "catalogue/add-bulk-category",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    dispatch(isCategoryLoading(true));
    try {
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/catalogue/category/add-category-bulk`,
        headers: {
          "Content-Type": "multipart/form-data",
        },

        data: data,
      });

      if (response.status === 200) {
        dispatch(isCategoryLoading(false));
        dispatch(
          getCategoryList({
            page: 0,
            limit: 6,
            search: "",
            sort: "desc",
            filter: "",
          })
        );
        toast.success(response?.data?.message, options);
        return response?.data?.status;
      } else {
        dispatch(isCategoryLoading(false));
        toast.error(response?.data?.message, options);
      }
    } catch (err) {
      dispatch(isCategoryLoading(false));
      toast.error(err, options);
    }
  }
);
