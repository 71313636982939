/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import CustomTable from "components/CustomTable";
import ConformationPopup from "components/ui/ConformationPopup";
import SearchField from "components/ui/SearchField";
import useDisableSkelaton from "hooks/useDisableSkelaton";
import useFetchList from "hooks/useFetchList";
import useDeleteContent from "hooks/useDeleteContent";
import ModuleLayout from "layouts/ModuleLayout";
import {
  planType,
  recurringCycleOption,
  SortItems,
} from "helper/placeholder-data";
import { setPageDetails } from "modules/auth/redux/authSlice";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { getBreadcrumbName, unixTimeToReadableFormat } from "helper/helper";
import { useNavigate } from "react-router-dom";
import DropDownBox from "components/DropDownBox";
import { useFormik } from "formik";
import { deletePlan, getPlanList, planActivateDeactivate } from "../redux/planApi";
import useSelectRows from "hooks/useSelectRows";
import UserTable from "modules/settings/users/components/UserTable";

const Plans = ({ setTitle }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    setTitle("Plan Management");
  }, [setTitle]);

  const { planList, metaData, planLoading } = useSelector(
    (state) => state.plan,
    shallowEqual
  );

  const [active, setActive] = useState("plans");
  const [mobileSidebar, setMobileSidebar] = useState(false);
  const [minimizedSidebar, setMinimizedSidebar] = useState(false);
  const { contentLoading } = useDisableSkelaton(planLoading);
  const [alertBox2, setAlertBox2] = useState(false);
  const [popupDataTwo, setPopupDataTwo] = useState();
  const [popupCustomWarning, setPopupCustomWarning] = useState();

  const {
    content,
    alertBox,
    setAlertBox,
    selectAllRows,
    selectedRows,
    setSelectedRows,
    toggleSelectAll,
    toggleRowSelection,
    deleteFunction,
  } = useSelectRows(planList, "plan_id");

  const {
    privileges,
    limit,
    setLimit,
    sort,
    setSort,
    search,
    setSearch,
    btnNo,
    setBtnNo,
    tableIndex,
    setTableIndex,
  } = useFetchList(getPlanList, metaData, selectAllRows, setSelectedRows);

  const listContent = planList.map((item, i) => {
    const { id, num, name, slug, recurringCycle, type, createdAt, price, status } =
      item;
    const userStatus = status ? "active" : "inactive";
    const crDt = unixTimeToReadableFormat(createdAt);
    const similarItems = [
      { style: "title", val: name },
      { style: `status ${userStatus}`, val: userStatus },
      { style: "", val: recurringCycle },
      { style: "", val: type },
      { style: "", val: `${price?.currency?.symbol} ${price?.value}` },
      { style: "", val: crDt },
    ];
    return [id, num, name, similarItems, status];
  });

  const TABLEDATA = {
    loadingState: contentLoading,
    skalatonDetails: { col: 7, rows: limit },
    listHeading: ["Name", "Status", "Recurring Cycle", "Type", "Price", "Created At"],
    listContent,
    selectedRows,
    selectAllRows,
    setSelectedRows,
    toggleSelectAll,
    toggleRowSelection,
    deleteFunction,
    sliceName: "plan",
    search,
    PagAPI: getPlanList,
    sort,
    limit,
    setLimit,
    btnNo,
    setBtnNo,
    tableIndex,
    setTableIndex,
    privileges,
    hasAccess: "planManagement",
    navigateTo: (params) => {
      const user = planList.find((plan) => plan.id === params);
      const breadcrumbName = getBreadcrumbName(user);
      navigate(`/plans/${params}`, {
        state: { slug: params, title: breadcrumbName },
      });
    },
    setAlertBox2,
    setPopupDataTwo,
    setPopupCustomWarning,
    metaData,
    customIcon: {enable: "fa-light fa-circle-check", disable: "fa-light fa-circle-x"}
  };


  const formik = useFormik({
    initialValues: {
      type: "",
      recurringCycle: "",
    },
  });

  useEffect(() => {
    const data = {
      page: 0,
      limit: limit,
      sort: sort,
      type: formik.values.type,
      recurringCycle: formik.values.recurringCycle,
    };
    dispatch(getPlanList(data));
  }, [formik.values, sort]);

  return (
    <ModuleLayout
      minimizedSidebar={minimizedSidebar}
      setMinimizedSidebar={setMinimizedSidebar}
      mobileSidebar={mobileSidebar}
      setMobileSidebar={setMobileSidebar}
      active={active}
      setActive={setActive}
    >
      <header className="list-header">
        <div className="list-title">
          <h2>Plan Management</h2>
          <div className="flex-center gap-1">
            {Object.values(privileges["questionManagement"]).includes(
              "write"
            ) && (
                <button
                  className="btn-primary flex-center gap-1"
                  onClick={() => navigate("/plans/add-new")}
                >
                  <i className="fa-solid fa-feather"></i> Add Plan
                </button>
              )}

            {Object?.values(privileges["questionManagement"])?.includes(
              "delete"
            ) && selectedRows.length !== 0 ? (
              <button
                className="btn-secondary flex-center gap-1"
                onClick={() => {
                  deleteFunction("all Questions");
                }}
              >
                <i className="fa-light fa-trash"></i>
                {`Delete ${selectedRows.length}`}
              </button>
            ) : null}
          </div>
        </div>
      </header>
      <div className="list-container custom-box">
        <div className="flex-between">
          <div className="flex gap-1">
            <DropDownBox
              options={SortItems}
              dropDownTitle="Sort"
              animateDropDownTitle
              customSetter={setSort}
              customDropBoxStyles={{
                opacity: 0.6,
                color: "#262626",
                padding: "0.6rem",
                width: "9rem",
                border: "1px solid var(--color-neutral-92)",
              }}
            />
            <DropDownBox
              options={planType}
              dropDownTitle="Select Type"
              animateDropDownTitle
              customSetter={formik.setFieldValue}
              customFormikLabel="type"
              customDropBoxStyles={{
                opacity: 0.6,
                color: "#262626",
                padding: "0.6rem",
                width: "9rem",
                border: "1px solid var(--color-neutral-92)",
              }}
              resetButton
            />
            <DropDownBox
              options={recurringCycleOption}
              dropDownTitle="Select Cycle"
              animateDropDownTitle
              customSetter={formik.setFieldValue}
              customFormikLabel="recurringCycle"
              customDropBoxStyles={{
                opacity: 0.6,
                color: "#262626",
                fontSize: "0.9rem",
                padding: "0.6rem",
                width: "9rem",
                border: "1px solid var(--color-neutral-92)",
              }}
              resetButton
              size={"small"}
            />
          </div>

          <SearchField value={search} setValue={setSearch} />
        </div>
        <UserTable {...TABLEDATA} />
      </div>

      {alertBox && (
        <ConformationPopup
          customState={alertBox}
          customSetter={setAlertBox}
          api={deletePlan}
          popUpData={content}
          customFunction={() => setSelectedRows(new Set())}
        // toastMessage={"Question removed successfully"}
        />
      )}

      {alertBox2 && (
        <ConformationPopup
          customState={alertBox2}
          customSetter={setAlertBox2}
          mainTitle="Manage Plan"
          subTitle={` Are you sure want to ${popupDataTwo?.data?.send?.status ? "Activate" : "Deactivate"} the plan?`}
          api={planActivateDeactivate}
          popUpData={popupDataTwo}
          reData={{ page: 0, search, sort: sort, limit }}
          reFetchApi={getPlanList}
          popupType="edit"
        // toastMessage="User state changed"
        />
      )}
    </ModuleLayout>
  );
};

export default Plans;
