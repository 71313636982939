import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { minSideLogo } from "assets/icons";

const MinimizeSidebar = ({ active, setActive }) => {
  const [catalogue, setCatalogue] = useState(false);
  const [cms, setCms] = useState(false);
  // const [products, setProducts] = useState(false);
  const [userlist, setUserList] = useState(false);
  const [openList, setOpenList] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  const { privileges, role } = useSelector((state) => state.auth, shallowEqual);

  const handleListClick = (navTabs) => {
    // Close the previously open list
    if (openList && openList !== navTabs && openList.setState) {
      openList.setState(false);
    }
    // Toggle the state of the clicked list if it has a setState function
    if (navTabs.setState && typeof navTabs.setState === "function") {
      navTabs.setState(!navTabs.state);
    }
    // Update the currently open list
    setOpenList(navTabs);
    setActive(navTabs.nav_title);

    if (navTabs.goto_link) {
      navigate(navTabs.goto_link);
    }
  };

  useEffect(() => {
    if (active === "cmsPages" || active === "cmsTemplates") {
      setCms(true);
    }

    // if (active === "inventory" || active === "product") {
    //   setProducts(true);
    // }

    if (
      active === "brands" ||
      active === "category" ||
      active === "skills" ||
      active === "quizes" ||
      active === "question" ||
      active === "glossary" ||
      active === "faq"
    ) {
      setCatalogue(true);
    }

    if (active === "roles" || active === "users") {
      setUserList(true);
    }
  }, [active]);

  const setActiveColor = (link, i) => {
    if (location.pathname === link)
      return {
        color: "var(--color-primary)",
      };
  };

  const navigationLinks = [
    {
      nav_title: "Dashboard",
      nav_icon: "fa-solid fa-grid-2",
      goto_link: "/",
      privilege: "head",
    },
    {
      nav_title: "Catalogue",
      nav_icon: "fa-regular fa-grid-round-2-plus",
      goto_link: "",
      state: catalogue,
      setState: setCatalogue,
      privilege:
        "categoryManagement" ||
        "skillManagement" ||
        "moduleManagement" ||
        "brandManagement",
      content: [
        {
          matchName: "brands",
          title: "Brand",
          goto_link: "/catalogue/brand-management",
          privilege: "brandManagement",
        },
        {
          matchName: "category",
          title: "Category",
          goto_link: "/catalogue/category-management",
          privilege: "categoryManagement",
        },
        {
          matchName: "skills",
          title: "Skill",
          goto_link: "/catalogue/skill-management",
          privilege: "skillManagement",
        },
        {
          matchName: "question",
          title: "Question",
          goto_link: "/catalogue/question-management",
          privilege: "questionManagement",
        },
        {
          matchName: "quizes",
          title: "Quiz",
          goto_link: "/catalogue/quiz-management",
          privilege: "quizManagement",
        },
        {
          matchName: "faq",
          title: "FAQs",
          goto_link: "/catalogue/faq-management",
          privilege: "faqManagement",
        },
        {
          matchName: "glossary",
          title: "Glossary",
          goto_link: "/catalogue/glossary-management",
          privilege: "glossaryManagement",
        },
      ],
    },
    {
      nav_title: "CMS Management",
      nav_icon: "fa-regular fa-clipboard-list",
      goto_link: "",
      state: cms,
      setState: setCms,
      privilege: "templateManagement" || "pageManagement",
      content: [
        {
          matchName: "cmsPages",
          title: "Pages",
          goto_link: "/content/pages",
          privilege: "pageManagement",
        },
        {
          matchName: "cmsTemplates",
          title: "Templates",
          goto_link: "/content/templates",
          privilege: "templateManagement",
        },
      ],
    },
    {
      nav_title: "Coupon Management",
      nav_icon: "fa-regular fa-tag",
      goto_link: "/coupons",
      privilege: "coupon_management",
    },
    {
      nav_title: "Daily Quiz Management",
      nav_icon: "fa-regular fa-block-question",
      goto_link: "/daily-quizes",
      privilege: "daily_quiz_management",
    },
    {
      nav_title: "Email Management",
      nav_icon: "fa-regular fa-envelope",
      goto_link: "/email",
      privilege: "email_management",
    },
    {
      nav_title: "Lead Board Management",
      nav_icon: "fa-regular fa-ranking-star",
      goto_link: "/leadboard",
      privilege: "leadboard_management",
    },
    {
      nav_title: "Notification Management",
      nav_icon: "fa-regular fa-bell",
      goto_link: "/notifications",
      privilege: "notifications_management",
    },
    {
      nav_title: "Reward Management",
      nav_icon: "fa-regular fa-gift",
      goto_link: "/rewards",
      privilege: "reward_management",
    },
    {
      nav_title: "Product Management",
      nav_icon: "fa-regular fa-box-taped",
      goto_link: "/products",
      privilege: "productManagement",
    },
    // {
    //   nav_title: "Product Management",
    //   nav_icon: "fa-regular fa-box-taped",
    //   goto_link: "/products",
    //   privilege: "productManagement",
    // },    {
    //   nav_title: "Product Management",
    //   nav_icon: "fa-regular fa-box-taped",
    //   goto_link: "/products",
    //   privilege: "productManagement",
    // },
    // {
    //   nav_title: "Products Management",
    //   nav_icon: "fa-regular fa-box-taped",
    //   goto_link: "",
    //   state: products,
    //   setState: setProducts,
    //   privilege: "productManagement",
    //   content: [
    //     {
    //       matchName: "product",
    //       title: "Product",
    //       goto_link: "/products",
    //       privilege: "productManagement",
    //     },
    //     {
    //       matchName: "inventory",
    //       title: "Inventory",
    //       goto_link: "/inventory",
    //       privilege: "productManagement",
    //     },
    //   ],
    // },
    {
      nav_title: "Score Card Management",
      nav_icon: "fa-regular fa-wallet",
      goto_link: "/score-card",
      privilege: "scorecard_management",
    },
    {
      nav_title: "Feedback Management",
      nav_icon: "fa-regular fa-comment",
      goto_link: "/feedback",
      privilege: "feedbackManagement",
    },
    {
      nav_title: "Order Management",
      nav_icon: "fa-regular fa-box-open",
      goto_link: "/orders",
      privilege: "orderManagement",
    },
    {
      nav_title: "Review Management",
      nav_icon: "fa-sharp fa-regular fa-face-smile",
      goto_link: "/reviews",
      privilege: "reviewManagement",
    },
    {
      nav_title: "Plan Management",
      nav_icon: "fa-sharp fa-light fa-award-simple",
      goto_link: "/plans",
      privilege: "planManagement",
    },
    {
      nav_title: "User",
      nav_icon: "fa-regular fa-users",
      goto_link: "",
      state: userlist,
      setState: setUserList,
      privilege: "userManagement",
      content: [
        {
          matchName: "roles",
          title: "Administrator",
          goto_link: "/users/administrator-directory",
          privilege: "userManagement",
        },
        {
          matchName: "users",
          title: "Application User",
          goto_link: "/users/application-users",
          privilege: "userManagement",
        },
      ],
    },
    {
      nav_title: "My Profile",
      nav_icon: "fa-duotone fa-user",
      goto_link: "/profile",
      privilege: "head",
    },
    {
      nav_title: "Company",
      nav_icon: "fa-regular fa-buildings",
      goto_link: "/company",
      privilege: "head",
      hidden: role !== 1,
    },
    {
      nav_title: "Address",
      nav_icon: "fa-regular fa-warehouse",
      goto_link: "/address",
      privilege: "addressManagement",
    },
  ];

  return (
    <div className={`sidebar-main mini`}>
      {/* ____ Heading _______ */}
      <div
        className={`sidebar-header mini flex-center`}
        onClick={() => navigate("/")}
      >
        <img src={minSideLogo} alt="peak_72" />
      </div>

      {/* Navigation List */}
      <ul className={`min-sidebar-menu `}>
        {navigationLinks.map((navTabs, i) => {
          const { privilege } = navTabs;
          const list =
            (privileges.hasOwnProperty(privilege) &&
              Object?.values(privileges[privilege])?.includes("read")) ||
            privilege === "head" ? (
              <li
                key={i}
                className={`${
                  navTabs.state === true ? "nav-button active" : "nav-button"
                } `}
                onClick={() => handleListClick(navTabs)}
                style={navTabs.hidden ? { display: "none" } : null}
              >
                <div
                  className={navTabs?.state === true ? "sc-c active" : "sc-c"}
                >
                  <i
                    className={`${navTabs.nav_icon} large`}
                    style={setActiveColor(navTabs.goto_link, i)}
                  ></i>
                </div>

                <div
                  className={`${
                    navTabs.state === true ? "accordian active" : "accordian"
                  } `}
                >
                  <h4
                    className={
                      navTabs.state === true
                        ? "nav-link-title active"
                        : "nav-link-title"
                    }
                  >
                    {navTabs.nav_title}

                    {navTabs.content ? (
                      <i
                        className={`fa-regular fa-chevron-right small ${
                          navTabs.state ? "rotate-90" : "rotate-0"
                        }`}
                      ></i>
                    ) : null}
                  </h4>

                  {/* ____ Nested List ______  */}
                  {navTabs.state === true && (
                    <ul className="nested-list">
                      {navTabs.content
                        ? navTabs.content.map((list, i) => (
                            <li
                              key={i}
                              className={"nested-list-link"}
                              onClick={() => {
                                setActive(list.title);
                              }}
                            >
                              <Link
                                className={
                                  active === list.matchName ? "active-link" : ""
                                }
                                to={list.goto_link}
                              >
                                {list.title}
                              </Link>
                            </li>
                          ))
                        : null}
                    </ul>
                  )}
                </div>
              </li>
            ) : null;
          return list;
        })}
      </ul>
    </div>
  );
};

export default MinimizeSidebar;
