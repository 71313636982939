import React from "react";

const Cards = ({ isLoading, icon, title, value, precentage }) => {
  const posNeg =
    precentage?.charAt(0) === "-"
      ? "negative"
      : precentage?.charAt(0) === "+"
        ? "positive"
        : "negative";

  return (
    <>
      {isLoading ? (
        <div className="custom-box flex-center gap-2">
          <div className="skelaton flex-1">
            <div className="line h17 m10"></div>
            <div className="line h10 w50"></div>
          </div>
          <div>
            <div className="square circle"></div>
          </div>
        </div>
      ) : (
        <div className="dashboard-card custom-box">
          <h1 className="dashboard-card__total">
            {String(value).padStart(2, "0")}
          </h1>
          <p className="dashboard-card__title">{title}</p>
          <div className="dashboard-card__icon flex-center">
            <div className="flex-center">
              <i className={`${icon} large`}></i>
            </div>
          </div>
          {precentage && (
            <div className="dashboard-card__precentage flex-center">
              <span className={posNeg}>{precentage}</span>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Cards;
