import React, { useState } from "react";
// import DeletePopup from "./DeletePopup";
// import DeactivatePopup from "./DeactivatePopup";
import ConformationPopup from "components/ui/ConformationPopup";
import { deactivateAccount, deleteAccount } from "../redux/profileApi";

const DeleteAcc = () => {
    const [ischeckpush, setischeckpush] = useState(false);
    const [deletePop, setDeletePop] = useState(false)
    const [deactivatePop, setdDactivatePop] = useState(false)

    return (
        <div className="profile-details">
            <div className="header-wrapper">
                <h2 className="title">Delete Account</h2>
                <p style={{ fontSize: ".9rem" }}>Once you delete your account, there is no going back. Please be certain.</p>
            </div>
            <div className="flex-between p-1">
                <div className="flex-center gap-1">
                    <div>
                        <input
                            type="checkbox"
                            name="confirm"
                            id="confirm"
                            style={{ display: "none" }}
                            className="cc-inp"
                            checked={ischeckpush}
                            onChange={() => setischeckpush(!ischeckpush)}
                        />
                        <label htmlFor="confirm">
                            <div className="custom-checkbox">
                                <div className="check-circle"></div>
                            </div>
                        </label>
                    </div>
                    <div>
                        <p>Confirm</p>
                        <p style={{ fontSize: ".9rem" }}>I want to delete/deactivate my account.</p>
                    </div>
                </div>
                <div className="flex gap-1">
                    <button type="button" disabled={!ischeckpush} className="btn-outlined" onClick={() => setdDactivatePop((!deactivatePop))}>Deactivate</button>
                    <button type="button" disabled={!ischeckpush} className="btn-secondary" onClick={() => setDeletePop((!deletePop))}>Delete Account</button>
                </div>
            </div>
            {
                deletePop &&
                // <DeletePopup deletePop={deletePop} setDeletePop={setDeletePop} />
                <ConformationPopup
                    customSetter={setDeletePop}
                    customState={deletePop}
                    api={deleteAccount}
                    popUpData={{ title: "Account", data: { is_delete: true } }}
                    navigateTo={'/'}
                />
            }
            {deactivatePop &&
                // <DeactivatePopup deactivatePop={deactivatePop} setdDactivatePop={setdDactivatePop} />
                <ConformationPopup
                    customSetter={setdDactivatePop}
                    customState={deactivatePop}
                    api={deactivateAccount}
                    popUpData={{ title: "Account", data: { status: false } }}
                    navigateTo={'/'}
                />
            }
        </div>
    );
};

export default DeleteAcc;
