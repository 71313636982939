import CardSkelaton from "components/skelaton/CardSkelaton";
import React from "react";

const Card = ({ data, isLoading = false }) => {
  return (
    <div className="card-wrapper mt-2">
      {isLoading
        ? new Array(6).fill(undefined).map((_, i) => <CardSkelaton key={i} />)
        : data?.map((item, i) => (
            <div className="card" key={i}>
              <div className="card-main">
                <div className="card-main-left">
                  <img
                    src={item?.image}
                    alt="card-icon"
                    className="card-icon"
                  />
                </div>
                <div className="card-main-right">
                  <h4 className="card-main__title">{item?.title}</h4>
                  <p className="card-main__txt">{item.description}</p>
                </div>
              </div>
              <div className="card-action mt-1">
                {item.levels.map((lev, i) => (
                  <div className={`level-box ${lev.status}`} key={i}>
                    {lev.title}
                    {lev.status === "success" && (
                      <i className="fa-solid fa-circle-check"></i>
                    )}
                    {lev.status === "inprogress" && (
                      <i className="fa-solid fa-circle-quarter-stroke" style={{color:"#E8C842"}}></i>
                    )}
                  </div>
                ))}
              </div>
            </div>
          ))}
    </div>
  );
};

export default Card;
