import { createSlice } from "@reduxjs/toolkit";
import { getFaqDetail, getFaqList } from "./faqApi";


const faqSlice = createSlice({
    name: "faq",
    initialState: {
        faqLoading: false,
        faqList: [],
        faqDetail: {},
        metaData: {}
    },
    reducers: {
        isFaqLoading: (state, action) =>
        (state = {
            ...state,
            faqLoading: action.payload,
        }),
    },
    extraReducers: (builder) => {
        // faq List reducers-------------------------
        builder.addCase(getFaqList.pending, (state, action) => {
            state.faqLoading = true;
        });
        builder.addCase(getFaqList.fulfilled, (state, action) => {
            state.faqLoading = false;
            state.faqList = action.payload.data;
            state.metaData = action.payload.metadata[0];
        });
        builder.addCase(getFaqList.rejected, (state, action) => {
            state.faqLoading = false;
            state.questionList = [];
        });

        // faq detail 
        builder.addCase(getFaqDetail.pending, (state, action) => {
            state.faqLoading = true;
        });
        builder.addCase(getFaqDetail.fulfilled, (state, action) => {
            state.faqLoading = false;
            state.faqDetail = action.payload.data;
        });
        builder.addCase(getFaqDetail.rejected, (state, action) => {
            state.faqLoading = false;
            state.faqDetail = {};
        });
    }
})

export const { isFaqLoading } = faqSlice.actions;
export default faqSlice.reducer;