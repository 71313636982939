import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { manageTfa } from "../redux/profileApi";

const Authentication = () => {
  const { profileDetail } = useSelector((state) => state.profile, shallowEqual);

  const dispatch = useDispatch();
  const [ischeckpush, setischeckpush] = useState(true);

  // const handlePushToggle = async () => {
  //   const data = {
  //     is_2FA: !ischeckpush,
  //   };
  //   const response = await dispatch(manageTfa(data));
  //   if (response?.payload) {
  //     setischeckpush(!ischeckpush);
  //   }
  // };


  let timeoutId = null;
  const handlePushToggle = () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(async () => {
      const response = await dispatch(manageTfa({ is_2FA: !ischeckpush }));
      if (response?.payload) {
        setischeckpush(!ischeckpush);
      }
    }, 200);
  };

  useEffect(() => {
    if (profileDetail) {
      setischeckpush(profileDetail?.is2FA);
    }
  }, [profileDetail]);

  return (
    <div className="profile-details">
      <div className="header-wrapper">
        <h2 className="title">Manage Authentication</h2>
      </div>
      <div className="flex flex-col gap-2" style={{ padding: "1rem" }}>
        <div className="flex-between">
          <p>Two Factor Authentication</p>
          <div>
            <input
              type="checkbox"
              name="push"
              id="push"
              style={{ display: "none" }}
              className="cc-inp"
              checked={ischeckpush}
              onChange={handlePushToggle}
            />
            <label htmlFor="push">
              <div className="custom-checkbox">
                <div className="check-circle"></div>
              </div>
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Authentication;
