import { createSlice } from "@reduxjs/toolkit";
import {
  getQuestionDetail,
  getQuestionList,
  getLevelList,
  getAllQues,
} from "./questionApi";

const questionSlice = createSlice({
  name: "question",
  initialState: {
    questionLoading: false,
    questionList: [],
    allQues: [],
    metaData: [],
    questionDetail: {},
    levelList: [],
  },
  reducers: {
    isQuestionLoading: (state, action) =>
      (state = {
        ...state,
        questionLoading: action.payload,
      }),

    clearQuestionList: (state, action) => {
      state.questionList = [];
    },
    clearQuestionDetail: (state, action) => {
      state.questionDetail = {};
    },
  },
  extraReducers: (builder) => {
    // getLevelList reducers-------------------------
    builder.addCase(getLevelList.pending, (state, action) => {
      state.questionLoading = true;
    });
    builder.addCase(getLevelList.fulfilled, (state, action) => {
      state.questionLoading = false;
      state.levelList = action.payload.data;
      state.metaData = action.payload.metadata[0];
    });
    builder.addCase(getLevelList.rejected, (state, action) => {
      state.questionLoading = false;
      state.questionList = [];
    });
    // getAllQues reducers-------------------------
    builder.addCase(getAllQues.pending, (state, action) => {
      state.questionLoading = true;
    });
    builder.addCase(getAllQues.fulfilled, (state, action) => {
      state.questionLoading = false;
      state.allQues = action.payload.data;
      state.metaData = action.payload.metadata[0];
    });
    builder.addCase(getAllQues.rejected, (state, action) => {
      state.questionLoading = false;
      state.allQues = [];
    });
    // getQuestionList reducers-------------------------
    builder.addCase(getQuestionList.pending, (state, action) => {
      state.questionLoading = true;
    });
    builder.addCase(getQuestionList.fulfilled, (state, action) => {
      state.questionLoading = false;
      state.questionList = action.payload.data;
      state.metaData = action.payload.metadata[0];
    });
    builder.addCase(getQuestionList.rejected, (state, action) => {
      state.questionLoading = false;
      state.questionList = [];
    });
    // getQuestionDetail reducers-------------------------
    builder.addCase(getQuestionDetail.pending, (state, action) => {
      state.questionLoading = true;
    });
    builder.addCase(getQuestionDetail.fulfilled, (state, action) => {
      state.questionLoading = false;
      state.questionDetail = action.payload.data;
    });
    builder.addCase(getQuestionDetail.rejected, (state, action) => {
      state.questionLoading = false;
      state.questionDetail = {};
    });
  },
});

export const { isQuestionLoading, clearQuestionList, clearQuestionDetail } =
  questionSlice.actions;
export default questionSlice.reducer;
