import React from "react";

const Checkbox = ({ index = "", name = "", id = "", checked, onChange }) => {
  return (
    <label htmlFor={`checkbox${index}`} className="TD_CheckBox_LB">
      <input
        name={name}
        id={`checkbox${index}`}
        type="checkbox"
        className="checkbox-animated"
        checked={checked}
        onChange={onChange}
      />
      <div className="checkmark"></div>
    </label>
  );
};

export default Checkbox;
