import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { AddNumField, options } from "helper/helper";
import { isReviewLoading } from "./reviewSlice";

export const getReviewList = createAsyncThunk(
  "admin/review-list",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      const response = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/review/review-list?page=${data?.page}&limit=${data?.limit}&sort=${data?.sort ? data?.sort : "desc"}&search=${data.search ? data.search : ""}&filter[rating]=${data.rating ? data.rating : ""}`,
      });

      if (response.status === 200) {
        if (response?.data?.data?.length > 0) {
          const res = await AddNumField(
            response?.data?.data?.[0],
            data?.page * data?.limit
          );

          return fulfillWithValue(res);
        } else {
          const modPage = Math.max(data.page - 1, 0);
          const responseAgain = await axios({
            method: "GET",
            url: `${process.env.REACT_APP_AUTH_URL}/admin/review/review-list?page=${data?.page}&limit=${data?.limit}&sort=${data?.sort ? data?.sort : "desc"}&search=${data.search ? data.search : ""}&filter[rating]=${data.rating ? data.rating : ""}`,
          });
          if (responseAgain.status === 200) {
            const resAgain = await AddNumField(
              responseAgain?.data,
              modPage * data?.limit
            );
            return fulfillWithValue(resAgain);
          } else {
            toast.error(responseAgain?.data?.message, options);
            return rejectWithValue();
          }
        }
      } else {
        // toast.error(response?.data?.message, options);
        return rejectWithValue();
      }
    } catch (err) {
      return rejectWithValue();
    }
  }
);

export const getReviewDetail = createAsyncThunk(
  "admin/review-detail",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      const response = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/review/review-detail/?page=${data?.page ? data?.page : 0}&limit=${data?.limit ? data?.limit : 5}&sort=${data?.sort ? data?.sort : "desc"}&search=${data?.search ? data?.search : ""}&filter[reviewId]=${data?.id}`,
      });
      if (response.status === 200) {
        return fulfillWithValue(response?.data);
      } else {
        toast.error(response?.data?.message, options);
        return rejectWithValue();
      }
    } catch (err) {
      toast.error(err, options);
      return rejectWithValue();
    }
  }
);

export const deleteReview = createAsyncThunk(
  "review/delete-review",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      dispatch(isReviewLoading(true));
      const response = await axios({
        method: "DELETE",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/review/delete-review`,
        data: data?.send,
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.status === 200) {
        dispatch(isReviewLoading(false));
        dispatch(getReviewDetail({ id: data.id }));
        toast.success(response?.data?.message, options);
        return response?.data?.status;
      } else {
        dispatch(isReviewLoading(false));
        toast.error(response?.data?.message, options);
      }
    } catch (err) {
      dispatch(isReviewLoading(false));
      toast.error(err, options);
    }
  }
);