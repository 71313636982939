import CardSkelaton from "components/skelaton/CardSkelaton";
import { unixTimeToReadableFormat } from "helper/helper";
import React from "react";

const CatCard = ({
  isLoading,
  data,
  privileges,
  hasPrivilege,
  handleClick,
  deleteCard,
}) => {
  return (
    <>
      {isLoading
        ? new Array(6).fill(undefined).map((_, i) => <CardSkelaton key={i} />)
        : data?.map((item, i) => (
            <div className="category" key={i}>
              {item?.type && (
                <p className={`category-main__type ${item?.type}`}>
                  {item?.type}
                </p>
              )}

              {item?.isPremium === true && (
                <p className={`category-main__type premium`}>Premium</p>
              )}

              {item?.isPremium === false && (
                <p className={`category-main__type free`}>Free</p>
              )}
              <div className="category-main">
                {item?.image?.bucketUrl || item?.image?.localUrl ? (
                  <img
                    src={ item?.image?.localUrl || item?.image?.bucketUrl }
                    alt="category-icon"
                    crossOrigin={item?.image?.localUrl && "anonymous"}
                    className="category-main-left"
                  />
                ) : (
                  <div className="category-main-left flex-center">
                    <i class="fa-regular fa-image-slash" style={{fontSize:"3rem", color:"rgb(149 143 143)"}}></i>
                  </div>
                )}
                <div className="category-main-right">
                  <h2 title={item?.name}>{item?.name}</h2>
                  <div className="category-main__txt">
                    <div className="txt">
                      <h4>
                        {item?.createdBy?.name?.firstName +
                          " " +
                          item?.createdBy?.name?.lastName}
                      </h4>
                      <cite>Created By</cite>
                    </div>
                    <div className="txt">
                      <h4>{unixTimeToReadableFormat(item?.createdAt)}</h4>
                      <cite>Created At</cite>
                    </div>
                  </div>
                </div>
              </div>
              <div className="category-action">
                {Object.values(privileges?.[hasPrivilege]).includes(
                  "delete"
                ) && (
                  <button
                    className="btn-outlined w-full cancel"
                    style={{ color: "var(--color-tertiary)" }}
                    onClick={() => deleteCard(item?.id, item?.name, 6)}
                  >
                    Delete
                  </button>
                )}

                {Object?.values?.(privileges?.[hasPrivilege])?.includes(
                  "write"
                ) && (
                  <button
                    className="btn-outlined w-full primary"
                    style={{ padding: "0.5rem 0.9rem" }}
                    onClick={() => handleClick("update", item?.id)}
                  >
                    View
                  </button>
                )}
              </div>
            </div>
          ))}
    </>
  );
};

export default CatCard;
