import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { iscompanyToggle } from "./companySlice";
import { toast } from "react-toastify";

export const getCompanyDetail = createAsyncThunk(
  "company/company-details",
  async (data, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/setting/get-detail`,
      });

      if (response.status === 200) {
        return fulfillWithValue(response.data);
      } else {
        return rejectWithValue();
      }
    } catch (error) {
      return rejectWithValue();
    }
  }
);

export const changeCompanyLogo = createAsyncThunk(
  "profile/change-profile-picture",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      dispatch(iscompanyToggle(true));
      const response = await axios({
        method: "PUT",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/setting/change-logo`,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: data,
      });

      if (response.status === 200) {
        dispatch(getCompanyDetail());
        dispatch(iscompanyToggle(false));
        toast.success(response?.data?.message);
        return response?.data?.status;
      } else {
        dispatch(iscompanyToggle(false));
        toast.error(response?.data?.message);
      }
    } catch (err) {
      dispatch(iscompanyToggle(false));
      toast.error(err);
    }
  }
);

export const getLocality = createAsyncThunk(
  "public/get-locality",
  async (data, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_AUTH_URL}/public/locality-detail`,
        data: data,
      });
      if (response.status === 200) {
        return fulfillWithValue(response?.data);
      } else {
        toast.error(response?.data?.message);
        return rejectWithValue();
      }
    } catch (err) {
      // toast.error(err, options);
      return rejectWithValue();
    }
  }
);

export const updateCompanyyDetail = createAsyncThunk(
  "admin/setting/update-detail",
  async (data, { fulfillWithValue, rejectWithValue, dispatch }) => {
    try {
      dispatch(iscompanyToggle(true));
      const response = await axios({
        method: "PUT",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/setting/update-detail`,
        data,
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.status === 200) {
        dispatch(iscompanyToggle(false));
        toast.success(response?.data?.message);
        dispatch(getCompanyDetail());
        return response?.data?.status;
      } else {
        dispatch(iscompanyToggle(false));
        toast.error(response?.data?.message);
      }
    } catch (err) {
      dispatch(iscompanyToggle(false));
      toast.error(err);
    }
  }
);

export const updateMaintenance = createAsyncThunk(
  "admin/setting/manage-maintenance",
  async (data, { fulfillWithValue, rejectWithValue, dispatch }) => {
    try {
      dispatch(iscompanyToggle(true));
      const response = await axios({
        method: "PUT",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/setting/manage-maintenance`,
        data,
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.status === 200) {
        dispatch(iscompanyToggle(false));
        toast.success(response?.data?.message);
        dispatch(getCompanyDetail());
        return response?.data?.status;
      } else {
        dispatch(iscompanyToggle(false));
        toast.error(response?.data?.message);
      }
    } catch (err) {
      dispatch(iscompanyToggle(false));
      toast.error(err);
    }
  }
);
