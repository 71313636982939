/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";

const ActionButton = ({ title, addF, buttonStyles = {} }) => {
  const [showMenu, setShowMenu] = useState(false);
  const [addStyle, setAddStyle] = useState(false);
  const menuRef = useRef();

  const handleClick = () => {
    setAddStyle(!addStyle);
    chatBoxVisibility();
  };

  function chatBoxVisibility() {
    if (showMenu) {
      const styleTimer = setTimeout(() => {
        setShowMenu(false);
        clearTimeout(styleTimer);
      }, 200);
    } else {
      setShowMenu(true);
    }
  }

  useEffect(() => {
    const handleGlobalClick = (event) => {
      if (
        menuRef?.current &&
        !menuRef?.current?.contains(event.target) &&
        showMenu
      )
        handleClick();
    };

    document.addEventListener("click", handleGlobalClick);
    return () => {
      document.removeEventListener("click", handleGlobalClick);
    };
  }, [menuRef, showMenu]);

  return (
    <div
      className={`sort-box-wrapper shakeAnimation ${showMenu ? "border-0" : ""}`}
      ref={showMenu ? menuRef : null}
    >
      <button
        className="btn-primary"
        style={buttonStyles}
        onClick={() => handleClick()}
      >
        <span className="title">{title}</span>{" "}
        <i
          className={`fa-solid fa-caret-down ${showMenu ? "rotate-180" : "rotate-0"}`}
        ></i>
      </button>

      {showMenu && (
        <ul
          className={
            "action-tooltip w-full" +
            (addStyle
              ? " show_component-common-animation"
              : " hide_component-common-animation ")
          }
        >
          <li className="" onClick={() => addF("single")}>
            Add Single
          </li>
          <li className="" onClick={() => addF("multiple")}>
            Add Multiple
          </li>
        </ul>
      )}
    </div>
  );
};

export default ActionButton;
