import ModuleLayout from 'layouts/ModuleLayout'
import React, { useState } from 'react'
import General from '../component/General';
import Payment from '../component/Payment';
import Notes from '../component/Notes';

export default function ManageShipment() {
    const [active, setActive] = useState("shipment");
    const [mobileSidebar, setMobileSidebar] = useState(false);
    const [minimizedSidebar, setMinimizedSidebar] = useState(false);
    const [activeTab, setActiveTab] = useState("general")
    const [condition, setCondition] = useState(2)
    const handleContinue = () => {
        if (condition < 4) {
            setCondition(condition + 1)
        }
    }
    return (
        <ModuleLayout
            minimizedSidebar={minimizedSidebar}
            setMinimizedSidebar={setMinimizedSidebar}
            mobileSidebar={mobileSidebar}
            setMobileSidebar={setMobileSidebar}
            active={active}
            setActive={setActive}
        >
            <div className="list-container custom-box">
                <div className="tb_controls">
                    <h2>Shipment Management </h2>
                </div>
            </div>
            <main>
                <div>
                    <div className="custom-box">
                        <div className="flex-between">
                            <div>
                                <h3>Order ID: #123456 </h3>
                            </div>
                            <div className="flex gap-1">
                                {/* <button className='btn-outlined' type="button">Cancel</button>
                                <button className='btn-outlined' type="button">Hold</button> */}
                                <button
                                    className='btn-primary'
                                    type="button"
                                    onClick={handleContinue}
                                >
                                    {
                                        condition === 1 ? "Accept Order" :
                                            (condition === 2 ? "Continue" :
                                                (condition === 3 ? "Create Shipment" :
                                                    "Finish"
                                                )
                                            )
                                    }
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="flex gap-1 od-tab">
                        <button type="button" className={`odt-btn ${activeTab === "general" && "active"}`} onClick={() => setActiveTab("general")}>General</button>
                        <button type="button" className={`odt-btn ${activeTab === "payments" && "active"}`} onClick={() => setActiveTab("payments")}>Payments</button>
                        <button type="button" className={`odt-btn ${activeTab === "notes" && "active"}`} onClick={() => setActiveTab("notes")}>Notes</button>
                    </div>
                </div>

                {
                    activeTab === "general" &&
                    <div className="sticky-status OD-status">
                        <span
                            className="status first"
                            style={{ background: condition >= 1 ? "#4BB543" : "#D9D9D9" }}
                        >
                            <span>Pending</span>
                            <span
                                className="arrow-right"
                                style={{
                                    background: "#4BB543"
                                }}
                            ></span>
                        </span>
                        <span
                            className="status"
                            style={{
                                background:
                                    condition >= 2
                                        ? "#4BB543"
                                        : condition === 1
                                            ? "#D9D9D9"
                                            : "#D9D9D9",
                            }}
                        >
                            <span className="arrow-left"></span>
                            <span>Processing</span>
                            <span
                                className="arrow-right"
                                style={{
                                    background:
                                        condition >= 2
                                            ? "#4BB543"
                                            : condition === 1
                                                ? "#D9D9D9"
                                                : "#D9D9D9",
                                }}
                            ></span>
                        </span>
                        <span
                            className="status"
                            style={{
                                background:
                                    condition >= 3
                                        ? "#4BB543"
                                        : condition === 1
                                            ? "#D9D9D9"
                                            : "#D9D9D9",
                            }}
                        >
                            {" "}
                            <span className="arrow-left"></span>
                            <span>Shipped</span>
                            <span
                                className="arrow-right"
                                style={{
                                    background:
                                        condition >= 3
                                            ? "#4BB543"
                                            : condition === 1
                                                ? "#D9D9D9"
                                                : "#D9D9D9",
                                }}
                            ></span>
                        </span>
                        <span
                            className="status last"
                            style={{
                                background:
                                    condition === 4
                                        ? "#4BB543"
                                        : condition === 3
                                            ? "#D9D9D9"
                                            : "#D9D9D9",
                            }}
                        >
                            {" "}
                            <span className="arrow-left"></span>
                            <span>Completed</span>
                        </span>
                    </div>
                }

                <div className="main-ODetais">
                    {activeTab === "general" && <General />}
                    {activeTab === "payments" && <Payment />}
                    {activeTab === "notes" && <Notes />}
                </div>


            </main>
        </ModuleLayout>
    )
}
