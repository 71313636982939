export const Checkbox = ({
  name,
  checked = false,
  onChange,
  label,
  disabled,
}) => (
  <>
    <label className="TD_CheckBox_LB flex-center gap-2">
      <input
        id={name}
        name={name}
        type="checkbox"
        checked={checked}
        onChange={onChange}
        disabled={!disabled}
      />
      <div className="checkmark"></div>
      <span className="pr_Body_TD_Val">{label}</span>
    </label>
  </>
);
