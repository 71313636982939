import { createSlice } from "@reduxjs/toolkit";
import { getBrandList, getBrandDetail } from "./brandsApi";

const brandSlice = createSlice({
  name: "CatBrand",
  initialState: {
    brandLoading: false,
    brandList: [],
    metaData: [],
    brandDetail: {},
  },
  reducers: {
    isBrandLoading: (state, action) =>
      (state = {
        ...state,
        brandLoading: action.payload,
      }),
    clearBrandDetail: (state, action) => {
      state.brandDetail = {};
    },
  },
  extraReducers: (builder) => {
    // getBrandList reducers-------------------------
    builder.addCase(getBrandList.pending, (state, action) => {
      state.brandLoading = true;
    });
    builder.addCase(getBrandList.fulfilled, (state, action) => {
      state.brandLoading = false;
      state.brandList = action?.payload?.data;
      state.metaData = action?.payload?.metadata[0];
    });
    builder.addCase(getBrandList.rejected, (state, action) => {
      state.brandLoading = false;
      state.brandList = [];
    });

    // getGlossaryDetail reducers-------------------------
    builder.addCase(getBrandDetail.pending, (state, action) => {
      state.brandLoading = true;
    });
    builder.addCase(getBrandDetail.fulfilled, (state, action) => {
      state.brandLoading = false;
      state.brandDetail = action.payload.data;
    });
    builder.addCase(getBrandDetail.rejected, (state, action) => {
      state.brandLoading = false;
    });
  },
});

export const { isBrandLoading, clearBrandDetail } = brandSlice.actions;
export default brandSlice.reducer;
