import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { AddNumField, options } from "helper/helper";
import { isplanLoading } from "./planSlice";

export const addPlan = createAsyncThunk(
    "plan/add-plan",
    async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
      try {
        dispatch(isplanLoading(true));
        const response = await axios({
          method: "POST",
          url: `${process.env.REACT_APP_AUTH_URL}/admin/subscription/plan/create-plan`,
          data: data,
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (response.status === 200) {
          dispatch(isplanLoading(false));
          toast.success(response?.data?.message);
          return response?.data?.status;
        } else {
          dispatch(isplanLoading(false));
          toast.error(response?.data?.message);
        }
      } catch (err) {
        dispatch(isplanLoading(false));
        toast.error(err);
      }
    }
  );

export const getPlanList = createAsyncThunk(
    "admin/plan-list",
    async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
        try {
            const response = await axios({
                method: "GET",
                url: `${process.env.REACT_APP_AUTH_URL}/admin/subscription/plan/plan-list?page=${data?.page}&limit=${data?.limit}&sort=${data?.sort ? data?.sort : "desc"}&search=${data.search ? data.search : ""}&filter[type]=${data.type ? data.type : ""}&filter[recurringCycle]=${data.recurringCycle ? data.recurringCycle : ""}`,
            });

            if (response.status === 200) {
                if (response?.data?.data?.length > 0) {
                    const res = await AddNumField(
                        response?.data?.data?.[0],
                        data?.page * data?.limit
                    );

                    return fulfillWithValue(res);
                } else {
                    const modPage = Math.max(data.page - 1, 0);
                    const responseAgain = await axios({
                        method: "GET",
                        url: `${process.env.REACT_APP_AUTH_URL}/admin/subscription/plan/plan-list?page=${data?.page}&limit=${data?.limit}&sort=${data?.sort ? data?.sort : "desc"}&search=${data.search ? data.search : ""}&filter[type]=${data.type ? data.type : ""}&filter[recurringCycle]=${data.recurringCycle ? data.recurringCycle : ""}`,
                    });
                    if (responseAgain.status === 200) {
                        const resAgain = await AddNumField(
                            responseAgain?.data,
                            modPage * data?.limit
                        );
                        return fulfillWithValue(resAgain);
                    } else {
                        toast.error(responseAgain?.data?.message, options);
                        return rejectWithValue();
                    }
                }
            } else {
                // toast.error(response?.data?.message, options);
                return rejectWithValue();
            }
        } catch (err) {
            return rejectWithValue();
        }
    }
);

export const getPlanDetail = createAsyncThunk(
    "admin/plan-detail",
    async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
        try {
            dispatch(isplanLoading(true));
            const response = await axios({
                method: "GET",
                url: `${process.env.REACT_APP_AUTH_URL}/admin/subscription/plan/plan-detail/${data.id}`,
                headers: {
                    "Content-Type": "application/json"
                }
            })
            if (response.status === 200) {
                dispatch(isplanLoading(false));
                return fulfillWithValue(response?.data);
            } else {
                dispatch(isplanLoading(false));
                return rejectWithValue();
            }
        } catch (err) {
            dispatch(isplanLoading(false));
            return rejectWithValue();
        }
    }
)

export const updatePlan = createAsyncThunk(
    "plan/update-plan",
    async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {

        try {
            dispatch(isplanLoading(true));
            const response = await axios({
                url: `${process.env.REACT_APP_AUTH_URL}/admin/subscription/plan/update-detail/${data?.id}`,
                method: "PUT",
                data: data.send,
                headers: {
                    "Content-Type": "application/json"
                }
            })
            if (response?.status === 200) {
                dispatch(getPlanDetail({ id: data?.id }))
                toast.success(response?.data?.message, options)
                return fulfillWithValue(response?.data);
            } else {
                toast.error(response?.data?.message, options)
                dispatch(isplanLoading(false));
                return rejectWithValue();
            }
        } catch (error) {
            dispatch(isplanLoading(false));
            return rejectWithValue();
        }
    }
)

export const deletePlan = createAsyncThunk(
    "plan/delete-plan",
    async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
        try {
            dispatch(isplanLoading(true));
            const response = await axios({
                method: "DELETE",
                url: `${process.env.REACT_APP_AUTH_URL}/admin/subscription/plan/delete-plan`,
                data: data?.send,
                headers: {
                    "Content-Type": "application/json",
                },
            });
            if (response.status === 200) {
                dispatch(isplanLoading(false));
                dispatch(
                    getPlanList({
                        page: data?.page,
                        limit: data.limit,
                        search: data.search,
                        sort: data.sort,
                    })
                );
                toast.success(response?.data?.message, options);
                return response?.data?.status;
            } else {
                dispatch(isplanLoading(false));
                toast.error(response?.data?.message, options);
            }
        } catch (err) {
            dispatch(isplanLoading(false));
            toast.error(err, options);
        }
    }
);

export const planActivateDeactivate = createAsyncThunk(
    "plan/activate-deactivate",
    async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
        console.log('data: ', data);
      try {
        dispatch(isplanLoading(true));
        const response = await axios({
          method: "PUT",
          url: `${process.env.REACT_APP_AUTH_URL}/admin/subscription/plan/manage-plan/${data?.id}`,
          data: data?.send,
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (response.status === 200) {
          dispatch(isplanLoading(false));
          toast.success(response?.data?.message, options)
          return response?.data?.status;
        } else {
          dispatch(isplanLoading(false));
          toast.error(response?.data?.message, options);
        }
      } catch (err) {
        dispatch(isplanLoading(false));
        toast.error(err, options);
      }
    }
  );