/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import DropDownBox from "components/DropDownBox";
import InputField from "components/ui/InputField";
import SubmitButton from "components/ui/SubmitButton";
import ModuleLayout from "layouts/ModuleLayout";
import { Checkbox } from "../../components/Checkbox";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  minBirthDate,
  minDate,
  unixTimeToYYYYMMDD,
  yyyyMMDDToISO,
} from "helper/helper";
import { addAdmin, getAdminDetail, updateAdmin } from "../redux/adminApi";
import { useFormik } from "formik";
import * as Yup from "yup";

const ManageAdmin = ({ setTitle }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      setTitle("Administrators Directory | Update Admin Details");
      dispatch(getAdminDetail({ id: id }));
    } else {
      setTitle("Administrators Directory | Add Admin Details");
    }
  }, [setTitle]);

  const { pathname } = useLocation();
  const ActivePage = pathname.includes("add-user") ? "Add" : "Update";

  const [isoList, setIsoList] = useState([]);
  const [active, setActive] = useState("roles");
  const [mobileSidebar, setMobileSidebar] = useState(false);
  const [minimizedSidebar, setMinimizedSidebar] = useState(false);
  const [activeInputs, setActiveInputs] = useState(true);
  const [modulePermissions, setModulePermissions] = useState({});

  const { adminDetail, adminLoading } = useSelector(
    (state) => state.admin,
    shallowEqual
  );

  const { countryList } = useSelector((state) => state.profile, shallowEqual);
  const { privileges } = useSelector((state) => state.auth, shallowEqual);

  useEffect(() => {
    if (countryList?.length > 0) {
      const cc = countryList.map((item) => {
        // const label = `${item?.flag?.symbol} ${item?.dialCode} ${item?.name}`;
        const label = `<img src = "${item?.flag?.icon}" width=15 style="margin-right: 10px"/>  ${item?.dialCode} ${item.name}`;
        const value = item.isoCode;
        const key = item.dialCode;
        return { label, value, key };
      });
      setIsoList(cc);
    }
  }, [countryList]);

  const initialValues = {
    first_name: "",
    last_name: "",
    email: "",
    phone_no: "",
    gender: "",
    dob: "",
    role: 2,
    privileges: {},
    country: id ? "" : "IN",
  };

  const profileSchema = Yup.object({
    first_name: Yup.string()
      .trim()
      .min(3, "First Name must be at least 3 characters long")
      .max(80, "First name must be less then 80 characters")
      .matches(/^[A-Za-z ]+$/, "Please enter a valid name")
      .required("First name is required"),
    last_name: Yup.string()
      .trim()
      .min(3, "Last Name must be at least 3 characters long")
      .max(80, "Last name must be less then 80 characters")
      .matches(/^[A-Za-z ]+$/, "Please enter a valid name")
      .required("Last name is required"),
    email: Yup.string()
      .trim()
      .email("Complete email address")
      .matches(
        /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
        "Enter a valid email, please!"
      )
      .required("Email address should not be empty"),
    country: Yup.string(),
    phone_no: Yup.string()
      .required("Phone number is required")
      .when("country", {
        is: "IN-+91",
        then: () =>
          Yup.string().matches(
            /^[0-9]\d{9}$/,
            "Please enter a valid phone number"
          ),
      }),
    gender: Yup.string().required("Gender is required"),
    dob: Yup.date()
      .required("Birth date is required")
      .test("age", "User must be 18 years old or above", (value) => {
        const today = new Date(); // Define 'today' as the current date
        const birthDate = new Date(value);
        const ageDifferenceInMilliseconds = today - birthDate;
        const ageInYears =
          ageDifferenceInMilliseconds / (365.25 * 24 * 60 * 60 * 1000); // Approximate calculation
        return ageInYears >= 18;
      })
      .test("age two", "User's age must be less than 100 years", (value) => {
        if (new Date(value).getFullYear() >= new Date().getFullYear() - 100) {
          return true;
        } else {
          return false;
        }
      }),
    role: Yup.string().required("Role is required"),
    // status: Yup.string().required("Status is required"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: profileSchema,
    onSubmit: async (values) => {
      const contryCode = isoList?.filter(
        (ele) => ele.value === formik.values.country
      )?.[0];
      const data = {
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        iso_code: values.country,
        phone: `${contryCode?.key}${values.phone_no}`,
        gender: values.gender,
        date_of_birth: yyyyMMDDToISO(values.dob),
        role: Number(values.role),
        privileges: Object.entries(values.privileges).map(([key, value]) => [
          key,
          (() => {
            if (value.includes("all")) {
              return ["read", "write", "delete"];
            } else {
              return value;
            }
          })(),
        ]),
      };

      if (pathname.includes("add-user")) {
        const response = await dispatch(addAdmin(data));
        if (response?.payload) {
          navigate(-1);
        }
      } else if (id?.length > 0) {
        const response = await dispatch(updateAdmin({ send: data, id }));

        if (response?.payload) {
          navigate(-1);
        }
      }
    },
  });

  useEffect(() => {
    if (id?.length > 0 && adminDetail) {
      const iso = countryList.filter(
        (ele) => ele.isoCode === adminDetail?.phone?.isoCode
      )?.[0];
      formik.setValues({
        first_name: adminDetail?.name?.firstName,
        last_name: adminDetail?.name?.lastName,
        email: adminDetail?.email?.value,
        phone_no: adminDetail?.phone?.value?.slice(iso?.dialCode?.length),
        country: adminDetail?.phone?.isoCode,
        gender: adminDetail?.gender,
        role: adminDetail?.role,
        dob: unixTimeToYYYYMMDD(adminDetail?.dob),
        privileges: adminDetail?.privileges,
      });
      setModulePermissions(adminDetail.privileges);
    }
  }, [adminLoading, adminDetail]);

  const updatePermissions = (moduleName, permissionType, value) => {
    setModulePermissions((prevPermissions) => {
      const updatedPermissions = { ...prevPermissions };

      if (value) {
        // Add the permission to the module if the checkbox is checked
        if (!updatedPermissions[moduleName]) {
          updatedPermissions[moduleName] = [];
        }
        switch (permissionType) {
          case "read":
            updatedPermissions[moduleName] = ["read"];
            break;
          case "write":
            if (updatedPermissions[moduleName].includes("read")) {
              updatedPermissions[moduleName] = [
                ...new Set([...updatedPermissions[moduleName], "write"]),
              ];
            }
            break;
          case "delete":
            if (
              updatedPermissions[moduleName].includes("read") &&
              updatedPermissions[moduleName].includes("write")
            ) {
              updatedPermissions[moduleName] = [
                ...new Set([...updatedPermissions[moduleName], "delete"]),
              ];
            }
            break;
          case "all":
            updatedPermissions[moduleName] = ["all", "read", "write", "delete"];
            break;
          default:
            break;
        }
      } else {
        // Remove the permission from the module if the checkbox is unchecked
        if (updatedPermissions[moduleName]) {
          if (permissionType === "all") {
            delete updatedPermissions[moduleName];
          } else if (permissionType === "read") {
            delete updatedPermissions[moduleName];
          } else {
            updatedPermissions[moduleName] = updatedPermissions[
              moduleName
            ].filter((perm) => perm !== permissionType);
            if (permissionType === "write") {
              updatedPermissions[moduleName] = updatedPermissions[
                moduleName
              ].filter((perm) => perm !== "delete");
            }
            if (updatedPermissions[moduleName].length === 0) {
              delete updatedPermissions[moduleName];
            }
          }
        }
      }

      // Automatically check "all" if "read", "write", and "delete" are checked
      if (
        updatedPermissions[moduleName]?.includes("read") &&
        updatedPermissions[moduleName]?.includes("write") &&
        updatedPermissions[moduleName]?.includes("delete")
      ) {
        updatedPermissions[moduleName] = ["all", "read", "write", "delete"];
      } else if (
        updatedPermissions[moduleName]?.includes("all") &&
        (!updatedPermissions[moduleName]?.includes("read") ||
          !updatedPermissions[moduleName]?.includes("write") ||
          !updatedPermissions[moduleName]?.includes("delete"))
      ) {
        updatedPermissions[moduleName] = updatedPermissions[moduleName].filter(
          (perm) => perm !== "all"
        );
      }

      // Remove the module if it has no permissions
      if (
        updatedPermissions[moduleName] &&
        updatedPermissions[moduleName].length === 0
      ) {
        delete updatedPermissions[moduleName];
      }

      // Update formik values with the updated permissions
      formik.setFieldValue("privileges", updatedPermissions);

      return updatedPermissions;
    });
  };

  return (
    <ModuleLayout
      minimizedSidebar={minimizedSidebar}
      setMinimizedSidebar={setMinimizedSidebar}
      mobileSidebar={mobileSidebar}
      setMobileSidebar={setMobileSidebar}
      active={active}
      setActive={setActive}
    >
      <div className="list-container custom-box">
        <h2>User Management</h2>
        <div className="form-header">
          <div className="form-header-title">
            <b>General Details</b>
            <p>{ActivePage} user's personal details here!</p>
          </div>

          {id?.length > 0 && (
            <div className="flex gap-2">
              {activeInputs ? (
                <>
                  <button
                    type="button"
                    onClick={() => navigate("/users/administrator-directory")}
                    className="btn-outlined cancel"
                  >
                    Back
                  </button>
                  <button
                    type="button"
                    onClick={() => setActiveInputs(false)}
                    className="btn-primary"
                  >
                    Update
                  </button>
                </>
              ) : (
                <>
                  <button
                    type="button"
                    onClick={() => setActiveInputs(true)}
                    className="btn-outlined tertiary"
                  >
                    Abort
                  </button>
                  <SubmitButton
                    className="btn-primary"
                    type="submit"
                    onClick={formik.handleSubmit}
                    isLoading={formik.isSubmitting}
                  >
                    Submit
                  </SubmitButton>
                </>
              )}
            </div>
          )}

          {pathname.includes("add-user") && (
            <div className="form-control">
              <button
                type="button"
                onClick={() => navigate(-1)}
                className="btn-outlined cancel"
              >
                Back
              </button>
              <SubmitButton
                className="btn-primary"
                type="submit"
                onClick={formik.handleSubmit}
                isLoading={formik.isSubmitting}
              >
                Submit
              </SubmitButton>
            </div>
          )}
        </div>
        <main className="main">
          <div className="auth-form-wrapper">
            <form
              className="form"
              onSubmit={formik.handleSubmit}
              // noValidate="novalidate"
            >
              {/* {id && (
                <div className="form-control">
                  <label className="form-label">User ID</label>
                  <div className="form-input-box">
                    <InputField
                      id="id"
                      name="id"
                      type="text"
                      value={formik.values.id}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      readOnly
                      disabled
                    />
                  </div>
                  {formik.errors.id && formik.touched.id ? (
                    <span className="err">{formik.errors.id}</span>
                  ) : null}
                </div>
              )} */}

              <div className="form-col-2">
                <div className="form-control">
                  <label className="form-label">First Name</label>
                  <div className="form-input-box">
                    <InputField
                      type="text"
                      id="first_name"
                      name="first_name"
                      value={formik.values.first_name || ""}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      onInput={(e) => {
                        e.target.value = e.target.value
                          .replace(/[^A-Za-z ]/gi, "")
                          .replace(/(?:^|\s)\S/g, (match) =>
                            match.toUpperCase()
                          );
                      }}
                      onPaste={(e) => {
                        e.preventDefault();
                      }}
                      onCopy={(e) => {
                        e.preventDefault();
                      }}
                      onKeyDown={(e) => {
                        if (e.key === " ") {
                          e.preventDefault();
                        }
                      }}
                      disabled={id && activeInputs}
                      maxLength={81}
                    />
                  </div>
                  {formik.errors.first_name && formik.touched.first_name ? (
                    <span className="err">{formik.errors.first_name}</span>
                  ) : null}
                </div>
                <div className="form-control">
                  <label className="form-label">Last Name</label>
                  <div className="form-input-box">
                    <InputField
                      type="text"
                      id="last_name"
                      name="last_name"
                      value={formik.values.last_name || ""}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      onInput={(e) => {
                        e.target.value = e.target.value
                          .replace(/[^A-Za-z ]/gi, "")
                          .replace(/(?:^|\s)\S/g, (match) =>
                            match.toUpperCase()
                          );
                      }}
                      onPaste={(e) => {
                        e.preventDefault();
                      }}
                      onCopy={(e) => {
                        e.preventDefault();
                      }}
                      onKeyDown={(e) => {
                        if (e.key === " ") {
                          e.preventDefault();
                        }
                      }}
                      maxLength={81}
                      disabled={id && activeInputs}
                    />
                  </div>
                  {formik.errors.last_name && formik.touched.last_name ? (
                    <span className="err">{formik.errors.last_name}</span>
                  ) : null}
                </div>
              </div>

              <div className="form-col-2">
                <div className="form-control">
                  <label className="form-label">Email</label>
                  <div className="form-input-box">
                    <InputField
                      type="text"
                      id="email"
                      name="email"
                      className="form-input"
                      value={formik.values.email || ""}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={id && activeInputs}
                      onPaste={(e) => {
                        e.preventDefault();
                      }}
                      onCopy={(e) => {
                        e.preventDefault();
                      }}
                      onKeyDown={(e) => {
                        if (e.key === " ") {
                          e.preventDefault();
                        }
                      }}
                      maxLength={81}
                    />
                  </div>
                  {formik.errors.email && formik.touched.email ? (
                    <span className="err">{formik.errors.email}</span>
                  ) : null}
                </div>
                <div className="form-control">
                  <label className="form-label">Phone Number</label>
                  <div className="form-input-box ph-input">
                    <DropDownBox
                      showSearchBar
                      options={isoList}
                      customFormikLabel="country"
                      customSetter={formik.setFieldValue}
                      incomingValue={formik.values.country}
                      disabled={id && !activeInputs}
                      customDropBoxStyles={{
                        color: "#262626",
                        border: "1px solid #d2d6da",
                      }}
                      isPhoneCode
                    />
                    <InputField
                      type="text"
                      id="phone_no"
                      name="phone_no"
                      className="form-input"
                      value={formik.values.phone_no}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      onInput={(e) =>
                        (e.target.value = e.target.value.replace(/\D/g, ""))
                      }
                      maxLength={17}
                      disabled={id && activeInputs}
                    />
                    {/* {id?.length > 0 && activeInputs ? (
                      <InputField
                        type="text"
                        id="phone_no"
                        name="phone_no"
                        className="form-input"
                        value={formik.values.phone_no || ""}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        onInput={(e) =>
                          (e.target.value = e.target.value.replace(/\D/g, ""))
                        }
                        maxLength={17}
                        disabled={id && activeInputs}
                      />
                    ) : (
                      <>
                        <DropDownBox
                          showSearchBar
                          options={JSON.parse(JSON.stringify(isoList))}
                          customFormikLabel="country"
                          customSetter={formik.setFieldValue}
                          incomingValue={"IN-+91"}
                          disabled={id && !activeInputs}
                          customDropBoxStyles={{
                            color: "#262626",
                            border: "1px solid #d2d6da",
                          }}
                        />
                        <InputField
                          type="text"
                          id="phone_no"
                          name="phone_no"
                          className="form-input"
                          value={formik.values.phone_no || ""}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          onInput={(e) =>
                            (e.target.value = e.target.value.replace(/\D/g, ""))
                          }
                          maxLength={17}
                          disabled={id && activeInputs}
                        />
                      </>
                    )} */}
                  </div>
                  {formik.errors.phone_no && formik.touched.phone_no ? (
                    <span className="err">{formik.errors.phone_no}</span>
                  ) : null}
                </div>
              </div>

              <div className="form-col-2">
                <div className="form-control">
                  <label className="form-label">Gender</label>
                  <div className="form-input-box">
                    <DropDownBox
                      options={[
                        { label: "Male", value: "male" },
                        {
                          label: "Female",
                          value: "female",
                        },
                        {
                          label: "Other",
                          value: "other",
                        },
                      ]}
                      defaultValue="Select Gender"
                      customSetter={formik.setFieldValue}
                      customFormikLabel="gender"
                      customDropBoxStyles={{
                        border: "1px solid #e6e6e6",
                        borderRadius: "0.5rem",
                        color: "#454545",
                      }}
                      customTextStyle={{
                        color: "#212229",
                        opacity: "0.7",
                      }}
                      incomingValue={id && adminDetail?.gender}
                      disabled={id && !activeInputs}
                    />
                  </div>
                  {formik.errors.gender && formik.touched.gender ? (
                    <span className="err">{formik.errors.gender}</span>
                  ) : null}
                </div>
                <div className="form-control">
                  <label className="form-label">Date of Birth</label>
                  <div className="form-input-box">
                    <input
                      type="date"
                      id="dob"
                      name="dob"
                      className="form-input"
                      value={formik.values.dob || ""}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={id && activeInputs}
                      max={minBirthDate}
                      min={minDate}
                    />
                  </div>
                  {formik.errors.dob && formik.touched.dob ? (
                    <span className="err">{formik.errors.dob}</span>
                  ) : null}
                </div>
              </div>

              {/* <div className="form-col-2 btn"> */}
              {/* <div className="form-control">
                <label className="form-label">Role</label>
                <div className="form-input-box">
                  <DropDownBox
                    options={[
                      { label: "Admin", value: 2 },
                      { label: "User", value: 3 },
                    ]}
                    defaultValue="Select Role"
                    animateDropDownTitle
                    customSetter={formik.setFieldValue}
                    customFormikLabel="role"
                    customDropBoxStyles={{
                      border: "1px solid #d2d6da",
                      borderRadius: "0.5rem",
                      color: "#454545",
                    }}
                    customTextStyle={{
                      color: "#212229",
                      opacity: "0.7",
                    }}
                    incomingValue={id && adminDetail?.role}
                    disabled={id && !activeInputs}
                  />
                </div>
              </div> */}
              {/* <button className="btn-outlined">
                  <i className="fa-regular fa-circle-plus"></i>Add Role
                </button> */}
              {/* </div> */}
              {formik.errors.role && formik.touched.role ? (
                <span className="err">{formik.errors.role}</span>
              ) : null}

              <div className="privileges-container">
                <h2 className="privileges-header">Privileges</h2>
                <div className="prTable-wrapper">
                  <table className="prTable" cellSpacing={0}>
                    <tbody className="pr_TBody">
                      {Object.keys(privileges)?.map((moduleName) => (
                        <tr className="pr_Body_TR" key={moduleName}>
                          <td className="pr_Body_TD">
                            {moduleName.replace(/([A-Z])/g, " $1")}
                          </td>
                          <td className="pr_Body_TD">
                            <Checkbox
                              name="all"
                              checked={modulePermissions?.[
                                moduleName
                              ]?.includes("delete")}
                              onChange={(e) =>
                                updatePermissions(
                                  moduleName,
                                  "all",
                                  e.target.checked
                                )
                              }
                              label="All"
                              disabled={id ? !activeInputs : true}
                            />
                          </td>
                          <td className="pr_Body_TD">
                            <Checkbox
                              name="read"
                              checked={
                                modulePermissions?.[moduleName]?.includes(
                                  "read"
                                ) || false
                              }
                              onChange={(e) =>
                                updatePermissions(
                                  moduleName,
                                  "read",
                                  e.target.checked
                                )
                              }
                              label="Read"
                              disabled={id ? !activeInputs : true}
                            />
                          </td>
                          <td className="pr_Body_TD">
                            <Checkbox
                              name="write"
                              checked={
                                modulePermissions?.[moduleName]?.includes(
                                  "write"
                                ) || false
                              }
                              onChange={(e) =>
                                updatePermissions(
                                  moduleName,
                                  "write",
                                  e.target.checked
                                )
                              }
                              label="Write"
                              disabled={id ? !activeInputs : true}
                            />
                          </td>
                          <td className="pr_Body_TD">
                            <Checkbox
                              name="delete"
                              checked={
                                modulePermissions?.[moduleName]?.includes(
                                  "delete"
                                ) || false
                              }
                              onChange={(e) =>
                                updatePermissions(
                                  moduleName,
                                  "delete",
                                  e.target.checked
                                )
                              }
                              label="Delete"
                              disabled={id ? !activeInputs : true}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </form>
          </div>
        </main>
      </div>
    </ModuleLayout>
  );
};

export default ManageAdmin;
