const SideNav = ({ selectedTab, setSelectedTab }) => {
  return (
    <aside className="profile-side-nav">
      <ul className="nav-list">
        <li className="list-item">
          <button
            className={`${selectedTab === "general" ? "active" : ""}`}
            onClick={() => setSelectedTab("general")}
          >
            <div className="sidebar-icon">
              <i className="fa-regular fa-user"></i>
            </div>
            <span>Profile</span>
          </button>
        </li>
        <li className="list-item">
          <button
            className={`${selectedTab === "changePass" ? "active" : ""}`}
            onClick={() => setSelectedTab("changePass")}
          >
            <div className="sidebar-icon">
              <i className="fa-regular fa-lock-open"></i>
            </div>
            <span>Change Password</span>
          </button>
        </li>

        <li className="list-item">
          <button
            className={`${selectedTab === "notification" ? "active" : ""}`}
            onClick={() => setSelectedTab("notification")}
          >
            <div className="sidebar-icon">
              <i className="fa-regular fa-bell"></i>
            </div>
            <span>Manage Notification</span>
          </button>
        </li>

        <li className="list-item">
          <button
            className={`${selectedTab === "authentication" ? "active" : ""}`}
            onClick={() => setSelectedTab("authentication")}
          >
            <div className="sidebar-icon">
              <i className="fa-sharp fa-regular fa-shield-keyhole"></i>
            </div>
            <span>2FA</span>
          </button>
        </li>

        <li className="list-item">
          <button
            className={`${selectedTab === "deactivate" ? "active" : ""}`}
            onClick={() => setSelectedTab("deactivate")}
          >
            <div className="sidebar-icon">
              <i className="fa-regular fa-trash-xmark"></i>
            </div>
            <span>Deactivate Account</span>
          </button>
        </li>
        <li className="list-item">
          <button
            style={{
              color: "#D0202E",
              background: "#FCEDEF"
            }}
            onClick={() => setSelectedTab("logout")}
          >
            <div className="sidebar-icon">
              <i className="fa-regular fa-arrow-right-from-bracket"></i>
            </div>
            <span style={{ color: "#D0202E" }}>Logout from all devices</span>
          </button>
        </li>
      </ul>
    </aside>
  );
};

export default SideNav;
