/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
// import { toast } from "react-toastify";

const ConformationPopup = ({
  customSetter,
  customState,
  api,
  mainTitle,
  subTitle,
  popUpData,
  reFetchApi,
  reData = {},
  navigateTo, // path to navigate
  customFunction = false,
  popupType = "Yes, Delete",
  cancelText = "No, Cancel",
  // toastMessage,
  // resetState,
  // resetArray,
}) => {
  const [addStyle, setAddStyle] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const title = popUpData?.title;
  const data = popUpData?.data;

  const handleClick = (e) => {
    setAddStyle(!addStyle);
    chatBoxVisibility();
    // setShowChat(false);
  };

  function chatBoxVisibility() {
    if (customState) {
      const styleTimer = setTimeout(() => {
        customSetter(false);
        document
          .getElementsByClassName("container")[0]
          .classList.remove("popUp-container");
        clearTimeout(styleTimer);
      }, 200);
    }
  }
  useEffect(() => {
    document
      .getElementsByClassName("container")[0]
      .classList.add("popUp-container");
  }, []);
  const handleDeletion = async () => {
    if (api) {
      // console.log('api: ', api);
      const { payload } = await dispatch(api(data));

      if (payload) {
        // toast.success(
        //   `${toastMessage ? toastMessage : title + " deleted successfully"}`
        // );
        if (reFetchApi) {
          dispatch(reFetchApi(reData));
        }
        if (navigateTo) {
          navigate(`${navigateTo}`);
        }
        if (customFunction) {
          customFunction();
        }
      }
    }
  };

  return (
    <div id="confirm-box-wrapper">
      <div
        className={
          "event-card" +
          (addStyle
            ? " show_delete-component-fadeIn-animation"
            : " hide_delete-component-fadeOut-animation")
        }
      >
        <div className="popup_main_logo">
          <i
            className={
              popupType === "edit"
                ? "fa-duotone fa-file-pen"
                : "fa-regular fa-trash-can large"
            }
            style={
              popupType === "edit"
                ? { color: "rgb(48, 133, 214)" }
                : { color: "red" }
            }
          ></i>
        </div>

        <div className="del-message">
          <h2>
            {mainTitle
              ? mainTitle
              : title
                ? `Are you sure about removing ${title + "?"}`
                : "Are you sure?"}
          </h2>
          <p>
            {subTitle ? (
              <>
                {/* <i className="fa-regular fa-triangle-exclamation"></i>{" "} */}
                {subTitle}
              </>
            ) : (
              <>
                {/* <i className="fa-regular fa-triangle-exclamation"></i>  */}
                you won't be able to revert this!
              </>
            )}
          </p>
        </div>

        <div className="del_ben-container">
          <button className="del-cancel" onClick={handleClick}>
            {cancelText}
          </button>
          <button
            className="del-conform"
            onClick={() => {
              handleDeletion();
              handleClick();
            }}
          >
            {popupType === "edit" ? "Submit" : popupType}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConformationPopup;
