/* eslint-disable no-useless-escape */
import React, { useEffect, useState } from "react";
import AuthLayout from "layouts/AuthLayout";
import SubmitButton from "components/ui/SubmitButton";
import { resetPassword } from "../redux/authApi";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";

const ResetPassword = ({ setTitle }) => {
  useEffect(() => {
    setTitle("Peak72 finance | Reset Password");
  }, [setTitle]);

  const [showPwd, setShowPwd] = useState(false);
  const [showCnPwd, setShowCnPwd] = useState(false);

  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const initialValues = {
    password: "",
    confirm_password: "",
  };

  const ResetPasswordSchema = Yup.object({
    password: Yup.string()
      .trim()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,20})/,
        "Password should contains at least 1 digit, 1 uppercase & 1 lowercase,1 special character,length between 8 to 20"
      )
      .required("Password is required"),
    confirm_password: Yup.string()
      .oneOf([Yup.ref("password")], "Passwords does not match")
      .required("Confirm Password is required"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: ResetPasswordSchema,
    onSubmit: async (values, { resetForm }) => {
      const data = {
        user_verification_token: params.id,
        password: values.password,
        password_confirmation: values.confirm_password,
      };

      const res = await dispatch(resetPassword(data));
      if (res) {
        resetForm();
        navigate("/");
      }
    },
  });

  const InputProps = {
    className: "form-input",
    onChange: formik.handleChange,
    onBlur: formik.handleBlur,
    onKeyDown: (e) => {
      e.key === " " && e.preventDefault();
    },
    onPaste: (e) => e.preventDefault(),
    onCopy: (e) => e.preventDefault(),
  };

  return (
    <AuthLayout
      title="Create A New Password"
      subtitle="Your password must be at least 6 characters and should include a combination of numbers, letters and special characters"
    >
      <form
        className="form"
        onSubmit={formik.handleSubmit}
        noValidate="novalidate"
      >
        <div className="form-control">
          <label className="form-label">New Password</label>
          <div className="form-input-box">
            <input
              id="password"
              name="password"
              type={showPwd ? "text" : "password"}
              placeholder="Enter A new password"
              value={formik.values.password}
              {...InputProps}
              maxLength={20}
            />
            <i
              className={`form-input-box__icon ${
                showPwd
                  ? "fa-sharp fa-thin fa-eye"
                  : "fa-sharp fa-thin fa-eye-slash"
              }`}
              onClick={() => setShowPwd(!showPwd)}
            ></i>
          </div>
          {formik.errors.password && formik.touched.password ? (
            <span className="err">{formik.errors.password}</span>
          ) : null}
        </div>
        <div className="form-control">
          <label className="form-label">Confirm Password</label>
          <div className="form-input-box">
            <input
              id="confirm_password"
              name="confirm_password"
              type={showCnPwd ? "text" : "password"}
              placeholder="Confirm the new password"
              value={formik.values.confirm_password}
              {...InputProps}
              maxLength={20}
            />
            <i
              className={`form-input-box__icon ${
                showCnPwd
                  ? "fa-sharp fa-thin fa-eye"
                  : "fa-sharp fa-thin fa-eye-slash"
              }`}
              onClick={() => setShowCnPwd(!showCnPwd)}
            ></i>
          </div>
          {formik.errors.confirm_password && formik.touched.confirm_password ? (
            <span className="err">{formik.errors.confirm_password}</span>
          ) : null}
        </div>
        <SubmitButton
          type="submit"
          className="btn-primary"
          isLoading={formik.isSubmitting}
        >
          Continue
        </SubmitButton>

        <p className="text-center">
          Back to{" "}
          <Link to="/login" style={{ color: "var(--color-primary)" }}>
            Login
          </Link>
        </p>
      </form>
    </AuthLayout>
  );
};
export default ResetPassword;
