import { createSlice } from "@reduxjs/toolkit";
import { getQuizDetail, getQuizList } from "./quizApi";

const quizSlice = createSlice({
  name: "quiz",
  initialState: {
    quizLoading: false,
    quizList: [],
    metaData: [],
    quizDetail: {},
  },
  reducers: {
    isQuizLoading: (state, action) =>
      (state = {
        ...state,
        quizLoading: action.payload,
      }),
    clearQuizDetail: (state) => {
      state.quizDetail = {};
    },
  },
  extraReducers: (builder) => {
    // getQuizList reducers-------------------------
    builder.addCase(getQuizList.pending, (state, action) => {
      state.quizLoading = true;
    });
    builder.addCase(getQuizList.fulfilled, (state, action) => {
      state.quizLoading = false;
      state.quizList = action.payload.data;
      state.metaData = action.payload.metadata[0];
    });
    builder.addCase(getQuizList.rejected, (state, action) => {
      state.quizLoading = false;
      state.quizList = [];
    });
    // getQuizDetail reducers-------------------------
    builder.addCase(getQuizDetail.pending, (state, action) => {
      state.quizLoading = true;
    });
    builder.addCase(getQuizDetail.fulfilled, (state, action) => {
      state.quizLoading = false;
      state.quizDetail = action.payload.data;
    });
    builder.addCase(getQuizDetail.rejected, (state, action) => {
      state.quizLoading = false;
    });
  },
});

export const { isQuizLoading, clearQuizDetail } = quizSlice.actions;
export default quizSlice.reducer;
