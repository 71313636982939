import { walnut, xp } from "assets/icons";
import { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getLeaderboardList } from "../redux/dashboardApi";

const LeadBoardTable = () => {
  const dispatch = useDispatch()
  const { leaderboardList } = useSelector((state) => state.dashboard, shallowEqual)
  
  useEffect(() => {
    const data = {
      page: 0,
      limit: 0,
      sort: "desc"
    }
    dispatch(getLeaderboardList(data))
  }, [])
  return (
    // <div className="table_container">
    <div className="table_content">
      <table className="main_table" cellSpacing={0}>
        <thead className="tb_head">
          <tr className="tb_row">
            <th className="tbh_name">Name</th>
            {/* <th className="tbh_name">Location</th> */}
            <th className="tbh_name">Membership</th>
            <th className="tbh_name">Walnuts Points</th>
            <th className="tbh_name">XP Points</th>
          </tr>
        </thead>

        <tbody className="tb_body">
          {leaderboardList?.length > 0 &&
            leaderboardList?.map((item) => (
              <tr key={item.id} className="tb_row">
                <td className="tb_data">{`${item.name?.firstName} ${item.name?.lastName}`}</td>
                {/* <td className="tb_data">{item.location}</td> */}
                <td className="tb_data flex-center">
                  <div
                    className={`tb_data_membership ${item.isPremium === true ? "active" : "inactive"}`}
                  >
                    {item.isPremium === true ? "active" : "inactive"}
                  </div>
                </td>
                <td className="tb_data">
                  <div className="flex-center">
                    <img src={walnut} alt="" />
                    {item.walnut}
                  </div>
                </td>
                <td className="tb_data">
                  <div className="flex-center">
                    <img src={xp} alt="" />
                    {item.xp}
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default LeadBoardTable;
