/* eslint-disable no-useless-escape */
import * as Yup from "yup";
import Cookies from "js-cookie";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { incrptPSW, decryptPSW, getDeviceId, IpAddress } from "helper/helper";
import { login } from "../redux/authApi";
import SubmitButton from "components/ui/SubmitButton";
import AuthLayout from "layouts/AuthLayout";

const Login = ({ setTitle }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    setTitle("Peak72 finance | Login");
  }, [setTitle, dispatch]);

  const { ip } = useSelector((state) => state.auth, shallowEqual);

  const [visibility, setVisibility] = useState(false);

  const decryptedPSW = Cookies.get("sessionID")
    ? decryptPSW(Cookies.get("sessionID"), Cookies.get("sessionKey"))
    : "";

  const initialValues = {
    email: !Cookies.get("email") ? "" : Cookies.get("email"),
    password: !Cookies.get("sessionID") ? "" : decryptedPSW,
    isRem: false,
  };

  const loginSchema = Yup.object({
    email: Yup.string()
      .trim()
      .email("Please enter a valid email")
      .matches(
        /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
        "Please enter a valid email!"
      )
      .required("Email address should not be empty"),
    // .matches("@peak72.com", "email must contain @peak72.com domain"),
    password: Yup.string()
      .trim()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,20})/,
        "Password should contains at least 1 digit, 1 uppercase & 1 lowercase,1 special character,length between 8 to 20"
      )
      .required("Password is required"),
  });

  const device_info = {
    device_id: getDeviceId(),
    app_id: navigator?.buildID ? navigator?.buildID : "20181001000000",
    name: navigator.userAgentData?.brands[0]?.brand
      ? navigator.userAgentData?.brands[0].brand
      : navigator?.appName,
    model: navigator?.userAgent,
    platform: navigator.userAgentData?.platform
      ? navigator.userAgentData?.platform
      : navigator?.platform,
    version: navigator.userAgentData?.brands[0]?.version
      ? navigator.userAgentData?.brands[0].version
      : navigator?.appVersion,
    latitude: "",
    longitude: "",
    ip: ip?.length > 0 ? ip : "00.00.00.00",
  };

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { resetForm }) => {
      if (values.isRem) {
        const { decrypt, key } = await incrptPSW(values.password);
        Cookies.set("email", values.email, {
          expires: 5,
          sameSite: "strict",
        });
        Cookies.set("sessionID", decrypt, {
          expires: 5,
          sameSite: "strict",
        });
        Cookies.set("sessionKey", key, { expires: 5, sameSite: "strict" });
      }
      const data = {
        email: values.email,
        password: values.password,
        device_info,
      };

      try {
        const position = await new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve, reject);
        });
        data.device_info.latitude = position.coords.latitude;
        data.device_info.longitude = position.coords.longitude;
        const response = await dispatch(login(data));
        if (response?.meta?.requestStatus === "fulfilled") {
          navigate("/");
        }
      } catch (error) {
        const res = await IpAddress();
        data.device_info.latitude = res?.latitude;
        data.device_info.longitude = res?.longitude;
        const response = await dispatch(login(data));
        if (response?.meta?.requestStatus === "fulfilled") {
          navigate("/");
        }
      }
    },
  });

  const InputProps = {
    className: "form-input",
    onChange: formik.handleChange,
    onBlur: formik.handleBlur,
    onKeyDown: (e) => {
      e.key === " " && e.preventDefault();
    },
    onPaste: (e) => e.preventDefault(),
    onCopy: (e) => e.preventDefault(),
  };

  return (
    <AuthLayout title={"Welcome User"} subtitle={"Get Into Your Dashboard"}>
      <form className="form" onSubmit={formik.handleSubmit}>
        <div className="form-control">
          <label className="form-label">Email</label>
          <div className="form-input-box">
            <input
              id="email"
              name="email"
              type="text"
              placeholder="Enter your email address"
              value={formik.values.email}
              maxLength={80}
              {...InputProps}
            />
            <i className="fa-thin fa-envelope form-input-box__icon"></i>
          </div>
          {formik.errors.email && formik.touched.email ? (
            <span className="err">{formik.errors.email}</span>
          ) : null}
        </div>

        <div className="form-control">
          <label className="form-label">Password</label>
          <div className="form-input-box">
            <input
              id="password"
              name="password"
              type={visibility ? "text" : "password"}
              placeholder="Enter your password"
              value={formik.values.password}
              {...InputProps}
              maxLength={20}
            />
            <i
              className={`form-input-box__icon ${
                visibility
                  ? "fa-sharp fa-thin fa-eye"
                  : "fa-sharp fa-thin fa-eye-slash"
              }`}
              onClick={() => setVisibility(!visibility)}
            ></i>
          </div>
          {formik.errors.password && formik.touched.password ? (
            <span className="err">{formik.errors.password}</span>
          ) : null}
        </div>

        <div className="form-check flex-center">
          <label htmlFor="isRem" className="TD_CheckBox_LB">
            <input
              id="isRem"
              name="isRem"
              type="checkbox"
              value={formik.values.isRem}
              onChange={formik.handleChange}
            />
            <div className="checkmark"></div>
          </label>

          <span>Remeber this Device</span>

          <Link to="/forgot-password" className="form-forgot">
            Forgot Password?
          </Link>
        </div>

        <SubmitButton
          type="submit"
          className="btn-primary"
          isLoading={formik.isSubmitting}
        >
          SIGN IN
        </SubmitButton>
      </form>
    </AuthLayout>
  );
};

export default Login;
