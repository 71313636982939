import React from "react";
import Header from "components/Header";
import Sidebar from "components/Sidebar";
import MobileSidebar from "components/MobileSidebar";
import MinimizeSidebar from "components/MinimizeSidebar";

const ModuleLayout = ({
  minimizedSidebar,
  setMinimizedSidebar,
  mobileSidebar,
  setMobileSidebar,
  active,
  setActive,
  // alertBox,
  children,
}) => {

  return (
    <div className="container">
      <div className="app-container">
        <div className="home-container">
          {!minimizedSidebar ? (
            <div className="main-sidebar">
              <Sidebar active={active} setActive={setActive} />
            </div>
          ) : (
            <div className="minisidebar-container">
              <MinimizeSidebar active={active} setActive={setActive} />
            </div>
          )}
          <div className={!mobileSidebar ? "mobile-sidebar" : "mobile-show"}>
            <MobileSidebar
              active={active}
              setActive={setActive}
              setMobileSidebar={setMobileSidebar}
            />
          </div>

          <div
            className={
              minimizedSidebar ? "content-wrapper extended" : "content-wrapper"
            }
          >
            <Header
              setMobileSidebar={setMobileSidebar}
              minimizedSidebar={minimizedSidebar}
              setMinimizedSidebar={setMinimizedSidebar}
            />
            <div className="content-body">{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModuleLayout;
