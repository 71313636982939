import { createSlice } from "@reduxjs/toolkit";
import { getInventoryList, getInventoryDetail } from "./inventoryApi";

const inventoryslice = createSlice({
  name: "inventory",
  initialState: {
    inventoryLoading: false,
    inventoryList: [],
    inventoryDetail: [],
    metaData: [],
  },
  reducers: {
    isInventoryLoading: (state, action) =>
      (state = {
        ...state,
        inventoryLoading: action.payload,
      }),
  },
  extraReducers: (builder) => {
    // getInventoryList reducers-------------------------
    builder.addCase(getInventoryList.pending, (state, action) => {
      state.inventoryLoading = true;
    });
    builder.addCase(getInventoryList.fulfilled, (state, action) => {
      state.inventoryLoading = false;
      state.inventoryList = action.payload.data;
      state.metaData = action.payload.metadata[0];
    });
    builder.addCase(getInventoryList.rejected, (state, action) => {
      state.inventoryLoading = false;
      state.inventoryList = [];
    });
    // getInventoryDetail reducers-------------------------
    builder.addCase(getInventoryDetail.pending, (state, action) => {
      state.inventoryLoading = true;
    });
    builder.addCase(getInventoryDetail.fulfilled, (state, action) => {
      state.inventoryLoading = false;
      state.inventoryDetail = action.payload.data;
    });
    builder.addCase(getInventoryDetail.rejected, (state, action) => {
      state.inventoryLoading = false;
    });
  },
});

export const { isInventoryLoading } = inventoryslice.actions;
export default inventoryslice.reducer;
