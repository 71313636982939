import React, { useEffect, useState } from "react";
import AddProducts from "./AddProducts";

const General = () => {

    const [deliverydate, setDeliverydate] = useState({ status: false, value: "" })
    const [carrierDetail, setCarrierDetail] = useState({ status: false, value: "" })
    const [trankNumber, setTrankNumber] = useState({ status: false, value: "" })

    const TableData = [
        {
            sku: "KSG92791",
            name: "Product Name",
            deliverydate: "Dec, 31, 2023",
            price: "25,000",
            discount: "1000",
            qty: "4",
            total: "24000",
        },
        {
            sku: "KSG92792",
            name: "Product Name",
            deliverydate: "Dec, 31, 2023",
            price: "25,000",
            discount: "1000",
            qty: "4",
            total: "24000",
        },
        {
            sku: "KSG92793",
            name: "Pkroduct Name",
            deliverydate: "Dec, 31, 2023",
            price: "25,000",
            discount: "1000",
            qty: "4",
            total: "24000",
        },
        {
            sku: "KSG92794",
            name: "Pkroduct Name",
            deliverydate: "Dec, 31, 2023",
            price: "25,000",
            discount: "1000",
            qty: "4",
            total: "24000",
        },
        {
            sku: "KSG92795",
            name: "Pkroduct Name",
            deliverydate: "Dec, 31, 2023",
            price: "25,000",
            discount: "1000",
            qty: "4",
            total: "24000",
        },
    ];

    const [Tbdata, setTbData] = useState([]);

    const [addedProducts, setAddedProducts] = useState([])


    const [addPopup, setAddPopup] = useState(false)

    useEffect(() => {
        const filteredProduct = TableData.filter((data) => Tbdata.includes(data.sku))
        setAddedProducts(filteredProduct)
    }, [Tbdata])

    return (
        <div className="general-main">
            <div className="general-m2 mt-1 form-col-4 gap-0">
                <div className="gm2-1 first">
                    <div className="gm2-1-head">
                        <span className="title">Billing Details</span>
                        <span className="eDiv">Edit</span>
                    </div>
                    <div className="gm2-1-content">
                        <div className="title">Kimberly Ezzell</div>
                        <div className="gmc2-data">
                            <span className="material-symbols-outlined icon">location_on</span>
                            <span className="val">1455 Rosewood Lane Gardena, CA 90247</span>
                        </div>
                        <div className="gmc2-data">
                            <span className="material-symbols-outlined icon">call</span>
                            <span className="val">+91 12345 67890</span>
                        </div>
                        <div className="gmc2-data">
                            <span className="material-symbols-outlined icon">mail</span>
                            <span className="val">kimberlyezzell@text.com</span>
                        </div>
                    </div>
                </div>
                <div className="gm2-1">
                    <div className="gm2-1-head center">
                        <span className="title">Shipping Details</span>
                        <span className="eDiv">Edit</span>
                    </div>
                    <div className="gm2-1-content">
                        <div className="title">Kimberly Ezzell</div>
                        <div className="gmc2-data">
                            <span className="material-symbols-outlined icon">location_on</span>
                            <span className="val">1455 Rosewood Lane Gardena, CA 90247</span>
                        </div>
                        <div className="gmc2-data">
                            <span className="material-symbols-outlined icon">call</span>
                            <span className="val">+91 12345 67890</span>
                        </div>
                        <div className="gmc2-data">
                            <span className="material-symbols-outlined icon">mail</span>
                            <span className="val">kimberlyezzell@text.com</span>
                        </div>
                    </div>
                </div>
                <div className="gm2-1">
                    <div className="gm2-1-head center">
                    </div>
                    <div className="gm2-1-content">
                        <div className="gm3-detail">
                            <div className="gm3-left">
                                <div className="gm3-l">Delivery Date</div>
                                {
                                    deliverydate.status ?
                                        <input
                                            type="date"
                                            name="delDate"
                                            id="delDate"
                                            value={deliverydate.value}
                                            onChange={(e) => setDeliverydate({ status: deliverydate.status, value: e.target.value })}
                                        /> :
                                        <div className="insert">{deliverydate.value || "Add to insert"}</div>
                                }
                            </div>
                            <span className="eDiv" onClick={() => setDeliverydate({ status: !deliverydate.status, value: deliverydate.value })}>
                                {deliverydate.status ? "Save" : (deliverydate.value ? "Edit" : "Add")}
                            </span>
                        </div>
                        <div className="gm3-detail">
                            <div className="gm3-left">
                                <div className="gm3-l">Carrier Details</div>
                                {
                                    carrierDetail.status ?
                                        <input
                                            type="text"
                                            name="carrier"
                                            id="carrier"
                                            value={carrierDetail.value}
                                            onChange={(e) => setCarrierDetail({ status: carrierDetail.status, value: e.target.value })}
                                        /> :
                                        <div className="insert">{carrierDetail.value || "Add to insert"}</div>
                                }
                            </div>
                            <span className="eDiv" onClick={() => setCarrierDetail({ status: !carrierDetail.status, value: carrierDetail.value })}>
                                {carrierDetail.status ? "Save" : (carrierDetail.value ? "Edit" : "Add")}
                            </span>
                        </div>
                        <div className="gm3-detail">
                            <div className="gm3-left">
                                <div className="gm3-l">Tracking Number</div>
                                {
                                    trankNumber.status ?
                                        <input
                                            type="text"
                                            name="carrier"
                                            id="carrier"
                                            value={trankNumber.value}
                                            onChange={(e) => setTrankNumber({ status: trankNumber.status, value: e.target.value })}
                                        /> :
                                        <div className="insert">{trankNumber.value || "Add to insert"}</div>
                                }
                            </div>
                            <span className="eDiv" onClick={() => setTrankNumber({ status: !trankNumber.status, value: trankNumber.value })}>
                                {trankNumber.status ? "Save" : (trankNumber.value ? "Edit" : "Add")}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="gm2-1 last">
                    <div className="gm2-1-head last">
                    </div>
                    <div className="gm2-1-content">
                        <div className="gm3-detail">
                            <div className="gm3-left">
                                <div className="gm3-l">Lebel</div>
                            </div>
                            <span className="gmc2-data icons">
                                <span className="material-symbols-outlined">print</span>
                                <span className="material-symbols-outlined">download</span>
                            </span>
                        </div>
                        <hr />
                        <div className="gm3-detail">
                            <div className="gm3-left">
                                <div className="gm3-l">Invoice</div>
                            </div>
                            <span className="gmc2-data icons">
                                <span className="material-symbols-outlined">print</span>
                                <span className="material-symbols-outlined">download</span>
                            </span>
                        </div>
                        <hr />
                        <div className="gm3-detail">
                            <div className="gm3-left">
                                <div className="gm3-l">Manifest</div>
                            </div>
                            <span className="gmc2-data icons">
                                <span className="material-symbols-outlined">print</span>
                                <span className="material-symbols-outlined">download</span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="general-m3 mt-2">
                <div className="gm3-Top">
                    <div className="title">Products</div>
                    <button type="button" className="btn-primary" onClick={() => setAddPopup(true)}>+ Add Products</button>
                </div>
                <div className="gm3-bottom">
                    {
                        addedProducts.length > 0 &&
                        <Table addedProducts={addedProducts} />
                    }
                </div>
            </div>
            {addPopup && (
                <AddProducts
                    addPopup={addPopup}
                    setAddPopup={setAddPopup}
                    setTbData={setTbData}
                    Tbdata={Tbdata}
                />
            )}
        </div>
    );
};

export default General;

export const Table = ({ addedProducts }) => {
    return (
        <div className="gm-TableMain">
            <table className="total-table">
                <thead>
                    <tr>
                        <th className="tt-header"></th>
                        <th className="tt-header">SKU</th>
                        <th className="tt-header">Name</th>
                        <th className="tt-header">Delivery Date</th>
                        <th className="tt-header">Price</th>
                        <th className="tt-header">Discount</th>
                        <th className="tt-header">Quantity</th>
                        <th className="tt-header">Total</th>
                    </tr>
                </thead>
                <tbody>
                    {addedProducts?.map((table, index) => (
                        <tr key={index}>
                            <td className="tt-data">
                            </td>
                            <td className="tt-data">{table.sku}</td>
                            <td className="tt-data">{table.name}</td>
                            <td className="tt-data">{table.deliverydate}</td>
                            <td className="tt-data">₹ {table.price}</td>
                            <td className="tt-data">₹ {table.discount}</td>
                            <td className="tt-data">{table.qty}</td>
                            <td className="tt-data">₹ {table.total}</td>
                        </tr>
                    ))}
                </tbody>
            </table>

        </div>
    );
};
