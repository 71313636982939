import React from "react";

const SeprPagination = ({ metaData, setRecords, records, handlePageChange, isLimitBox }) => {
    const renderPageButtons = () => {
        const currentPage = metaData?.page || 0;
        const numberOfPage = metaData?.totalPages || 0;

        const startPageIndex = Math.max(0, currentPage - 1);
        const endPageIndex = Math.min(numberOfPage, currentPage + 2);
        const pageButtons = Array.from(
            { length: metaData?.totalPages },
            (_, index) => index
        )
            .slice(startPageIndex, endPageIndex)
            .map((page) => (
                <button
                    key={page + 1}
                    className={`${page === currentPage ? "PG_Button_Active" : "PG_Button"
                        }`}
                    onClick={() => handlePageChange(page)}
                    disabled={page === currentPage}
                    type="button"
                >
                    {page + 1}
                </button>
            ));

        if (endPageIndex < numberOfPage) {
            pageButtons.push(<span key="ellipsis">...</span>);
            pageButtons.push(
                <button
                    key={numberOfPage}
                    className={`${numberOfPage - 1 === currentPage ? "PG_Button_Active" : "PG_Button"
                        }`}
                    onClick={() => handlePageChange(numberOfPage - 1)}
                    disabled={numberOfPage - 1 === currentPage}
                    type="button"
                >
                    {numberOfPage}
                </button>
            );
        }

        return pageButtons;
    };
    const handleLimit = (val) => {
        setRecords(val);
    };
    const currentPage = metaData.page + 1;
    const totalEntries = metaData.total;

    const startIndex = (currentPage - 1) * records + 1;
    const endIndex = Math.min(currentPage * records, totalEntries);
    const displayLine = `Showing ${startIndex} to ${endIndex} of ${totalEntries} entries`;
    
    return (
        <div className="tb_footer flex-between">
            {isLimitBox && <div className="flex gap-1 align-center">
                <span>Rows per page :</span>
                <select
                    value={records}
                    onChange={(e) => handleLimit(e.target.value)}
                    className="limit"
                    style={{ background: "white" }}
                >
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="20">20</option>
                </select>
                <p>{displayLine}</p>
            </div>}

            <div className="table_BL_Footer">
                <button
                    className="Arrow"
                    onClick={() =>
                        handlePageChange(
                            metaData?.hasOwnProperty("hasPrevPage") ? metaData?.prevPage : 0
                        )
                    }
                    style={{
                        cursor: metaData?.hasOwnProperty("hasPrevPage")
                            ? metaData?.hasPrevPage
                                ? "pointer"
                                : "not-allowed"
                            : "not-allowed",
                    }}
                    disabled={
                        metaData?.hasOwnProperty("hasPrevPage")
                            ? !metaData?.hasPrevPage
                            : false
                    }
                    type="button"
                >
                    <i className="fa-solid fa-arrow-left"></i>
                </button>
                {renderPageButtons()}
                <button
                    className="Arrow"
                    onClick={() =>
                        handlePageChange(
                            metaData?.hasOwnProperty("hasNextPage") ? metaData?.nextPage : 0
                        )
                    }
                    style={{
                        cursor: metaData?.hasOwnProperty("hasNextPage")
                            ? metaData?.hasNextPage
                                ? "pointer"
                                : "not-allowed"
                            : "not-allowed",
                    }}
                    disabled={
                        metaData?.hasOwnProperty("hasNextPage")
                            ? !metaData?.hasNextPage
                            : false
                    }
                    type="button"
                >
                    <i className="fa-solid fa-arrow-right"></i>
                </button>
            </div>
        </div>
    );
};

export default SeprPagination;
