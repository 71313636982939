/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import DropDownBox from "components/DropDownBox";
import SeprPagination from "components/ui/SeprPagination";
import { getQuestionList } from "modules/catalogue/questions/redux/questionApi";
import { clearQuestionList } from "modules/catalogue/questions/redux/questionSlice";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

export default function QueListPopup({ quePopup, setQuePopup, setSelQues, selQues, quizLevel }) {
  const dispatch = useDispatch();
  const [addStyle, setAddStyle] = useState(true);
  const [activeInputs, setActiveInputs] = useState(true);
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [type, setType] = useState("");
  // eslint-disable-next-line
  const [level, setLevel] = useState("");
  
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState("");
  // eslint-disable-next-line
  const [isSurvey, setIsSurvey] = useState("false")

  const { questionList, metaData, questionLoading } = useSelector(
    (state) => state.question,
    shallowEqual
  );

  useEffect(() => {
    const data = {
      page: page,
      limit: limit,
      type: type,
      level: quizLevel || "",
      search: search,
      survey: false
    };
    dispatch(getQuestionList(data));

    return () => clearQuestionList();
  }, [page, limit, search, type, quizLevel]);

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };

  const handleClick = () => {
    if (activeInputs === false) {
      setActiveInputs(true);
      setQuePopup(false);
    } else {
      setQuePopup(false);
      setAddStyle(!addStyle);
      chatBoxVisibility();
    }
  };

  function chatBoxVisibility() {
    if (quePopup) {
      const styleTimer = setTimeout(() => {
        setQuePopup(!quePopup);
        document
          .getElementsByClassName("container")[0]
          .classList.remove("popUp-container");
        return clearTimeout(styleTimer);
      }, 200);
    }
  }

  const handleCheckboxChange = (item) => {
    if (selectedQuestions.includes(item.id)) {
      setSelectedQuestions(selectedQuestions.filter((id) => id !== item.id));
    } else {
      setSelectedQuestions([...selectedQuestions, item.id]);
    }
  };

  useEffect(() => {
    if (selQues?.length > 0) {
      setSelectedQuestions(selQues)
    }
  }, [selQues])


  const handleInsert = () => {
    setSelQues(selectedQuestions);
    setQuePopup(false);
  };

  function camelToTitleCase(str) {
    return str
      .replace(/([A-Z])/g, " $1")
      .replace(/^./, (str) => str.toUpperCase());
  }

  return (
    <div id="confirm-box-wrapper">
      <div
        className={
          "popup-wrapper" +
          (addStyle
            ? " show_delete-component-fadeIn-animation"
            : " hide_delete-component-fadeOut-animation")
        }
      >
        <div className="popup-content que-popup">
          <div className="popup-header">
            <h2>
              Question List{" "}
              <span style={{ fontSize: ".8rem" }}>
                {" "}
                {selectedQuestions?.length > 0 &&
                  `(${selectedQuestions?.length} selected)`}
              </span>
            </h2>
            <button className="close flex-center" onClick={() => handleClick()}>
              <i className="fa-sharp fa-regular fa-xmark-large"></i>
            </button>
          </div>
          <div className="que-filter">
            <div className="search-box">
              <input
                type="text"
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Search..."
              />
            </div>
            <DropDownBox
              animateDropDownTitle
              options={[
                { label: "Short Answer", value: "shortAnswer" },
                { label: "Multiple Choice", value: "multipleChoice" },
                { label: "Checkbox", value: "checkbox" },
                { label: "Dropdown", value: "dropdown" },
              ]}
              incomingValue={type}
              customSetter={setType}
              customDropBoxStyles={{
                border: "1px solid #d2d6da",
              }}
              dropDownTitle="Select Type"
              resetButton
            />
            {/* <DropDownBox
              animateDropDownTitle
              resetButton
              options={[
                { label: "Easy", value: "easy" },
                { label: "Medium", value: "medium" },
                { label: "Hard", value: "hard" },
                { label: "Expert", value: "expert" },
                { label: "Master", value: "master" },
                { label: "Extreme", value: "extreme" },
              ]}
              dropDownTitle="Select level"
              customSetter={setLevel}
              incomingValue={level}
              customDropBoxStyles={{
                border: "1px solid #d2d6da",
              }}
            /> */}
            {/* <DropDownBox
              animateDropDownTitle
              resetButton
              options={[
                { label: "Quiz", value: "false" },
                { label: "Survey", value: "true" },
              ]}
              dropDownTitle="Select Quiz"
              customSetter={setIsSurvey}
              incomingValue={isSurvey}
              customDropBoxStyles={{
                border: "1px solid #d2d6da",
              }}
            /> */}
          </div>
          <table className="que-table">
            <thead className="que-head">
              <tr className="que-head__row">
                <th>Select</th>
                <th>Title</th>
                <th>Type</th>
                <th>Level</th>
              </tr>
            </thead>
            {!questionLoading ? (
              <tbody>
                {questionList && questionList.length > 0 ? (
                  questionList.map((que) => (
                    <tr key={que.id}>
                      <td>
                        <label
                          htmlFor={`checkbox${que.id}`}
                          className="TD_CheckBox_LB"
                        >
                          <input
                            name={"checkbox"}
                            id={`checkbox${que.id}`}
                            type="checkbox"
                            className="checkbox-animated"
                            checked={selectedQuestions.includes(que.id)}
                            onChange={() => handleCheckboxChange(que)}
                          />
                          <div className="checkmark"></div>
                        </label>
                      </td>
                      <td>{que.title}</td>
                      <td>{camelToTitleCase(que.type)}</td>
                      <td>{que.level}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={4}>No question found</td>
                  </tr>
                )}
              </tbody>
            ) : (
              <tbody>
                <tr>
                  <td colSpan={4}>No question found</td>
                </tr>
              </tbody>
            )}
          </table>
          <div className="footer">
            <SeprPagination
              metaData={metaData}
              handlePageChange={handlePageChange}
              setRecords={setLimit}
              records={limit}
            />
            <button
              type="button"
              className="btn-outlined"
              onClick={handleInsert}
            >
              Insert Selected Questions
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
