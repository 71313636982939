/* eslint-disable react-hooks/exhaustive-deps */
import CardSkelaton from "components/skelaton/CardSkelaton";
import { unixTimeToReadableFormat } from "helper/helper";
import {
  USER_PROFILE_POINTS_TABS,
  STREAK_DATA,
  LIFES_DATA,
} from "helper/placeholder-data";
import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getPointsTransaction } from "../redux/userApi";
import DropDownBox from "components/DropDownBox";
import SeprPagination from "components/ui/SeprPagination";

const UserPoints = () => {
  const dispatch = useDispatch()
  const { userDetail, pointsInfo, pointsTransactionList, pointsTransactionMetaData } = useSelector((state) => state.user, shallowEqual)

  const [selectedTab, setSelectedTab] = useState("walnut");
  const TABS = {
    walnut: <WalnutsCard pointsTransactionList={pointsTransactionList} />,
    streaks: <StreaksCard />,
    xp: <PointsCard pointsTransactionList={pointsTransactionList} />,
    lifes: <LifesCard />,
  };

  const [page, setPage] = useState(0)
  const [limit, setLimit] = useState(5)
  const [sort, setSort] = useState("desc")
  const [search, setSearch] = useState("")
  const [transactionType, setTransactionType] = useState("")

  useEffect(() => {
    if (userDetail?.id) {
      const data = {
        page: page,
        limit: limit,
        search: search,
        sort: sort,
        transactionType: transactionType,
        pointType: selectedTab,
        userId: userDetail?.id
      }
      dispatch(getPointsTransaction(data))
    }
  }, [selectedTab, userDetail, page, limit, search, sort, transactionType])

  return (
    <div className="custom-box">
      <h3>Points</h3>
      <div className="flex gap-1 mt-1">
        <div className="points-tab"> Total Walnut Points: {pointsInfo?.walnut?.total}</div>
        <div className="points-tab"> Overall Walnut Points: {pointsInfo?.walnut?.overall}</div>
        <div className="points-tab"> Total XP Points: {pointsInfo?.xp?.overall}</div>
        <div className="points-tab"> Overall XP Points: {pointsInfo?.xp?.overall}</div>
      </div>
      <div className="flex-between mt-1">
        <ul className="points-tab-buttons">
          {USER_PROFILE_POINTS_TABS.map((item, i) => (
            <li
              key={i}
              className={`points-tab flex-center gap-1 ${selectedTab === item.tab ? "active" : ""}`}
              onClick={() => setSelectedTab(item.tab)}
            >
              <img src={item.icon} alt="points-icon" />
              <span>{item.title}</span>
            </li>
          ))}
        </ul>
        <div className="flex gap-1">
          <DropDownBox
            options={[
              { label: "Newest", value: "desc" },
              { label: "Oldest", value: "asc" },
            ]}
            size="small"
            dropDownTitle="Sort"
            animateDropDownTitle
            customSetter={setSort}
            customDropBoxStyles={{
              opacity: 0.6,
              color: "#262626",
              padding: "0.6rem",
              border: "1px solid var(--color-neutral-92)",
            }}
          />

          <DropDownBox
            options={[
              { label: "Credit", value: "credit" },
              { label: "Debit", value: "debit" },
            ]}
            size="small"
            dropDownTitle="Transaction Type"
            animateDropDownTitle
            customSetter={setTransactionType}
            customDropBoxStyles={{
              opacity: 0.6,
              color: "#262626",
              padding: "0.6rem",
              border: "1px solid var(--color-neutral-92)",
            }}
            resetButton
          />
          <input
            type="text"
            name="search"
            id="search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            style={{
              opacity: 0.6,
              color: "#262626",
              padding: "0.6rem",
              border: "1px solid var(--color-neutral-92)",
              width: "200px"
            }}
            placeholder="Search..."
          />
        </div>
      </div>
      <div className="card-wrapper mt-2">{TABS[selectedTab]}</div>
      {
        pointsTransactionMetaData?.totalPages > 1 &&
        <SeprPagination
          metaData={pointsTransactionMetaData}
          setRecords={setLimit}
          handlePageChange={setPage}
          records={limit}
          isLimitBox
        />
      }
    </div>
  );
};
export default UserPoints;

const WrapperCard = ({
  index,
  isLoading = false,
  title,
  desc,
  image,
  footer,
  ...props
}) =>
  isLoading ? (
    new Array(index).fill(undefined).map((_, i) => <CardSkelaton key={i} />)
  ) : (
    <div
      className="card"
      key={index}
      style={props.isStreakAvailable === false ? { opacity: ".4" } : null}
    >
      <div className="">
        {/* {image && (
          <div className="card-main-left">
            <img src={image} alt="card-icon" className="card-icon" />
          </div>
        )} */}
        <div className="card-main-right">
          <h4 className="card-main__title">{title}</h4>
          {/* <p className="card-main__txt">{desc}</p> */}
        </div>
      </div>
      {footer}
    </div>
  );

const WalnutsCard = ({ pointsTransactionList }) => {
  return (
    <>
      {pointsTransactionList?.map((wal, i) => (
        <WrapperCard
          key={i}
          index={i}
          title={wal.title}
          footer={
            <div className="card-action mt-1">
              <div className="flex-center gap-1">
                <div className="walnuts-number">
                  Walnuts: <span style={{ fontWeight: "bold", color: wal.transactionType === "credit" ? "green" : "red" }}>
                    {wal.transactionType === "credit" ? '+' : "-"}{wal?.value}
                  </span>
                </div>
              </div>
              <p className="points-time">{unixTimeToReadableFormat(wal?.createdOn)}</p>
            </div>
          }
        />
      ))}
    </>
  );
};

const PointsCard = ({ pointsTransactionList }) => {
  
  // const { user_id } = useParams();
  // const title = getBreadcrumbName({
  //   first_name: "Sanjeev",
  //   last_name: "Kushwaha",
  // });
  return (
    <>
      {pointsTransactionList?.map((wal, i) => (
        <WrapperCard
          key={i}
          index={i}
          // image={wal.image}
          title={wal.title}
          // desc={wal.description}
          footer={
            <div className="card-action mt-1">
              <div className="flex-center gap-1">
                {/* <div className="walnuts-number">{wal.xp_points} XP Points</div> */}
                <div className="walnuts-number">
                  XP Points: <span style={{ fontWeight: "bold", color: wal.transactionType === "credit" ? "green" : "red" }}>
                    {wal.transactionType === "credit" ? '+' : "-"}{wal?.value}
                  </span>
                </div>
                {/* <Link
                  to={`/users/application-users/${user_id}/points/xp`}
                  state={{
                    slug: user_id,
                    title,
                  }}
                  className="view-details"
                >
                  View Details
                </Link> */}
              </div>
              <p className="points-time">{unixTimeToReadableFormat(wal?.createdOn)}</p>
            </div>
          }
        />
      ))}
    </>
  );
};

const LifesCard = () => {
  return (
    <>
      {LIFES_DATA.map((wal, i) => (
        <WrapperCard
          key={i}
          index={i}
          image={wal.image}
          title={wal.title}
          desc={wal.description}
          footer={
            <div className="card-action mt-1">
              <div className="flex-center gap-1">
                <div className="walnuts-number">{wal.lifes} Lifes</div>
              </div>
              <p className="points-time">{wal.time}</p>
            </div>
          }
        />
      ))}
    </>
  );
};

const StreaksCard = () => (
  <>
    {STREAK_DATA.map((str, i) => (
      <WrapperCard
        key={i}
        index={i}
        title={str.title}
        desc={str.description}
        isStreakAvailable={str.streak_points !== 0}
        footer={
          <div className="card-action mt-1">
            <div className="flex-center gap-1">
              <div className="walnuts-number">{str.streak_points} streak</div>
            </div>
            <p className="points-time">{str.time}</p>
          </div>
        }
      />
    ))}
  </>
);
