import { createSlice } from "@reduxjs/toolkit";
import { getSkillDetail, getSkillList } from "./skillApi";

const skillSlice = createSlice({
  name: "CatSkill",
  initialState: {
    skillLoading: false,
    skillList: [],
    metaData: [],
    skillDetail: {},
  },
  reducers: {
    isSkillLoading: (state, action) =>
      (state = {
        ...state,
        skillLoading: action.payload,
      }),
    clearSkillDetail: (state, action) => {
      state.skillDetail = {};
    },
  },
  extraReducers: (builder) => {
    // getSkillList reducers-------------------------
    builder.addCase(getSkillList.pending, (state, action) => {
      state.skillLoading = true;
    });
    builder.addCase(getSkillList.fulfilled, (state, action) => {
      state.skillLoading = false;
      state.skillList = action?.payload?.data;
      state.metaData = action?.payload?.metadata[0];
    });
    builder.addCase(getSkillList.rejected, (state, action) => {
      state.skillLoading = false;
      state.skillList = [];
    });
    // getGlossaryDetail reducers-------------------------
    builder.addCase(getSkillDetail.pending, (state, action) => {
      state.skillLoading = true;
    });
    builder.addCase(getSkillDetail.fulfilled, (state, action) => {
      state.skillLoading = false;
      state.skillDetail = action.payload.data;
    });
    builder.addCase(getSkillDetail.rejected, (state, action) => {
      state.skillLoading = false;
    });
  },
});

export const { isSkillLoading, clearSkillDetail } = skillSlice.actions;
export default skillSlice.reducer;
