import { createAsyncThunk } from "@reduxjs/toolkit";
import { isFaqLoading } from "./faqSlice";
import axios from "axios";
import { AddNumField, options } from "helper/helper";
import { toast } from "react-toastify";

export const getFaqList = createAsyncThunk(
    "admin/faq-list",
    async (data, { fulfillWithValue, rejectWithValue, dispatch }) => {
        try {
            dispatch(isFaqLoading(true));
            const response = await axios({
                method: "GET",
                url: `${process.env.REACT_APP_AUTH_URL}/admin/catalogue/faq/faq-list?page=${data.page}&limit=${data.limit}&sort=${data?.sort ? data?.sort : "desc"}&search=${data.search ? data.search : ""}&filter[categoryId]=${data?.categoryId ? data.categoryId : ""}`,
            });
            if (response.status === 200) {
                if (response?.data?.data?.length > 0) {
                    const res = await AddNumField(
                        response?.data?.data?.[0],
                        data?.page * data?.limit
                    );

                    dispatch(isFaqLoading(false));
                    return fulfillWithValue(res);
                } else {
                    const modPage = Math.max(data.page - 1, 0);
                    const responseAgain = await axios({
                        method: "GET",
                        url: `${process.env.REACT_APP_AUTH_URL}/admin/faq/faq-list?page=${data.page}&limit=${data.limit}&sort=${data?.sort ? data?.sort : "desc"}&search=${data?.search ? data.search : ""}&filter[categoryId]=${data?.categoryId ? data.categoryId : ""}`,
                    });
                    if (responseAgain.status === 200) {
                        const resAgain = await AddNumField(
                            responseAgain?.data?.data?.[0],
                            modPage * data?.limit
                        );
                        dispatch(isFaqLoading(false));
                        return fulfillWithValue(resAgain);
                    } else {
                        dispatch(isFaqLoading(false));
                        toast.error(responseAgain?.data?.message, options);
                        return rejectWithValue();
                    }
                }
            } else {
                dispatch(isFaqLoading(false));
                // toast.error(response?.data?.message, options);
                return rejectWithValue();
            }
        } catch (err) {
            return rejectWithValue();
        }
    }
)

export const addFaq = createAsyncThunk(
    "catalogue/add-faq",
    async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {

        try {
            dispatch(isFaqLoading(true));
            const response = await axios({
                method: "POST",
                url: `${process.env.REACT_APP_AUTH_URL}/admin/catalogue/faq/create-faq`,
                data: data,
                headers: {
                    "Content-Type": "application/json",
                },
            });
            if (response.status === 200) {
                dispatch(isFaqLoading(false));
                dispatch(
                    getFaqList({
                        page: 0,
                        limit: 6,
                        sort: "desc",
                        search: "",
                    })
                );
                toast.success(response?.data?.message, options);
                return response?.data?.status;
            } else {
                dispatch(isFaqLoading(false));
                toast.error(response?.data?.message, options);
            }
        } catch (err) {
            dispatch(isFaqLoading(false));
            toast.error(err, options);
        }
    }
);

export const getFaqDetail = createAsyncThunk(
    "catalogue/get-faq-detail",
    async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
        try {
            const response = await axios({
                method: "GET",
                url: `${process.env.REACT_APP_AUTH_URL}/admin/catalogue/faq/faq-detail/${data?.id}`,
            });
            if (response.status === 200) {
                return fulfillWithValue(response?.data);
            } else {
                toast.error(response?.data?.message, options);
                return rejectWithValue();
            }
        } catch (err) {
            toast.error(err, options);
            return rejectWithValue();
        }
    }
);

export const updateFaqDetail = createAsyncThunk(
    "catalogue/update-faq",
    async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
        try {
            dispatch(isFaqLoading(true));
            const response = await axios({
                method: "PUT",
                url: `${process.env.REACT_APP_AUTH_URL}/admin/catalogue/faq/update-detail/${data?.id}`,
                data: data?.send,
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (response.status === 200) {
                dispatch(isFaqLoading(false));
                dispatch(
                    getFaqList({
                        page: 0,
                        limit: 6,
                        search: "",
                        sort: "desc",
                    })
                );
                toast.success(response?.data?.message, options);
                return response?.data;
            } else {
                dispatch(isFaqLoading(false));
                toast.error(response?.data?.message, options);
            }
        } catch (err) {
            dispatch(isFaqLoading(false));
            toast.error(err, options);
        }
    }
);

export const deleteFaq = createAsyncThunk(
    "faq/delete-faq",
    async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
      try {
        dispatch(isFaqLoading(true));
        const response = await axios({
          method: "DELETE",
          url: `${process.env.REACT_APP_AUTH_URL}/admin/catalogue/faq/delete-faq`,
          data: data?.send,
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (response.status === 200) {
          dispatch(isFaqLoading(false));
          dispatch(
            getFaqList({
              page: data?.page,
              limit: data.limit,
              search: data.search,
              sort: data.sort,
            })
          );
          toast.success(response?.data?.message, options);
          return response?.data?.status;
        } else {
          dispatch(isFaqLoading(false));
          toast.error(response?.data?.message, options);
        }
      } catch (err) {
        dispatch(isFaqLoading(false));
        toast.error(err, options);
      }
    }
  );