import { nodata } from "assets/icons";
import React from "react";

const NotFound = () => {
  return (
    <div className="not-found">
      <img src={nodata} alt="no-data-found" />
      <h1>No results found</h1>
      <p>Try again</p>
    </div>
  );
};

export default NotFound;
