/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useState } from "react";
import ModuleLayout from "layouts/ModuleLayout";
import DropDownBox from "components/DropDownBox";
import Loader from "components/ui/Loader";
import InputField from "components/ui/InputField";
import SubmitButton from "components/ui/SubmitButton";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { addPlan, getPlanDetail, updatePlan } from "../redux/planApi";
import ReactQuill from "react-quill";
import { planType, recurringCycleOption, toolbarOptions } from "helper/placeholder-data";

const ManagePlans = ({ setTitle }) => {
    const [active, setActive] = useState("quizes");
    const [mobileSidebar, setMobileSidebar] = useState(false);
    const [minimizedSidebar, setMinimizedSidebar] = useState(false);
    const [activeInputs, setActiveInputs] = useState(true);
    const [currencyOpts, setCurrencyOpts] = useState();
    const [selectedCountry, setSelectedCountry] = useState({});
    const { countryList } = useSelector((state) => state.profile, shallowEqual);
    const { planLoading, planDetail } = useSelector(state => state.plan, shallowEqual)


    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();


    useEffect(() => {
        setTitle("Manage Plans");
    }, [setTitle, dispatch]);

    useEffect(() => {
        if (id) {
            dispatch(getPlanDetail({ id: id }));
        }
    }, [dispatch, id]);

    useEffect(() => {
        if (countryList?.length > 0) {
            const cr = countryList.map((item) => {
                const label = `${item?.currency.code} | ${item?.name}`;
                const value = item?.currency.code;
                const key = item.dialCode;
                return { label, value, key };
            });
            setCurrencyOpts(cr);
        }
    }, [countryList]);

    const initialValues = {
        name: "",
        description: "",
        type: "",
        price: "",
        currency_code: "",
        currency_symbol: "",
        recurring_cycle: ""
    };

    const validationSchema = Yup.object({
        name: Yup.string().required("Plan name is required"),
        description: Yup.string().required("Plan description is required"),
        type: Yup.string().required("Plan type is required"),
        price: Yup.string().required("Plan price is required"),
        currency_code: Yup.string().required("Please select currency code"),
        recurring_cycle: Yup.string().required("Please select recurring cycle"),
    });

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values, { resetForm }) => {
            const data = {
                send: {
                    name: values.name,
                    description: values.description,
                    type: values.type,
                    price: values.price,
                    currency_code: values.currency_code,
                    currency_symbol: values.currency_symbol,
                    recurring_cycle: values.recurring_cycle
                },
                id: id,
            };
            if (!id) {
                const res = await dispatch(addPlan(data.send));
                if (res?.payload) {
                    resetForm();
                    navigate(-1);
                }
            } else {
                const res = await dispatch(updatePlan(data));
                if (res?.payload) {
                    resetForm();
                    navigate(-1);
                }
            }
        },
    });

    useEffect(() => {
        if (id && planDetail) {
            formik.setValues({
                name: planDetail?.name,
                type: planDetail?.type,
                price: planDetail?.price?.value,
                currency_code: planDetail?.price?.currency?.code,
                recurring_cycle: planDetail?.recurringCycle,
                description: planDetail?.description
            })
        }

        return () => {
            formik.resetForm()
        }
    }, [planDetail])

    useEffect(() => {
        if (formik.values.currency_code) {
            const data = countryList.filter(
                (ele) => ele?.currency?.code === formik.values.currency_code
            );
            setSelectedCountry(data?.[0]);
        }
    }, [formik.values.currency_code]);

    useEffect(() => {
        if (selectedCountry) {
            formik.setValues({
                ...formik.values,
                currency_symbol: selectedCountry?.currency?.symbol,
            });
        }
    }, [selectedCountry]);

    return (
        <ModuleLayout
            minimizedSidebar={minimizedSidebar}
            setMinimizedSidebar={setMinimizedSidebar}
            setMobileSidebar={setMobileSidebar}
            mobileSidebar={mobileSidebar}
            setActive={setActive}
            active={active}
        >
            <Loader isLoading={planLoading} />

            <div className="list-container custom-box">
                <div className="form-header">
                    <h2>Plan Management</h2>

                    {id && (
                        <div className="flex gap-2">
                            {activeInputs ? (
                                <>
                                    <button
                                        type="button"
                                        onClick={() => navigate(-1)}
                                        className="btn-outlined cancel"
                                    >
                                        Back
                                    </button>
                                    <button
                                        type="button"
                                        onClick={() => setActiveInputs(false)}
                                        className="btn-primary"
                                    >
                                        Update
                                    </button>
                                </>
                            ) : (
                                <>
                                    <button
                                        type="button"
                                        onClick={() => setActiveInputs(true)}
                                        className="btn-outlined tertiary"
                                    >
                                        Abort
                                    </button>
                                    <SubmitButton
                                        className="btn-primary"
                                        type="submit"
                                        onClick={formik.handleSubmit}
                                        disabled={formik.isSubmitting}
                                    >
                                        Submit
                                    </SubmitButton>
                                </>
                            )}
                        </div>
                    )}

                    {!id && (
                        <div className="flex gap-1">
                            <button
                                type="button"
                                onClick={() => navigate(-1)}
                                className="btn-outlined cancel"
                            >
                                Back
                            </button>
                            <button
                                className="btn-primary"
                                type="submit"
                                onClick={() => formik.handleSubmit()}
                                disabled={formik.isSubmitting}
                            >
                                Submit
                            </button>
                        </div>
                    )}
                </div>

                <main className="main">
                    <div className="auth-form-wrapper">
                        <form>
                            <div className="form-control">
                                <label className="form-label">Plan Name</label>
                                <div className="form-input-box">
                                    <InputField
                                        type="text"
                                        id="name"
                                        name="name"
                                        value={formik.values.name}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        disabled={id ? activeInputs : false}
                                        onInput={(e) =>
                                        (e.target.value = e.target.value
                                            // .replace(/[^A-Za-z ]/gi, "")
                                            .replace(/(?:^|\s)\S/g, (match) =>
                                                match.toUpperCase()
                                            ))
                                        }
                                    />
                                </div>
                                {formik.errors.name && formik.touched.name ? (
                                    <span className="err">{formik.errors.name}</span>
                                ) : null}
                            </div>
                            <div className="form-col-3 mt-1">
                                <div>
                                    <div className="form-control">
                                        <label className="form-label">Select Currency</label>
                                        <div className="form-input-box">
                                            <DropDownBox
                                                disabled={id ? !activeInputs : activeInputs}
                                                options={currencyOpts}
                                                defaultValue="Select Currency"
                                                animateDropDownTitle
                                                customSetter={formik.setFieldValue}
                                                customFormikLabel="currency_code"
                                                customDropBoxStyles={{
                                                    border: "0.063rem solid #d2d6da",
                                                    borderRadius: "0.5rem",
                                                    color: "#262626",
                                                }}
                                                customTextStyle={{
                                                    color: "#454545",
                                                    opacity: "0.7",
                                                }}
                                                incomingValue={formik.values.currency_code}
                                                showSearchBar
                                            />
                                        </div>
                                    </div>
                                    {formik.errors.currency_code &&
                                        formik.touched.currency_code ? (
                                        <span className="err">
                                            {formik.errors.currency_code}
                                        </span>
                                    ) : null}
                                </div>
                                <div>
                                    <div className="form-control">
                                        <label className="form-label">Currency Symbol</label>
                                        <div className="form-input-box">
                                            <InputField
                                                type="text"
                                                id="currency_symbol"
                                                name="currency_symbol"
                                                className="form-input"
                                                value={formik.values.currency_symbol}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                disabled
                                            />
                                        </div>
                                    </div>
                                    {formik.errors.currency_symbol &&
                                        formik.touched.currency_symbol ? (
                                        <span className="err">{formik.errors.currency_symbol}</span>
                                    ) : null}
                                </div>
                                <div>
                                    <div className="form-control">
                                        <label className="form-label">Price</label>
                                        <div className="form-input-box">
                                            <InputField
                                                type="text"
                                                id="price"
                                                name="price"
                                                className="form-input"
                                                value={formik.values.price}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                disabled={id ? activeInputs : false}
                                                onInput={(e) => {
                                                    let inputValue = e.target.value;
                                                    e.target.value = e.target.value.replace(/[^\d\.]/g, "")
                                                    e.target.value = inputValue.slice(0, 6).replace(/\D/g, "");
                                                }}
                                            />
                                        </div>
                                    </div>
                                    {formik.errors.price &&
                                        formik.touched.price ? (
                                        <span className="err">{formik.errors.price}</span>
                                    ) : null}
                                </div>
                            </div>
                            <div className="form-col-2 mt-1">
                                <div>
                                    <div className="form-control">
                                        <label className="form-label">Select Type</label>
                                        <div className="form-input-box">
                                            <DropDownBox
                                                disabled={id ? !activeInputs : activeInputs}
                                                options={planType}
                                                defaultValue="Select Type"
                                                animateDropDownTitle
                                                customSetter={formik.setFieldValue}
                                                customFormikLabel="type"
                                                customDropBoxStyles={{
                                                    border: "0.063rem solid #d2d6da",
                                                    borderRadius: "0.5rem",
                                                    color: "#262626",
                                                }}
                                                customTextStyle={{
                                                    color: "#454545",
                                                    opacity: "0.7",
                                                }}
                                                incomingValue={formik.values.type}
                                            />
                                        </div>
                                    </div>
                                    {formik.errors.type && formik.touched.type ?
                                        (<span className="err">{formik.errors.type}</span>) : null
                                    }
                                </div>

                                <div>
                                    <div className="form-control">
                                        <label className="form-label">Select Recurring Cycle</label>
                                        <div className="form-input-box">
                                            <DropDownBox
                                                disabled={id ? !activeInputs : activeInputs}
                                                options={recurringCycleOption}
                                                defaultValue="Select Recurring Cycle"
                                                animateDropDownTitle
                                                customSetter={formik.setFieldValue}
                                                customFormikLabel="recurring_cycle"
                                                customDropBoxStyles={{
                                                    border: "0.063rem solid #d2d6da",
                                                    borderRadius: "0.5rem",
                                                    color: "#262626",
                                                }}
                                                customTextStyle={{
                                                    color: "#454545",
                                                    opacity: "0.7",
                                                }}
                                                incomingValue={formik.values.recurring_cycle}
                                            />
                                        </div>
                                    </div>
                                    {formik.errors.recurring_cycle && formik.touched.recurring_cycle ?
                                        (<span className="err">{formik.errors.recurring_cycle}</span>) : null
                                    }
                                </div>
                            </div>
                            <div className="form-control mt-1">
                                <label className="form-label">Description</label>
                                <div className={`text-editor`}>
                                    <ReactQuill
                                        theme="snow"
                                        modules={toolbarOptions}
                                        value={formik.values.description || ""}
                                        onChange={(ev) => formik.setFieldValue("description", ev)}
                                        // readOnly={slug && activeInputs}
                                        readOnly={id ? activeInputs : false}
                                    />
                                </div>
                                {formik.errors.description && formik.touched.description && (
                                    <span className="err">{formik.errors.description}</span>
                                )}
                            </div>
                        </form>
                    </div>
                </main>
            </div>
        </ModuleLayout>
    );
};

export default ManagePlans;
