import { walnut } from "assets/icons";
import Table from "components/Table";
import { rewardList } from "helper/placeholder-data";
import { unixTimeToReadableFormat } from "helper/helper";

const UserRewards = () => {
  const listContent = rewardList.map((obj, i) => {
    const { _id, brand, logo, offer, date, purchase_points } = obj;
    const valArr = [
      _id,
      [
        { style: "tb_data_name", val: brand, image: logo },
        { style: "f-email", val: offer },
        { style: "date", val: unixTimeToReadableFormat(date) },
        { style: ``, val: `${purchase_points} Points` },
      ],
    ];
    return valArr;
  });

  const table_props = {
    loadingState: false,
    skalatonDetails: { col: 5, rows: 5 },
    listHeading: ["Brand", "Offer", "Date", "Used Points"],
    listContent,
  };

  return (
    <div className="custom-box user-rewards">
      <h3>Rewards</h3>

      <div className="cards">
        <div className="card">
          <div>
            <h1 className="points">25000</h1>
            <p className="subtitle">Total Available Walnuts Points</p>
          </div>
          <img src={walnut} alt="walnut" className="walnut" />
        </div>

        <div className="card">
          <div>
            <h1 className="points">1500</h1>
            <p className="subtitle">Used Walnuts Points</p>
          </div>
          <img src={walnut} alt="walnut" className="walnut" />
        </div>
      </div>

      <div className="transaction-list mt-2">
        <h3>Transaction History</h3>

        <Table {...table_props} />
      </div>
    </div>
  );
};

export default UserRewards;
