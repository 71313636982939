import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { AddNumField, options } from "helper/helper";
import { toast } from "react-toastify";
import { isInventoryLoading } from "./inventorySlice";

export const getInventoryList = createAsyncThunk(
  "inventory/get-inventory-list",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/inventory/inventory-list?page=${data.page}&limit=${data.limit}&sort=${data?.sort ? data?.sort : "desc"}&search=${data.search}`,
      });

      if (response.status === 200) {
        if (response?.data?.data?.length > 0) {
          const res = await AddNumField(
            response?.data,
            data?.page * data?.limit
          );
          return fulfillWithValue(res);
        } else {
          const modPage = Math.max(data.page - 1, 0);
          const responseAgain = await axios({
            method: "POST",
            url: `${process.env.REACT_APP_AUTH_URL}/admin/inventory/inventory-list?page=${data.page}&limit=${data.limit}&sort=${data?.sort ? data?.sort : ""}&search=${data.search}`,
          });
          if (responseAgain.status === 200) {
            const resAgain = await AddNumField(
              responseAgain?.data,
              modPage * data?.limit
            );
            return fulfillWithValue(resAgain);
          } else {
            toast.error(responseAgain?.data?.message, options);
            return rejectWithValue();
          }
        }
      } else {
        // toast.error(response?.data?.message, options);
        return rejectWithValue();
      }
    } catch (err) {
      return rejectWithValue();
    }
  }
);

export const addInventory = createAsyncThunk(
  "inventory/add-inventory",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      dispatch(isInventoryLoading(true));
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/inventory/add-inventory`,
        data: data,
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.status === 200) {
        dispatch(isInventoryLoading(false));
        toast.success(response?.data?.message, options);
        return response?.data?.status;
      } else {
        dispatch(isInventoryLoading(false));
        toast.error(response?.data?.message, options);
      }
    } catch (err) {
      dispatch(isInventoryLoading(false));
      toast.error(err, options);
    }
  }
);

export const getInventoryDetail = createAsyncThunk(
  "inventory/get-inventory-detail",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/inventory/inventory-detail/${data?.id}`,
      });
      if (response.status === 200) {
        return fulfillWithValue(response?.data);
      } else {
        toast.error(response?.data?.message, options);
        return rejectWithValue();
      }
    } catch (err) {
      toast.error(err, options);
      return rejectWithValue();
    }
  }
);

export const updateInventoryDetail = createAsyncThunk(
  "inventory/update-inventory",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      dispatch(isInventoryLoading(true));
      const response = await axios({
        method: "PUT",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/inventory/update-detail/${data?.id}`,
        data: data?.send,
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.status === 200) {
        dispatch(isInventoryLoading(false));
        toast.success(response?.data?.message, options);

        return response?.data?.status;
      } else {
        dispatch(isInventoryLoading(false));
        toast.error(response?.data?.message, options);
      }
    } catch (err) {
      dispatch(isInventoryLoading(false));
      toast.error(err, options);
    }
  }
);

export const deleteInventory = createAsyncThunk(
  "inventory/delete-inventory",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      dispatch(isInventoryLoading(true));
      const response = await axios({
        method: "DELETE",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/inventory/delete-inventory`,
        data: data?.send,
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.status === 200) {
        dispatch(isInventoryLoading(false));
        dispatch(
          getInventoryList({
            page: data?.page,
            limit: data.limit,
            search: data.search,
            sort: data.sort,
          })
        );
        // toast.success(response?.data?.message, options);
        return response?.data?.status;
      } else {
        dispatch(isInventoryLoading(false));
        // toast.error(response?.data?.message, options);
      }
    } catch (err) {
      dispatch(isInventoryLoading(false));
      toast.error(err, options);
    }
  }
);
