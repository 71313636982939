import { StarRating } from "components/StarRating";
import React, { useEffect, useState } from "react";

const FeedbackPopup = ({ popup, setPopup }) => {
  const [addStyle, setAddStyle] = useState(true);

  const handleClick = () => {
    setAddStyle(!addStyle);
    chatBoxVisibility();
  };

  function chatBoxVisibility() {
    if (popup.show) {
      const styleTimer = setTimeout(() => {
        setPopup({ ...popup, show: !popup.show });
        document
          .getElementsByClassName("container")[0]
          .classList.remove("popUp-container");
        clearTimeout(styleTimer);
      }, 200);
    }
  }

  useEffect(() => {
    document
      .getElementsByClassName("container")[0]
      .classList.add("popUp-container");
  }, []);

  return (
    <div id="confirm-box-wrapper">
      <div
        className={
          "popup-wrapper" +
          (addStyle
            ? " show_delete-component-fadeIn-animation"
            : " hide_delete-component-fadeOut-animation")
        }
      >
        <div className={`popup-content`}>
          <div className="popup-header">
            <h2>Feedback Detail</h2>
            <button className="close flex-center" onClick={() => handleClick()}>
              <i className="fa-sharp fa-regular fa-xmark-large"></i>
            </button>
          </div>

          <div className="flex flex-col gap-1" style={{ padding: "1.2rem" }}>
            <div className="flex gap-1" style={{ alignItems: "baseline" }}>
              <h3>User Name: </h3>
              <p>
                {popup?.feedback?.user?.name?.firstName}{" "}
                {popup?.feedback?.user?.name?.lastName}
              </p>
            </div>
            <div className="flex gap-1" style={{ alignItems: "baseline" }}>
              <h3>Type: </h3>
              <p style={{textTransform: "capitalize"}}>
                {popup?.feedback?.type}
              </p>
            </div>
            <div className="flex gap-1" style={{ alignItems: "center" }}>
              <h3>Rating: </h3>
              <StarRating rating={popup?.feedback?.rating} />
            </div>
            <div>
              <h3>Description:</h3>
              {popup?.feedback?.description}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeedbackPopup;
