/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import InputField from 'components/ui/InputField'
import SubmitButton from 'components/ui/SubmitButton'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { updateMaintenance } from '../redux/companyApi'
import { useFormik } from 'formik'
import * as Yup from "yup"

export default function Maintenance() {
    const { companyDetail, companyLoading } = useSelector(state => state.company, shallowEqual)
    const dispatch = useDispatch()
    const [activeInputs, setActiveInputs] = useState(false);

    function formatDateTime(timestamp) {
        const date = new Date(timestamp);

        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();

        const hour = date.getHours();
        const minute = date.getMinutes();
        const second = date.getSeconds();

        const dateStr = `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
        const timeStr = `${String(hour).padStart(2, '0')}:${String(minute).padStart(2, '0')}:${String(second).padStart(2, '0')}`;

        return { date: dateStr, time: timeStr };
    }

    const initialValues = {
        status: true,
        date: "",
        time: ""
    }

    const validationSchema = Yup.object({
        status: Yup.boolean().required("Status is required"),
        time: Yup.string().when("status", {
            is: true,
            then: () => Yup.string().required("Time is required")
        }),
        date: Yup.string().when("status", {
            is: true,
            then: () => Yup.string().required("Date is required")
        })
    })

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
            if (values.date && values.time) {
                const dateTime = `${values.date}T${values.time}`;
                const dateObject = new Date(dateTime);
                const isoString = dateObject?.toISOString();
                const data = {
                    status: values.status,
                    time: values.status ? isoString : ""
                }
                const res = await dispatch(updateMaintenance(data))
                if (res.payload) {
                    setActiveInputs(false)
                }
            } else {
                const data = {
                    status: values.status,
                }
                const res = await dispatch(updateMaintenance(data))
                if (res.payload) {
                    setActiveInputs(false)
                }
            }
        }
    })

    useEffect(() => {
        formik.setValues({
            status: companyDetail?.maintenance?.status,
            date: companyDetail?.maintenance?.time ? formatDateTime(companyDetail?.maintenance?.time)?.date : "",
            time: companyDetail?.maintenance?.time ? formatDateTime(companyDetail?.maintenance?.time)?.time : ""
            // date: companyDetail?.maintenance?.time ? (localDateAndTime(companyDetail?.maintenance?.time)?.date && localDateAndTime(companyDetail?.maintenance?.time)?.date) : "",
            // time: companyDetail?.maintenance?.time ? (localDateAndTime(companyDetail?.maintenance?.time)?.time && localDateAndTime(companyDetail?.maintenance?.time)?.time) : "",
        })
    }, [companyDetail])


    return (
        <div>
            <h2 className="title">Maintenance Details</h2>
            <form onSubmit={formik.handleSubmit}>
                <div className="flex gap-1" style={{ alignItems: "center" }}>
                    <p>Maintenance Mode</p>
                    <input
                        type="checkbox"
                        name="status"
                        id="status"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        checked={formik.values.status}
                        style={{ display: "none" }}
                        className='cc-inp'
                        disabled={!activeInputs}
                    />
                    <label htmlFor="status">
                        <div className='custom-checkbox'>
                            <div className="check-circle"></div>
                        </div>
                    </label>
                </div>
                {
                    formik.values.status &&
                    <div className="form-col-2">
                        <div className="form-control mt-1">
                            <label className="form-label">Set Maintenance Date</label>
                            <div className="form-input-box">
                                <InputField
                                    type="date"
                                    id="date"
                                    name="date"
                                    value={formik.values.date}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    min={new Date().toISOString().split("T")[0]}
                                />
                            </div>
                            {formik.errors.date && formik.touched.date ? (
                                <span className="err">{formik.errors.date}</span>
                            ) : null}
                        </div>
                        <div className="form-control mt-1">
                            <label className="form-label">Set Maintenance Time</label>
                            <div className="form-input-box">
                                <InputField
                                    type="time"
                                    id="time"
                                    name="time"
                                    value={formik.values.time}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </div>
                            {formik.errors.time && formik.touched.time ? (
                                <span className="err">{formik.errors.time}</span>
                            ) : null}
                        </div>
                    </div>
                }
                <div className='mt-1'>
                    {
                        activeInputs ?
                            <SubmitButton
                                type="submit"
                                className="btn-primary"
                                isLoading={companyLoading}
                                size='3rem'
                            >
                                Update
                            </SubmitButton> : <button className="btn-primary" type='button' onClick={() => setActiveInputs(true)}>Change</button>
                    }

                </div>
            </form>
        </div>
    )
}
