import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { AddNumField, options } from "helper/helper";
import { isBrandLoading } from "./brandsSlice";

export const getBrandList = createAsyncThunk(
  "catalogue/get-brand-list",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    console.log('data: ', data);
    try {
      const response = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/catalogue/brand/brand-list?page=${data?.page}&limit=${data?.limit}&sort=${data?.sort ? data?.sort : "desc"}&search=${data.search}`,
      });

      if (response.status === 200) {
        if (response?.data?.data?.length > 0) {
          const res = await AddNumField(
            response?.data?.data?.[0],
            data?.page * data?.limit
          );

          return fulfillWithValue(res);
        } else {
          const modPage = Math.max(data.page - 1, 0);
          const responseAgain = await axios({
            method: "GET",
            url: `${process.env.REACT_APP_AUTH_URL}/admin/catalogue/brand/brand-list?page=${data?.page}&limit=${data?.limit}&sort=${data?.sort ? data?.sort : "desc"}&search=${data.search}`,
          });
          if (responseAgain.status === 200) {
            const resAgain = await AddNumField(
              responseAgain?.data,
              modPage * data?.limit
            );
            return fulfillWithValue(resAgain);
          } else {
            toast.error(responseAgain?.data?.message, options);
            return rejectWithValue();
          }
        }
      } else {
        // toast.error(response?.data?.message, options);
        return rejectWithValue();
      }
    } catch (err) {
      return rejectWithValue();
    }
  }
);

export const addBrand = createAsyncThunk(
  "catalogue/add-brand",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    
    try {
      dispatch(isBrandLoading(true));
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/catalogue/brand/add-brand`,
        data: data,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (response.status === 200) {
        dispatch(isBrandLoading(false));
        dispatch(
          getBrandList({
            page: 0,
            limit: 6,
            sort: "desc",
            search: "",
          })
        );
        toast.success(response?.data?.message, options);
        return response?.data?.status;
      } else {
        dispatch(isBrandLoading(false));
        toast.error(response?.data?.message, options);
      }
    } catch (err) {
      dispatch(isBrandLoading(false));
      toast.error(err, options);
    }
  }
);

export const getBrandDetail = createAsyncThunk(
  "catalogue/get-brand-detail",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      const response = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/catalogue/brand/brand-detail/${data?.id}`,
      });
      if (response.status === 200) {
        return fulfillWithValue(response?.data);
      } else {
        toast.error(response?.data?.message, options);
        return rejectWithValue();
      }
    } catch (err) {
      toast.error(err, options);
      return rejectWithValue();
    }
  }
);

export const updateBrandDetail = createAsyncThunk(
  "catalogue/update-brand",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      dispatch(isBrandLoading(true));
      const response = await axios({
        method: "PUT",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/catalogue/brand/update-brand/${data?.id}`,
        data: data?.send,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.status === 200) {
        dispatch(isBrandLoading(false));
        dispatch(
          getBrandList({
            page: 0,
            limit: 6,
            search: "",
            sort: "desc",
          })
        );
        toast.success(response?.data?.message, options);
        return response?.data;
      } else {
        dispatch(isBrandLoading(false));
        toast.error(response?.data?.message, options);
      }
    } catch (err) {
      dispatch(isBrandLoading(false));
      toast.error(err, options);
    }
  }
);

export const deleteBrandAsync = createAsyncThunk(
  "catalogue/delete-brand",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      dispatch(isBrandLoading(true));
      const response = await axios({
        method: "DELETE",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/catalogue/brand/delete-brand`,
        data: data?.send,
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.status === 200) {
        dispatch(isBrandLoading(false));
        dispatch(
          getBrandList({
            page: data?.page,
            limit: data.limit,
            search: data.search,
            sort: data.sort,
          })
        );
        toast.success(response?.data?.message, options);
        return response?.data?.status;
      } else {
        dispatch(isBrandLoading(false));
        toast.error(response?.data?.message, options);
      }
    } catch (err) {
      dispatch(isBrandLoading(false));
      toast.error(err, options);
    }
  }
);

export const addBulkBrandAsync = createAsyncThunk(
  "catalogue/add-bulk-brand",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    dispatch(isBrandLoading(true));
    try {
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/catalogue/brand/add-brand-bulk`,
        headers: {
          "Content-Type": "multipart/form-data",
        },

        data: data,
      });

      if (response.status === 200) {
        dispatch(isBrandLoading(false));
        dispatch(
          getBrandList({
            page: 0,
            limit: 6,
            search: "",
            sort: "desc",
          })
        );
        toast.success(response?.data?.message, options);
        return response?.data?.status;
      } else {
        dispatch(isBrandLoading(false));
        toast.error(response?.data?.message, options);
      }
    } catch (err) {
      dispatch(isBrandLoading(false));
      toast.error(err, options);
    }
  }
);
