import { combineReducers } from "@reduxjs/toolkit";
import authSlice from "modules/auth/redux/authSlice";
import dashboardSlice from "modules/dashboard/redux/dashboardSlice";
import glossarySlice from "modules/catalogue/glossary/redux/glossarySlice";
import profileSlice from "modules/settings/profile/redux/profileSlice";
import policiesSlice from "modules/cms/policies/redux/policiesSlice";
import templateSlice from "modules/cms/templates/redux/templateSlice";
import categorySlice from "modules/catalogue/categories/redux/categorySlice";
import skillSlice from "modules/catalogue/skills/redux/skillSlice";
import quizSlice from "modules/catalogue/quizes/redux/quizSlice";
import questionSlice from "modules/catalogue/questions/redux/questionSlice";
import productSlice from "modules/products/products/redux/productSlice";
import inventorySlice from "modules/products/inventory/redux/inventorySlice";
import adminSlice from "modules/settings/users/administrators/redux/adminSlice";
import userSlice from "modules/settings/users/app_users/redux/userSlice";
import companySlice from "modules/settings/company/redux/companySlice";
import addressSlice from "modules/address/redux/addressSlice";
import brandsSlice from "modules/catalogue/brands/redux/brandsSlice";
import feedbackSlice from "modules/feedback/redux/feedbackSlice";
import orderSlice from "modules/orders/redux/orderSlice";
import reviewSlice from "modules/reviews/redux/reviewSlice";
import faqSlice from "modules/catalogue/faq/redux/faqSlice";
import planSlice from "modules/plans/redux/planSlice";

const rootReducer = combineReducers({
  auth: authSlice,
  profile: profileSlice,
  admin: adminSlice,
  user: userSlice,
  glossary: glossarySlice,
  dashboard: dashboardSlice,
  cmsPolicy: policiesSlice,
  cmsTemplate: templateSlice,
  CatCategory: categorySlice,
  CatSkill: skillSlice,
  CatBrand: brandsSlice,
  question: questionSlice,
  quiz: quizSlice,
  products: productSlice,
  inventory: inventorySlice,
  company: companySlice,
  address: addressSlice,
  feedback: feedbackSlice,
  order: orderSlice,
  review: reviewSlice,
  faq: faqSlice,
  plan: planSlice
});

export default rootReducer;
