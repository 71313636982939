import React from "react";

const InputField = (props) => {
  return <input className={props.className || "form-input"} {...props} />;
};

export default InputField;

// {
//   id,
//   name,
//   type,
//   value,
//   placeholder,
//   onChange,
//   onBlur,
//   accept,
//   disabled,
//   maxLength,
//   className = "form-input",
  // onInput = (e) => {
  //   e.target.value = e.target.value
  //     // .replace(/[^A-Za-z ]/gi, "")
  //     .replace(/(?:^|\s)\S/g, (match) => match.toUpperCase());
  // },
  // onKeyDown = (e) => {
  //   if (e.key === " ") e.preventDefault();
  // },
//   ...props
// }
