import { createSlice } from "@reduxjs/toolkit";
import { getOrderDetail, getOrderList } from "./orderApi";

const orderSlice = createSlice({
  name: "order",
  initialState: {
    orderLoading: false,
    orderList: [],
    metaData: {},
    orderDetail: {}
  },
  reducers: {
    isOrderLoading: (state, action) =>
      (state = {
        ...state,
        orderLoading: action.payload,
      }),
  },
  extraReducers: (builder) => {
    builder.addCase(getOrderList.pending, (state, action) => {
      state.orderLoading = true;
    });
    builder.addCase(getOrderList.fulfilled, (state, action) => {
        
      state.orderLoading = false;
      state.orderList = action?.payload?.data;
      state.metaData = action?.payload?.metadata[0];
    });
    builder.addCase(getOrderList.rejected, (state, action) => {
      state.orderLoading = false;
      state.orderList = [];
    });

    builder.addCase(getOrderDetail.pending, (state, action) => {
      state.orderLoading = true;
    });
    builder.addCase(getOrderDetail.fulfilled, (state, action) => {
      state.orderLoading = false;
      state.orderDetail = action?.payload?.data;
    });
    builder.addCase(getOrderDetail.rejected, (state, action) => {
      state.orderLoading = false;
    });
  },
});

export const { isOrderLoading } = orderSlice.actions;
export default orderSlice.reducer;
