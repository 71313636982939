import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Error = ({ setTitle }) => {
  useEffect(() => {
    setTitle("Page Not Found");
  }, [setTitle]);

  const navigate = useNavigate();
  return (
    <div className="container">
      <div className="app-container">
        <main className="maintenance-container flex-center">
          <figure className="maintenance-bg">
            <img src={require("../../assets/icons/erro-bg.svg").default} alt="error" />
          </figure>
          <header className="maintenance-text">
            <h1 className="maintenance-title">Opps!!!</h1>
            <h2 className="maintenance-subtitle">
              This page you are looking for could not be found.
            </h2>
          </header>

          <button onClick={() => navigate("/")} className="btn-primary">
            Go Back to Home
          </button>
        </main>
      </div>
    </div>
  );
};

export default Error;
