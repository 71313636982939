import { userProfile } from "assets/images";
import notificationProf from "../assets/images/profile-bg.webp";
import {
  amazon,
  apple,
  chess,
  education,
  flipcart,
  lenscart,
  myntra,
  puzzle,
  savings,
  taxes,
  walnut,
  xp,
} from "assets/icons";

// export const privileges = {
//   head: ["read", "write", "delete"],
//   userManagement: ["read", "write", "delete"],
//   cms_management: ["read", "write", "delete"],
//   glossaryManagement: ["read", "write", "delete"],
//   round_management: ["read", "write", "delete"],
//   daily_quiz_management: ["read", "write", "delete"],
//   leadboard_management: ["read", "write", "delete"],
//   notifications_management: ["read", "write", "delete"],
//   coupon_management: ["read", "write", "delete"],
//   reward_management: ["read", "write", "delete"],
//   feedback_management: ["read", "write", "delete"],
//   scorecard_management: ["read", "write", "delete"],
//   email_management: ["read", "write", "delete"],
//   userManagement: ["read", "write", "delete"],
//   userManagement: ["read", "write", "delete"],
//   cmsManagement: ["read", "write", "delete"],
//   settingManagement: ["read", "write", "delete"]
// };

export const privileges = {
  head: ["read", "write", "delete"],
  userManagement: ["read", "write", "delete"],
  templateManagement: ["read", "write", "delete"],
  settingManagement: ["read", "write", "delete"],
};

// export const Modules = [
//   "userManagement",
//   "cms_management",
//   "glossaryManagement",
//   "round_management",
//   "daily_quiz_management",
//   "leadboard_management",
//   "notifications_management",
//   "coupon_management",
//   "reward_management",
//   "feedback_management",
//   "scorecard_management",
//   "email_management",
//   "userManagement",
// ];

export const Modules = [
  "settingManagement",
  "userManagement",
  "templateManagement",
  "pageManagement",
  "addressManagement",
];

// Dashboard Leadboard
export const DashLeadBoard = [
  {
    id: 1,
    fullName: "Sunil Joshi",
    location: "Jodhpur, India",
    membership: "active",
    walnuts: "25k",
    xp: "50k",
  },
  {
    id: 2,
    fullName: "Manohar Lal",
    location: "Surat, India",
    membership: "inactive",
    walnuts: "25k",
    xp: "50k",
  },
  {
    id: 3,
    fullName: "Manish Kumar",
    location: "Chennai, India",
    membership: "inactive",
    walnuts: "25k",
    xp: "50k",
  },
  {
    id: 4,
    fullName: "Hitesh Purohit",
    location: "Banglore, India",
    membership: "active",
    walnuts: "25k",
    xp: "50k",
  },
];

// Notification Data
export const notifications = [
  {
    id: 1,
    img: notificationProf,
    title: "Roman Joined The Team !",
    message: "Rom Rom Bhaiyo!",
  },
  {
    id: 2,
    img: notificationProf,
    title: "New Message Received !",
    message: "Salama sent you new message.",
  },
  {
    id: 3,
    img: notificationProf,
    title: "Roman Joined The Team !",
    message: "Congratulate him.",
  },

  {
    id: 1,
    img: notificationProf,
    title: "Roman Joined The Team !",
    message: "Congratulate him",
  },
  {
    id: 2,
    img: notificationProf,
    title: "New Message Received !",
    message: "Salama sent you new message.",
  },
  {
    id: 3,
    img: notificationProf,
    title: "Roman Joined The Team !",
    message: "Congratulate him.",
  },
  {
    id: 1,
    img: notificationProf,
    title: "Roman Joined The Team !",
    message: "Congratulate him",
  },
  {
    id: 2,
    img: notificationProf,
    title: "New Message Received !",
    message: "Salama sent you new message.",
  },
  {
    id: 3,
    img: notificationProf,
    title: "Roman Joined The Team !",
    message: "Congratulate him.",
  },
];

// dashboard content data //
// Card Data <<--------------------
export const cards = [
  {
    id: 1,
    icon: "fa-solid fa-user",
    title: "total users",
    total: "3.6m+",
    precentage: "+10.8%",
  },
  {
    id: 2,
    icon: "fa-solid fa-layer-group",
    title: "total modules",
    total: "260+",
    precentage: "+2",
  },
  {
    id: 3,
    icon: "fa-solid fa-square-question",
    title: "total questions",
    total: "50k",
    precentage: "+5.8%",
  },
  {
    id: 4,
    icon: "fa-solid fa-users",
    title: "total active users",
    total: "2.5m+",
    precentage: "-3.8%",
  },
];

// graph data <<---------------

export const Graphstatistics = [
  ["Month", "Subscription", "Active User"],
  ["Jan", 1000, 400],
  ["Feb", 1170, 460],
  ["Mar", 660, 1120],
  ["Apr", 1030, 540],
];

export const GraphOptions = {
  // title: "statistics",
  curveType: "function",
  // chartArea: { top: 0, left: 0, height: "100%", width: "100%" },
  legend: { position: "top", maxLines: 4, alignment: "center" },
  colors: ["#0076BB", "#238F51"],
  pointSize: 5,
  series: {
    0: { pointShape: "circle" },
    // 1: { pointShape: 'triangle' },
    // 2: { pointShape: 'square' },
    // 3: { pointShape: 'diamond' },
    // 4: { pointShape: 'star' },
    // 5: { pointShape: 'polygon' }
  },
  textStyle: {
    fontName: "DM Sans",
    fontSize: 18,
    bold: true,
    italic: true,
    // The color of the text.
    color: "#871b47",
    // The color of the text outline.
    auraColor: "#d799ae",
    // The transparency of the text.
    opacity: 0.8,
  },
  chartArea: { width: "80%", height: "80%" },
};

export const GeoChartdata = [
  ["State Code", "State", "overview"],
  // ["IN-AN", "Andaman and Nicobar Islands", 12200],
  ["IN-AP", "Andhra Pradesh", 13000],
  ["IN-AR", "Arunachal Pradesh", 14004],
  ["IN-AS", "Assam", 9000],
  ["IN-BR", "Bihar", 10000],
  ["IN-CH", "Chandigarh", 2000],
  ["IN-CT", "Chhattisgarh", 5000],
  // ["IN-DN", "Dadra and Nagar Haveli", 34],
  // ["IN-DD", "Daman and Diu", 34],
  // ["IN-DL", "Delhi", 34],
  // ["IN-GA", "Goa", 76],
  ["IN-GJ", "Gujarat", 12204],
  // ["IN-HR", "Haryana", 14],
  // ["IN-HP", "Himachal Pradesh", 34],
  // ["IN-JK", "Jammu and Kashmir", 34],
  // ["IN-JH", "Jharkhand", 34],
  // ["IN-KA", "Karnataka", 34],
  ["IN-KL", "Kerala", 8000],
  // ["IN-LA", "Ladakh", 34],
  // ["IN-LD", "Lakshadweep", 34],
  ["IN-MP", "Madhya Pradesh", 5999],
  ["IN-MH", "Maharashtra", 9004],
  // ["IN-MN", "Manipur", 34],
  // ["IN-ML", "Meghalaya", 34],
  // ["IN-MZ", "Mizoram", 23],
  // ["IN-NL", "Nagaland", 34],
  // ["IN-OR", "Odisha", 34],
  // ["IN-PY", "Puducherry", 34],
  ["IN-PB", "Punjab", 8993],
  ["IN-RJ", "Rajasthan", 15000],
  // ["IN-SK", "Sikkim", 34],
  ["IN-TN", "Tamil Nadu", 13000],
  // ["IN-TG", "Telangana", 34],
  // ["IN-TR", "Tripura", 34],
  ["IN-UP", "Uttar Pradesh", 4004],
  // ["IN-UT", "Uttarakhand", 34],
  // ["IN-WB", "West Bengal", 54],
];

export const GeoChartoptions = {
  region: "IN",
  resolution: "provinces",
  colorAxis: {
    colors: [
      "#52BFFF",
      "#FFECAA",
      "#74DDA1",
      "#BFBFBF",
      "#EF959C",
      "#B1FFF1",
      "#FFDCA8",
    ],
  },
  is3D: true,
  // chartArea: { top: 0, left: 0, height: "100%", width: "100%" },
  textStyle: { color: "#FF0000" },
  showColorCode: true,
};

export const differGender = {
  male: 60,
  female: 40,
};

export const userList = [
  {
    id: "45904",
    status: true,
    fullName: "Sunil Joshi",
    contactsInfo: {
      email: "jsunil@yopmail.com",
      number: "9684555555",
    },
    acc: "free",
    subscribe: true,
    role: "user",
    IdCreated: "26 Jan 2020",
    lastLogin: "4 months ago",
  },
  {
    id: "459045",
    status: false,
    fullName: "Arjun Narayan",
    contactsInfo: {
      email: "arjun888@yopmail.com",
      number: "9684539455",
    },
    acc: "premium",
    subscribe: "false",
    role: "user",
    IdCreated: "11 April 2017",
    lastLogin: "14 days ago",
  },
  {
    id: "45903",
    status: true,
    fullName: "Himanshu Kohli",
    contactsInfo: {
      email: "himanshu@yopmail.com",
      number: "6845558799",
    },
    acc: "premium",
    subscribe: "false",
    role: "user",
    IdCreated: "11 May 2021",
    lastLogin: "Two week ago",
  },
  {
    id: "45902",
    status: true,
    fullName: "Punit Manawat",
    contactsInfo: {
      email: "punitmanawat@yopmail.com",
      number: "9684555555",
    },
    acc: "free",
    subscribe: true,
    role: "admin",
    IdCreated: "23 May 2018",
    lastLogin: "4 months ago",
  },
  {
    id: "459041",
    status: true,
    fullName: "Hira lal",
    contactsInfo: {
      email: "jsunil@yopmail.com",
      number: "7868554444",
    },
    acc: "free",
    role: "user",
    subscribe: true,
    IdCreated: "10 May 2024",
    lastLogin: "2 months ago",
  },
];

export const toolbarOptions = {
  toolbar: [
    [{ font: [] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", "underline", "strike"],
    [{ color: [] }, { background: [] }],
    [{ script: "sub" }, { script: "super" }],
    ["blockquote", "code-block"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
    ["link"],
    ["clean"],
  ],
};

export const TemplateFilters = [
  { label: "Email", value: "email" },
  { label: "Message", value: "sms" },
  { label: "Notification", value: "notification" },
];

export const appUserFilters = [
  { name: "All", value: "" },
  { name: "Free", value: "free" },
  { name: "Premium", value: "premium" },
];

export const categoryFilters = [
  { label: "Skills", value: "skill" },
  { label: "Products", value: "product" },
];

export const SortItems = [
  { label: "Newest", value: "desc" },
  { label: "Oldest", value: "asc" },
];

export const FilterGender = [
  { label: "Male", value: "male" },
  { label: "Female", value: "female" },
  { label: "Other", value: "other" },
];

/*  User Module _________________
    ---------->> Application User
*/

export const App_User_Tab_NavList = [
  {
    title: "General Details",
    icon: "fa-regular fa-user",
    tab: "GeneralDetails",
    link: (id) => `/users/application-users/${id}`,
  },
  // {
  //   title: "Learning Skills",
  //   icon: "fa-sharp fa-regular fa-paintbrush-pencil",
  //   tab: "LearningSkills",
  //   link: (id) => `/users/application-users/${id}/skills`,
  // },
  {
    title: "Point",
    icon: "fa-regular fa-hand-holding-heart",
    tab: "Points",
    link: (id) => `/users/application-users/${id}/points`,
  },
  // {
  //   title: "Rewards",
  //   icon: "fa-regular fa-medal",
  //   tab: "Rewards",
  //   link: (id) => `/users/application-users/${id}/rewards`,
  // },
  // {
  //   title: "Orders",
  //   icon: "fa-regular fa-bag-shopping",
  //   tab: "Orders",
  //   link: (id) => `/users/application-users/${id}/orders`,
  // },
  // {
  //   title: "Bookmarks",
  //   icon: "fa-regular fa-bookmark",
  //   tab: "Bookmarks",
  //   link: (id) => `/users/application-users/${id}/bookmarks`,
  // },
  {
    title: "User's Friends",
    icon: "fa-regular fa-user-group",
    tab: "UserFriends",
    link: (id) => `/users/application-users/${id}/friends`,
  },
];

// Application user schema
export const appUserSchema = {
  _id: "45904",
  first_name: "Sunil",
  last_name: "Joshi",
  email: { value: "jsunil@yopmail.com" },
  // number: "9684555555",
  dp: userProfile,
  status: true,
  acc: "free",
  subscription: false,
  // role: "user",
  num: 1,
  IdCreated: new Date("26 Jan 2023"),
  lastLogin: new Date("20 Jun 2023"),
  address: "Gujrat, India",
  badges: 45,
  trophy: 5,
  referers: 120,
  strikes: 10,
  lifes: 5,
  walnuts: 25000,
  exP: 50000,
};

// Previous Skills
export const prevSkills = [
  {
    image: savings,
    title: "Savings & Investment",
    description:
      "Lorem ipsum dolor sit amet consectetur. Vel tempor semper arcu accumsan.",
    levels: [
      { title: "level 1", status: "success" },
      { title: "level 2", status: "inprogress" },
      { title: "level 3", status: "pending" },
    ],
  },
  {
    image: education,
    title: "Trade Stock Options",
    description:
      "Lorem ipsum dolor sit amet consectetur. Vel tempor semper arcu accumsan.",
    levels: [
      { title: "level 1", status: "success" },
      { title: "level 2", status: "inprogress" },
      { title: "level 3", status: "pending" },
    ],
  },
  {
    image: taxes,
    title: "Invest in Index Funds",
    description:
      "Lorem ipsum dolor sit amet consectetur. Vel tempor semper arcu accumsan.",
    levels: [
      { title: "level 1", status: "success" },
      { title: "level 2", status: "inprogress" },
      { title: "level 3", status: "pending" },
    ],
  },
  {
    image: savings,
    title: "Savings & Investment",
    description:
      "Lorem ipsum dolor sit amet consectetur. Vel tempor semper arcu accumsan.",
    levels: [
      { title: "level 1", status: "success" },
      { title: "level 2", status: "inprogress" },
      { title: "level 3", status: "pending" },
    ],
  },
  {
    image: education,
    title: "Trade Stock Options",
    description:
      "Lorem ipsum dolor sit amet consectetur. Vel tempor semper arcu accumsan.",
    levels: [
      { title: "level 1", status: "success" },
      { title: "level 2", status: "inprogress" },
      { title: "level 3", status: "pending" },
    ],
  },
  {
    image: taxes,
    title: "Invest in Index Funds",
    description:
      "Lorem ipsum dolor sit amet consectetur. Vel tempor semper arcu accumsan.",
    levels: [
      { title: "level 1", status: "success" },
      { title: "level 2", status: "inprogress" },
      { title: "level 3", status: "pending" },
    ],
  },
];

// Points ----------
export const USER_PROFILE_POINTS_TABS = [
  { title: "Walnuts", icon: walnut, tab: "walnut" },
  // { title: "Streaks", icon: fire, tab: "streaks" },
  { title: "XP Points", icon: xp, tab: "xp" },
  // { title: "Life", icon: heart, tab: "lifes" },
];

// walnut list
export const WALNUTS_DATA = [
  {
    image: savings,
    title: "Savings & Investment",
    description:
      "Lorem ipsum dolor sit amet consectetur. Vel tempor semper arcu accumsan.",
    walnut_points: 250,
    time: "5h ago",
  },
  {
    image: education,
    title: "Trade Stock Options",
    description:
      "Lorem ipsum dolor sit amet consectetur. Vel tempor semper arcu accumsan.",
    walnut_points: 250,
    time: "5h ago",
  },
  {
    image: taxes,
    title: "Invest in Index Funds",
    description:
      "Lorem ipsum dolor sit amet consectetur. Vel tempor semper arcu accumsan.",
    walnut_points: 250,
    time: "5h ago",
  },
  {
    image: savings,
    title: "Savings & Investment",
    description:
      "Lorem ipsum dolor sit amet consectetur. Vel tempor semper arcu accumsan.",
    walnut_points: 250,
    time: "5h ago",
  },
  {
    image: education,
    title: "Trade Stock Options",
    description:
      "Lorem ipsum dolor sit amet consectetur. Vel tempor semper arcu accumsan.",
    walnut_points: 250,
    time: "5h ago",
  },
  {
    image: taxes,
    title: "Invest in Index Funds",
    description:
      "Lorem ipsum dolor sit amet consectetur. Vel tempor semper arcu accumsan.",
    walnut_points: 250,
    time: "5h ago",
  },
];

// steak list
export const STREAK_DATA = [
  {
    title: "20 May, 2024 - Monday",
    description:
      "Lorem ipsum dolor sit amet consectetur. Vel tempor semper arcu accumsan.",
    streak_points: 1,
    time: "5h ago",
  },
  {
    title: "21 May, 2024 - Tuesday",
    description:
      "Lorem ipsum dolor sit amet consectetur. Vel tempor semper arcu accumsan.",
    streak_points: 1,
    time: "5h ago",
  },
  {
    title: "22 May, 2024 - Wednesday",
    description:
      "Lorem ipsum dolor sit amet consectetur. Vel tempor semper arcu accumsan.",
    streak_points: 0,
    time: "5h ago",
  },
  {
    title: "23 May, 2024 - Thrusday",
    description:
      "Lorem ipsum dolor sit amet consectetur. Vel tempor semper arcu accumsan.",
    streak_points: 1,
    time: "5h ago",
  },
  {
    title: "24 May, 2024 - Friday",
    description:
      "Lorem ipsum dolor sit amet consectetur. Vel tempor semper arcu accumsan.",
    streak_points: 0,
    time: "5h ago",
  },
  {
    title: "25 May, 2024 - Saturday",
    description:
      "Lorem ipsum dolor sit amet consectetur. Vel tempor semper arcu accumsan.",
    streak_points: 1,
    time: "5h ago",
  },
];

// xp points list
export const XP_POINTS_DATA = [
  {
    image: savings,
    title: "Savings & Investment",
    description:
      "Lorem ipsum dolor sit amet consectetur. Vel tempor semper arcu accumsan.",
    xp_points: 350,
    time: "5h ago",
  },
  {
    image: education,
    title: "Trade Stock Options",
    description:
      "Lorem ipsum dolor sit amet consectetur. Vel tempor semper arcu accumsan.",
    xp_points: 250,
    time: "5h ago",
  },
  {
    image: taxes,
    title: "Invest in Index Funds",
    description:
      "Lorem ipsum dolor sit amet consectetur. Vel tempor semper arcu accumsan.",
    xp_points: 350,
    time: "5h ago",
  },
  {
    image: savings,
    title: "Savings & Investment",
    description:
      "Lorem ipsum dolor sit amet consectetur. Vel tempor semper arcu accumsan.",
    xp_points: 250,
    time: "5h ago",
  },
  {
    image: education,
    title: "Trade Stock Options",
    description:
      "Lorem ipsum dolor sit amet consectetur. Vel tempor semper arcu accumsan.",
    xp_points: 250,
    time: "5h ago",
  },
  {
    image: taxes,
    title: "Invest in Index Funds",
    description:
      "Lorem ipsum dolor sit amet consectetur. Vel tempor semper arcu accumsan.",
    xp_points: 450,
    time: "5h ago",
  },
];

// lifes list
export const LIFES_DATA = [
  {
    image: savings,
    title: "Savings & Investment",
    description:
      "Lorem ipsum dolor sit amet consectetur. Vel tempor semper arcu accumsan.",
    lifes: -5,
    time: "5h ago",
  },
  {
    image: education,
    title: "Trade Stock Options",
    description:
      "Lorem ipsum dolor sit amet consectetur. Vel tempor semper arcu accumsan.",
    lifes: -3,
    time: "5h ago",
  },
  {
    image: taxes,
    title: "Invest in Index Funds",
    description:
      "Lorem ipsum dolor sit amet consectetur. Vel tempor semper arcu accumsan.",
    lifes: -1,
    time: "5h ago",
  },
  {
    image: savings,
    title: "Savings & Investment",
    description:
      "Lorem ipsum dolor sit amet consectetur. Vel tempor semper arcu accumsan.",
    lifes: -1,
    time: "5h ago",
  },
  {
    image: education,
    title: "Trade Stock Options",
    description:
      "Lorem ipsum dolor sit amet consectetur. Vel tempor semper arcu accumsan.",
    lifes: -3,
    time: "5h ago",
  },
  {
    image: taxes,
    title: "Invest in Index Funds",
    description:
      "Lorem ipsum dolor sit amet consectetur. Vel tempor semper arcu accumsan.",
    lifes: -4,
    time: "5h ago",
  },
];

// reward management
// export const rewardList = [
//   {
//     _id: "6567583e86ub968yn83ke54",
//     brand: "Apple",
//     logo: "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/1001.jpg",
//     offer: "Save up to ₹1999",
//     date: 1643723400,
//     purchase_points: 100,
//     status: "active",
//   },
// ];
export const rewardList = [
  {
    _id: "reward-1",
    brand: "Apple",
    logo: apple,
    offer: "Get 10% off on all purchases",
    date: 1643723400,
    purchase_points: 500,
    status: "active",
  },
  {
    _id: "reward-22",
    brand: "Amazon",
    logo: amazon,
    offer: "Get 20% off on all purchases",
    date: 1643723400,
    purchase_points: 500,
    status: "active",
  },
  {
    _id: "reward-2",
    brand: "Flipkart",
    logo: flipcart,
    offer: "Buy one get one free on all electronics",
    date: 1643723410,
    purchase_points: 300,
    status: "inactive",
  },
  // {
  //   _id: "reward-3",
  //   brand: "Ola",
  //   logo: "https://example.com/ola-logo.png",
  //   offer: "Get 50% off on all rides",
  //   date: 1643723420,
  //   purchase_points: 200,
  //   status: "active",
  // },
  // {
  //   _id: "reward-4",
  //   brand: "Swiggy",
  //   logo: "https://example.com/swiggy-logo.png",
  //   offer: "Get 30% off on all food orders",
  //   date: 1643723430,
  //   purchase_points: 400,
  //   status: "active",
  // },
  // {
  //   _id: "reward-5",
  //   brand: "Paytm",
  //   logo: "https://example.com/paytm-logo.png",
  //   offer: "Get 10% cashback on all transactions",
  //   date: 1643723440,
  //   purchase_points: 250,
  //   status: "inactive",
  // },
  // {
  //   _id: "reward-6",
  //   brand: "Zomato",
  //   logo: "https://example.com/zomato-logo.png",
  //   offer: "Get 25% off on all food orders",
  //   date: 1643723450,
  //   purchase_points: 350,
  //   status: "active",
  // },
  // {
  //   _id: "reward-7",
  //   brand: "Uber",
  //   logo: "https://example.com/uber-logo.png",
  //   offer: "Get 20% off on all rides",
  //   date: 1643723460,
  //   purchase_points: 450,
  //   status: "active",
  // },
  {
    _id: "reward-8",
    brand: "Myntra",
    logo: myntra,
    offer: "Get 30% off on all fashion products",
    date: 1643723470,
    purchase_points: 300,
    status: "inactive",
  },
  // {
  //   _id: "reward-9",
  //   brand: "BookMyShow",
  //   logo: "https://example.com/bookmyshow-logo.png",
  //   offer: "Get 20% off on all movie tickets",
  //   date: 1643723480,
  //   purchase_points: 250,
  //   status: "active",
  // },
  {
    _id: "reward-10",
    brand: "Lenscart",
    logo: lenscart,
    offer: "Buy 1 Get 1 Free",
    date: 1643723490,
    purchase_points: 400,
    status: "active",
  },
];

export const orderList = [
  {
    _id: "#45904",
    user_id: "#459045554654378656543545",
    product: "Chess Game",
    image: chess,
    payment_method: "Cash On Delivery",
    status: "delivered",
    order_date: 1643723400,
    price: "₹29,999",
  },
  {
    _id: "#45904",
    user_id: "#459045554654378656543545",
    product: "Puzzle Game",
    image: puzzle,
    payment_method: "Cash On Delivery",
    status: "in transit",
    order_date: 1643723400,
    price: "₹29,999",
  },
  {
    _id: "#45904",
    user_id: "#459045554654378656543545",
    product: "Chess Game",
    payment_method: "Cash On Delivery",
    image: chess,
    status: "cancel",
    order_date: 1643723400,
    price: "₹29,999",
  },
  {
    _id: "#45904",
    user_id: "#459045554654378656543545",
    product: "Chess Game",
    payment_method: "Online",
    image: chess,
    status: "pending",
    order_date: 1643723400,
    price: "₹29,999",
  },
];
// referral friends
export const friendsList = [
  {
    _id: "66b1e0897298e41f715c7ac9",
    user_name: "john doe",
    avatar:
      "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/1001.jpg",
    email_id: "john.doe@example.com",
    reg_date: 1643723400,
    referral_pt: 100,
    status: "active",
  },
  {
    _id: "66b1e0897298e41f715c7ac7",
    user_name: "jane smith",
    avatar:
      "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/1001.jpg",
    email_id: "jane.smith@example.com",
    reg_date: 1643723410,
    referral_pt: 50,
    status: "inactive",
  },
  {
    _id: "66b1e0897298e41f715c7ac5",
    user_name: "bob johnson",
    avatar:
      "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/1001.jpg",
    email_id: "bob.johnson@example.com",
    reg_date: 1643723420,
    referral_pt: 200,
    status: "active",
  },
  {
    _id: "66b1e0897298e41f715c7ac3",
    user_name: "alice brown",
    email_id: "alice.brown@example.com",
    avatar:
      "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/1001.jpg",
    reg_date: 1643723430,
    referral_pt: 150,
    status: "active",
  },
  {
    _id: "66b1e0897298e41f715c7ac1",
    user_name: "mike davis",
    email_id: "mike.davis@example.com",
    avatar:
      "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/1001.jpg",
    reg_date: 1643723440,
    referral_pt: 250,
    status: "inactive",
  },
  {
    _id: "66b1e0897298e41f715c7abf",
    user_name: "emily taylor",
    email_id: "emily.taylor@example.com",
    avatar:
      "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/1001.jpg",
    reg_date: 1643723450,
    referral_pt: 300,
    status: "active",
  },
  {
    _id: "66b1e0897298e41f715c7abe",
    user_name: "david lee",
    email_id: "david.lee@example.com",
    avatar:
      "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/1001.jpg",
    reg_date: 1643723460,
    referral_pt: 100,
    status: "inactive",
  },
  {
    _id: "66b1e0897298e41f715c7abc",
    user_name: "sarah kim",
    email_id: "sarah.kim@example.com",
    avatar:
      "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/1001.jpg",
    reg_date: 1643723470,
    referral_pt: 200,
    status: "active",
  },
  {
    _id: "66b1e0897298e41f715c7aba",
    user_name: "kevin white",
    email_id: "kevin.white@example.com",
    avatar:
      "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/1001.jpg",
    reg_date: 1643723480,
    referral_pt: 150,
    status: "active",
  },
  {
    _id: "66b1e0897298e41f715c7ab8",
    user_name: "olivia martin",
    email_id: "olivia.martin@example.com",
    avatar:
      "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/1001.jpg",
    reg_date: 1643723490,
    referral_pt: 250,
    status: "inactive",
  },
];

export const levels = [
  { label: "Easy", value: "easy" },
  { label: "Medium", value: "medium" },
  { label: "Hard", value: "hard" },
  { label: "Expert", value: "expert" },
  { label: "Master", value: "master" },
  { label: "Extreme", value: "extreme" },
];

export const questionTypes = [
  { label: "Short Answer", value: "shortAnswer" },
  { label: "Multiple Choice", value: "multipleChoice" },
  { label: "Checkbox", value: "checkbox" },
  { label: "Dropdown", value: "dropdown" },
];

export const recurringCycleOption = [
  { label: "Daily", value: "daily" },
  { label: "Weekly", value: "weekly" },
  { label: "Biweekly", value: "biweekly" },
  { label: "Monthly", value: "monthly" },
  { label: "Quarterly", value: "quarterly" },
  { label: "Annually", value: "annually" },
]

export const planType = [
  { label: "Trial", value: "trial" },
  { label: "Basic", value: "basic" },
  { label: "Standard", value: "standard" },
]