import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { AddNumField } from "helper/helper";
import { isPolicyLoading } from "./policiesSlice";

export const getPolicyList = createAsyncThunk(
  "cmsPolicy/get-policy-list",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      const response = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/page/page-list?page=${data.page}&limit=${data.limit}&sort=${data?.sort ? data?.sort : ""}&search=${data.search}`,
      });

      if (response.status === 200) {
        if (response?.data?.data?.length > 0) {
          const res = await AddNumField(
            response?.data?.data?.[0],
            data?.page * data?.limit
          );
          return fulfillWithValue(res);
        } else {
          const modPage = Math.max(data.page - 1, 0);
          const responseAgain = await axios({
            method: "GET",
            url: `${process.env.REACT_APP_AUTH_URL}/admin/page/page-list?page=${data.page}&limit=${data.limit}&sort=${data?.sort ? data?.sort : ""}&search=${data.search}`,
          });
          if (responseAgain.status === 200) {
            const resAgain = await AddNumField(
              responseAgain?.data,
              modPage * data?.limit
            );
            return fulfillWithValue(resAgain);
          } else {
            toast.error(responseAgain?.data?.messages);
            return rejectWithValue();
          }
        }
      } else {
        // toast.error(response?.data?.message);
        return rejectWithValue();
      }
    } catch (err) {
      return rejectWithValue();
    }
  }
);

export const addPolicy = createAsyncThunk(
  "cmsPolicy/add-policy",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      dispatch(isPolicyLoading(true));
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/page/create-page`,
        data: data,
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.status === 200) {
        dispatch(isPolicyLoading(false));
        toast.success(response?.data?.message);
        return response?.data?.status;
      } else {
        dispatch(isPolicyLoading(false));
        toast.error(response?.data?.message);
      }
    } catch (err) {
      dispatch(isPolicyLoading(false));
      toast.error(err);
    }
  }
);

export const deletePolicy = createAsyncThunk(
  "cmsPolicy/delete-policy",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      dispatch(isPolicyLoading(true));
      const response = await axios({
        method: "DELETE",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/page/delete-page`,
        data: data?.send,
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.status === 200) {
        dispatch(isPolicyLoading(false));

        dispatch(
          getPolicyList({
            page: data?.page,
            limit: data?.limit,
            sort: data?.sort,
            search: data?.search,
          })
        );
        toast.success(response?.data?.message);
        return response?.data?.status;
      } else {
        dispatch(isPolicyLoading(false));
        toast.error(response?.data?.message);
      }
    } catch (err) {
      dispatch(isPolicyLoading(false));
      toast.error(err);
    }
  }
);

export const getPolicyDetail = createAsyncThunk(
  "cmsPolicy/get-policy-details",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      const response = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/page/page-detail/${data?.slug}`,
      });
      if (response.status === 200) {
        return fulfillWithValue(response?.data);
      } else {
        toast.error(response?.data?.message);
        return rejectWithValue();
      }
    } catch (err) {
      toast.error(err);
      return rejectWithValue();
    }
  }
);

export const updatePolicy = createAsyncThunk(
  "cmsPolicy/update-policy",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      dispatch(isPolicyLoading(true));
      const response = await axios({
        method: "PUT",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/page/update-detail/${data?.slug}`,
        data: data?.send,
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.status === 200) {
        dispatch(isPolicyLoading(false));
        toast.success(response?.data?.message);
        return response?.data?.status;
      } else {
        dispatch(isPolicyLoading(false));
        toast.error(response?.data?.message);
      }
    } catch (err) {
      dispatch(isPolicyLoading(false));
      toast.error(err);
    }
  }
);
