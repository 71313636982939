import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { isProfileToggle } from "./profileSlice";
import { options } from "helper/helper";
import { defaultLogout } from "modules/auth/redux/authSlice";

export const getProfileDetails = createAsyncThunk(
  "profile/profile-details",
  async (data, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/get-detail`,
      });

      if (response.status === 200) {
        return fulfillWithValue(response.data);
      } else {
        toast.error(response?.data?.message);
        return rejectWithValue();
      }
    } catch (error) {
      toast.error(error);
      return rejectWithValue();
    }
  }
);

export const changePassword = createAsyncThunk(
  "profile/change-password",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    dispatch(isProfileToggle(true));
    try {
      const response = await axios({
        method: "PUT",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/change-password`,
        headers: {
          "Content-type": "application/json",
        },
        data,
      });
      if (response.status === 200) {
        toast.success(response?.data?.message);
        dispatch(isProfileToggle(false));
        return response?.data?.status;
      } else {
        toast.error(response.data.message);
        dispatch(isProfileToggle(false));
      }
    } catch (error) {
      dispatch(isProfileToggle(false));
      toast.error(error);
    }
  }
);

export const updateProfile = createAsyncThunk(
  "profile/update-profile",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      dispatch(isProfileToggle(true));
      const response = await axios({
        method: "PUT",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/update-detail`,
        data,
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.status === 200) {
        dispatch(isProfileToggle(false));
        toast.success(response?.data?.message);
        dispatch(getProfileDetails());
        return response?.data?.status;
      } else {
        dispatch(isProfileToggle(false));
        toast.error(response?.data?.message);
      }
    } catch (err) {
      dispatch(isProfileToggle(false));
      toast.error(err);
    }
  }
);

export const changeProfilePicture = createAsyncThunk(
  "profile/change-profile-picture",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {


    try {
      dispatch(isProfileToggle(true));
      const response = await axios({
        method: "PUT",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/change-picture`,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: data,
      });

      if (response.status === 200) {
        dispatch(getProfileDetails());
        dispatch(isProfileToggle(false));
        toast.success(response?.data?.message);
        return response?.data?.status;
      } else {
        dispatch(isProfileToggle(false));
        toast.error(response?.data?.message);
      }
    } catch (err) {
      dispatch(isProfileToggle(false));
      toast.error(err);
    }
  }
);

export const getCountryList = createAsyncThunk(
  "public/country-list",
  async (data, { fulfillWithValue, rejectWithValue, dispatch }) => {
    try {
      const response = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_AUTH_URL}/public/country-list`,
      })
      if (response.status === 200) {
        return fulfillWithValue(response.data);
      } else {
        // toast.error(response?.data?.message);
        return rejectWithValue();
      }
    } catch (error) {
      dispatch(isProfileToggle(false));
      toast.error(error);
    }
  }
)

export const reqEmailOtp = createAsyncThunk(
  "/public/send-otp/mail",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      dispatch(isProfileToggle(true));
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_AUTH_URL}/public/send-otp/mail`,
        data: data,
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.status === 200) {
        dispatch(isProfileToggle(false));
        toast.success(response?.data?.message, options);
        return response?.data?.status;
      } else {
        dispatch(isProfileToggle(false));
        toast.error(response?.data?.message, options);
      }
    } catch (error) {
      dispatch(isProfileToggle(false));
      toast.error(error, options);
    }
  }
)

export const reqPhoneOtp = createAsyncThunk(
  "/public/send-otp/message",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      dispatch(isProfileToggle(true));
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_AUTH_URL}/public/send-otp/message`,
        data: data,
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.status === 200) {
        dispatch(isProfileToggle(false));
        toast.success(response?.data?.message, options);
        return response?.data?.status;
      } else {
        dispatch(isProfileToggle(false));
        toast.error(response?.data?.message, options);
      }
    } catch (error) {
      dispatch(isProfileToggle(false));
      toast.error(error, options);
    }
  }
)

export const verifyEmail = createAsyncThunk(
  "admin/verify-email",
  async (data, { fulfillWithValue, rejectWithValue, dispatch }) => {
    try {
      dispatch(isProfileToggle(true))
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/verify-email`,
        data: data,
        headers: {
          "Content-Type": "application/json",
        }
      })
      if (response.status === 200) {
        dispatch(isProfileToggle(false));
        dispatch(getProfileDetails())
        toast.success(response?.data?.message, options);
        return response?.data?.status;
      } else {
        dispatch(isProfileToggle(false));
        toast.error(response?.data?.message, options);
      }
    } catch (error) {
      dispatch(isProfileToggle(false));
      toast.error(error, options);
    }
  }
)

export const verifyPhone = createAsyncThunk(
  "admin/verify-phone",
  async (data, { fulfillWithValue, rejectWithValue, dispatch }) => {
    try {
      dispatch(isProfileToggle(true))
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/verify-phone`,
        data: data,
        headers: {
          "Content-Type": "application/json",
        }
      })
      if (response.status === 200) {
        dispatch(isProfileToggle(false));
        dispatch(getProfileDetails())
        toast.success(response?.data?.message, options);
        return response?.data?.status;
      } else {
        dispatch(isProfileToggle(false));
        toast.error(response?.data?.message, options);
      }
    } catch (error) {
      dispatch(isProfileToggle(false));
      toast.error(error, options);
    }
  }
)

export const updateNewPhone = createAsyncThunk(
  "admin/update-phone",
  async (data, { fulfillWithValue, rejectWithValue, dispatch }) => {
    try {
      dispatch(isProfileToggle(true))
      const response = await axios({
        method: "PUT",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/update-phone`,
        data: data,
        headers: {
          "Content-Type": "application/json",
        }
      })
      if (response.status === 200) {
        dispatch(isProfileToggle(false));
        dispatch(getProfileDetails())
        toast.success(response?.data?.message, options);
        return response?.data?.status;
      } else {
        dispatch(isProfileToggle(false));
        toast.error(response?.data?.message, options);
      }
    } catch (error) {
      dispatch(isProfileToggle(false));
      toast.error(error, options);
    }
  }
)

export const updateNewEmail = createAsyncThunk(
  "admin/update-email",
  async (data, { fulfillWithValue, rejectWithValue, dispatch }) => {
    try {
      dispatch(isProfileToggle(true))
      const response = await axios({
        method: "PUT",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/update-email`,
        data: data,
        headers: {
          "Content-Type": "application/json",
        }
      })
      if (response.status === 200) {
        dispatch(isProfileToggle(false));
        dispatch(getProfileDetails())
        toast.success(response?.data?.message, options);
        return response?.data?.status;
      } else {
        dispatch(isProfileToggle(false));
        toast.error(response?.data?.message, options);
      }
    } catch (error) {
      dispatch(isProfileToggle(false));
      toast.error(error, options);
    }
  }
)

export const giveFeedback = createAsyncThunk(
  "admin/feedback/give-feedback",
  async (data, { fulfillWithValue, rejectWithValue, dispatch }) => {
    try {
      dispatch(isProfileToggle(true))
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/feedback/give-feedback`,
        data: data,
        headers: {
          "Content-Type": "application/json"
        }
      })
      if (response.status === 200) {
        dispatch(isProfileToggle(false))
        toast.success(response?.data?.message)
        return response?.data
      } else {
        dispatch(isProfileToggle(false))
        toast.error(response?.data?.message)
        return response?.data
      }
    } catch (error) {
      dispatch(isProfileToggle(false))
      return error.message
    }
  }
)

export const managePushNotification = createAsyncThunk(
  "admin/manage-push-notification",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      dispatch(isProfileToggle(true));
      const response = await axios({
        method: "PUT",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/manage/push/notification`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      });

      if (response.status === 200) {
        dispatch(isProfileToggle(false));
        dispatch(getProfileDetails());
        toast.success(response?.data?.message, options);
        return response?.data?.status;
      } else {
        dispatch(isProfileToggle(false));
        toast.error(response?.data?.message, options);
      }
    } catch (err) {
      dispatch(isProfileToggle(false));
      toast.error(err, options);
    }
  }
);

export const manageEmailNotification = createAsyncThunk(
  "admin/manage-email-notification",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      dispatch(isProfileToggle(true));
      const response = await axios({
        method: "PUT",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/manage/email/notification`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      });

      if (response.status === 200) {
        dispatch(isProfileToggle(false));
        dispatch(getProfileDetails());
        toast.success(response?.data?.message, options);
        return response?.data?.status;
      } else {
        dispatch(isProfileToggle(false));
        toast.error(response?.data?.message, options);
      }
    } catch (err) {
      dispatch(isProfileToggle(false));
      toast.error(err, options);
    }
  }
);

export const manageSmsNotification = createAsyncThunk(
  "admin/manage-message-notification",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      dispatch(isProfileToggle(true));
      const response = await axios({
        method: "PUT",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/manage/message/notification`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      });

      if (response.status === 200) {
        dispatch(isProfileToggle(false));
        dispatch(getProfileDetails());
        toast.success(response?.data?.message, options);
        return response?.data?.status;
      } else {
        dispatch(isProfileToggle(false));
        toast.error(response?.data?.message, options);
      }
    } catch (err) {
      dispatch(isProfileToggle(false));
      toast.error(err, options);
    }
  }
);

export const manageTfa = createAsyncThunk(
  "admin/manage-authentication",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      dispatch(isProfileToggle(true));
      const response = await axios({
        method: "PUT",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/manage-authentication`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      });

      if (response.status === 200) {
        dispatch(isProfileToggle(false));
        dispatch(getProfileDetails());
        toast.success(response?.data?.message, options);
        return response?.data?.status;
      } else {
        dispatch(isProfileToggle(false));
        toast.error(response?.data?.message, options);
      }
    } catch (err) {
      dispatch(isProfileToggle(false));
      toast.error(err, options);
    }
  }
);

export const deactivateAccount = createAsyncThunk(
  "admin/deactivateAccount",
  async (data, { fulfillWithValue, rejectWithValue, dispatch }) => {
    try {
      isProfileToggle(true)
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/deactivate-account`,
        data: data
      })
      if (response.status === 200) {
        isProfileToggle(false)
        dispatch(defaultLogout())
        toast.success(response.data.message)
        return response?.data?.status;
      } else {
        isProfileToggle(false)
        toast.error(response.data.message)
        return response?.data;
      }
    } catch (error) {
      toast.error(error.message)
      isProfileToggle(false)
      return error.message;
    }
  }
)

export const deleteAccount = createAsyncThunk(
  "admin/delete-account",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      dispatch(isProfileToggle(true));
      const response = await axios({
        method: "DELETE",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/delete-account`,
        data: data,
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.status === 200) {
        dispatch(isProfileToggle(false));
        toast.success(response.data.message)
        dispatch(defaultLogout());
        return response?.data?.status;
      } else {
        dispatch(isProfileToggle(false));
        toast.error(response?.data?.message);
      }
    } catch (err) {
      dispatch(isProfileToggle(false));
      toast.error(err.message);
    }
  }
);