import React, { useEffect, useState } from "react";
import CustomTable from "components/CustomTable";
import ConformationPopup from "components/ui/ConformationPopup";
import SearchField from "components/ui/SearchField";
import useDisableSkelaton from "hooks/useDisableSkelaton";
import useFetchList from "hooks/useFetchList";
import ModuleLayout from "layouts/ModuleLayout";
import { setPageDetails } from "modules/auth/redux/authSlice";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { deleteQuiz, getQuizList } from "../redux/quizApi";
import { getBreadcrumbName, unixTimeToReadableFormat } from "helper/helper";
import { useNavigate } from "react-router-dom";
import DropDownBox from "components/DropDownBox";
import { getSkillList } from "modules/catalogue/skills/redux/skillApi";
import { SortItems } from "helper/placeholder-data";
import useSelectRows from "hooks/useSelectRows";

const Quiz = ({ setTitle }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    setTitle("Quiz Management");
  }, [setTitle]);

  const { skillList } = useSelector((state) => state.CatSkill, shallowEqual);
  const { quizList, metaData, quizLoading } = useSelector(
    (state) => state.quiz,
    shallowEqual
  );

  const [active, setActive] = useState("quizes");
  const [skillOpts, setSkillOpts] = useState([]);
  const [mobileSidebar, setMobileSidebar] = useState(false);
  const [minimizedSidebar, setMinimizedSidebar] = useState(false);
  const { contentLoading } = useDisableSkelaton(quizLoading);
  const [type, setType] = useState("");
  const [skill, setSkill] = useState("");
  const [level, setLevel] = useState("");

  const {
    content,
    alertBox,
    setAlertBox,
    selectAllRows,
    selectedRows,
    setSelectedRows,
    toggleSelectAll,
    toggleRowSelection,
    deleteFunction,
  } = useSelectRows(quizList, "quiz_id");

  const {
    privileges,
    limit,
    setLimit,
    sort,
    setSort,
    search,
    setSearch,
    btnNo,
    setBtnNo,
    tableIndex,
    setTableIndex,
  } = useFetchList(getQuizList, metaData, selectAllRows, setSelectedRows);

  //   useEffect(() => {
  //   dispatch(setPageDetails({ page: metaData?.page, search }));
  // }, [dispatch, metaData, search]);

  const listContent = quizList.map((item, i) => {
    const { id, num, slug, name, createdBy, createdAt } = item;
    const crDt = unixTimeToReadableFormat(createdAt);
    const similarItems = [
      { style: "name text-left", val: name },
      { style: "email", val: slug },
      {
        style: "",
        val: `${createdBy?.name?.firstName} ${createdBy?.name?.lastName}`,
      },
      { style: "", val: crDt },
    ];
    return [id, num, name, slug, similarItems];
  });

  const TABLEDATA = {
    loadingState: contentLoading,
    skalatonDetails: { col: 4, rows: limit },
    list: quizList,
    listHeading: ["Quiz", "Slug", "Created By", "Created At"],
    listContent,
    selectedRows,
    selectAllRows,
    setSelectedRows,
    toggleSelectAll,
    toggleRowSelection,
    deleteFunction,
    sliceName: "quiz",
    PagAPI: getQuizList,
    search,
    sort,
    limit,
    setLimit,
    btnNo,
    setBtnNo,
    tableIndex,
    setTableIndex,
    privileges,
    hasAccess: "quizManagement",
    navigateTo: (params) => {
      const ques = quizList.find((quiz) => quiz.id === params);
      const breadcrumbName = getBreadcrumbName(ques);
      navigate(`/catalogue/quiz-management/${params}`, {
        state: { slug: params, title: breadcrumbName },
      });
    },
    metaData,
  };

  useEffect(() => {
    dispatch(getSkillList({ page: 0, limit: 0, search: "" }));
  }, [dispatch]);

  useEffect(() => {
    const skills = skillList.map((skill) => ({
      label: skill.name,
      value: skill.id,
    }));
    setSkillOpts(skills);
  }, [skillList]);

  useEffect(() => {
    const data = {
      page: 0,
      limit: limit,
      search: search,
      level: level,
      skillId: skill,
      type: type,
    };
    dispatch(getQuizList(data));
  }, [skill, level, sort, type, limit, search, dispatch]);

  return (
    <ModuleLayout
      minimizedSidebar={minimizedSidebar}
      setMinimizedSidebar={setMinimizedSidebar}
      mobileSidebar={mobileSidebar}
      setMobileSidebar={setMobileSidebar}
      active={active}
      setActive={setActive}
      content={content}
      alertBox={alertBox}
    >
      <header className="list-header">
        <div className="list-title">
          <h2>Quiz Management</h2>
          <div className="flex-center gap-1">
            {Object.values(privileges["quizManagement"])?.includes("write") && (
              <button
                className="btn-primary flex-center gap-1"
                onClick={() => navigate("/catalogue/quiz-management/add-quiz")}
              >
                <i className="fa-solid fa-feather"></i> Add Quiz
              </button>
            )}

            {Object?.values(privileges["quizManagement"])?.includes("delete") &&
            selectedRows.length !== 0 ? (
              <button
                className="btn-secondary flex-center gap-1"
                onClick={() => {
                  deleteFunction("all quizes");
                }}
              >
                <i className="fa-light fa-trash"></i>
                {`Delete ${selectedRows.length > 1 && selectedRows.length}`}
              </button>
            ) : null}
          </div>
        </div>
      </header>
      <div className="list-container custom-box">
        <div className="tb_controls">
          <div className="flex gap-1">
            <DropDownBox
              options={SortItems}
              size="small"
              dropDownTitle="Sort"
              animateDropDownTitle
              customSetter={setSort}
              customDropBoxStyles={{
                opacity: 0.6,
                color: "#262626",
                padding: "0.6rem",
                border: "1px solid var(--color-neutral-92)",
              }}
            />
            <DropDownBox
              animateDropDownTitle
              options={[
                { label: "Skill", value: "skill" },
                { label: "Survey", value: "survey" },
              ]}
              incomingValue={type}
              customSetter={setType}
              customDropBoxStyles={{
                opacity: 0.6,
                color: "#262626",
                padding: "0.6rem",
                border: "1px solid var(--color-neutral-92)",
              }}
              dropDownTitle="Select Type"
              resetButton
              size={"small"}
            />
            <DropDownBox
              size={"small"}
              animateDropDownTitle
              resetButton
              options={[
                { label: "Easy", value: "easy" },
                { label: "Medium", value: "medium" },
                { label: "Hard", value: "hard" },
                { label: "Expert", value: "expert" },
                { label: "Master", value: "master" },
                { label: "Extreme", value: "extreme" },
              ]}
              dropDownTitle="Select level"
              customSetter={setLevel}
              incomingValue={level}
              customDropBoxStyles={{
                opacity: 0.6,
                color: "#262626",
                padding: "0.6rem",
                border: "1px solid var(--color-neutral-92)",
              }}
            />
            <DropDownBox
              size={"small"}
              animateDropDownTitle
              resetButton
              options={skillOpts}
              dropDownTitle="Select Skill"
              customSetter={setSkill}
              incomingValue={skill}
              customDropBoxStyles={{
                opacity: 0.6,
                color: "#262626",
                padding: "0.6rem",
                border: "1px solid var(--color-neutral-92)",
              }}
            />
          </div>

          <SearchField value={search} setValue={setSearch} />
        </div>
        <CustomTable {...TABLEDATA} />
      </div>

      {alertBox && (
        <ConformationPopup
          customState={alertBox}
          customSetter={setAlertBox}
          api={deleteQuiz}
          popUpData={content}
          customFunction={() => setSelectedRows(new Set())}
          toastMessage={"Quiz removed successfully"}
        />
      )}
    </ModuleLayout>
  );
};

export default Quiz;
