/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps*/
import { useEffect, useState } from "react";

const useDisableSkelaton = (isLoading) => {
  const [contentLoading, setContentLoading] = useState(false);
  const [tableCount, setTableCount] = useState(null);

  useEffect(() => {
    if (tableCount) {
      clearTimeout(tableCount);
    }
    const count = setTimeout(() => {
      setContentLoading(isLoading);
    }, 300);
    setTableCount(count);
  }, [isLoading]);

  return { contentLoading };
};

export default useDisableSkelaton;
