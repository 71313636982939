import { createAsyncThunk } from "@reduxjs/toolkit";
import { isDashboardLoading } from "./dashboardSlice";
import axios from "axios";
import { AddNumField } from "helper/helper";

export const getDashboardDetail = createAsyncThunk(
    "dashboard/get-dashboard-detail",
    async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
        try {
            dispatch(isDashboardLoading(true))
            const response = await axios({
                method: "GET",
                url: `${process.env.REACT_APP_AUTH_URL}/admin/dashboard/dashboard-detail`,
            });
            if (response.status === 200) {
                dispatch(isDashboardLoading(false))
                return fulfillWithValue(response?.data);
            } else {
                dispatch(isDashboardLoading(false))
                return rejectWithValue();
            }
        } catch (err) {
            dispatch(isDashboardLoading(false))
            return rejectWithValue();
        }
    }
);

export const getLeaderboardList = createAsyncThunk(
    "dashboard.get-leaderboard-list",
    async(data, {rejectWithValue, fulfillWithValue, dispatch}) =>{
        try {
            const response = await axios({
                method: "GET",
                url: `${process.env.REACT_APP_AUTH_URL}/admin/dashboard/leaderboard-detail?page=${data?.page}&limit=${data?.limit}&sort=${data?.sort ? data?.sort : "desc"}&search=${data.search ? data.search : ""}`,
            });

            if (response.status === 200) {
                if (response?.data?.data?.length > 0) {
                    const res = await AddNumField(
                        response?.data?.data?.[0],
                        data?.page * data?.limit
                    );

                    return fulfillWithValue(res);
                } else {
                    const modPage = Math.max(data.page - 1, 0);
                    const responseAgain = await axios({
                        method: "GET",
                        url: `${process.env.REACT_APP_AUTH_URL}/admin/dashboard/leaderboard-detail?page=${data?.page}&limit=${data?.limit}&sort=${data?.sort ? data?.sort : "desc"}&search=${data.search ? data.search : ""}`,
                    });
                    if (responseAgain.status === 200) {
                        const resAgain = await AddNumField(
                            responseAgain?.data,
                            modPage * data?.limit
                        );
                        return fulfillWithValue(resAgain);
                    } else {
                        // toast.error(responseAgain?.data?.message, options);
                        return rejectWithValue();
                    }
                }
            } else {
                // toast.error(response?.data?.message, options);
                return rejectWithValue();
            }
        } catch (err) {
            return rejectWithValue();
        }
    }
)