/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import SubmitButton from "components/ui/SubmitButton";
import InputField from "components/ui/InputField";
import ModuleLayout from "layouts/ModuleLayout";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  addInventory,
  getInventoryDetail,
  updateInventoryDetail,
} from "../redux/inventoryApi";

const ManageInventory = ({ setTitle }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { id } = useParams();
  const pathName = useLocation().pathname;

  const { inventoryDetail } = useSelector(
    (state) => state.inventory,
    shallowEqual
  );

  useEffect(() => {
    if (pathName.includes("add-new")) {
      setTitle("Product Management | Add Inventory");
    } else if (id) {
      setTitle("Product Management | Update Inventory");
    }
  }, [setTitle]);

  const [mobileSidebar, setMobileSidebar] = useState(false);
  const [active, setActive] = useState("inventory");
  const [minimizedSidebar, setMinimizedSidebar] = useState(false);
  const [activeInputs, setActiveInputs] = useState(false);

  const initialValues = {
    sold: "",
    quantity: "",
    inventoryName: "",
    inventoryPrice: "",
    inventoryDescription: "",
  };

  const profileSchema = Yup.object({
    inventoryName: Yup.string()
      .trim()
      .min(3, "Name must be at least 3 characters long")
      .max(80, "Inventory name must be less then 80 characters")
      .matches(/^[A-Za-z ]+$/, "Please enter a valid name")
      .required("First name is required"),
    inventoryDescription: Yup.string()
      .trim()
      .min(3, "Description must be at least 3 characters long")
      .max(80, "Description must be less then 80 characters")
      .matches(/^[A-Za-z ]+$/, "Please enter a valid name")
      .required("Description should not ne empty"),
    inventoryPrice: Yup.number().required("Price is required"),
    quantity: Yup.number().required("Quantity is required"),
    sold: Yup.number().required("Sold is required"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: profileSchema,
    onSubmit: async (values) => {
      const data = {
        inventoryName: values.inventoryName,
        inventoryDescription: values.inventoryDescription,
        inventoryPrice: values.inventoryPrice,
        quantity: values.quantity,
        sold: values.sold,
      };

      if (pathName.includes("add-new")) {
        const response = await dispatch(addInventory(data));
        if (response?.payload) {
          navigate(-1);
        }
      } else if (id?.length > 0) {
        const response = await dispatch(
          updateInventoryDetail({ send: data, id })
        );
        if (response?.payload) {
          navigate(-1);
        }
      }
    },
  });

  useEffect(() => {
    if (id?.length > 0) {
      dispatch(getInventoryDetail({ id }));
    }
  }, [dispatch]);

  useEffect(() => {
    if (id?.length > 0 && inventoryDetail) {
      formik.setValues({
        inventoryName: inventoryDetail?.inventoryName,
        inventoryDescription: inventoryDetail?.inventoryDescription,
        inventoryPrice: inventoryDetail?.inventoryPrice,
        quantity: inventoryDetail?.quantity,
        sold: inventoryDetail?.sold,
      });
    }
  }, [activeInputs, inventoryDetail]);

  const ActivePage = pathName.includes("add-new")
    ? "Add Inventory"
    : "Update Inventory";

  return (
    <ModuleLayout
      minimizedSidebar={minimizedSidebar}
      setMinimizedSidebar={setMinimizedSidebar}
      mobileSidebar={mobileSidebar}
      setMobileSidebar={setMobileSidebar}
      active={active}
      setActive={setActive}
    >
      <div className="form-header">
        <h2>Content Management | {ActivePage}</h2>

        {id?.length > 0 && (
          <div className="form-control">
            {activeInputs ? (
              <>
                <button
                  type="button"
                  onClick={() => navigate("/content/pages")}
                  className="btn-outlined cancel"
                >
                  Back
                </button>
                <button
                  type="button"
                  onClick={() => setActiveInputs(false)}
                  className="btn-primary"
                >
                  Update
                </button>
              </>
            ) : (
              <>
                <button
                  type="button"
                  onClick={() => setActiveInputs(true)}
                  className="btn-outlined tertiary"
                >
                  Abort
                </button>
                <SubmitButton
                  className="btn-primary"
                  type="submit"
                  onClick={formik.handleSubmit}
                  disabled={formik.isSubmitting}
                >
                  Submit
                </SubmitButton>
              </>
            )}
          </div>
        )}

        {pathName.includes("add-new") && (
          <div className="form-control">
            <button
              type="button"
              onClick={() => navigate(-1)}
              className="btn-outlined cancel"
            >
              Back
            </button>
            <SubmitButton
              className="btn-primary"
              type="submit"
              onClick={formik.handleSubmit}
              disabled={formik.isSubmitting}
            >
              Submit
            </SubmitButton>
          </div>
        )}
      </div>
      <div className="form-wrapper custom-box">
        <div className="auth-form-wrapper">
          <form
            className="form"
            onSubmit={formik.handleSubmit}
            // noValidate="novalidate"
          >
            <div className="form-col-2">
              <div className="form-control">
                <label className="form-label">Product Name</label>
                <div className="form-input-box">
                  <InputField
                    type="text"
                    id="inventoryName"
                    name="inventoryName"
                    className="form-input"
                    value={formik.values.inventoryName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
                {formik.errors.inventoryName && formik.touched.inventoryName ? (
                  <span className="err">{formik.errors.inventoryName}</span>
                ) : null}
              </div>
              <div className="form-control">
                <label className="form-label">Price</label>
                <div className="form-input-box">
                  <InputField
                    type="text"
                    id="inventoryPrice"
                    name="inventoryPrice"
                    className="form-input"
                    value={formik.values.inventoryPrice}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
                {formik.errors.inventoryPrice &&
                formik.touched.inventoryPrice ? (
                  <span className="err">{formik.errors.inventoryPrice}</span>
                ) : null}
              </div>
            </div>
            <div className="form-control">
              <label className="form-label">Description</label>
              <div className="form-input-box">
                <textarea
                  id="inventoryDescription"
                  name="inventoryDescription"
                  className="form-input"
                  value={formik.values.inventoryDescription}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  disabled={id && activeInputs}
                  cols={4}
                />
              </div>
              {formik.errors.inventoryDescription &&
                formik.touched.inventoryDescription && (
                  <span className="err">
                    {formik.errors.inventoryDescription}
                  </span>
                )}
            </div>
            <div className="form-col-2">
              <div className="form-control">
                <label className="form-label">Quantity</label>
                <div className="form-input-box">
                  <InputField
                    type="text"
                    id="quantity"
                    name="quantity"
                    className="form-input"
                    value={formik.values.quantity}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
                {formik.errors.quantity && formik.touched.quantity ? (
                  <span className="err">{formik.errors.quantity}</span>
                ) : null}
              </div>
              <div className="form-control">
                <label className="form-label">Sold</label>
                <div className="form-input-box">
                  <InputField
                    type="text"
                    id="sold"
                    name="sold"
                    className="form-input"
                    value={formik.values.sold}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
                {formik.errors.sold && formik.touched.sold ? (
                  <span className="err">{formik.errors.sold}</span>
                ) : null}
              </div>
            </div>
          </form>
        </div>
      </div>
    </ModuleLayout>
  );
};

export default ManageInventory;
