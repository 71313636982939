export { default as peak72logo } from "assets/icons/peak72logo.svg";
export { default as minSideLogo } from "assets/icons/min-logo.svg";
export { default as male } from "assets/icons/male-icon.svg";
export { default as female } from "assets/icons/female-icon.svg";
export { default as walnut } from "assets/icons/walnut.svg";
export { default as xp } from "assets/icons/XP Point.svg";
export { default as fire } from "assets/icons/fire.svg";
export { default as heart } from "assets/icons/heart.svg";
export { default as nodata } from "assets/icons/No data-pana.svg";
export { default as excel } from "assets/icons/excel.svg";
export { default as GoogleIcon } from "./google.svg";
export { default as savings } from "assets/icons/Savings and spending.svg";
export { default as education } from "assets/icons/Education and careers.svg";
export { default as taxes } from "assets/icons/Government and taxes.svg";
export { default as premium } from "assets/icons/premium-badge.svg";
export { default as badge1 } from "assets/icons/badge-1.svg";
export { default as badge2 } from "assets/icons/badge-2.svg";
export { default as badge3 } from "assets/icons/badge-3.svg";
export { default as badge4 } from "assets/icons/badge-4.svg";
export { default as badge5 } from "assets/icons/badge-5.svg";
export { default as badge6 } from "assets/icons/badge-6.svg";
export { default as trophy1 } from "assets/icons/trophy-winner.png";
export { default as trophy2 } from "assets/icons/trophy-gold.png";
export { default as trophy3 } from "assets/icons/trophy-silver.png";
export { default as trophy4 } from "assets/icons/trophy-bronze.png";
export { default as apple } from "assets/icons/apple.svg";
export { default as amazon } from "assets/icons/amazon.svg";
export { default as flipcart } from "assets/icons/flipcart.svg";
export { default as myntra } from "assets/icons/mytra.svg";
export { default as lenscart } from "assets/icons/lenscart.svg";
export { default as puzzle } from "assets/icons/puzzle.png";
export { default as chess } from "assets/icons/chess.png";
