import React from "react";

const Notes = () => {
  return (
    <div className="payment-main">
      <div className="pm-bottom">
        <div className="pm-div" style={{ width: "100%" }}>
          <div className="pmtop">
            <span className="title">Add Notes</span>
          </div>
          <div className="pm-content">
            <div className="textAreaDiv">
              <textarea placeholder="" rows={5} />
            </div>
            <div className="flex-between mt-1">
              <div className="flex-center gap-1">
                <input
                  type="checkbox"
                  name="status"
                  id="status"
                  // onChange={formik.handleChange}
                  // onBlur={formik.handleBlur}
                  // checked={formik.values.status}
                  style={{ display: "none" }}
                  className='cc-inp'
                />
                <p>Private</p>
                <label htmlFor="status">
                  <div className='custom-checkbox'>
                    <div className="check-circle"></div>
                  </div>
                </label>
                <p>No</p>
              </div>
              <button className="btn-primary" type="button">Save</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notes;
