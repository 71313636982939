import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { isAddressLoading } from "./addressSlice";
import { toast } from "react-toastify";
import { options } from "helper/helper";

export const addAddress = createAsyncThunk(
    "admin/address/add-address",
    async (data, { fulfillWithValue, rejectWithValue, dispatch }) => {
        try {
            dispatch(isAddressLoading(true))
            const response = await axios({
                method: "POST",
                url: `${process.env.REACT_APP_AUTH_URL}/admin/address/add-address`,
                data: data,
                headers: {
                    "Content-Type": "application/json"
                }
            })
            if (response.status === 200) {
                toast.success(response?.data?.message)
                dispatch(isAddressLoading(false))
                return response.data
            } else {
                dispatch(isAddressLoading(false))
                toast.error(response?.data?.message)
                return response.data
            }
        } catch (error) {
            dispatch(isAddressLoading(false))
        }
    }
)

export const getAddressList = createAsyncThunk(
    "admin/address/address-list",
    async (data, { fulfillWithValue, rejectWithValue, dispatch }) => {
        try {
            dispatch(isAddressLoading(true));
            const response = await axios({
                method: "GET",
                url: `${process.env.REACT_APP_AUTH_URL}/admin/address/address-list`,
                data: data,
                headers: {
                    "Content-Type": "application/json"
                }
            })


            
            if (response.status === 200) {
                dispatch(isAddressLoading(false));
                return fulfillWithValue(response?.data);
            } else {
                dispatch(isAddressLoading(false));
                return rejectWithValue();
            }
        } catch (err) {
            dispatch(isAddressLoading(false));
            return rejectWithValue();
        }
    }
)

export const deleteAddress = createAsyncThunk(
    "admin/address/delete-address",
    async (data, { fulfillWithValue, rejectWithValue, dispatch }) => {
        try {
            dispatch(isAddressLoading(true))
            const response = await axios({
                method: "DELETE",
                url: `${process.env.REACT_APP_AUTH_URL}/admin/address/delete-address`,
                data: data,
                headers: {
                    "Content-Type": "application/json",
                }
            })
            if (response.status === 200) {
                dispatch(getAddressList())
                dispatch(isAddressLoading(false))
                toast.success(response?.data?.message, options)
            } else {
                toast.error(response?.data?.message, options)
                dispatch(isAddressLoading(false))
            }
        } catch (error) {
            dispatch(isAddressLoading(false))
            toast.error(error.message, options)
        }
    }
)

export const getAddressDetail = createAsyncThunk(
    "admin/address/address-detail",
    async (data, { fulfillWithValue, rejectWithValue, dispatch }) => {
        try {
            dispatch(isAddressLoading(true));
            const response = await axios({
                method: "GET",
                url: `${process.env.REACT_APP_AUTH_URL}/admin/address/address-detail/${data.id}`,
                headers: {
                    "Content-Type": "application/json"
                }
            })
            if (response.status === 200) {
                dispatch(isAddressLoading(false));
                return fulfillWithValue(response?.data);
            } else {
                dispatch(isAddressLoading(false));
                return rejectWithValue();
            }
        } catch (err) {
            dispatch(isAddressLoading(false));
            return rejectWithValue();
        }
    }
)

export const updateAddress = createAsyncThunk(
    "admin/address/update-detail",
    async (data, { fulfillWithValue, rejectWithValue, dispatch }) => {
        try {
            dispatch(isAddressLoading(true))
            const response = await axios({
                method: "PUT",
                url: `${process.env.REACT_APP_AUTH_URL}/admin/address/update-detail/${data.id}`,
                data: data.data,
                headers: {
                    "Content-Type": "application/json"
                }
            })
            
            if (response.status === 200) {
                toast.success(response?.data?.message)
                dispatch(isAddressLoading(false))
                return response.data
            } else {
                dispatch(isAddressLoading(false))
                toast.error(response?.data?.message)
                return response.data
            }
        } catch (error) {
            
            dispatch(isAddressLoading(false))
        }
    }
)