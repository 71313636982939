/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import CustomTable from "components/CustomTable";
import ConformationPopup from "components/ui/ConformationPopup";
import SearchField from "components/ui/SearchField";
import ModuleLayout from "layouts/ModuleLayout";
import { setPageDetails } from "modules/auth/redux/authSlice";
import { unixTimeToReadableFormat } from "helper/helper";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { getInventoryList, deleteInventory } from "../redux/inventoryApi";
import useDisableSkelaton from "hooks/useDisableSkelaton";
import useFetchList from "hooks/useFetchList";
import useSelectRows from "hooks/useSelectRows";

const Inventory = ({ setTitle }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    setTitle("Inventory Management");
  }, [setTitle]);

  const { inventoryList, metaData, inventoryLoading } = useSelector(
    (state) => state.inventory,
    shallowEqual
  );

  const [active, setActive] = useState("inventory");
  const [mobileSidebar, setMobileSidebar] = useState(false);
  const [minimizedSidebar, setMinimizedSidebar] = useState(false);

  const { contentLoading } = useDisableSkelaton(inventoryLoading);

  useEffect(() => {
    dispatch(setPageDetails({ page: metaData?.page, search }));
  }, [metaData]);

  const {
    content,
    alertBox,
    setAlertBox,
    selectAllRows,
    selectedRows,
    setSelectedRows,
    toggleSelectAll,
    toggleRowSelection,
    deleteFunction,
  } = useSelectRows(inventoryList, "inventory_ids");

  const {
    privileges,
    limit,
    setLimit,
    sort,
    setSort,
    search,
    setSearch,
    btnNo,
    setBtnNo,
    tableIndex,
    setTableIndex,
  } = useFetchList(getInventoryList, metaData, selectAllRows, setSelectedRows);

  const listContent = inventoryList.map((item, i) => {
    const { _id, num, name, quantity, sold, status, createdAt } = item;
    const crDt = unixTimeToReadableFormat(createdAt);
    const similarItems = [
      { style: "name", val: name },
      { style: "", val: quantity },
      {
        style: "",
        val: sold,
      },
      { style: "", val: status },
      { style: "", val: crDt },
    ];
    return [_id, num, name, similarItems];
  });

  const TABLEDATA = {
    loadingState: contentLoading,
    skalatonDetails: { col: 4, rows: limit },
    list: inventoryList,
    listHeading: ["Product", "Quantity", "Sold", "Status", "Created At"],
    listContent,
    selectedRows,
    selectAllRows,
    setSelectedRows,
    toggleSelectAll,
    toggleRowSelection,
    deleteFunction,
    sliceName: "inventory",
    search,
    PagAPI: getInventoryList,
    sort,
    limit,
    setLimit,
    btnNo,
    setBtnNo,
    tableIndex,
    setTableIndex,
    privileges,
    hasAccess: "productManagement",
    navigateTo: update,
    metaData,
  };

  function update(slug) {
    navigate(`/inventory/${slug}`);
  }

  return (
    <ModuleLayout
      minimizedSidebar={minimizedSidebar}
      setMinimizedSidebar={setMinimizedSidebar}
      mobileSidebar={mobileSidebar}
      setMobileSidebar={setMobileSidebar}
      active={active}
      setActive={setActive}
      content={content}
      alertBox={alertBox}
    >
      <header className="list-header">
        <div className="list-title">
          <h2>Inventory Management</h2>
          <div className="flex-center gap-1">
            {Object.values(privileges["productManagement"]).includes(
              "write"
            ) && (
              <button
                className="btn-primary flex-center gap-1"
                onClick={() => navigate("/inventory/add-new")}
              >
                <i className="fa-solid fa-feather"></i> Add Inventory
              </button>
            )}

            {Object?.values(privileges["productManagement"])?.includes(
              "delete"
            ) && selectedRows.length !== 0 ? (
              <button
                className="btn-secondary flex-center gap-1"
                onClick={() => {
                  deleteFunction("all glossaries");
                }}
              >
                <i className="fa-light fa-trash"></i>
                {`Delete ${selectedRows.length}`}
              </button>
            ) : null}
          </div>
        </div>
      </header>

      <div className="list-container custom-box">
        <div className="tb_controls">
          <div className="flex-center gap-1">
            {/* <Drawer
              title="Filters"
              buttonClassNames="btn-text-neutral flex-center gap-1"
              startIcon={<i className="fa-regular fa-arrow-down-arrow-up"></i>}
              drawerItems={SortItems}
              onClick={setSort}
            />
            <Drawer
              title="Sort"
              buttonClassNames="btn-text-neutral flex-center gap-1"
              startIcon={<i className="fa-regular fa-arrow-down-arrow-up"></i>}
              drawerItems={SortItems}
              onClick={setSort}
            /> */}
          </div>
          <SearchField value={search} setValue={setSearch} />
        </div>
        <CustomTable {...TABLEDATA} />
      </div>

      {alertBox && (
        <ConformationPopup
          customState={alertBox}
          customSetter={setAlertBox}
          api={deleteInventory}
          popUpData={content}
          customFunction={() => setSelectedRows(new Set())}
          toastMessage={"Inventory removed successfully"}
        />
      )}
    </ModuleLayout>
  );
};

export default Inventory;
