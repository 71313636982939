import Card from "../components/Card";
import { prevSkills } from "helper/placeholder-data";

const UserSkills = () => {
  return (
    <div className="learning-skills custom-box">
      <h3>Learning Skills</h3>
      <div>
        <h4>Currently Active Skills</h4>
        <Card isLoading={false} data={prevSkills.slice(1, 4)} />
      </div>

      <hr
        className="w-full"
        style={{ border: "1px solid var(--color-neutral-light)" }}
      />

      <div>
        <h4>Previous Completed Skills</h4>
        <Card isLoading={false} data={prevSkills} />
      </div>
    </div>
  );
};

export default UserSkills;
