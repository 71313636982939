import React, { useEffect, useState } from "react";
import CustomTable from "components/CustomTable";
import ConformationPopup from "components/ui/ConformationPopup";
import SearchField from "components/ui/SearchField";
import useDisableSkelaton from "hooks/useDisableSkelaton";
import useFetchList from "hooks/useFetchList";
import ModuleLayout from "layouts/ModuleLayout";
import { useSelector, shallowEqual } from "react-redux";
import { deleteGlosaaryAsync, getGlossaryList } from "../redux/glossaryApi";
import { getBreadcrumbName, unixTimeToReadableFormat } from "helper/helper";
import ToolkitManager from "./GlossaryManager";
import DropDownBox from "components/DropDownBox";
import { SortItems } from "helper/placeholder-data";
import useSelectRows from "hooks/useSelectRows";
import { useNavigate } from "react-router-dom";
import ActionButton from "components/ui/ActionButton";

const Glossary = ({ setTitle }) => {
  const navigate = useNavigate();
  useEffect(() => {
    setTitle("Glossary Management");
  }, [setTitle]);

  const { glossaryList, metaData, glossaryLoading } = useSelector(
    (state) => state.glossary,
    shallowEqual
  );

  const { contentLoading } = useDisableSkelaton(glossaryLoading);

  const [active, setActive] = useState("glossary");
  const [mobileSidebar, setMobileSidebar] = useState(false);
  const [minimizedSidebar, setMinimizedSidebar] = useState(false);
  const [popup, setPopup] = useState({ show: false, slug: "", type: "" });

  const updateGlossaryData = glossaryList?.filter(({ slug }) => slug === popup.slug);

  const {
    content,
    alertBox,
    setAlertBox,
    selectAllRows,
    selectedRows,
    setSelectedRows,
    toggleSelectAll,
    toggleRowSelection,
    deleteFunction,
  } = useSelectRows(glossaryList, "glossary_id");

  const {
    privileges,
    limit,
    setLimit,
    sort,
    setSort,
    search,
    setSearch,
    btnNo,
    setBtnNo,
    tableIndex,
    setTableIndex,
  } = useFetchList(getGlossaryList, metaData, selectAllRows, setSelectedRows);

  const listContent = glossaryList.map((item, i) => {
    const { id, num, slug, name, createdBy, createdAt } = item;
    const crDt = unixTimeToReadableFormat(createdAt);
    const similarItems = [
      { style: "name", val: name },
      { style: "email", val: slug },
      {
        style: "",
        val: `${createdBy?.name?.firstName} ${createdBy?.name?.lastName}`,
      },
      { style: "", val: crDt },
    ];
    return [id, num, name, slug, similarItems];
  });

  const TABLEDATA = {
    loadingState: contentLoading,
    skalatonDetails: { col: 4, rows: limit },
    list: glossaryList,
    listHeading: ["Glossary", "Slug", "Created By", "Created At"],
    listContent,
    selectedRows,
    selectAllRows,
    setSelectedRows,
    toggleSelectAll,
    toggleRowSelection,
    deleteFunction,
    sliceName: "glossary",
    search,
    PagAPI: getGlossaryList,
    sort,
    limit,
    setLimit,
    btnNo,
    setBtnNo,
    tableIndex,
    setTableIndex,
    privileges,
    hasAccess: "glossaryManagement",
    navigateTo: (params) => {
      // setPopup({ show: true, slug: params, type: "update-glossary" }),
      const user = glossaryList.find((item) => item.id === params);
      const breadcrumbName = getBreadcrumbName(user);
      navigate(`/catalogue/glossary-management/${params}`, {
        state: { slug: params, title: breadcrumbName },
      });
    },
    metaData,
  };

  const handleClick = (val) => {
    if (val === "single") {
      // setPopup({
      //   show: true,
      //   type: "add-glossary",
      // });
      navigate("/catalogue/glossary-management/add-glossary")
    } else if (val === "multiple") {
      setPopup({
        show: true,
        type: "add-multiple-glossary",
      });
    }
  };

  return (
    <ModuleLayout
      active={active}
      setActive={setActive}
      minimizedSidebar={minimizedSidebar}
      setMinimizedSidebar={setMinimizedSidebar}
      mobileSidebar={mobileSidebar}
      setMobileSidebar={setMobileSidebar}
    >
      <header className="list-header">
        <div className="list-title">
          <h2>Glossary Management</h2>
          <div className="flex-center gap-1">
            {Object.values(privileges["glossaryManagement"]).includes(
              "write"
            ) && (
                // <button
                //   className="btn-primary"
                //   // onClick={() => handleClick("single")}
                //   onClick={() => navigate("/catalogue/glossary-management/add-glossary")}
                // >
                //   <i className="fa-solid fa-feather"></i> Add Glossary
                // </button>
                <ActionButton
                  title="Add Glossary"
                  addF={handleClick}
                  buttonStyles={{ width: "10rem" }}
                />

              )}

            {Object?.values(privileges["glossaryManagement"])?.includes(
              "delete"
            ) && selectedRows.length !== 0 ? (
              <button
                className="btn-secondary flex-center gap-1"
                onClick={() => {
                  deleteFunction("all glossaries");
                }}
              >
                <i className="fa-light fa-trash"></i>
                {`Delete ${selectedRows.length}`}
              </button>
            ) : null}
          </div>
        </div>
      </header>
      <div className="list-container custom-box">
        <div className="tb_controls">
          <DropDownBox
            options={SortItems}
            size="small"
            dropDownTitle="Sort"
            animateDropDownTitle
            customSetter={setSort}
            customDropBoxStyles={{
              opacity: 0.6,
              color: "#262626",
              padding: "0.6rem",
              border: "1px solid var(--color-neutral-92)",
            }}
          />
          <SearchField value={search} setValue={setSearch} />
        </div>
        <CustomTable {...TABLEDATA} />
      </div>

      {popup.show && (
        <ToolkitManager
          updateData={updateGlossaryData[0]}
          popup={popup}
          setPopup={setPopup}
          limit={limit}
        />)
      }

      {
        alertBox && (
          <ConformationPopup
            customState={alertBox}
            customSetter={setAlertBox}
            api={deleteGlosaaryAsync}
            popUpData={content}
            customFunction={() => setSelectedRows(new Set())}
          />
        )
      }
    </ModuleLayout >
  );
};

export default Glossary;
