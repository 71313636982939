import Table from "components/Table";
import { orderList } from "helper/placeholder-data";
import { unixTimeToReadableFormat } from "helper/helper";

const UserRewards = () => {
  const listContent = orderList.map((obj, i) => {
    const { _id, product, image, payment_method, status, price, order_date } =
      obj;
    const valArr = [
      _id,
      [
        { style: "", val: _id },
        { style: "tb_data_name", val: product, image: image },
        { style: "", val: payment_method },
        { style: `tb_data_membership ${status}`, val: status },
        { style: "date", val: unixTimeToReadableFormat(order_date) },
        { style: "", val: price },
      ],
    ];
    return valArr;
  });

  const table_props = {
    loadingState: false,
    skalatonDetails: { col: 5, rows: 5 },
    listHeading: [
      "User Details",
      "Product",
      "Payment Info",
      "Order Status",
      "Date",
      "Price",
    ],
    listContent,
  };

  return (
    <div className="custom-box user-orders">
      <div className="details">
        <div className="user-info">
          <h3>User Info</h3>
          <div className="justify-between">
            <p className="dt-title">Name:</p>
            <p className="font-bold">Sanjeev Kushwaha</p>
          </div>
          <div className="justify-between">
            <p className="dt-title">Email:</p>
            <p className="font-bold">sanjeev@yopmail.com</p>
          </div>{" "}
          <div className="justify-between">
            <p className="dt-title">Phone:</p>
            <p className="font-bold">+91 6549875698</p>
          </div>{" "}
          <div className="justify-between">
            <p className="dt-title">Alternative Phone:</p>
            <p className="font-bold">+91 6549875699</p>
          </div>
        </div>

        <div className="user-info">
          <h3>Shipping Address</h3>
          <div className="justify-between">
            <p className="dt-title">Block Number:</p>
            <p className="font-bold">C-103</p>
          </div>
          <div className="justify-between">
            <p className="dt-title">Address:</p>
            <p className="font-bold">S.G Business Hub, Gota, Ahm.</p>
          </div>{" "}
          <div className="justify-between">
            <p className="dt-title">Pincode:</p>
            <p className="font-bold">380051</p>
          </div>{" "}
          <div className="justify-between">
            <p className="dt-title">Phone:</p>
            <p className="font-bold">+91 6549875699</p>
          </div>
        </div>

        <div className="user-info">
          <h3>Billing Address</h3>
          <div className="justify-between">
            <p className="dt-title">Block Number:</p>
            <p className="font-bold">C-103</p>
          </div>
          <div className="justify-between">
            <p className="dt-title">Address:</p>
            <p className="font-bold">S.G Business Hub, Gota, Ahm.</p>
          </div>{" "}
          <div className="justify-between">
            <p className="dt-title">Pincode:</p>
            <p className="font-bold">380051</p>
          </div>{" "}
          <div className="justify-between">
            <p className="dt-title">Phone:</p>
            <p className="font-bold">+91 6549875699</p>
          </div>
        </div>
      </div>

      <div className="transaction-list mt-2">
        <h3>Customer Order</h3>

        <Table {...table_props} />
      </div>
    </div>
  );
};

export default UserRewards;
