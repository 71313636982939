/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import ModuleLayout from "layouts/ModuleLayout";
import { companyImage } from "assets/images";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  changeCompanyLogo,
  getCompanyDetail,
  getLocality,
  updateCompanyyDetail,
} from "../redux/companyApi";
import { useFormik } from "formik";
import InputField from "components/ui/InputField";
import { toast } from "react-toastify";
import SubmitButton from "components/ui/SubmitButton";
import DropDownBox from "components/DropDownBox";
import Maintenance from "../components/Maintenance";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";

const Company = ({ setTitle }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    setTitle("Company Details");
  }, [setTitle]);

  const [companyLogo, setCompanyLogo] = useState(companyImage);
  const [pinStatue, setPinStatue] = useState(true);
  const { countryList } = useSelector((state) => state.profile, shallowEqual);
  const { companyDetail, companyLoading, locality } = useSelector((state) => state.company, shallowEqual);
  const { privileges, role } = useSelector((state) => state.auth, shallowEqual);

  useEffect(() => {
    if (role !== 1) {
      navigate("/");
    }
    dispatch(getCompanyDetail());
  }, []);

  const [selectedCountryData, setSelectedCountryData] = useState();
  const [countryOptions, setCountryOptions] = useState();
  const [tzOptions, setTzOptions] = useState();
  const [languageOptions, setLanguageOptions] = useState();
  const [active, setActive] = useState("company");
  const [mobileSidebar, setMobileSidebar] = useState(false);
  const [minimizedSidebar, setMinimizedSidebar] = useState(false);
  const [activeInputs, setActiveInputs] = useState(false);
  const initialValues = {
    orgName: "",
    appName: "",
    cin: "",
    gst: "",
    lineOne: "",
    lineTwo: "",
    city: "",
    state: "",
    country: "",
    pincode: "",
    orgCountry: "",
    timezone: "",
    timezoneFormat: "",
    language: "",
    currency: "",
    dateFormat: "",
    timeformat: "",
    week_start_on: "",
    twitter: "",
    facebook: "",
    instagram: "",
    youtube: "",
    linkedin: "",
    github: "",
    pinterest: "",
  };

  const validationSchema = Yup.object({
    orgName: Yup.string().required("Organization name is required"),
    appName: Yup.string().required("App name is required"),
    cin: Yup.string().required("CIN is required"),
    gst: Yup.string().required("GST is required").min(15, "GST number should be 15 digits long").max(15, "GST number should be 15 digits long").trim(),
    lineOne: Yup.string().required("Address line one is required"),
    lineTwo: Yup.string(),
    city: Yup.string().required("City is required"),
    state: Yup.string().required("State is required"),
    country: Yup.string().required("Country is required"),
    pincode: Yup.string().required("Pincode is required"),
    orgCountry: Yup.string().required("Please select Organization Country"),
    timezone: Yup.string().required("Please select Time Zone"),
    timezoneFormat: Yup.string().required("Please select Time Zone Format "),
    language: Yup.string().required("Please select Language"),
    currency: Yup.string().required("Please select Currency"),
    dateFormat: Yup.string().required("Please select Date Format"),
    timeformat: Yup.string().required("Please select Time Format"),
    week_start_on: Yup.string().required("Please select Week Start Day"),
    twitter: Yup.string().nullable(),
    facebook: Yup.string().nullable(),
    instagram: Yup.string().nullable(),
    youtube: Yup.string().nullable(),
    linkedin: Yup.string().nullable(),
    github: Yup.string().nullable(),
    pinterest: Yup.string().nullable(),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      const data = {
        application_name: values.appName,
        organization: {
          name: values.orgName,
          cin: values.cin,
          gst: values.gst,
          address: {
            line_one: values.lineOne,
            line_two: values.lineTwo,
            city: values.city,
            state: values.state,
            country: values.country,
            pincode: values.pincode,
          }
        },
        country: {
          name: values.orgCountry,
          code: selectedCountryData?.isoCode,
        },
        timezone: {
          name: values.timezone,
          format: values.timezoneFormat,
        },
        language: {
          name: values.language?.split("#")?.[0],
          code: values.language?.split("#")?.[1],
        },
        currency: {
          code: values.currency,
          symbol: selectedCountryData?.currency?.symbol,
        },
        date_format: values.dateFormat,
        time_format: values.timeformat,
        week_start_on: "Sunday",
        social_link: {
          twitter: values.twitter,
          facebook: values.facebook,
          instagram: values.instagram,
          youtube: values.youtube,
          linkedin: values.linkedin,
          github: values.github,
          pinterest: values.pinterest,
        },
      };
      const res = await dispatch(updateCompanyyDetail(data));
      if (res?.payload) {
        setActiveInputs(false);
      }
    },
  });

  const handleProfilePic = (e) => {
    const selectedImg = e.target.files[0];

    if (
      selectedImg.type === "image/png" ||
      selectedImg.type === "image/svg+xml"
    ) {
      if (selectedImg.size > 5 * 1024 * 1024) {
        toast.warning(
          "File size exceeds 5MB limit. Please select a smaller file."
        );
        e.target.value = null;
      } else {
        setCompanyLogo(URL.createObjectURL(selectedImg));
        e.target.value = null;
        dispatch(changeCompanyLogo({ logo: selectedImg }));
      }
    } else {
      toast.warning("Accepts .png and .svg files only.");
      e.target.value = null;
    }
  };

  const handleRemove = async () => {
    setCompanyLogo(companyImage);
    fetch(companyImage)
      .then((res) => res.blob())
      .then((blob) => {
        const fileName = companyImage.split("/").pop();
        const file = new File([blob], fileName, { type: blob.type });
        dispatch(changeCompanyLogo({ logo: file }));
      });
  };

  useEffect(() => {
    if (Object.keys(companyDetail).length > 0) {
      formik.setValues({
        orgName: formik.values.orgName || companyDetail?.application?.organization?.name || "",
        appName: formik.values.appName || companyDetail?.application?.name || "",
        gst: formik.values.gst || companyDetail?.application?.organization?.GST || "",
        cin: formik.values.cin || companyDetail?.application?.organization?.CIN || "",
        lineOne: formik.values.lineOne || companyDetail.application.organization?.address?.lineOne || "",
        lineTwo: formik.values.lineTwo || companyDetail.application.organization?.address?.lineTwo || "",
        city: locality?.[0]?.city ? locality?.[0]?.city : companyDetail.application.organization?.address?.city
          ? companyDetail.application.organization?.address?.city : "",
        state: locality?.[0]?.state ? locality?.[0]?.state : companyDetail.application.organization?.address?.state || "",
        country: locality?.[0]?.country ? locality?.[0]?.country : companyDetail.application.organization?.address?.country || "",
        pincode: formik.values.pincode ? formik.values.pincode : companyDetail.application.organization?.address?.pincode || "",
        orgCountry: formik.values.orgCountry || companyDetail.application.country.name,
        timezone: formik.values.timezone || companyDetail.application.timezone.name,
        timezoneFormat: formik.values.timezoneFormat || companyDetail.application.timezone.format,
        week_start_on: formik.values.week_start_on || companyDetail.application.weekStartsOn,
        language: formik.values.language || `${companyDetail.application.language.name} # ${companyDetail.application.language.code}`,
        currency: formik.values.currency || companyDetail.application.currency.code,
        dateFormat: formik.values.dateFormat || companyDetail.application.dateFormat,
        timeformat: formik.values.timeformat || companyDetail.application.timeFormat,
        facebook: formik.values.facebook || companyDetail.application.socialLinks.facebook.link,
        twitter: formik.values.twitter || companyDetail.application.socialLinks.twitter.link,
        youtube: formik.values.youtube || companyDetail.application.socialLinks.youtube.link,
        pinterest: formik.values.pinterest || companyDetail.application.socialLinks.pinterest.link,
        instagram: formik.values.instagram || companyDetail.application.socialLinks.instagram.link,
        linkedin: formik.values.linkedin || companyDetail.application.socialLinks.linkedin.link,
        github: formik.values.github || companyDetail.application.socialLinks.github.link,
      });
      setCompanyLogo(
        companyDetail?.application?.logo?.bucketUrl ||
        companyDetail?.application?.logo?.localUrl ||
        companyImage
      );
    }
  }, [companyDetail, locality]);
  console.log('formik.values.language', formik.values.language)
  const getLocalAddress = async (e) => {
    formik.setFieldValue("pincode", e);
    if (e.length === 6) {
      const data = {
        pincode: e,
      };
      const res = await dispatch(getLocality(data));
      if (res?.error) {
        setPinStatue(false);
      } else {
        setPinStatue(true);
      }
    }
  };

  useEffect(() => {
    if (countryList.length > 0) {
      const cc = countryList.map((item) => {
        const label = `${item?.name}`;
        const value = item?.name;
        const key = item.id;
        return { label, value, key };
      });
      setCountryOptions(cc);
    }
  }, [countryList]);

  useEffect(() => {
    if (formik.values.orgCountry) {
      const data = countryList?.filter(
        (ele) => ele.name === formik.values.orgCountry
      )?.[0];
      setSelectedCountryData(data);
    }
  }, [formik.values.orgCountry]);

  useEffect(() => {
    if (selectedCountryData) {
      const opts = selectedCountryData?.timeZone.map((item, i) => {
        const label = `${item?.name} - ${item.format}`;
        const value = item?.name;
        const key = i;
        return { label, value, key };
      });
      setTzOptions(opts);
      const langOpts = selectedCountryData?.language.map((item, i) => {
        const label = `${item?.name}`;
        const value = `${item?.name} # ${item.code}`;
        const key = i;
        return { label, value, key };
      });
      setLanguageOptions(langOpts);
      formik.setFieldValue("currency", selectedCountryData?.currency?.code);
    }
  }, [selectedCountryData]);

  useEffect(() => {
    if (formik.values.timezone) {
      const tzf = tzOptions?.filter(
        (ele) => ele.value === formik.values.timezone
      )?.[0];
      const ff = tzf?.label.replace("-", "%").split("%")?.[1];
      formik.setFieldValue("timezoneFormat", ff);
    }
  }, [formik.values.timezone]);

  return (
    <ModuleLayout
      minimizedSidebar={minimizedSidebar}
      setMinimizedSidebar={setMinimizedSidebar}
      mobileSidebar={mobileSidebar}
      setMobileSidebar={setMobileSidebar}
      active={active}
      setActive={setActive}
    >
      <div
        className="profile-wrapper"
        style={{ padding: "1.6rem", marginBottom: "1rem" }}
      >
        <div className="header-wrapper">
          <div className="sub-header flex-between">
            <h2 className="title">Company Details</h2>
            <div className="flex-center gap-2">
              {activeInputs && (
                <button
                  className="btn-outlined cancel"
                  onClick={() => {
                    setActiveInputs(!activeInputs);
                  }}
                  type="button"
                >
                  Discard
                </button>
              )}
              {activeInputs ? (
                <SubmitButton
                  type="submit"
                  onClick={() => {
                    formik.handleSubmit();
                  }}
                  className="btn-primary"
                  isLoading={companyLoading}
                >
                  Update
                </SubmitButton>
              ) : (
                <button
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    setActiveInputs(true);
                  }}
                  className="btn-outlined edit flex-center gap-2"
                >
                  <i className="fa-regular fa-pen-to-square"></i>
                  Edit
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="custom-box">
        <div className="update-dp flex gap-2">
          <div className="image-box">
            <img
              src={companyLogo}
              alt="profile-logo"
              className="profile-img"
              crossOrigin="anonymous"
              style={{ height: "100%" }}
            />
          </div>
          <div className="flex-center gap-2">
            {privileges.hasOwnProperty("settingManagement") &&
              Object?.values(privileges["settingManagement"])?.includes(
                "write"
              ) && (
                <label htmlFor="company_logo" className="profChange_label">
                  Change Logo
                  <input
                    type="file"
                    accept=".jpg,.png,.jpeg,.webp"
                    className="profChange_input"
                    name="company_logo"
                    id="company_logo"
                    onChange={handleProfilePic}
                    hidden
                  />
                </label>
              )}

            {privileges.hasOwnProperty("settingManagement") &&
              Object?.values(privileges["settingManagement"])?.includes(
                "delete"
              ) && (
                <button
                  className="btn-outlined cancel"
                  onClick={handleRemove}
                  style={{ color: "#D0202E" }}
                >
                  Remove
                </button>
              )}
          </div>
        </div>
        <form className="company-form">
          <h2 className="mt-1">Organization Details</h2>
          <div className="form-col-2">
            <div className="form-control">
              <label className="form-label">Organization Name</label>
              <div className="form-input-box">
                <InputField
                  type="text"
                  id="orgName"
                  name="orgName"
                  value={formik.values.orgName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  disabled={!activeInputs}
                  maxLength={81}
                />
              </div>
              {formik.errors.orgName && formik.touched.orgName ? (
                <span className="err">{formik.errors.orgName}</span>
              ) : null}
            </div>
            <div className="form-control">
              <label className="form-label">App Name</label>
              <div className="form-input-box">
                <InputField
                  type="text"
                  id="appName"
                  name="appName"
                  value={formik.values.appName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  disabled={!activeInputs}
                  maxLength={81}
                />
              </div>
              {formik.errors.appName && formik.touched.appName ? (
                <span className="err">{formik.errors.appName}</span>
              ) : null}
            </div>
          </div>
          <div className="form-col-2 mt-1">
            <div className="form-control">
              <label className="form-label">Corporate Identification Number</label>
              <div className="form-input-box">
                <InputField
                  type="text"
                  id="cin"
                  name="cin"
                  value={formik.values.cin}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  disabled={!activeInputs}
                  maxLength={81}
                />
              </div>
              {formik.errors.cin && formik.touched.cin ? (
                <span className="err">{formik.errors.cin}</span>
              ) : null}
            </div>
            <div className="form-control">
              <label className="form-label">GST Number</label>
              <div className="form-input-box">
                <InputField
                  type="text"
                  id="gst"
                  name="gst"
                  value={formik.values.gst}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  disabled={!activeInputs}
                  maxLength={81}
                />
              </div>
              {formik.errors.gst && formik.touched.gst ? (
                <span className="err">{formik.errors.gst}</span>
              ) : null}
            </div>
          </div>
          <h3 className="mt-1">Organization Address</h3>
          <div className="flex flex-col gap-1">
            <div className="form-control">
              <label className="form-label">Address Line One</label>
              <div className="form-input-box">
                <InputField
                  type="text"
                  id="lineOne"
                  name="lineOne"
                  value={formik.values.lineOne}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  disabled={!activeInputs}
                  maxLength={81}
                />
              </div>
              {formik.errors.lineOne && formik.touched.lineOne ? (
                <span className="err">{formik.errors.lineOne}</span>
              ) : null}
            </div>
            <div className="form-control">
              <label className="form-label">Address Line Two</label>
              <div className="form-input-box">
                <InputField
                  type="text"
                  id="lineTwo"
                  name="lineTwo"
                  value={formik.values.lineTwo}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  disabled={!activeInputs}
                  maxLength={81}
                />
              </div>
              {formik.errors.lineTwo && formik.touched.lineTwo ? (
                <span className="err">{formik.errors.lineTwo}</span>
              ) : null}
            </div>
          </div>
          <div className="form-col-2 mt-1">
            <div className="form-control">
              <label className="form-label">Pincode</label>
              <div className="form-input-box">
                <InputField
                  type="text"
                  id="pincode"
                  name="pincode"
                  value={formik.values.pincode}
                  onChange={(e) => getLocalAddress(e.target.value)}
                  onBlur={formik.handleBlur}
                  disabled={!activeInputs}
                  onInput={(e) => {
                    e.target.value = e.target.value
                      .slice(0, 6)
                      .replace(/\D/g, "");
                  }}
                  maxLength={6}
                />
              </div>
              {formik.errors.pincode && formik.touched.pincode ? (
                <span className="err">{formik.errors.pincode}</span>
              ) : null}
            </div>
            <div className="form-control">
              <label className="form-label">City</label>
              <div className="form-input-box">
                <InputField
                  type="text"
                  id="city"
                  name="city"
                  value={formik.values.city}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  disabled={pinStatue}
                  maxLength={81}
                />
              </div>
              {formik.errors.city && formik.touched.city ? (
                <span className="err">{formik.errors.city}</span>
              ) : null}
            </div>
          </div>
          <div className="form-col-2 mt-1">
            <div className="form-control">
              <label className="form-label">State</label>
              <div className="form-input-box">
                <InputField
                  type="text"
                  id="state"
                  name="state"
                  value={formik.values.state}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  disabled={pinStatue}
                  maxLength={81}
                />
              </div>
              {formik.errors.state && formik.touched.state ? (
                <span className="err">{formik.errors.state}</span>
              ) : null}
            </div>
            <div className="form-control">
              <label className="form-label">Country</label>
              <div className="form-input-box">
                <InputField
                  type="text"
                  id="country"
                  name="country"
                  value={formik.values.country}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  disabled={pinStatue}
                  maxLength={81}
                />
              </div>
              {formik.errors.country && formik.touched.country ? (
                <span className="err">{formik.errors.country}</span>
              ) : null}
            </div>
          </div>
          <div className="form-col-2 mt-1">
            <div className="form-control">
              <label className="form-label">Organization Country</label>
              <div className="form-input-box">
                <DropDownBox
                  options={countryOptions}
                  customSetter={formik.setFieldValue}
                  customFormikLabel="orgCountry"
                  incomingValue={formik.values.orgCountry}
                  showSearchBar
                  disabled={activeInputs}
                  customDropBoxStyles={{
                    border: "1px solid #d2d6da",
                    borderRadius: ".5rem",
                    color: "#454545",
                  }}
                />
              </div>
              {formik.errors.orgCountry && formik.touched.orgCountry ? (
                <span className="err">{formik.errors.orgCountry}</span>
              ) : null}
            </div>
            <div className="form-control">
              <label className="form-label">Time Zone</label>
              <div className="form-input-box">
                <DropDownBox
                  options={tzOptions}
                  customSetter={formik.setFieldValue}
                  customFormikLabel="timezone"
                  incomingValue={formik.values.timezone}
                  showSearchBar
                  disabled={activeInputs}
                  customDropBoxStyles={{
                    border: "1px solid #d2d6da",
                    borderRadius: ".5rem",
                    color: "#454545",
                  }}
                />
              </div>
              {formik.errors.timezone && formik.touched.timezone ? (
                <span className="err">{formik.errors.timezone}</span>
              ) : null}
            </div>
          </div>
          <div className="form-col-2 mt-1">
            <div className="form-control">
              <label className="form-label">Language</label>
              <div className="form-input-box">
                <DropDownBox
                  options={languageOptions}
                  customSetter={formik.setFieldValue}
                  customFormikLabel="language"
                  incomingValue={formik.values.language}
                  showSearchBar
                  disabled={activeInputs}
                  customDropBoxStyles={{
                    border: "1px solid #d2d6da",
                    borderRadius: ".5rem",
                    color: "#454545",
                  }}
                />
              </div>
              {formik.errors.language && formik.touched.language ? (
                <span className="err">{formik.errors.language}</span>
              ) : null}
            </div>
            <div className="form-control">
              <label className="form-label">Currency</label>
              <div className="form-input-box">
                <InputField
                  type="text"
                  id="currency"
                  name="currency"
                  value={formik.values.currency}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  maxLength={81}
                  disabled
                />
              </div>
              {formik.errors.currency && formik.touched.currency ? (
                <span className="err">{formik.errors.currency}</span>
              ) : null}
            </div>
          </div>
          <div className="form-col-3 mt-1">
            <div className="form-control">
              <label className="form-label">Date Format</label>
              <div className="form-input-box">
                <DropDownBox
                  options={[
                    {
                      label: "31/12/2024",
                      value: "31/12/2024",
                    },
                    {
                      label: "12/31/2024",
                      value: "12/31/2024",
                    },
                    {
                      label: "2024-12-31",
                      value: "2024-12-31",
                    },
                  ]}
                  customSetter={formik.setFieldValue}
                  customFormikLabel="dateFormat"
                  incomingValue={formik.values.dateFormat}
                  disabled={activeInputs}
                  customDropBoxStyles={{
                    border: "1px solid #d2d6da",
                    borderRadius: ".5rem",
                    color: "#454545",
                  }}
                />
              </div>
              {formik.errors.dateFormat && formik.touched.dateFormat ? (
                <span className="err">{formik.errors.dateFormat}</span>
              ) : null}
            </div>
            <div className="form-control">
              <label className="form-label">Time Format</label>
              <div className="form-input-box">
                <DropDownBox
                  options={[
                    {
                      label: "12 Hours",
                      value: "12hour",
                    },
                    {
                      label: "24 Hours",
                      value: "24hour",
                    },
                  ]}
                  customSetter={formik.setFieldValue}
                  customFormikLabel="timeformat"
                  incomingValue={formik.values.timeformat}
                  disabled={activeInputs}
                  customDropBoxStyles={{
                    border: "1px solid #d2d6da",
                    borderRadius: ".5rem",
                    color: "#454545",
                  }}
                />
              </div>
              {formik.errors.timeformat && formik.touched.timeformat ? (
                <span className="err">{formik.errors.timeformat}</span>
              ) : null}
            </div>
            <div className="form-control">
              <label className="form-label">Week Start Day</label>
              <div className="form-input-box">
                <DropDownBox
                  options={[
                    {
                      label: "Sunday",
                      value: "Sunday",
                    },
                    {
                      label: "Monday",
                      value: "Monday",
                    },
                  ]}
                  customSetter={formik.setFieldValue}
                  customFormikLabel="week_start_on"
                  incomingValue={formik.values.week_start_on}
                  disabled={activeInputs}
                  customDropBoxStyles={{
                    border: "1px solid #d2d6da",
                    borderRadius: ".5rem",
                    color: "#454545",
                  }}
                />
              </div>
              {formik.errors.week_start_on && formik.touched.week_start_on ? (
                <span className="err">{formik.errors.week_start_on}</span>
              ) : null}
            </div>
          </div>
          <h3 className="mt-1">Social Links</h3>
          <div className="flex flex-col gap-1">
            <div className="form-control">
              <div className="form-input-box flex-center">
                <div className="social-icon">
                  <i className="fa-brands fa-x-twitter"></i>
                </div>
                <InputField
                  type="text"
                  id="twitter"
                  name="twitter"
                  value={formik.values.twitter}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  disabled={!activeInputs}
                  maxLength={81}
                />
              </div>
              {formik.errors.twitter && formik.touched.twitter ? (
                <span className="err">{formik.errors.twitter}</span>
              ) : null}
            </div>
            <div className="form-control">
              <div className="form-input-box flex-center">
                <div className="social-icon">
                  <i className="fa-brands fa-facebook-f"></i>
                </div>
                <InputField
                  type="text"
                  id="facebook"
                  name="facebook"
                  value={formik.values.facebook}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  disabled={!activeInputs}
                  maxLength={81}
                />
              </div>
              {formik.errors.facebook && formik.touched.facebook ? (
                <span className="err">{formik.errors.facebook}</span>
              ) : null}
            </div>
            <div className="form-control">
              <div className="form-input-box flex-center">
                <div className="social-icon">
                  <i className="fa-brands fa-instagram"></i>
                </div>
                <InputField
                  type="text"
                  id="instagram"
                  name="instagram"
                  value={formik.values.instagram}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  disabled={!activeInputs}
                  maxLength={81}
                />
              </div>
              {formik.errors.instagram && formik.touched.instagram ? (
                <span className="err">{formik.errors.instagram}</span>
              ) : null}
            </div>
            <div className="form-control">
              <div className="form-input-box flex-center">
                <div className="social-icon">
                  <i className="fa-brands fa-youtube"></i>
                </div>
                <InputField
                  type="text"
                  id="youtube"
                  name="youtube"
                  value={formik.values.youtube}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  disabled={!activeInputs}
                  maxLength={81}
                />
              </div>
              {formik.errors.youtube && formik.touched.youtube ? (
                <span className="err">{formik.errors.youtube}</span>
              ) : null}
            </div>
            <div className="form-control">
              <div className="form-input-box flex-center">
                <div className="social-icon">
                  <i className="fa-brands fa-linkedin-in"></i>
                </div>
                <InputField
                  type="text"
                  id="linkedin"
                  name="linkedin"
                  value={formik.values.linkedin}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  disabled={!activeInputs}
                  maxLength={81}
                />
              </div>
              {formik.errors.linkedin && formik.touched.linkedin ? (
                <span className="err">{formik.errors.linkedin}</span>
              ) : null}
            </div>
            <div className="form-control">
              <div className="form-input-box flex-center">
                <div className="social-icon">
                  <i className="fa-brands fa-github"></i>
                </div>
                <InputField
                  type="text"
                  id="github"
                  name="github"
                  value={formik.values.github}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  disabled={!activeInputs}
                  maxLength={81}
                />
              </div>
              {formik.errors.github && formik.touched.github ? (
                <span className="err">{formik.errors.github}</span>
              ) : null}
            </div>
            <div className="form-control">
              <div className="form-input-box flex-center">
                <div className="social-icon">
                  <i className="fa-brands fa-pinterest-p"></i>
                </div>
                <InputField
                  type="text"
                  id="pinterest"
                  name="pinterest"
                  value={formik.values.pinterest}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  disabled={!activeInputs}
                  maxLength={81}
                />
              </div>
              {formik.errors.pinterest && formik.touched.pinterest ? (
                <span className="err">{formik.errors.pinterest}</span>
              ) : null}
            </div>
          </div>
        </form>
      </div>
      <div className="maint-mng">
        <Maintenance />
      </div>
    </ModuleLayout>
  );
};

export default Company;
