/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  badge1,
  badge2,
  badge3,
  badge4,
  badge5,
  badge6,
  trophy1,
  trophy2,
  trophy3,
  trophy4,
} from "assets/icons";
import DropDownBox from "components/DropDownBox";
import InputField from "components/ui/InputField";
import { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getUserDetail } from "../redux/userApi";
import { useFormik } from "formik";
import { unixTimeToYYYYMMDD } from "helper/helper";

const UserDetails = () => {
  const { user_id } = useParams();
  const dispatch = useDispatch();
  const { userDetail } = useSelector((state) => state.user, shallowEqual);

  useEffect(() => {
    if (user_id) {
      dispatch(getUserDetail(user_id));
    }
  }, [dispatch]);

  const activeInputs = false;

  const badgesList = [
    { name: "badge 1", src: badge1 },
    { name: "badge 2", src: badge2 },
    { name: "badge 3", src: badge3 },
    { name: "badge 4", src: badge4 },
    { name: "badge 5", src: badge5 },
    { name: "badge 6", src: badge6 },
  ];

  const trophyList = [
    { name: "Gold", date: "April 2024", src: trophy2 },
    { name: "Silver", date: "March 2024", src: trophy3 },
    { name: "Bronze", date: "Feb 2024", src: trophy4 },
  ];

  const initialValues = {
    userId: "",
    userName: "",
    firstName: "",
    lName: "",
    email: "",
    phone_no: "",
    gender: "",
    dob: "",
    age: "",
  };

  const formik = useFormik({
    initialValues,
    onSubmit: async (values) => {

    },
  });

  useEffect(() => {
    if (userDetail) {
      formik.setValues({
        userId: userDetail?.id,
        userName: userDetail?.username,
        firstName: userDetail?.name?.firstName,
        lastName: userDetail?.name?.lastName,
        email: userDetail?.email?.value,
        phone_no: userDetail?.phone?.value,
        gender: userDetail.gender,
        dob: unixTimeToYYYYMMDD(userDetail?.dob),
      });
    }
  }, [userDetail]);

  return (
    <div className="details-wrapper-body">
      <div className="details-general custom-box">
        <h3>General Details</h3>
        <div className="auth-form-wrapper">
          <form className="form" noValidate="novalidate">
            <div className="form-col-2">
              <div className="form-control">
                <label className="form-label">User ID</label>
                <div className="form-input-box">
                  <InputField
                    type="text"
                    id="user_id"
                    name="user_id"
                    value={formik.values.userId}
                    className="form-input border-0"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    disabled={!activeInputs}
                    onInput={(e) => {
                      e.target.value = e.target.value
                        .replace(/[^A-Za-z ]/gi, "")
                        .replace(/(?:^|\s)\S/g, (match) => match.toUpperCase());
                    }}
                    onPaste={(e) => {
                      e.preventDefault();
                    }}
                    onCopy={(e) => {
                      e.preventDefault();
                    }}
                    onKeyDown={(e) => {
                      if (e.key === " ") {
                        e.preventDefault();
                      }
                    }}
                    maxLength={81}
                  />
                </div>
              </div>
              <div className="form-control">
                <label className="form-label">User Name</label>
                <div className="form-input-box">
                  <InputField
                    type="text"
                    id="userName"
                    name="userName"
                    value={formik.values.userName}
                    className="form-input border-0"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    onInput={(e) => {
                      e.target.value = e.target.value
                        .replace(/[^A-Za-z ]/gi, "")
                        .replace(/(?:^|\s)\S/g, (match) => match.toUpperCase());
                    }}
                    maxLength={81}
                    disabled={!activeInputs}
                  />
                </div>
              </div>
            </div>
            <div className="form-col-2">
              <div className="form-control">
                <label className="form-label">First Name</label>
                <div className="form-input-box">
                  <InputField
                    type="text"
                    id="firstName"
                    name="firstName"
                    value={formik.values.firstName}
                    className="form-input border-0"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    disabled={!activeInputs}
                    onInput={(e) => {
                      e.target.value = e.target.value
                        .replace(/[^A-Za-z ]/gi, "")
                        .replace(/(?:^|\s)\S/g, (match) => match.toUpperCase());
                    }}
                    onPaste={(e) => {
                      e.preventDefault();
                    }}
                    onCopy={(e) => {
                      e.preventDefault();
                    }}
                    onKeyDown={(e) => {
                      if (e.key === " ") {
                        e.preventDefault();
                      }
                    }}
                    maxLength={81}
                  />
                </div>
              </div>
              <div className="form-control">
                <label className="form-label">Last Name</label>
                <div className="form-input-box">
                  <InputField
                    type="text"
                    id="lastName"
                    name="lastName"
                    className="form-input border-0"
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    onInput={(e) => {
                      e.target.value = e.target.value
                        .replace(/[^A-Za-z ]/gi, "")
                        .replace(/(?:^|\s)\S/g, (match) => match.toUpperCase());
                    }}
                    maxLength={81}
                    disabled={!activeInputs}
                  />
                </div>
              </div>
            </div>
            <div className="form-control">
              <label className="form-label">Email</label>
              <div className="form-input-box">
                <InputField
                  type="text"
                  id="email"
                  name="email"
                  className="form-input border-0"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  disabled
                />
                {
                  userDetail?.email?.isVerified &&
                  <div className="verf-box">
                    <i className="fa-sharp fa-solid fa-circle-check"></i>
                  </div>
                }
              </div>
            </div>
            <div className="form-control">
              <label className="form-label">Phone Number</label>
              <div className="form-input-box">
                <InputField
                  type="text"
                  id="phone_no"
                  name="phone_no"
                  className={`form-input`}
                  value={formik.values.phone_no}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  onInput={(e) => {
                    e.target.value = e.target.value
                      .slice(0, 10)
                      .replace(/\D/g, "");
                  }}
                  disabled={!activeInputs}
                />
                {
                  userDetail?.phone?.isVerified &&
                  <div className="verf-box">
                    <i className="fa-sharp fa-solid fa-circle-check"></i>
                  </div>
                }
              </div>
            </div>

            <div className="form-col-2">
              <div className="form-control">
                <label className="form-label">Gender</label>
                <div className="form-input-box">
                  <DropDownBox
                    options={[
                      { label: "Male", value: "male" },
                      {
                        label: "Female",
                        value: "female",
                      },
                    ]}
                    defaultValue="Male"
                    customSetter={formik.setFieldValue}
                    customFormikLabel="gender"
                    customDropBoxStyles={{
                      border: "none",
                      borderRadius: "0.5rem",
                      color: "#454545",
                      padding: "1rem .9rem",
                    }}
                    customTextStyle={{
                      color: "#212229",
                      opacity: "0.7",
                    }}
                    incomingValue={userDetail?.gender}
                    disabled={activeInputs}
                  />
                </div>
              </div>
              <div className="form-control">
                <label className="form-label">Date of Birth</label>
                <div className="form-input-box">
                  <InputField
                    type="date"
                    id="dob"
                    name="dob"
                    className="form-input border-0"
                    value={formik.values.dob}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    disabled={!activeInputs}
                  />
                </div>
              </div>

              {/* <div className="form-control">
                <label className="form-label">Age</label>
                <div className="form-input-box">
                  <InputField
                    type="text"
                    id="age"
                    name="age"
                    value={"29"}
                    className="form-input border-0"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    disabled={!activeInputs}
                  />
                </div>
              </div> */}
            </div>
            <div className="form-col-3">
              {formik.errors.gender && formik.touched.gender ? (
                <span className="err">{formik.errors.gender}</span>
              ) : null}
            </div>
          </form>
        </div>
      </div>

      <div className="details-awards">
        <div className="custom-box flex flex-col gap-2">
          <h3>Badges Earned</h3>
          <div className="latest-badge">
            <img src={badge3} alt="latest earned badge" />
            <b>lorem ipsum</b>
          </div>
          <div className="all-badges">
            {badgesList.map((item, i) => (
              <div className="badge" key={i}>
                <img src={item.src} alt={item.name} />
                <p>{item.name}</p>
              </div>
            ))}
          </div>
        </div>
        <div className="custom-box flex flex-col gap-2">
          <h3>Trophies Earned</h3>
          <div className="latest-badge">
            <img src={trophy1} alt="latest earned badge" />
            <b>lorem ipsum</b>
          </div>
          <div className="all-badges">
            {trophyList.map((item, i) => (
              <div className="badge flex-center flex-col gap-1" key={i}>
                <img src={item.src} alt={item.name} />
                <p className="name">{item.name}</p>
                <p className="date">{item.date}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserDetails;
