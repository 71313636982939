import React from "react";
import { ClipLoader } from "react-spinners";

const SubmitButton = ({
  isLoading,
  className,
  children,
  onClick,
  size = "100%",
  type = "button",
}) => {
  return (
    <button
      type={type}
      className={className}
      onClick={onClick}
      style={
        isLoading
          ? {
              cursor: "not-allowed",
              textAlign: "center",
              width: size,
              opacity: 0.7,
            }
          : {}
      }
      disabled={isLoading}
    >
      {isLoading ? (
        <ClipLoader
          loading={true}
          size={15}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      ) : (
        children
      )}
    </button>
  );
};

export default SubmitButton;
