import { useEffect, useState } from "react";

const useSelectRows = (tableData, valueType) => {
  const [content, setContent] = useState();
  const [alertBox, setAlertBox] = useState(false);
  const [selectedRows, setSelectedRows] = useState(new Set());

  // Handles selecting/deselecting all rows
  const toggleSelectAll = () => {
    if (selectedRows.size === tableData.length) {
      setSelectedRows(new Set());
    } else {
      const allIds = tableData.map((item) => item.id);
      setSelectedRows(new Set(allIds));
    }
  };

  // Checks if all rows are selected
  const selectAllRows = selectedRows.size === tableData.length;

  // Update selectedRows based on individual row selection
  const toggleRowSelection = (id) => {
    setSelectedRows((prevSelected) => {
      const newSelected = new Set(prevSelected);
      if (newSelected.has(id)) {
        newSelected.delete(id);
      } else {
        newSelected.add(id);
      }
      return newSelected;
    });
  };

  const deleteFunction = (slug, title, limit = 5) => {
    const collectAllRows = Array.from(selectedRows);
    console.log("collectAllRows: ", collectAllRows);
    setContent({
      data: {
        slug,
        page: 0,
        search: "",
        sort: "desc",
        limit: limit,
        send: {
          is_delete: true,
          [valueType]: collectAllRows.length > 0 ? collectAllRows : [slug],
        },
      },
      title,
    });
    // setSelectedRows(new Set());
    setAlertBox(true);
  };

  useEffect(() => {
    if (selectedRows.size === 0) {
      setSelectedRows(new Set());
    }
  }, [tableData]);

  return {
    content,
    alertBox,
    setAlertBox,
    selectAllRows,
    setSelectedRows,
    selectedRows: Array.from(selectedRows),
    toggleSelectAll,
    toggleRowSelection,
    deleteFunction,
  };
};

export default useSelectRows;
