/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { yyyyMMDDToISO } from "helper/helper";
import SubmitButton from "components/ui/SubmitButton";
import ModuleLayout from "layouts/ModuleLayout";
import { useFormik } from "formik";
import * as Yup from "yup";

const PersonalDetails = ({ setTitle }) => {
  useEffect(() => {
    setTitle("Personal Details");
  }, [setTitle]);

  const navigate = useNavigate();

  const [active, setActive] = useState("Personal Details");
  const [mobileSidebar, setMobileSidebar] = useState(false);
  const [minimizedSidebar, setMinimizedSidebar] = useState(false);

  const [activeInputs, setActiveInputs] = useState(true);

  const initialValues = {
    userID: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    gender: "",
    dob: "",
    address_line_one: "",
    address_line_two: "",
    pincode: "",
    city: "",
    state: "",
    country: "",
  };

  const profileSchema = Yup.object({
    firstName: Yup.string()
      .trim()
      .min(3, "Name must be at least 3 characters long")
      .max(80, "First name must be less then 80 characters")
      .matches(/^[A-Za-z ]+$/, "Please enter a valid name")
      .required("First name is required"),
    lastName: Yup.string()
      .trim()
      .min(3, "Last Name must be at least 3 characters long")
      .max(80, "Last name must be less then 80 characters")
      .matches(/^[A-Za-z ]+$/, "Please enter a valid name")
      .required("Last name is required"),
    email: Yup.string()
      .trim()
      .email("Complete email address")
      .matches(
        /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
        "Enter a valid email, please!"
      )
      .required("Email address should not be empty"),
    phone: Yup.string()
      .matches(/^[6-9]\d{9}$/, "Please enter a valid phone number")
      .required("Phone number is required"),
    gender: Yup.string().required("Gender is required"),
    dob: Yup.date()
      .required("Birth date is required")
      .test("age", "You must be 18 years old or above", (value) => {
        const today = new Date(); // Define 'today' as the current date
        const birthDate = new Date(value);
        const ageDifferenceInMilliseconds = today - birthDate;
        const ageInYears =
          ageDifferenceInMilliseconds / (365.25 * 24 * 60 * 60 * 1000); // Approximate calculation
        return ageInYears >= 18;
      })
      .test("age two", "User's age must be less than 100 years", (value) => {
        if (new Date(value).getFullYear() >= new Date().getFullYear() - 100) {
          return true;
        } else {
          return false;
        }
      }),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: profileSchema,
    onSubmit: async (values) => {
      const data = {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        phone: `+91${values.phone}`,
        gender: values.gender,
        dob: yyyyMMDDToISO(values.dob),
      };
            
    },
  });

  const personalDetail = true;
  return (
    <ModuleLayout
      minimizedSidebar={minimizedSidebar}
      setMinimizedSidebar={setMinimizedSidebar}
      mobileSidebar={mobileSidebar}
      setMobileSidebar={setMobileSidebar}
      active={active}
      setActive={setActive}
    >
      <div className="list-container custom-box">
        <h2>Personal Details</h2>
        <div className="form-header">
          <div className="form-header-title">
            <b>General Details</b>
            <p>Update your personal details here.</p>
          </div>

          {personalDetail > 0 && (
            <div className="form-control">
              {activeInputs ? (
                <>
                  <button
                    type="button"
                    onClick={() => navigate(-1)}
                    className="btn-outlined cancel"
                  >
                    Back
                  </button>
                  <button
                    type="button"
                    onClick={() => setActiveInputs(false)}
                    className="btn-primary"
                  >
                    Update
                  </button>
                </>
              ) : (
                <>
                  <button
                    type="button"
                    onClick={() => setActiveInputs(true)}
                    className="btn-outlined tertiary"
                  >
                    Abort
                  </button>
                  <SubmitButton
                    className="btn-primary"
                    type="submit"
                    onClick={formik.handleSubmit}
                    disabled={formik.isSubmitting}
                  >
                    Submit
                  </SubmitButton>
                </>
              )}
            </div>
          )}
        </div>
        <main className="main">
          <div className="auth-form-wrapper">
            <h1>form will be here</h1>
          </div>
        </main>
      </div>
    </ModuleLayout>
  );
};

export default PersonalDetails;
