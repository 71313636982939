export const StarRating = ({ rating }) => {
  const stars = [];
  for (let i = 1; i <= 5; i++) {
    if (i <= Math.floor(rating)) {
      stars.push(<span key={i} className="star full"></span>);
    } else if (i === Math.floor(rating) + 1) {
      const partialFill = (rating % 1) * 100;
      stars.push(<span key={i} className="star partial" style={{ '--rating': `${partialFill}%` }}></span>);
    } else {
      stars.push(<span key={i} className="star empty"></span>);
    }
  }
  return <div className="star-rating" title={`${rating} Star`}>{stars}</div>;
};