import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { AddNumField, options } from "helper/helper";
import { isOrderLoading } from "./orderSlice";

export const getOrderList = createAsyncThunk(
  "admin/order-list",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      const response = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/order/order-list?page=${data?.page}&limit=${data?.limit}&sort=${data?.sort ? data?.sort : "desc"}&search=${data.search ? data.search : ""}&filter[status]=${data.status ? data.status : ""}&filter[paymentStatus]=${data.paymentStatus ? data.paymentStatus : ""}`,
      });

      if (response.status === 200) {
        if (response?.data?.data?.length > 0) {
          const res = await AddNumField(
            response?.data?.data?.[0],
            data?.page * data?.limit
          );

          return fulfillWithValue(res);
        } else {
          const modPage = Math.max(data.page - 1, 0);
          const responseAgain = await axios({
            method: "GET",
            url: `${process.env.REACT_APP_AUTH_URL}/admin/order/order-list?page=${data?.page}&limit=${data?.limit}&sort=${data?.sort ? data?.sort : "desc"}&search=${data.search ? data.search : ""}&filter[status]=${data.status ? data.status : ""}&filter[paymentStatus]=${data.paymentStatus ? data.paymentStatus : ""}`,
          });
          if (responseAgain.status === 200) {
            const resAgain = await AddNumField(
              responseAgain?.data,
              modPage * data?.limit
            );
            return fulfillWithValue(resAgain);
          } else {
            toast.error(responseAgain?.data?.message, options);
            return rejectWithValue();
          }
        }
      } else {
        // toast.error(response?.data?.message, options);
        return rejectWithValue();
      }
    } catch (err) {
      return rejectWithValue();
    }
  }
);

export const getOrderDetail = createAsyncThunk(
  "admin/order-detail",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      dispatch(isOrderLoading(true));
      const response = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/order/order-detail/${data.id}`,
        headers: {
          "Content-Type": "application/json"
        }
      })
      if (response.status === 200) {
        dispatch(isOrderLoading(false));
        return fulfillWithValue(response?.data);
      } else {
        dispatch(isOrderLoading(false));
        return rejectWithValue();
      }
    } catch (err) {
      dispatch(isOrderLoading(false));
      return rejectWithValue();
    }
  }
)

export const updateOrderStatus = createAsyncThunk(
  "order/manage-order",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    console.log('data: ', data);
    try {
      dispatch(isOrderLoading(true));
      const response = await axios({
        url: `${process.env.REACT_APP_AUTH_URL}/admin/order/manage-order/${data?.id}`,
        method: "PUT",
        data: data.send,
        headers: {
          "Content-Type": "application/json"
        }
      })
      if (response?.status === 200) {
        dispatch(getOrderDetail({ id: data?.id }))
        toast.success(response?.data?.message, options)
        return fulfillWithValue(response?.data);
      } else {
        toast.error(response?.data?.message, options)
        dispatch(isOrderLoading(false));
        return rejectWithValue();
      }
    } catch (error) {
      dispatch(isOrderLoading(false));
      return rejectWithValue();
    }
  }
)