import Dashboard from "modules/dashboard/pages/Dashboard1";
import Profile from "modules/settings/profile/pages/Profile";
import Policies from "modules/cms/policies/pages/Policies";
import ManagePolicy from "modules/cms/policies/pages/ManagePolicy";
import Templates from "modules/cms/templates/pages/Templates";
import ManageTemplate from "modules/cms/templates/pages/ManageTemplate";
import Products from "modules/products/products/pages/Products";
import ManageProduct from "modules/products/products/pages/ManageProduct";
import Inventory from "modules/products/inventory/pages/Inventory";
import Category from "modules/catalogue/categories/pages/Category";
import Brand from "modules/catalogue/brands/pages/Brand";
import Skills from "modules/catalogue/skills/pages/Skills";
import Questions from "modules/catalogue/questions/pages/Questions";
import QueManager from "modules/catalogue/questions/pages/QueManager";
import Notifications from "modules/notifications/pages/Notifications";
import Leadboard from "modules/leadboard/pages/Leadboard";
import Glossary from "modules/catalogue/glossary/pages/Glossary";
import Coupons from "modules/coupons/pages/Coupons";
import Rewards from "modules/rewards/pages/Rewards";
import FeedBack from "modules/feedback/pages/FeedBack";
import Scores from "modules/scorecard/pages/Scores";
import Email from "modules/email/pages/Email";
import AdminList from "modules/settings/users/administrators/pages/AdminList";
import ManageAdmin from "modules/settings/users/administrators/pages/ManageAdmin";
import UserList from "modules/settings/users/app_users/pages/UserList";
import ManageInventory from "modules/products/inventory/pages/ManageInventory";
import ProgressPoints from "modules/settings/users/app_users/pages/ProgressPoints";
import UserDetails from "modules/settings/users/app_users/pages/UserDetails";
import UserPoints from "modules/settings/users/app_users/pages/UserPoints";
import UserSkills from "modules/settings/users/app_users/pages/UserSkills";
import UserRewards from "modules/settings/users/app_users/pages/UserRewards";
import UserFriends from "modules/settings/users/app_users/pages/UserFriends";
import UserBookmarks from "modules/settings/users/app_users/pages/UserBookmarks";
import UserOrders from "modules/settings/users/app_users/pages/UserOrders";
import UserLayout from "modules/settings/users/app_users/pages/UserLayout";
import Company from "modules/settings/company/pages/Company";
import PersonalDetails from "modules/settings/company/pages/PersonalDetails";
import Address from "modules/address/pages/Address";
import AddAddress from "modules/address/pages/AddAddress";
import Quiz from "modules/catalogue/quizes/pages/Quiz";
import QuizManager from "modules/catalogue/quizes/pages/QuizManager";
import OrderList from "modules/orders/pages/OrderList";
import OrderDetail from "modules/orders/pages/OrderDetail";
import ManageShipment from "modules/shipment/pages/ManageShipment";
import Shipment from "modules/shipment/pages/Shipment";
import Reviews from "modules/reviews/pages/Reviews";
import Managereviews from "modules/reviews/pages/Managereviews";
import Faq from "modules/catalogue/faq/pages/Faq";
import Plans from "modules/plans/pages/Plans";
import ManagePlans from "modules/plans/pages/ManagePlans";
import GlossaryManagement from "modules/catalogue/glossary/pages/GlossaryManagement";

export const MainRoutes = [
  {
    path: "/",
    component: Dashboard,
    position: "head",
  },
  {
    path: "/profile",
    component: Profile,
    position: "head",
  },
  {
    path: "/company",
    component: Company,
    position: "head",
  },

  {
    path: "/company/personal",
    component: PersonalDetails,
    position: "head",
  },
  //  --------->> User Routes <<-----------
  {
    path: "/users",
    component: UserList,
    position: "parent",
    privilegeTag: "userManagement",
  },
  {
    path: "/users/application-users",
    component: UserList,
    position: "parent",
    privilegeTag: "userManagement",
  },
  {
    path: "/users/application-users/:user_id",
    component: UserLayout,
    position: "child",
    privilegeTag: "userManagement",
    children: [
      {
        index: true,
        path: "",
        component: UserDetails,
      },
      {
        path: "skills",
        component: UserSkills,
      },
      {
        path: "points",
        component: UserPoints,
      },
      {
        path: "points/:type",
        component: ProgressPoints,
      },
      {
        path: "rewards",
        component: UserRewards,
      },
      {
        path: "orders",
        component: UserOrders,
      },
      {
        path: "bookmarks",
        component: UserBookmarks,
      },
      {
        path: "friends",
        component: UserFriends,
      },
    ],
  },
  {
    path: "/users/administrator-directory",
    component: AdminList,
    position: "parent",
    privilegeTag: "userManagement",
  },
  {
    path: "/users/administrator-directory/:id",
    component: ManageAdmin,
    position: "child",
    privilegeTag: "userManagement",
  },
  {
    path: "/users/administrator-directory/add-user",
    component: ManageAdmin,
    position: "child",
    privilegeTag: "userManagement",
  },
  //  --------->> CMS Routes <<-----------
  {
    path: "/content/",
    component: Policies,
    position: "parent",
    privilegeTag: "pageManagement",
  },
  {
    path: "/content/pages",
    component: Policies,
    position: "parent",
    privilegeTag: "pageManagement",
  },
  {
    path: "/content/pages/add-new",
    component: ManagePolicy,
    position: "child",
    privilegeTag: "pageManagement",
  },
  {
    path: "/content/pages/:slug",
    component: ManagePolicy,
    position: "child",
    privilegeTag: "pageManagement",
  },
  {
    path: "/content/templates",
    component: Templates,
    position: "parent",
    privilegeTag: "templateManagement",
  },
  {
    path: "/content/templates/add-new",
    component: ManageTemplate,
    position: "child",
    privilegeTag: "templateManagement",
  },
  {
    path: "/content/templates/:slug",
    component: ManageTemplate,
    position: "child",
    privilegeTag: "templateManagement",
  },
  //  --------->> Email Routes <<-----------
  {
    path: "/email",
    component: Email,
    position: "parent",
    privilegeTag: "email_management",
  },
  //  --------->> Notification Routes <<-----------
  {
    path: "/notifications",
    component: Notifications,
    position: "parent",
    privilegeTag: "notifications_management",
  },
  //  --------->> Catalogue Routes <<-----------
  {
    path: "/catalogue/",
    component: Brand,
    position: "parent",
    privilegeTag: "brandManagement",
  },
  {
    path: "/catalogue/category-management",
    component: Category,
    position: "parent",
    privilegeTag: "categoryManagement",
  },
  {
    path: "/catalogue/skill-management",
    component: Skills,
    position: "parent",
    privilegeTag: "skillManagement",
  },
  {
    path: "/catalogue/faq-management",
    component: Faq,
    position: "parent",
    privilegeTag: "faqManagement",
  },
  {
    path: "/catalogue/brand-management",
    component: Brand,
    position: "parent",
    privilegeTag: "brandManagement",
  },
  {
    path: "/catalogue/question-management",
    component: Questions,
    position: "parent",
    privilegeTag: "questionManagement",
  },
  {
    path: "/catalogue/question-management/:question_id",
    component: QueManager,
    position: "child",
    privilegeTag: "questionManagement",
  },
  {
    path: "/catalogue/quiz-management",
    component: Quiz,
    position: "parent",
    privilegeTag: "quizManagement",
  },
  {
    path: "/catalogue/quiz-management/:quiz_id",
    component: QuizManager,
    position: "child",
    privilegeTag: "quizManagement",
  },
  //  --------->> Toolkit/Glossary Routes <<-----------
  {
    path: "/catalogue/glossary-management",
    component: Glossary,
    position: "parent",
    privilegeTag: "glossaryManagement",
  },
  {
    path: "/catalogue/glossary-management/:glossary_id",
    component: GlossaryManagement,
    position: "parent",
    privilegeTag: "glossaryManagement",
  },
  //  --------->> Product Routes <<-----------
  {
    path: "/products",
    component: Products,
    position: "parent",
    privilegeTag: "productManagement",
  },
  {
    path: "/products/:product_id",
    component: ManageProduct,
    position: "child",
    privilegeTag: "productManagement",
  },
  {
    path: "/inventory",
    component: Inventory,
    position: "child",
    privilegeTag: "productManagement",
  },
  {
    path: "/inventory/add-new",
    component: ManageInventory,
    position: "child",
    privilegeTag: "productManagement",
  },
  {
    path: "/inventory/:id",
    component: ManageInventory,
    position: "child",
    privilegeTag: "productManagement",
  },
  {
    path: "/leadboard",
    component: Leadboard,
    position: "parent",
    privilegeTag: "leadboard_management",
  },
  {
    path: "/coupons",
    component: Coupons,
    position: "parent",
    privilegeTag: "coupon_management",
  },
  {
    path: "/rewards",
    component: Rewards,
    position: "parent",
    privilegeTag: "reward_management",
  },
  {
    path: "/feedback",
    component: FeedBack,
    position: "parent",
    privilegeTag: "feedbackManagement",
  },
  {
    path: "/score-card",
    component: Scores,
    position: "parent",
    privilegeTag: "scorecard_management",
  },
  //  --------->> Address Routes <<-----------
  {
    path: "/address",
    component: Address,
    position: "parent",
    privilegeTag: "addressManagement",
  },
  {
    path: "/address/add-new",
    component: AddAddress,
    position: "child",
    privilegeTag: "addressManagement",
  },
  {
    path: "/address/:id",
    component: AddAddress,
    position: "child",
    privilegeTag: "addressManagement",
  },
  {
    path: "/orders",
    component: OrderList,
    position: "parent",
    privilegeTag: "orderManagement",
  },
  {
    path: "/orders/:id",
    component: OrderDetail,
    position: "child",
    privilegeTag: "orderManagement",
  },
  {
    path: "/shipment",
    component: Shipment,
    position: "parent",
    privilegeTag: "orderManagement",
  },
  {
    path: "/shipment/:id",
    component: ManageShipment,
    position: "child",
    privilegeTag: "orderManagement",
  },
  {
    path: "/reviews",
    component: Reviews,
    position: "parent",
    privilegeTag: "reviewManagement",
  },
  {
    path: "/reviews/:id",
    component: Managereviews,
    position: "child",
    privilegeTag: "reviewManagement",
  },
  {
    path: "/plans",
    component: Plans,
    position: "parent",
    privilegeTag: "planManagement",
  },
  {
    path: "/plans/:id",
    component: ManagePlans,
    position: "child",
    privilegeTag: "planManagement",
  },
  {
    path: "/plans/add-new",
    component: ManagePlans,
    position: "child",
    privilegeTag: "planManagement",
  },
];
