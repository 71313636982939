import React from "react";
import { Link, useLocation } from "react-router-dom";

const Breadcrumb = () => {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);
  const data = location.state;

  const generateBreadcrumbItems = () => {
    let breadcrumbs = [];

    if (pathnames.length > 0) {
      breadcrumbs.push(
        <Link key="dashboard" to="/" className="highlight-link">
          Dashboard
        </Link>
      );

      let pathAccumulator = "";

      pathnames.forEach((value, index) => {
        pathAccumulator += `/${value}`;
        const to = pathAccumulator;

        const newVal =
          value === data?.slug
            ? data?.title?.replaceAll("-", " ")
            : value.replaceAll("-", " ");

        if (index === pathnames.length - 1) {
          breadcrumbs.push(
            <span key={to}>
              {" / "}
              {newVal}
              {/* {value.replaceAll("-", " ")} */}
            </span>
          );
        } else {
          breadcrumbs.push(
            <span key={to}>
              {" / "}
              <Link to={to} className="highlight-link">
                {/* {value.replaceAll("-", " ")} */}
                {newVal}
              </Link>
            </span>
          );
        }
      });
    }
    return breadcrumbs;
  };

  return <div className="breadcrumbs-wrapper">{generateBreadcrumbItems()}</div>;
};

export default Breadcrumb;
