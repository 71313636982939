import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  isAuthToggle,
  setIp,
  setIsVerify,
  setLatitude,
  setLongitude,
} from "./authSlice";
import { setProfileDetail } from "modules/settings/profile/redux/profileSlice";
import { IpAddress, options } from "helper/helper";
import { toast } from "react-toastify";

export const login = createAsyncThunk(
  "auth/login",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    
    try {
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/login`,
        data: data,
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.status === 200) {
        let is2FA = response?.data?.data?.data?.is_2FA;
        if (is2FA === true) {
          dispatch(
            send2faOtp({
              email: response?.data?.data?.data?.email,
              phone: `${response?.data?.data?.data?.phone}`,
              iso_code: response?.data?.data?.data?.isoCode,
            })
          );
        } else {
          toast.success(response?.data?.message, options);
        }
        return fulfillWithValue(response?.data);
      } else {
        toast.error(response?.data?.message, options);
        return rejectWithValue();
      }
    } catch (err) {
      toast.error(err.message, options);
      return rejectWithValue();
    }
  }
);

export const logout = createAsyncThunk(
  "auth/logout",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/logout`,
      });
      if (response.status === 200) {
        toast.success(response?.data?.message, options);
        dispatch(setProfileDetail({}));
        return fulfillWithValue(response?.data?.status);
      } else {
        // toast.error(response?.data?.message, options);
        return rejectWithValue();
      }
    } catch (err) {
      return rejectWithValue();
    }
  }
);

export const forgotPassword = createAsyncThunk(
  "auth/forgot-password",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/forgot-password`,
        data: data,
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.status === 200) {
        toast.success(response?.data?.message, options);
        dispatch(isAuthToggle(false));
        return fulfillWithValue(response?.data);
      } else {
        toast.error(response?.data?.message, options);
        dispatch(isAuthToggle(false));
        return rejectWithValue();
      }
    } catch (err) {
      dispatch(isAuthToggle(false));
      return rejectWithValue();
    }
  }
);

export const verifyTokenAsync = createAsyncThunk(
  "auth/verify-token",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      dispatch(isAuthToggle(true));
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_AUTH_URL}/auth/verify-token`,
        data: data,
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.status === 200) {
        dispatch(isAuthToggle(false));
        return response.data.status;
      } else {
        dispatch(isAuthToggle(false));
        return response.data.status;
      }
    } catch (err) {
      dispatch(isAuthToggle(false));
      return;
    }
  }
);

export const resetPassword = createAsyncThunk(
  "auth/reset-password",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios({
        method: "PUT",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/reset-password`,
        data,
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.status === 200) {
        toast.success(response?.data?.message, options);
        return response?.data?.status;
      } else {
        toast.error(response?.data?.message, options);
        return response?.data?.status;
      }
    } catch (error) {
      return rejectWithValue();
    }
  }
);

export const verifyOtp = createAsyncThunk(
  "auth/verify-otp",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      dispatch(isAuthToggle(true));
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_AUTH_URL}/auth/verify-otp`,
        data: data,
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.status === 200) {
        toast.success(response?.data?.message, options);
        dispatch(isAuthToggle(false));
        dispatch(setIsVerify(true));
        return response?.data?.status;
      } else {
        toast.error(response?.data?.message, options);
        dispatch(isAuthToggle(false));
      }
    } catch (err) {
      dispatch(isAuthToggle(false));
      toast.error(err, options);
    }
  }
);

export const getIPaddressAsync = createAsyncThunk(
  "auth/get-ip-address",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      const responseIp = await IpAddress();
      if (responseIp) {
        dispatch(setLatitude(responseIp?.latitude));
        dispatch(setLongitude(responseIp?.longitude));
        dispatch(setIp(responseIp?.ip));

        return fulfillWithValue(responseIp);
      } else {
        return rejectWithValue();
      }
    } catch (err) {
      return rejectWithValue();
    }
  }
);

export const sendOtp = createAsyncThunk(
  "auth/send-otp/message",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      dispatch(isAuthToggle(true));
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_AUTH_URL}/public/send-otp/mail`,
        data: data,
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.status === 200) {
        dispatch(isAuthToggle(false));
        toast.success(response?.data?.message, options);
        return response?.data?.status;
      } else {
        dispatch(isAuthToggle(false));
        toast.error(response?.data?.message, options);
      }
    } catch (err) {
      dispatch(isAuthToggle(false));
      toast.error(err, options);
    }
  }
);

export const send2faOtp = createAsyncThunk(
  "auth/send-otp",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      dispatch(isAuthToggle(true));
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_AUTH_URL}/public/send-otp`,
        data: data,
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.status === 200) {
        dispatch(isAuthToggle(false));
        toast.success(response?.data?.message, options);
        return response?.data?.status;
      } else {
        dispatch(isAuthToggle(false));
        toast.error(response?.data?.message, options);
      }
    } catch (err) {
      dispatch(isAuthToggle(false));
      toast.error(err, options);
    }
  }
);

export const verify2faOtp = createAsyncThunk(
  "auth/verify-2fa-otp",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      dispatch(isAuthToggle(true));
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_AUTH_URL}/public/verify-otp`,
        data: data,
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.status === 200) {
        toast.success(response?.data?.message, options);
        dispatch(isAuthToggle(false));
        return response?.data?.status;
      } else {
        toast.error(response?.data?.message, options);
        dispatch(isAuthToggle(false));
      }
    } catch (err) {
      dispatch(isAuthToggle(false));
      toast.error(err, options);
    }
  }
);

export const logoutAllDevices = createAsyncThunk(
  "auth/logout-all-devices",
  async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_AUTH_URL}/admin/logout-all`,
      });

      if (response.status === 200) {
        toast.success(response?.data?.message, options);
        // dispatch(setProfileDetail({}));
        return fulfillWithValue(response?.data);
      } else {
        // toast.error(response?.data?.message, options);
        return rejectWithValue();
      }
    } catch (err) {
      return rejectWithValue();
    }
  }
);
