/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import ConformationPopup from "components/ui/ConformationPopup";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import {
  deleteAdminAsync,
  getAdminList,
  adminActivateDeactivate,
} from "../redux/adminApi";
import useDisableSkelaton from "hooks/useDisableSkelaton";
import useFetchList from "hooks/useFetchList";
import SearchField from "components/ui/SearchField";
import ModuleLayout from "layouts/ModuleLayout";
import { getBreadcrumbName, unixTimeToReadableFormat } from "helper/helper";
import UserTable from "../../components/UserTable";
import { FilterGender, SortItems } from "helper/placeholder-data";
import DropDownBox from "components/DropDownBox";
import useSelectRows from "hooks/useSelectRows";

const AdminList = ({ setTitle }) => {
  useEffect(() => {
    setTitle("Administrators Directory");
  }, [setTitle]);

  const { adminList, metaData, adminLoading } = useSelector(
    (state) => state.admin,
    shallowEqual
  );

  const [active, setActive] = useState("roles");
  const [mobileSidebar, setMobileSidebar] = useState(false);
  const [minimizedSidebar, setMinimizedSidebar] = useState(false);
  const [alertBox2, setAlertBox2] = useState(false);
  const [popupDataTwo, setPopupDataTwo] = useState();
  const [popupCustomWarning, setPopupCustomWarning] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    content,
    alertBox,
    setAlertBox,
    selectAllRows,
    selectedRows,
    setSelectedRows,
    toggleSelectAll,
    toggleRowSelection,
    deleteFunction,
  } = useSelectRows(adminList, "user_id");

  const {
    privileges,
    limit,
    setLimit,
    sort,
    setSort,
    search,
    setSearch,
    btnNo,
    setBtnNo,
    tableIndex,
    setTableIndex,
  } = useFetchList(getAdminList, metaData, selectAllRows, setSelectedRows);

  const { contentLoading } = useDisableSkelaton(adminLoading);

  const listContent = adminList.map((item) => {
    const { id, num, name, email, gender, role, status, createdAt, lastLogin } =
      item;

    const userName = `${name?.firstName} ${name?.lastName}`;
    const userStatus = status ? "active" : "inactive";
    const crDt = unixTimeToReadableFormat(createdAt);
    const lDt = lastLogin
      ? new Date(lastLogin?.lastLogin).toLocaleString()
      : "Not yet login!";
    const similarItems = [
      { style: "firstName", val: userName },
      { style: "email", val: email },
      { style: `status ${userStatus}`, val: userStatus },
      { style: "", val: gender },
      { style: "date", val: lDt },
      { style: "date", val: crDt },
    ];
    return [id, num, userName, similarItems, status, role];
  });

  const TABLEDATA = {
    loadingState: contentLoading,
    skalatonDetails: { col: 7, rows: limit },
    list: adminList,
    listHeading: [
      "Name",
      "Contact Info",
      "Status",
      "gender",
      "Last Login",
      "Created At",
    ],
    listContent,
    selectedRows,
    selectAllRows,
    setSelectedRows,
    toggleSelectAll,
    toggleRowSelection,
    deleteFunction,
    sliceName: "admin",
    search,
    PagAPI: getAdminList,
    sort,
    limit,
    setLimit,
    btnNo,
    setBtnNo,
    tableIndex,
    setTableIndex,
    privileges,
    hasAccess: "userManagement",
    navigateTo: update,
    setAlertBox2,
    setPopupDataTwo,
    setPopupCustomWarning,
    metaData,
  };

  function update(id) {
    const user = adminList.find((user) => user.id === id);
    const breadcrumbName = getBreadcrumbName(user);
    navigate(`/users/administrator-directory/${id}`, {
      state: { slug: id, title: breadcrumbName },
    });
  }

  const [filterVal, setFilterVal] = useState("");

  useEffect(() => {
    dispatch(getAdminList({ page: 0, search, sort, limit, gender: filterVal }));
  }, [filterVal]);

  return (
    <ModuleLayout
      minimizedSidebar={minimizedSidebar}
      setMinimizedSidebar={setMinimizedSidebar}
      mobileSidebar={mobileSidebar}
      setMobileSidebar={setMobileSidebar}
      active={active}
      setActive={setActive}
      content={content}
      alertBox={alertBox}
    >
      <div className="list-container custom-box">
        <header className="list-header">
          <div className="list-title">
            <h2>Administrators Insights</h2>
            <div className="flex-center gap-1">
              <button
                className="btn-primary flex-center gap-1"
                onClick={() =>
                  navigate("/users/administrator-directory/add-user")
                }
              >
                <i className="fa-solid fa-feather"></i> Add User
              </button>

              {Object?.values(privileges["userManagement"])?.includes(
                "delete"
              ) && selectedRows.length !== 0 ? (
                <button
                  className="btn-secondary flex-center gap-1"
                  onClick={() => deleteFunction("all templates")}
                >
                  <i className="fa-light fa-trash"></i>
                  {`Delete ${selectedRows.length}`}
                </button>
              ) : null}
            </div>
          </div>
        </header>
        <div className="tb_controls mt-2">
          <div className="flex-center gap-1">
            <DropDownBox
              options={SortItems}
              size="small"
              dropDownTitle="Sort"
              animateDropDownTitle
              customSetter={setSort}
              customDropBoxStyles={{
                opacity: 0.6,
                color: "#262626",
                padding: "0.6rem",
                border: "1px solid var(--color-neutral-92)",
              }}
            />

            <DropDownBox
              options={FilterGender}
              size="small"
              dropDownTitle="Gender"
              animateDropDownTitle
              customSetter={setFilterVal}
              customDropBoxStyles={{
                opacity: 0.6,
                color: "#262626",
                padding: "0.6rem",
                border: "1px solid var(--color-neutral-92)",
              }}
              resetButton
            />
          </div>
          <SearchField value={search} setValue={setSearch} />
        </div>
        <UserTable {...TABLEDATA} />
      </div>

      {alertBox && (
        <ConformationPopup
          customState={alertBox}
          customSetter={setAlertBox}
          api={deleteAdminAsync}
          popUpData={content}
          customFunction={() => setSelectedRows(new Set())}
          toastMessage={"User removed successfully"}
        />
      )}

      {alertBox2 && (
        <ConformationPopup
          customState={alertBox2}
          customSetter={setAlertBox2}
          mainTitle={popupCustomWarning}
          subTitle={` Are you sure want to ${popupDataTwo?.data?.send?.status ? "Activate" : "Deactivate"} the user?`}
          api={adminActivateDeactivate}
          popUpData={popupDataTwo}
          reData={{ page: 0, search, sort: sort, limit }}
          reFetchApi={getAdminList}
          popupType="edit"
          toastMessage="User state changed"
        />
      )}
    </ModuleLayout>
  );
};

export default AdminList;
