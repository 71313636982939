import ForgotPassword from "modules/auth/pages/ForgotPassword";
import Login from "modules/auth/pages/Login";
import ResetPassword from "modules/auth/pages/ResetPassword";

export const AuthRoutes = [
  {
    path: "/login",
    component: Login,
  },
  {
    path: "/reset-password/:id",
    component: ResetPassword,
  },
  {
    path: "/forgot-password",
    component: ForgotPassword,
  },
];
