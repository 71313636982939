const General = ({ detail }) => {
    return (
        <div className="general-main">
            <div className="general-m2 mt-1 form-col-2 gap-0">
                <div className="gm2-1 first">
                    <div className="gm2-1-head">
                        <span className="title">Billing Details</span>
                        {/* <span className="eDiv">Edit</span> */}
                    </div>
                    <div className="gm2-1-content">
                        <div className="title">{detail?.billingAddress?.name}</div>
                        <div className="gmc2-data">
                            <span className="material-symbols-outlined icon">location_on</span>
                            <span className="val">{`${detail?.billingAddress?.address?.line_one}, ${detail?.billingAddress?.address?.line_two}, ${detail?.billingAddress?.address?.city}, ${detail?.billingAddress?.address?.state}, ${detail?.billingAddress?.address?.country}`}</span>
                        </div>
                        <div className="gmc2-data">
                            <span className="material-symbols-outlined icon">call</span>
                            <span className="val">{detail?.billingAddress?.phone?.value}</span>
                        </div>
                        <div className="gmc2-data">
                            <span className="material-symbols-outlined icon">mail</span>
                            <span className="val">{detail?.billingAddress?.email}</span>
                        </div>
                    </div>
                </div>
                <div className="gm2-1">
                    <div className="gm2-1-head center">
                        <span className="title">Shipping Details</span>
                        {/* <span className="eDiv">Edit</span> */}
                    </div>
                    <div className="gm2-1-content">
                        <div className="title">{detail?.shippingAddress?.name}</div>
                        <div className="gmc2-data">
                            <span className="material-symbols-outlined icon">location_on</span>
                            <span className="val">{`${detail?.shippingAddress?.address?.line_one}, ${detail?.shippingAddress?.address?.line_two}, ${detail?.shippingAddress?.address?.city}, ${detail?.shippingAddress?.address?.state}, ${detail?.shippingAddress?.address?.country}`}</span>
                        </div>
                        <div className="gmc2-data">
                            <span className="material-symbols-outlined icon">call</span>
                            <span className="val">{detail?.shippingAddress?.phone?.value}</span>
                        </div>
                        <div className="gmc2-data">
                            <span className="material-symbols-outlined icon">mail</span>
                            <span className="val">{detail?.shippingAddress?.email}</span>
                        </div>
                    </div>
                </div>
                {/* <div className="gm2-1">
                    <div className="gm2-1-head center">
                    </div>
                    <div className="gm2-1-content">
                        <div className="gm3-detail">
                            <div className="gm3-left">
                                <div className="gm3-l">Delivery Date</div>
                                {
                                    deliverydate.status ?
                                        <input
                                            type="date"
                                            name="delDate"
                                            id="delDate"
                                            value={deliverydate.value}
                                            onChange={(e) => setDeliverydate({ status: deliverydate.status, value: e.target.value })}
                                        /> :
                                        <div className="insert">{deliverydate.value || "Add to insert"}</div>
                                }
                            </div>
                            <span className="eDiv" onClick={() => setDeliverydate({ status: !deliverydate.status, value: deliverydate.value })}>
                                {deliverydate.status ? "Save" : (deliverydate.value ? "Edit" : "Add")}
                            </span>
                        </div>
                        <div className="gm3-detail">
                            <div className="gm3-left">
                                <div className="gm3-l">Carrier Details</div>
                                {
                                    carrierDetail.status ?
                                        <input
                                            type="text"
                                            name="carrier"
                                            id="carrier"
                                            value={carrierDetail.value}
                                            onChange={(e) => setCarrierDetail({ status: carrierDetail.status, value: e.target.value })}
                                        /> :
                                        <div className="insert">{carrierDetail.value || "Add to insert"}</div>
                                }
                            </div>
                            <span className="eDiv" onClick={() => setCarrierDetail({ status: !carrierDetail.status, value: carrierDetail.value })}>
                                {carrierDetail.status ? "Save" : (carrierDetail.value ? "Edit" : "Add")}
                            </span>
                        </div>
                        <div className="gm3-detail">
                            <div className="gm3-left">
                                <div className="gm3-l">Tracking Number</div>
                                {
                                    trankNumber.status ?
                                        <input
                                            type="text"
                                            name="carrier"
                                            id="carrier"
                                            value={trankNumber.value}
                                            onChange={(e) => setTrankNumber({ status: trankNumber.status, value: e.target.value })}
                                        /> :
                                        <div className="insert">{trankNumber.value || "Add to insert"}</div>
                                }
                            </div>
                            <span className="eDiv" onClick={() => setTrankNumber({ status: !trankNumber.status, value: trankNumber.value })}>
                                {trankNumber.status ? "Save" : (trankNumber.value ? "Edit" : "Add")}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="gm2-1 last">
                    <div className="gm2-1-head last">
                    </div>
                    <div className="gm2-1-content">
                        <div className="gm3-detail">
                            <div className="gm3-left">
                                <div className="gm3-l">Lebel</div>
                            </div>
                            <span className="gmc2-data icons">
                                <span className="material-symbols-outlined">print</span>
                                <span className="material-symbols-outlined">download</span>
                            </span>
                        </div>
                        <hr />
                        <div className="gm3-detail">
                            <div className="gm3-left">
                                <div className="gm3-l">Invoice</div>
                            </div>
                            <span className="gmc2-data icons">
                                <span className="material-symbols-outlined">print</span>
                                <span className="material-symbols-outlined">download</span>
                            </span>
                        </div>
                        <hr />
                        <div className="gm3-detail">
                            <div className="gm3-left">
                                <div className="gm3-l">Manifest</div>
                            </div>
                            <span className="gmc2-data icons">
                                <span className="material-symbols-outlined">print</span>
                                <span className="material-symbols-outlined">download</span>
                            </span>
                        </div>
                    </div>
                </div> */}
            </div>
            <div className="general-m3 mt-2">
                <div className="gm3-Top">
                    <div className="title">Total Items</div>
                </div>
                <div className="gm3-bottom">
                    <Table Tbdata={detail?.items} currencySymbol={detail?.currency?.symbol} />
                </div>
            </div>
            <div className="general-m3 mt-2">
                <div className="gm3-Top">
                    <div className="title">Order Summary</div>
                </div>
                <div className="gm3-bottom">
                    <div className="orderSum">
                        <span className="key">Total</span>
                        <span className="val">{`${detail?.currency?.symbol} ${detail?.total}`}</span>
                    </div>
                    <div className="orderSum">
                        <span className="key">Discount</span>
                        <span className="val">{`${detail?.currency?.symbol} ${detail?.discount} (${detail?.discountPercent} % )`}</span>
                    </div>
                    <div className="orderSum">
                        <span className="key">Taxable Amount</span>
                        <span className="val">{`${detail?.currency?.symbol} ${detail?.taxableAmount}`}</span>
                    </div>
                    <div className="orderSum">
                        <span className="key">Tax Amount</span>
                        <span className="val">{`${detail?.currency?.symbol} ${detail?.taxAmount}`}</span>
                    </div>
                    <div className="orderSum">
                        <span className="key">Sub Total</span>
                        <span className="val">{`${detail?.currency?.symbol} ${detail?.subTotal}`}</span>
                    </div>
                    <div className="orderSum">
                        <span className="key">Shipping Charge</span>
                        <span className="val">{`${detail?.currency?.symbol} ${detail?.additionalCharge?.shipping}`}</span>
                    </div>
                    <div className="orderSum">
                        <span className="key">Packaging Charge</span>
                        <span className="val">{`${detail?.currency?.symbol} ${detail?.additionalCharge?.packaging}`}</span>
                    </div>
                    <div className="orderSum">
                        <span className="key"> <b> Net Amount</b></span>
                        <span className="val"> <b> {`${detail?.currency?.symbol} ${detail?.netAmount}`}</b></span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default General;

export const Table = ({ Tbdata, currencySymbol }) => {
    return (
        <div className="gm-TableMain">
            <table className="total-table">
                <thead>
                    <tr>
                        <th className="tt-header"></th>
                        <th className="tt-header">SKU</th>
                        <th className="tt-header">Name</th>
                        <th className="tt-header">Price</th>
                        <th className="tt-header">Discount</th>
                        <th className="tt-header">Quantity</th>
                        <th className="tt-header">Additional Charges</th>
                        <th className="tt-header"> Total</th>
                    </tr>
                </thead>
                <tbody>
                    {Tbdata?.map((table, index) => (
                        <tr key={index}>
                            <td className="tt-data"><img src={table?.images?.localUrl} crossOrigin="anonymous" width={50} height={30} alt="" /></td>
                            <td className="tt-data">{table.sku}</td>
                            <td className="tt-data">{table.name}</td>
                            <td className="tt-data">{currencySymbol} {table.sellingPrice}</td>
                            <td className="tt-data">{currencySymbol} {table.discount}</td>
                            <td className="tt-data">{table.quantity}</td>
                            <td className="tt-data">{Number(table.additionalCharge?.shipping) + Number(table.additionalCharge?.packaging)}</td>
                            <td className="tt-data">{currencySymbol} {table.netAmount}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};
