import SubmitButton from 'components/ui/SubmitButton';
import { useFormik } from 'formik';
import { uploadImage, urlToObject } from 'helper/helper';
import ModuleLayout from 'layouts/ModuleLayout'
import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from "yup"
import { addGlossary, getGlossaryDetail, updateGlossaryDetail } from '../redux/glossaryApi';

export default function GlossaryManagement() {
  const [minimizedSidebar, setMinimizedSidebar] = useState(false);
  const [mobileSidebar, setMobileSidebar] = useState(false);
  const [activeInputs, setActiveInputs] = useState(true);
  const [active, setActive] = useState("glossary");
  const { glossary_id } = useParams();
  const [mainImage, setMainImage] = useState(null)
  const [glossaryIconImage, setGlossaryIconImage] = useState(null)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { glossaryDetail } = useSelector((state) => state.glossary, shallowEqual);

  useEffect(() => {
    if (glossary_id !== "add-glossary") {
      // setActiveInputs(false);
      dispatch(getGlossaryDetail({ id: glossary_id }));
    }
  }, []);

  const validationSchema = Yup.object({
    name: Yup.string()
      .trim()
      .min(3, "Name must be at least 3 characters long")
      .max(80, "Glossary name must be less then 80 characters")
      .required("Glossary name is required"),
    description: Yup.string()
      .trim()
      .min(3, "Description must be at least 3 characters long")
      .required("Description should not be empty"),
    shortDesc: Yup.string().required("Short description is required"),
    glossaryIcon: Yup.mixed().required("Logo is required"),
  })

  const formik = useFormik({
    initialValues: {
      name: "",
      shortDesc: "",
      description: "",
      glossaryIcon: null,
      image: null
    },
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      const formData = new FormData()
      formData.append("glossary_name", values.name.trim())
      formData.append("description", values.shortDesc.trim())
      formData.append("longdescription", values.description.trim())
      formData.append("image", values.image)
      formData.append("logo", values.glossaryIcon)
      if (glossary_id === "add-glossary") {
        const res = await dispatch(addGlossary(formData))
        if (res?.payload) {
          navigate(-1)
        }
      } else {
        const res = await dispatch(updateGlossaryDetail({ id: glossary_id, send: formData }))
        if (res?.payload) {
          navigate(-1);
        }
      }
    }
  })
  const InputProps = {
    className: "form-input",
    onBlur: formik.handleBlur,
    onChange: formik.handleChange,
    onInput: (e) =>
    (e.target.value = e.target.value.replace(/^./, (match) =>
      match.toUpperCase()
    )),
    disabled: glossary_id !== "add-glossary" ? activeInputs : false,
  };

  useEffect(() => {
    if (formik?.values?.image) {
      const img = URL.createObjectURL(formik?.values?.image);
      setMainImage(img);
    }
  }, [formik?.values?.image]);

  useEffect(() => {
    if (formik?.values?.glossaryIcon) {
      const img = URL.createObjectURL(formik?.values?.glossaryIcon);
      setGlossaryIconImage(img);
    }
  }, [formik?.values?.glossaryIcon]);

  useEffect(() => {
    if (glossary_id !== "add-glossary" && glossaryDetail) {
      ; (async () => {
        const image = glossaryDetail?.image?.localUrl || glossaryDetail?.image?.bucketUrl;
        const logo = glossaryDetail?.logo?.localUrl || glossaryDetail?.logo?.bucketUrl;
        const imageObject = await urlToObject(image);
        const logoObject = await urlToObject(logo);
        formik.setValues({
          image: imageObject,
          glossaryIcon: logoObject,
          name: glossaryDetail?.name,
          description: glossaryDetail?.longdescription,
          shortDesc: glossaryDetail?.description
        })
      })()
    }
  }, [glossaryDetail, glossary_id])


  return (
    <ModuleLayout
      minimizedSidebar={minimizedSidebar}
      setMinimizedSidebar={setMinimizedSidebar}
      setMobileSidebar={setMobileSidebar}
      mobileSidebar={mobileSidebar}
      setActive={setActive}
      active={active}
    >
      <div className='list-container custom-box'>
        <div className="form-header">
          <h2>Glossary Management</h2>

          {glossary_id !== "add-glossary" && (
            <div className="flex gap-2">
              {activeInputs ? (
                <>
                  <button
                    type="button"
                    onClick={() => navigate(-1)}
                    className="btn-outlined cancel"
                  >
                    Back
                  </button>
                  <button
                    type="button"
                    onClick={() => setActiveInputs(false)}
                    className="btn-primary"
                  >
                    Update
                  </button>
                </>
              ) : (
                <>
                  <button
                    type="button"
                    onClick={() => setActiveInputs(true)}
                    className="btn-outlined tertiary"
                  >
                    Abort
                  </button>
                  <SubmitButton
                    className="btn-primary"
                    type="submit"
                    onClick={formik.handleSubmit}
                    disabled={formik.isSubmitting}
                  >
                    Submit
                  </SubmitButton>
                </>
              )}
            </div>
          )}

          {glossary_id === "add-glossary" && (
            <div className="flex gap-1">
              <button
                type="button"
                onClick={() => navigate(-1)}
                className="btn-outlined cancel"
              >
                Back
              </button>
              <button
                className="btn-primary"
                type="submit"
                onClick={() => formik.handleSubmit()}
                disabled={formik.isSubmitting}
              >
                Submit
              </button>
            </div>
          )}
        </div>

        <main className="main">
          <div className="auth-form-wrapper">
            <form
              onSubmit={formik.handleSubmit}
            >
              <div className="genral-quiz-info">
                <div className='form'>
                  <div className="form-control">
                    <label className="form-label">Name</label>
                    <div className="form-input-box">
                      <input
                        id="name"
                        name="name"
                        type="text"
                        placeholder="Please enter glossary here"
                        value={formik.values.name || ""}
                        onInput={(e) =>
                        (e.target.value = e.target.value.replace(
                          /(?:^|\s)\S/g,
                          (match) => match.toUpperCase()
                        ))
                        }
                        maxLength={81}
                        {...InputProps}
                      />
                    </div>
                    {formik.errors.name && formik.touched.name && (
                      <span className="err">{formik.errors.name}</span>
                    )}
                  </div>

                  <div className="form-control">
                    <label className="form-label">Long Description</label>
                    <div className="form-input-box">
                      <textarea
                        type="text"
                        id="description"
                        name="description"
                        value={formik.values.description}
                        onInput={(e) =>
                        (e.target.value = e.target.value.replace(
                          /^./,
                          (match) => match.toUpperCase()
                        ))
                        }
                        {...InputProps}
                        maxLength={151}
                        rows={11}
                        cols={5}
                      />
                    </div>
                    {formik.errors.description &&
                      formik.touched.description && (
                        <span className="err">{formik.errors.description}</span>
                      )}
                  </div>

                  <div className="form-control">
                    <label className="form-label">Description</label>
                    <div className="form-input-box">
                      <textarea
                        type="text"
                        id="shortDesc"
                        name="shortDesc"
                        value={formik.values.shortDesc}
                        onInput={(e) =>
                        (e.target.value = e.target.value.replace(
                          /^./,
                          (match) => match.toUpperCase()
                        ))
                        }
                        {...InputProps}
                        maxLength={151}
                        rows={4}
                        cols={5}
                      />
                    </div>
                    {formik.errors.shortDesc &&
                      formik.touched.shortDesc && (
                        <span className="err">{formik.errors.shortDesc}</span>
                      )}
                  </div>
                </div>
                <div className='form'>
                  <div className='form-control'>
                    <p className='form-label'>Glossary Icon</p>
                    <label htmlFor="glossaryIcon" className="image-label">
                      {glossaryIconImage ? (
                        <img
                          src={glossaryIconImage}
                          alt="glossary-icon"
                          crossOrigin="anonymous"
                          style={{
                            width: "50px",
                            height: "50px",
                            objectFit: "cover",
                            borderRadius: "100px",
                          }}
                        />
                      ) : (
                        <div className="dummy-img flex-center" style={{
                          width: "50px",
                          height: "50px",
                          borderRadius: "10px"
                        }}>
                          <i className="fa-duotone fa-solid fa-image dummy-img__icon"></i>
                        </div>
                      )}
                      <input
                        id="glossaryIcon"
                        name="glossaryIcon"
                        type="file"
                        className="form-input"
                        accept=".jpg, .jpeg, .png, .gif, .webp"
                        onChange={(e) => uploadImage(e, formik.setFieldValue, "glossaryIcon")}
                        onBlur={formik.handleBlur}
                        disabled={glossary_id !== "add-glossary" ? activeInputs : false}
                        hidden
                      />
                    </label>
                    {formik.errors.glossaryIcon && formik.touched.glossaryIcon && (
                      <span className="err">{formik.errors.glossaryIcon}</span>
                    )}
                  </div>

                  <div className='form-control'>
                    <p className='form-label'>Glossary Image</p>
                    <label htmlFor="image" className="image-label">
                      {mainImage ? (
                        <img
                          src={mainImage}
                          alt="main"
                          crossOrigin="anonymous"
                          style={{
                            width: "100%",
                            height: "300px",
                            objectFit: "cover",
                          }}
                        />
                      ) : (
                        <div className="dummy-img flex-center" style={{
                          width: "100%",
                          height: "300px",
                          borderRadius: "10px"
                        }}>
                          <i className="fa-duotone fa-solid fa-image dummy-img__icon"></i>
                        </div>
                      )}
                      <input
                        id="image"
                        name="image"
                        type="file"
                        className="form-input"
                        accept=".jpg, .jpeg, .png, .gif, .webp"
                        onChange={(e) => uploadImage(e, formik.setFieldValue, "image")}
                        onBlur={formik.handleBlur}
                        disabled={glossary_id !== "add-glossary" ? activeInputs : false}
                        hidden
                      />
                    </label>
                    {formik.errors.image && formik.touched.image && (
                      <span className="err">{formik.errors.image}</span>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </main>
      </div>
    </ModuleLayout>
  )
}
