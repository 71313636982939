import React, { useEffect, useState } from "react";
import CustomTable from "components/CustomTable";
import ConformationPopup from "components/ui/ConformationPopup";
import SearchField from "components/ui/SearchField";
import useFetchList from "hooks/useFetchList";
import useDisableSkelaton from "hooks/useDisableSkelaton";
import ModuleLayout from "layouts/ModuleLayout";
import { SortItems } from "helper/placeholder-data";
import { unixTimeToReadableFormat } from "helper/helper";
import { useSelector, shallowEqual } from "react-redux";
import { deleteFeedback, getFeedbackList } from "../redux/feedbackApi";
import FeedbackPopup from "./FeedbackPopup";
import DropDownBox from "components/DropDownBox";
import useSelectRows from "hooks/useSelectRows";

const FeedBack = ({ setTitle }) => {
  const [popup, setPopup] = useState({ show: false, slug: "" });

  useEffect(() => {
    setTitle("Feedback Management");
  }, [setTitle]);

  const { feedbackList, metaData, policyLoading } = useSelector(
    (state) => state.feedback,
    shallowEqual
  );

  const [active, setActive] = useState("feedback");
  const [mobileSidebar, setMobileSidebar] = useState(false);
  const [minimizedSidebar, setMinimizedSidebar] = useState(false);

  const {
    content,
    alertBox,
    setAlertBox,
    selectAllRows,
    selectedRows,
    setSelectedRows,
    toggleSelectAll,
    toggleRowSelection,
    deleteFunction,
  } = useSelectRows(feedbackList, "feedback_id");

  // Used useFetchList hook to fetch list dynamically, with search, & limit & sort
  const {
    privileges,
    limit,
    setLimit,
    sort,
    setSort,
    search,
    setSearch,
    btnNo,
    setBtnNo,
    tableIndex,
    setTableIndex,
  } = useFetchList(getFeedbackList, metaData, selectAllRows, setSelectedRows);
  // used this hook to disable skelaton loading if api request is less than 300ms
  const { contentLoading } = useDisableSkelaton(policyLoading);

  // Made an array of list items that will given as prop to custom table with tabledata object.
  const listContent = feedbackList.map((item, i) => {
    const { id, num, createdBy, type, rating, createdAt } = item;
    const crDt = unixTimeToReadableFormat(createdAt);
    const similarItems = [
      { style: "name", val: type },
      {
        style: "name",
        val: `${createdBy?.name?.firstName} ${createdBy?.name?.lastName}`,
      },
      { style: "name", val: `${rating} Star` },
      { style: "", val: crDt },
    ];
    return [id, num, type, createdBy, similarItems];
  });

  const TABLEDATA = {
    loadingState: contentLoading,
    skalatonDetails: { col: 6, rows: limit },
    list: feedbackList,
    listHeading: ["Type", "User", "Rating", "Created At"],
    listContent,
    selectedRows,
    selectAllRows,
    setSelectedRows,
    toggleSelectAll,
    toggleRowSelection,
    deleteFunction,
    sliceName: "feedback",
    search,
    PagAPI: getFeedbackList,
    sort,
    limit,
    setLimit,
    btnNo,
    setBtnNo,
    tableIndex,
    setTableIndex,
    privileges,
    metaData,
    hasAccess: "feedbackManagement",
    // navigateTo: update,
    navigateTo: update,
  };

  function update(id) {
    const feedback = feedbackList.find((template) => template.id === id);
    setPopup({ show: true, slug: id, feedback });
  }

  return (
    <ModuleLayout
      minimizedSidebar={minimizedSidebar}
      setMinimizedSidebar={setMinimizedSidebar}
      mobileSidebar={mobileSidebar}
      setMobileSidebar={setMobileSidebar}
      active={active}
      setActive={setActive}
      content={content}
      alertBox={alertBox}
    >
      <header className="list-header">
        <div className="list-title">
          <h2>Feedback List</h2>
          <div className="flex-center gap-1">
            {Object?.values(privileges["feedbackManagement"])?.includes(
              "delete"
            ) && selectedRows.length !== 0 ? (
              <button
                className="btn-secondary flex-center gap-1"
                onClick={() => {
                  deleteFunction("all Feedback");
                }}
              >
                <i className="fa-light fa-trash"></i>
                {`Delete ${selectedRows.length > 1 ? selectedRows.length : ""}`}
              </button>
            ) : null}
          </div>
        </div>
      </header>

      <div className="list-container custom-box">
        <div className="tb_controls">
          <DropDownBox
            options={SortItems}
            size="small"
            dropDownTitle="Sort"
            animateDropDownTitle
            customSetter={setSort}
            customDropBoxStyles={{
              opacity: 0.6,
              color: "#262626",
              padding: "0.6rem",
              border: "1px solid var(--color-neutral-92)",
            }}
          />
          {/* <SearchField value={search} setValue={setSearch} /> */}
        </div>
        <CustomTable {...TABLEDATA} />
      </div>

      {popup.show && <FeedbackPopup popup={popup} setPopup={setPopup} />}

      {alertBox && (
        <ConformationPopup
          customState={alertBox}
          customSetter={setAlertBox}
          api={deleteFeedback}
          popUpData={content}
          customFunction={() => setSelectedRows(new Set())}
          toastMessage={"Feedback removed successfully"}
        />
      )}
    </ModuleLayout>
  );
};

export default FeedBack;
