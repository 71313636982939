import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { AddNumField } from "helper/helper";
import { isFeedbackLoading } from "./feedbackSlice";
import { toast } from "react-toastify";

export const getFeedbackList = createAsyncThunk(
    "feedback/feedbacklist",
    async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
        try {
            const response = await axios({
                method: "GET",
                url: `${process.env.REACT_APP_AUTH_URL}/admin/feedback/feedback-list/?page=${data?.page ? data?.page : 0}&limit=${data?.limit ? data?.limit : 10}&sort=${data?.sort ? data?.sort : "desc"}&search=${data?.search ? data?.search : ""}&filter[type]=${data?.type ? data?.type : ""}&filter[userId]=${data?.uid ? data?.uid : ""}`,
                data: data?.send,
                headers: {
                    "Content-Type": "application/json",
                },
            });


            if (response.status === 200) {
                if (response?.data?.data?.length > 0) {
                    const res = await AddNumField(
                        response?.data?.data?.[0],
                        data?.page * data?.limit
                    );
                    return fulfillWithValue(res);
                } else {
                    const modPage = Math.max(data.page - 1, 0);
                    const responseAgain = await axios({
                        method: "GET",
                        url: `${process.env.REACT_APP_AUTH_URL}/admin/feedback/feedback-list/?page=${data?.page ? data.page : 0}&limit=${data?.limit ? data.limit : 10}&sort=${data?.sort ? data?.sort : "desc"}&search=${data?.search ? data?.search : ""}&filter[type]=${data?.type ? data?.type : ""}&filter[userId]=${data?.uid ? data?.uid : ""}`,
                    });
                    if (responseAgain.status === 200) {
                        const resAgain = await AddNumField(
                            responseAgain?.data,
                            modPage * data?.limit
                        );
                        return fulfillWithValue(resAgain);
                    } else {
                        return rejectWithValue();
                    }
                }
            } else {
                return rejectWithValue();
            }
        } catch (err) {
            return rejectWithValue();
        }
    }
);

export const deleteFeedback = createAsyncThunk(
    "cmsPolicy/delete-policy",
    async (data, { rejectWithValue, fulfillWithValue, dispatch }) => {
        try {
            dispatch(isFeedbackLoading(true));
            const response = await axios({
                method: "DELETE",
                url: `${process.env.REACT_APP_AUTH_URL}/admin/feedback/delete-feedback`,
                data: data?.send,
                headers: {
                    "Content-Type": "application/json",
                },
            });
            if (response.status === 200) {
                dispatch(isFeedbackLoading(false));

                dispatch(
                    getFeedbackList({
                        page: data?.page,
                        limit: data?.limit,
                        sort: data?.sort,
                        search: data?.search,
                    })
                );
                toast.success(response?.data?.message);
                return response?.data?.status;
            } else {
                dispatch(isFeedbackLoading(false));
                toast.error(response?.data?.message);
            }
        } catch (err) {
            dispatch(isFeedbackLoading(false));
            toast.error(err);
        }
    }
);