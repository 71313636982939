/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import ModuleLayout from "layouts/ModuleLayout";
import Pagination from "components/Pagination";
import DropDownBox from "components/DropDownBox";
import ConformationPopup from "components/ui/ConformationPopup";
// import ActionButton from "components/ui/ActionButton";
import SearchField from "components/ui/SearchField";
import NotFound from "components/ui/NotFound";
import CatCard from "components/ui/CatCard";
import useFetchList from "hooks/useFetchList";
import useDisableSkelaton from "hooks/useDisableSkelaton";
import { categoryFilters, SortItems } from "helper/placeholder-data";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { deleteCategoryAsync, getCategoryList } from "../redux/categoryApi";
import CategoryManager from "./CategoryManager";
import useSelectRows from "hooks/useSelectRows";

const Category = ({ setTitle }) => {
  useEffect(() => {
    setTitle("Category Management");
  }, [setTitle]);

  const { categoryList, metaData, categoryLoading } = useSelector(
    (state) => state.CatCategory,
    shallowEqual
  );

  const [popup, setPopup] = useState({ show: false, type: "", id: "" });
  const [active, setActive] = useState("category");
  const [mobileSidebar, setMobileSidebar] = useState(false);
  const [minimizedSidebar, setMinimizedSidebar] = useState(false);

  const { contentLoading } = useDisableSkelaton(categoryLoading);

  const {
    content,
    alertBox,
    setAlertBox,
    selectAllRows,
    selectedRows,
    setSelectedRows,
    // toggleSelectAll,
    // toggleRowSelection,
    deleteFunction,
  } = useSelectRows(categoryList, "category_id");

  const {
    privileges,
    limit,
    setLimit,
    sort,
    setSort,
    search,
    setSearch,
    btnNo,
    setBtnNo,
    tableIndex,
    setTableIndex,
  } = useFetchList(
    getCategoryList,
    metaData,
    selectAllRows,
    setSelectedRows,
    6
  );

  const handleClick = (val, id) => {
    if (val === "single") {
      setPopup({
        ...popup,
        show: true,
        type: "add-category",
      });
    } else if (val === "update") {
      setPopup({
        ...popup,
        show: true,
        type: "update-category",
        id: id,
      });
    } else if (val === "multiple") {
      setPopup({
        ...popup,
        show: true,
        type: "add-multiple-category",
      });
    }
  };
  const dispatch = useDispatch();

  const filterData = (value) => {
    dispatch(getCategoryList({ page: 0, search, sort, limit, filter: value }));
  };

  return (
    <ModuleLayout
      minimizedSidebar={minimizedSidebar}
      setMinimizedSidebar={setMinimizedSidebar}
      mobileSidebar={mobileSidebar}
      setMobileSidebar={setMobileSidebar}
      active={active}
      setActive={setActive}
      content={content}
      alertBox={alertBox}
    >
      <header className="list-header">
        <div className="list-title">
          <h2>Category Management</h2>
          <div className="flex-center gap-1">
            {Object.values(privileges["categoryManagement"]).includes(
              "write"
            ) && (
              <button
                className="btn-primary"
                onClick={() => handleClick("single")}
              >
                <i className="fa-solid fa-feather"></i> Add Catagory
              </button>
            )}

            {/* {Object?.values(privileges["categoryManagement"])?.includes(
              "delete"
            ) && selectAllRows ? (
              <button
                className="btn-secondary flex-center gap-1"
                onClick={() => {
                  deleteFunction("all Categories");
                }}
              >
                <i className="fa-light fa-trash"></i>
                {`Delete ${selectedRows.length}`}
              </button>
            ) : null} */}
          </div>
        </div>
      </header>

      <div className="list-container custom-box">
        <div className="tb_controls">
          <div className="flex-center gap-1">
            <DropDownBox
              options={SortItems}
              size="small"
              dropDownTitle="Sort"
              animateDropDownTitle
              customSetter={setSort}
              customDropBoxStyles={{
                opacity: 0.6,
                color: "#262626",
                padding: "0.6rem",
                border: "1px solid var(--color-neutral-92)",
              }}
            />
            <DropDownBox
              options={categoryFilters}
              size="small"
              dropDownTitle="Category Type"
              animateDropDownTitle
              customSetter={filterData}
              customDropBoxStyles={{
                opacity: 0.6,
                color: "#262626",
                padding: "0.6rem",
                border: "1px solid var(--color-neutral-92)",
              }}
              resetButton
            />
          </div>
          <SearchField value={search} setValue={setSearch} />
        </div>

        {categoryList?.length > 0 ? (
          <div className="category-wrapper">
            <CatCard
              isLoading={contentLoading}
              data={categoryList}
              privileges={privileges}
              hasPrivilege="categoryManagement"
              deleteCard={deleteFunction}
              handleClick={handleClick}
            />
          </div>
        ) : (
          <NotFound />
        )}

        {metaData?.total >= 5 && (
          <Pagination
            sliceName={"CatCategory"}
            api={getCategoryList}
            search={search}
            sort={sort}
            limit={limit}
            setLimit={setLimit}
            tableIndex={tableIndex}
            setTableIndex={setTableIndex}
            btnNo={btnNo}
            setBtnNo={setBtnNo}
            defaultRows={6}
            customFunction={() => setSelectedRows(new Set())}
          />
        )}
      </div>

      {popup.show && (
        <CategoryManager
          popup={popup}
          setPopup={setPopup}
          setBtnNo={setBtnNo}
          setTableIndex={setTableIndex}
        />
      )}
      {alertBox && (
        <ConformationPopup
          customState={alertBox}
          customSetter={setAlertBox}
          api={deleteCategoryAsync}
          popUpData={content}
          customFunction={() => setSelectedRows(new Set())}
          // toastMessage={"Category removed successfully"}
        />
      )}
    </ModuleLayout>
  );
};

export default Category;
