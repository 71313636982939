/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import CustomTable from "components/CustomTable";
import ConformationPopup from "components/ui/ConformationPopup";
import SearchField from "components/ui/SearchField";
import useDisableSkelaton from "hooks/useDisableSkelaton";
import useFetchList from "hooks/useFetchList";
import ModuleLayout from "layouts/ModuleLayout";
import { getBreadcrumbName, unixTimeToReadableFormat } from "helper/helper";
import { SortItems, TemplateFilters } from "helper/placeholder-data";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { deleteTemplate, getTemplateList } from "../redux/templateApi";
import { useNavigate } from "react-router-dom";
import DropDownBox from "components/DropDownBox";
import useSelectRows from "hooks/useSelectRows";

const Templates = ({ setTitle }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    setTitle("Content Management | Templates");
  }, [setTitle]);

  const { templateList, metaData, templateLoading } = useSelector(
    (state) => state.cmsTemplate,
    shallowEqual
  );

  const [active, setActive] = useState("cmsTemplates");
  const [mobileSidebar, setMobileSidebar] = useState(false);
  const [minimizedSidebar, setMinimizedSidebar] = useState(false);

  const {
    content,
    alertBox,
    setAlertBox,
    selectAllRows,
    selectedRows,
    setSelectedRows,
    toggleSelectAll,
    toggleRowSelection,
    deleteFunction,
  } = useSelectRows(templateList, "template_id");

  // Used useFetchList hook to fetch list dynamically, with search, & limit & sort
  const {
    privileges,
    limit,
    setLimit,
    sort,
    setSort,
    search,
    setSearch,
    btnNo,
    setBtnNo,
    tableIndex,
    setTableIndex,
  } = useFetchList(getTemplateList, metaData, selectAllRows, setSelectedRows);
  // used this hook to disable skelaton loading if api request is less than 300ms
  const { contentLoading } = useDisableSkelaton(templateLoading);

  // Made an array of list items that will given as prop to custom table with tabledata object.
  const listContent = templateList.map((item, i) => {
    const { id, num, title, type, slug, createdAt } = item;
    const crDt = unixTimeToReadableFormat(createdAt);
    const similarItems = [
      { style: "name", val: title },
      { style: "email", val: slug },
      { style: "", val: type },
      { style: "", val: crDt },
    ];
    return [id, num, title, slug, similarItems];
  });

  const TABLEDATA = {
    loadingState: contentLoading, //loading state for the list
    skalatonDetails: { col: 7, rows: limit }, // showing columns and rows in the skalton
    list: templateList, // list data
    listHeading: ["Title", "Slug", "Type", "Created At"], // list heading
    listContent,
    selectedRows,
    selectAllRows,
    setSelectedRows,
    toggleSelectAll,
    toggleRowSelection,
    deleteFunction,
    sliceName: "cmsTemplate", // slice name
    search, // search state
    PagAPI: getTemplateList, // api
    sort,
    limit,
    setLimit,
    btnNo,
    setBtnNo,
    tableIndex,
    setTableIndex,
    privileges,
    hasAccess: "templateManagement",
    navigateTo: update,
    metaData,
  };

  function update(id) {
    const template = templateList.find((template) => template.id === id);
    const breadcrumbName = getBreadcrumbName(template);
    navigate(`/content/templates/${id}`, {
      state: { slug: id, title: breadcrumbName },
    });
  }

  // useEffect(() => {
  //   dispatch(setPageDetails({ page: metaData?.page, search }));
  // }, [metaData]);

  const [filterVal, setFilterVal] = useState("");

  useEffect(() => {
    dispatch(
      getTemplateList({ page: 0, search, sort, limit, filter: filterVal })
    );
  }, [filterVal]);

  return (
    <ModuleLayout
      minimizedSidebar={minimizedSidebar}
      setMinimizedSidebar={setMinimizedSidebar}
      mobileSidebar={mobileSidebar}
      setMobileSidebar={setMobileSidebar}
      active={active}
      setActive={setActive}
      content={content}
      alertBox={alertBox}
    >
      <header className="list-header">
        <div className="list-title">
          <h2>Template Management | Email, SMS & Push Notifications</h2>
          <div className="flex-center gap-1">
            <button
              className="btn-primary flex-center gap-1"
              onClick={() => navigate("/content/templates/add-new")}
            >
              <i className="fa-solid fa-feather"></i> Add Template
            </button>

            {Object?.values(privileges["templateManagement"])?.includes(
              "delete"
            ) && selectedRows.length !== 0 ? (
              <button
                className="btn-secondary flex-center gap-1"
                onClick={() => {
                  deleteFunction("all templates");
                }}
              >
                <i className="fa-light fa-trash"></i>
                {`Delete ${selectedRows.length}`}
              </button>
            ) : null}
          </div>
        </div>
      </header>

      <div className="list-container custom-box">
        <div className="tb_controls">
          <div className="flex-center gap-1">
            <DropDownBox
              options={TemplateFilters}
              size="small"
              dropDownTitle="Filter"
              animateDropDownTitle
              customSetter={setFilterVal}
              customDropBoxStyles={{
                opacity: 0.6,
                color: "#262626",
                padding: "0.6rem",
                border: "1px solid var(--color-neutral-92)",
              }}
              resetButton
            />
            <DropDownBox
              options={SortItems}
              size="small"
              dropDownTitle="Sort"
              animateDropDownTitle
              customSetter={setSort}
              customDropBoxStyles={{
                opacity: 0.6,
                color: "#262626",
                padding: "0.6rem",
                border: "1px solid var(--color-neutral-92)",
              }}
            />
          </div>
          <SearchField value={search} setValue={setSearch} />
        </div>
        <CustomTable {...TABLEDATA} />
      </div>

      {alertBox && (
        <ConformationPopup
          customState={alertBox}
          customSetter={setAlertBox}
          api={deleteTemplate}
          popUpData={content}
          customFunction={() => setSelectedRows(new Set())}
          toastMessage={"Template is removed successfully"}
        />
      )}
    </ModuleLayout>
  );
};

export default Templates;
