import React from "react";

const Payment = () => {

  return (
    <div className="payment-main">
      <div className="pm-top">
        <div className="pm-div">
          <div className="pmtop">
            <span className="title">Payment Method</span>
            <div className="eDiv">Edit</div>
          </div>
          <div className="pm-content">
            <span className="val">GPay</span>
          </div>
        </div>
        <div className="pm-div">
          <div className="pmtop">
            <span className="title">Payment Status</span>
            <div className="eDiv">Edit</div>
          </div>
          <div className="pm-content">
            <span className="status-div">Paid</span>
          </div>
        </div>
      </div>
      <div className="pm-bottom">
        <div className="pm-div" style={{ width: "100%" }}>
          <div className="pmtop">
            <span className="title">Transaction History</span>
            <div className="Search-div">
              <span className="material-symbols-outlined">search</span>
              <input type="text" placeholder="Search" className="od-search" />
            </div>
          </div>
          <div className="pm-content">
            <div className="pmTable">
              <table className="pmTable-main">
                <thead>
                  <tr>
                    <th className="pmt-head">Type</th>
                    <th className="pmt-head">Amount</th>
                    <th className="pmt-head">Timestamp</th>
                    <th className="pmt-head">Details</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th className="pmt-data"><div className="status-div">
                      Paid</div></th>
                    <th className="pmt-data">₹ 25,000</th>
                    <th className="pmt-data">Dec, 29, 2023 / 10:43:24 AM</th>
                    <th className="pmt-data">Null</th>
                  </tr>
                  <tr>
                    <th className="pmt-data"><div className="status-div">
                      Paid</div></th>
                    <th className="pmt-data">₹ 25,000</th>
                    <th className="pmt-data">Dec, 29, 2023 / 10:43:24 AM</th>
                    <th className="pmt-data">Null</th>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Payment;
