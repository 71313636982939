import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import persistReducer from "redux-persist/es/persistReducer";
import rootReducer from "./rootReducer";
import { persistStore } from "redux-persist";
import { thunk } from "redux-thunk";
import { encryptTransform } from "redux-persist-transform-encrypt";

const persistConfig = {
  key: "auth",
  storage: storage,
  whitelist: ["auth"],
  blacklist: [],
  transforms: [encryptTransform({ secretKey: "secret-key-#1" })],
};

const pReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: pReducer,
  // middleware: [thunk],
  middleware: (getDefaultMiddleware) => {
    return [thunk];
  },
});

const persistor = persistStore(store);

export { store, persistor };
