/* eslint-disable no-useless-escape */
import React, { useEffect } from "react";
import AuthLayout from "layouts/AuthLayout";
import SubmitButton from "components/ui/SubmitButton";
import { forgotPassword } from "../redux/authApi";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";

const ForgotPassword = ({ setTitle }) => {
  useEffect(() => {
    setTitle("Peak72 finance | Forgot Password");
  }, [setTitle]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .trim()
        .email("Enter valid email")
        .required("Email Field should not be empty")
        .matches(
          /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
          "Please enter a valid email!"
        )
        .required("Email address should not be empty"),
      // .matches("@peak72.com", "email must contain @peak72.com domain"),
    }),
    onSubmit: async (values, { resetForm }) => {
      const data = {
        email: values.email.trim(),
      };
      const res = await dispatch(forgotPassword(data));
      if (res?.payload?.status) {
        resetForm();
        navigate("/");
      }
    },
  });

  const InputProps = {
    className: "form-input",
    onChange: formik.handleChange,
    onBlur: formik.handleBlur,
    onKeyDown: (e) => {
      e.key === " " && e.preventDefault();
    },
    onPaste: (e) => e.preventDefault(),
    onCopy: (e) => e.preventDefault(),
  };

  return (
    <AuthLayout
      title="Forgot your password?"
      subtitle="Please enter the email address associated with your account and We will email you a link to reset your password"
    >
      <form
        className="form"
        onSubmit={formik.handleSubmit}
        noValidate="novalidate"
      >
        <div className="form-control">
          <label className="form-label">Email Address</label>
          <div className="form-input-box">
            <input
              id="email"
              name="email"
              type="email"
              placeholder="Enter your email address"
              value={formik.values.email}
              {...InputProps}
              maxLength={80}
            />
          </div>
          {formik.errors.email && formik.touched.email ? (
            <span className="err">{formik.errors.email}</span>
          ) : null}
        </div>

        <SubmitButton
          type="submit"
          className="btn-primary"
          isLoading={formik.isSubmitting}
        >
          Continue
        </SubmitButton>

        <p className="text-center">
          Back to{" "}
          <Link to="/login" style={{ color: "var(--color-primary)" }}>
            Login
          </Link>
        </p>
      </form>
    </AuthLayout>
  );
};
export default ForgotPassword;
