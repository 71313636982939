import React from "react";

const SearchField = ({ value, setValue }) => {
  const handleClick = () => {
    if (value !== "") {
      setValue("");
    }
  };
  return (
    <div className="search-wrapper flex-center">
      <button className="icon icon-btn" onClick={handleClick}>
        <i
          className={
            value === "" ? "fa-regular fa-magnifying-glass" : "fa-regular fa-x"
          }
        ></i>
      </button>
      <input
        type="text"
        name="text"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        className="input"
        placeholder="Search Here.."
      />
    </div>
  );
};

export default SearchField;
