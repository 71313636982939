import React, { useEffect, useState } from "react";
import ModuleLayout from "layouts/ModuleLayout";
import ConformationPopup from "components/ui/ConformationPopup";
import Pagination from "components/Pagination";
import CatCard from "components/ui/CatCard";
import SearchField from "components/ui/SearchField";
import useDisableSkelaton from "hooks/useDisableSkelaton";
import useFetchList from "hooks/useFetchList";
import { deleteBrandAsync, getBrandList } from "../redux/brandsApi";
import { shallowEqual, useSelector } from "react-redux";
import ManageBrand from "./ManageBrand";
import DropDownBox from "components/DropDownBox";
import NotFound from "components/ui/NotFound";
import { SortItems } from "helper/placeholder-data";
import useSelectRows from "hooks/useSelectRows";

const Brand = ({ setTitle }) => {
  useEffect(() => {
    setTitle("Brand Management");
  }, [setTitle]);

  const { brandList, metaData, brandLoading } = useSelector(
    (state) => state.CatBrand,
    shallowEqual
  );

  const [popup, setPopup] = useState({ show: false, type: "", id: "" });
  const [active, setActive] = useState("brands");
  const [mobileSidebar, setMobileSidebar] = useState(false);
  const [minimizedSidebar, setMinimizedSidebar] = useState(false);

  const { contentLoading } = useDisableSkelaton(brandLoading);

  const {
    content,
    alertBox,
    setAlertBox,
    selectAllRows,
    selectedRows,
    setSelectedRows,
    // toggleSelectAll,
    // toggleRowSelection,
    deleteFunction,
  } = useSelectRows(brandList, "brand_id");

  const {
    privileges,
    limit,
    setLimit,
    sort,
    setSort,
    search,
    setSearch,
    btnNo,
    setBtnNo,
    tableIndex,
    setTableIndex,
  } = useFetchList(getBrandList, metaData, selectAllRows, setSelectedRows, 6);

  const handleClick = (val, id) => {
    if (val === "single") {
      setPopup({
        ...popup,
        show: true,
        type: "add-brand",
      });
    } else if (val === "update") {
      setPopup({
        ...popup,
        show: true,
        type: "update-brand",
        id: id,
      });
    } else if (val === "multiple") {
      setPopup({
        ...popup,
        show: true,
        type: "add-multiple-brand",
      });
    }
  };

  return (
    <ModuleLayout
      minimizedSidebar={minimizedSidebar}
      setMinimizedSidebar={setMinimizedSidebar}
      mobileSidebar={mobileSidebar}
      setMobileSidebar={setMobileSidebar}
      active={active}
      setActive={setActive}
      content={content}
      alertBox={alertBox}
    >
      <header className="list-header">
        <div className="list-title">
          <h2>Brand Management</h2>
          <div className="flex-center gap-1">
            {Object.values(privileges["brandManagement"]).includes("write") && (
              <button
                className="btn-primary"
                onClick={() => handleClick("single")}
              >
                <i className="fa-solid fa-feather"></i> Add Brand
              </button>
            )}

            {/* {Object?.values(privileges["brandManagement"])?.includes(
              "delete"
            ) && selectAllRows ? (
              <button
                className="btn-secondary flex-center gap-1"
                onClick={() => deleteFunction("all Brands")}
              >
                <i className="fa-light fa-trash"></i>
                {`Delete ${selectedRows.length}`}
              </button>
            ) : null} */}
          </div>
        </div>
      </header>

      {brandList?.length > 0 ? (
        <div className="list-container custom-box">
          <div className="tb_controls">
            <DropDownBox
              options={SortItems}
              size="small"
              dropDownTitle="Sort"
              animateDropDownTitle
              customSetter={setSort}
              customDropBoxStyles={{
                opacity: 0.6,
                color: "#262626",
                padding: "0.6rem",
                border: "1px solid var(--color-neutral-92)",
              }}
            />
            <SearchField value={search} setValue={setSearch} />
          </div>

          <div className="category-wrapper">
            <CatCard
              isLoading={contentLoading}
              data={brandList}
              privileges={privileges}
              hasPrivilege="brandManagement"
              deleteCard={deleteFunction}
              handleClick={handleClick}
            />
          </div>

          {metaData?.total >= 5 && (
            <Pagination
              sliceName="CatBrand"
              api={getBrandList}
              search={search}
              sort={sort}
              limit={limit}
              setLimit={setLimit}
              tableIndex={tableIndex}
              setTableIndex={setTableIndex}
              btnNo={btnNo}
              setBtnNo={setBtnNo}
              defaultRows={6}
              customFunction={() => setSelectedRows(new Set())}
            />
          )}
        </div>
      ) : (
        <NotFound />
      )}

      {popup.show && (
        <ManageBrand
          popup={popup}
          setPopup={setPopup}
          setBtnNo={setBtnNo}
          setTableIndex={setTableIndex}
        />
      )}
      {alertBox && (
        <ConformationPopup
          customState={alertBox}
          customSetter={setAlertBox}
          api={deleteBrandAsync}
          popUpData={content}
          customFunction={() => setSelectedRows(new Set())}
        />
      )}
    </ModuleLayout>
  );
};

export default Brand;
