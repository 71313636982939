/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useState } from "react";
import { glossarySheet } from "assets/files";
import InputField from "components/ui/InputField";
import BulkUpload from "components/BulkUpload";
import SubmitButton from "components/ui/SubmitButton";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  addBulkGlossaryAsync,
  addGlossary,
  getGlossaryDetail,
  updateGlossaryDetail,
} from "../redux/glossaryApi";
import { useFormik } from "formik";
import * as Yup from "yup";
import { clearGlossaryDetails } from "../redux/glossarySlice";

const GlossaryManager = ({ popup, setPopup, limit }) => {
  const [addStyle, setAddStyle] = useState(true);
  const [activeInputs, setActiveInputs] = useState(true);

  const dispatch = useDispatch();
  const { glossaryDetail } = useSelector(
    (state) => state.glossary,
    shallowEqual
  );

  useEffect(() => {
    const data = {
      id: popup.slug,
    };
    if (popup.slug) dispatch(getGlossaryDetail(data));

    return () => dispatch(clearGlossaryDetails());
  }, [popup.slug]);

  const initialValues = {
    name: "",
    description: "",
  };

  const glossarySchema = Yup.object({
    name: Yup.string()
      .trim()
      .min(3, "Name must be at least 3 characters long")
      .max(80, "Glossary name must be less then 80 characters")
      .required("Glossary name is required"),
    description: Yup.string()
      .trim()
      .min(3, "Description must be at least 3 characters long")
      .max(150, "Description must be less than 150 characters")
      .required("Description should not be empty"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: glossarySchema,
    onSubmit: async (values) => {
      if (popup.type === "add-glossary") {
        const data = {
          send: {
            glossary_name: values.name.trim(),
            description: values.description.trim(),
          },
          limit,
        };
        const response = await dispatch(addGlossary(data));
        if (response?.payload) {
          setPopup({ id: "", show: false, type: "" });
        }
      }

      if (popup.type === "update-glossary") {
        const data = {
          send: {
            glossary_name: values.name.trim(),
            description: values.description.trim(),
          },
          id: popup?.slug,
          limit,
        };
        const response = await dispatch(updateGlossaryDetail(data));
        if (response?.payload) {
          setPopup({ slug: "", show: false, type: "" });
        }
      }
    },
  });

  // function handleDelete() {
  //   formik.setFieldValue("excelFile", null);
  // }

  const handleClick = (e) => {
    setAddStyle(!addStyle);
    chatBoxVisibility();
  };

  function chatBoxVisibility() {
    if (popup.show) {
      const styleTimer = setTimeout(() => {
        setPopup({ ...popup, show: !popup.show });
        document
          .getElementsByClassName("container")[0]
          .classList.remove("popUp-container");
        clearTimeout(styleTimer);
      }, 200);
    }
  }

  useEffect(() => {
    document
      .getElementsByClassName("container")[0]
      .classList.add("popUp-container");

    if (popup.type === "update-glossary") {
      formik.setValues({
        name: glossaryDetail?.name,
        description: glossaryDetail?.description,
      });
    }
  }, [popup.type, glossaryDetail]);

  const InputProps = {
    className: "form-input",
    onChange: formik.handleChange,
    onBlur: formik.handleBlur,
    disabled: popup.type === "update-glossary" ? activeInputs : false,
  };

  return (
    <div id="confirm-box-wrapper">
      <div
        className={
          "popup-wrapper" +
          (addStyle
            ? " show_delete-component-fadeIn-animation"
            : " hide_delete-component-fadeOut-animation")
        }
      >
        <div
          className={`popup-content ${popup.type === "add-multiple-glossary" ? "w-32" : ""}`}
        >
          <div className="popup-header">
            <h2>{popup.type.replaceAll("-", " ")}</h2>
            <button className="close flex-center" onClick={() => handleClick()}>
              <i className="fa-sharp fa-regular fa-xmark-large"></i>
            </button>
          </div>

          {popup.type === "add-glossary" || popup.type === "update-glossary" ? (
            <div className="pc-wrapper">
              <div className="auth-form-wrapper">
                <form
                  className="form"
                  onSubmit={formik.handleSubmit}
                // noValidate="novalidate"
                >
                  <div className="form-control">
                    <label className="form-label">Name</label>
                    <div className="form-input-box">
                      <input
                        id="name"
                        name="name"
                        type="text"
                        placeholder="Please enter glossary here"
                        value={formik.values.name || ""}
                        onInput={(e) =>
                        (e.target.value = e.target.value.replace(
                          /(?:^|\s)\S/g,
                          (match) => match.toUpperCase()
                        ))
                        }
                        maxLength={81}
                        {...InputProps}
                      />
                    </div>
                    {formik.errors.name && formik.touched.name && (
                      <span className="err">{formik.errors.name}</span>
                    )}
                  </div>

                  <div className="form-control">
                    <label className="form-label">Description</label>
                    <div className="form-input-box">
                      <textarea
                        type="text"
                        id="description"
                        name="description"
                        value={formik.values.description}
                        onInput={(e) =>
                        (e.target.value = e.target.value.replace(
                          /^./,
                          (match) => match.toUpperCase()
                        ))
                        }
                        {...InputProps}
                        maxLength={151}
                        rows={4}
                        cols={5}
                      />
                    </div>
                    {formik.errors.description &&
                      formik.touched.description && (
                        <span className="err">{formik.errors.description}</span>
                      )}
                  </div>

                  <div className="flex-center">
                    {popup.type === "update-glossary" &&
                      activeInputs === true ? (
                      <button
                        className="btn-outlined flex-center"
                        onClick={() => setActiveInputs(!activeInputs)}
                      >
                        Edit Glossary
                      </button>
                    ) : (
                      <SubmitButton
                        className="btn-primary"
                        type="submit"
                        isLoading={formik.isSubmitting}
                      >
                        Submit
                      </SubmitButton>
                    )}
                  </div>
                </form>
              </div>
            </div>
          ) : null}
          {popup.type === "add-multiple-glossary" && (
            <BulkUpload
              sheet={glossarySheet}
              fetchApi={addBulkGlossaryAsync}
              handleClick={handleClick}
              templateName={"Glossary Template"}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default GlossaryManager;
