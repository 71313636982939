import React from "react"

const TableSkelaton = ({ col, row }) => {
  const columns = Array(col).fill(null)
  const rows = Array(row).fill(null)
  return (
    <div className='table-skelaton'>
      <table className='data-table skelaton' cellSpacing={0}>
        <thead>
          <tr>
            {columns?.map((column, i) => (
              <th className='tg-cly1' key={i}>
                <div className='line'></div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((row, i) => (
            <tr key={i}>
              {columns?.map((column, i) => (
                <td className='tg-cly1' key={i}>
                  <div className='line'></div>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default TableSkelaton
