/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { uploadImage, urlToObject } from "helper/helper";
import { skillsSheet } from "assets/files";
import DropDownBox from "components/DropDownBox";
import BulkUpload from "components/BulkUpload";
import SubmitButton from "components/ui/SubmitButton";
import InputField from "components/ui/InputField";
import { getCategoryList } from "modules/catalogue/categories/redux/categoryApi";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  addBulkSkillAsync,
  addSkill,
  getSkillDetail,
  updateSkillDetail,
} from "../redux/skillApi";
import { clearSkillDetail } from "../redux/skillSlice";

const SkillsManager = ({ popup, setPopup, setTableIndex, setBtnNo }) => {
  const [addStyle, setAddStyle] = useState(true);
  const [activeInputs, setActiveInputs] = useState(true);
  const [skillImg, setSkillImg] = useState(null);
  const [catList, setCatList] = useState([]);

  const dispatch = useDispatch();
  const { skillDetail } = useSelector((state) => state.CatSkill, shallowEqual);
  const SkillImage = skillDetail?.image?.localUrl || skillDetail?.image?.bucketUrl;

  const { categoryList } = useSelector(
    (state) => state.CatCategory,
    shallowEqual
  );

  useEffect(() => {
    if (categoryList?.length > 0) {
      const list = categoryList.map((category) => {
        const label = category?.name;
        const value = category?.id;
        return { label, value };
      });
      setCatList(list);
    }
  }, [categoryList]);

  useEffect(() => {
    dispatch(
      getCategoryList({
        page: "0",
        limit: "0",
        search: "",
        sort: "desc",
        filter: "skill",
      })
    );

    return () => dispatch(clearSkillDetail());
  }, [dispatch, popup.id]);

  useEffect(() => {
    if (popup?.id) {
      dispatch(getSkillDetail({ id: popup.id }));
    }

    return () => dispatch(clearSkillDetail());
  }, [popup.id, dispatch]);

  const formik = useFormik({
    initialValues: {
      file: null,
      name: "",
      category_id: "",
      is_premium: false,
      desc: ""
    },
    validationSchema: Yup.object({
      // file: Yup.mixed().required("Please upload image first"),
      file: Yup.mixed().required("Skill image is required"),
      name: Yup.string()
        .trim()
        .min(3, "Skill Name must be at least 3 characters long")
        .max(80, "Skill Name must be less then 80 characters")
        .required("Skill is required"),
      category_id: Yup.string().required("Please fill the category first"),
      is_premium: Yup.boolean(),
      desc: Yup.string().required("Description is required")
    }),
    onSubmit: async (values, { resetForm }) => {
      const data = {
        send: {
          image: values.file,
          skill_name: values.name.trim(),
          category_id: values.category_id.trim(),
          is_premium: values.is_premium,
          description: values.desc
        },
        id: popup.id,
      };
      if (popup.type === "add-skill") {
        const response = await dispatch(addSkill(data?.send));
        if (response?.payload) {
          setPopup({ show: false, type: "", id: "" });
          setBtnNo([1, 2, 3]);
          setTableIndex(0);
          resetForm();
        }
      }

      if (popup.type === "update-skill") {
        const response = await dispatch(updateSkillDetail(data));
        if (response?.payload) {
          setPopup({ show: false, type: "", id: "" });
          resetForm();
        }
      }
    },
  });

  useEffect(() => {
    if (popup.type === "update-skill" && skillDetail) {
      (async () => {
        const imageObject = SkillImage && (await urlToObject(SkillImage));
        formik.setValues({
          name: skillDetail.name,
          type: skillDetail.type,
          file: imageObject,
          is_premium: skillDetail.isPremium,
          category_id: skillDetail.categoryId,
          desc: skillDetail.description
        });
        setSkillImg(SkillImage);
      })();
    }
  }, [popup.type, skillDetail]);

  useEffect(() => {
    if (formik.values.file) {
      const img = URL.createObjectURL(formik.values.file);
      setSkillImg(img);
    }
  }, [formik.values.file]);

  useEffect(() => {
    document
      .getElementsByClassName("container")[0]
      .classList.add("popUp-container");
  }, []);

  const handleClick = () => {
    setAddStyle((prev) => !prev);
    chatBoxVisibility();
  };

  function chatBoxVisibility() {
    if (popup.show) {
      const styleTimer = setTimeout(() => {
        setPopup({ ...popup, show: false });
        document
          .getElementsByClassName("container")[0]
          .classList.remove("popUp-container");
        clearTimeout(styleTimer);
      }, 200);
    }
  }

  return (
    <div id="confirm-box-wrapper">
      <div
        className={
          "popup-wrapper" +
          (addStyle
            ? " show_delete-component-fadeIn-animation"
            : " hide_delete-component-fadeOut-animation")
        }
      >
        <div
          className={`popup-content ${popup.type === "add-multiple-skill" ? "w-32" : ""}`}
        >
          <div className="popup-header">
            <h2>{popup.type.replaceAll("-", " ")}</h2>
            <button className="close flex-center" onClick={() => handleClick()}>
              <i className="fa-sharp fa-regular fa-xmark-large"></i>
            </button>
          </div>

          {popup.type === "add-skill" || popup.type === "update-skill" ? (
            <div className="pc-wrapper">
              <div className="auth-form-wrapper">
                <form
                  className="form"
                  onSubmit={formik.handleSubmit}
                // noValidate="novalidate"
                >
                  <div className="form-control">
                    <label htmlFor="file" className="form-label">
                      {skillImg ? (
                        <div
                          className="update-image-wrapper flex-center"
                        // style={
                        //   activeInputs ? { cursor: "not-allowed" } : null
                        // }
                        >
                          <img
                            src={skillImg}
                            alt="brand"
                            crossOrigin="anonymous"
                            width={128}
                            height={128}
                          />
                        </div>
                      ) : (
                        <div className="update-image-wrapper flex-center gap-1">
                          <div
                            className="dummy-img flex-center"
                          // style={
                          //   activeInputs ? { cursor: "not-allowed" } : null
                          // }
                          >
                            <i className="fa-duotone fa-solid fa-image dummy-img__icon"></i>
                          </div>
                          <span className="profChange_label">Add Icon</span>
                        </div>
                      )}
                      <InputField
                        id="file"
                        name="file"
                        type="file"
                        accept=".jpg, .jpeg, .png, .gif, .webp"
                        onChange={(e) => uploadImage(e, formik.setFieldValue)}
                        onBlur={formik.handleBlur}
                        disabled={popup.type === "update-skill" && activeInputs}
                        hidden
                      />
                    </label>
                    {formik.errors.file && formik.touched.file && (
                      <span className="err">{formik.errors.file}</span>
                    )}
                  </div>

                  <div className="form-control">
                    <label className="form-label">Skill Name</label>
                    <div className="form-input-box">
                      <InputField
                        id="name"
                        name="name"
                        type="text"
                        placeholder="Please enter skill here..."
                        value={formik.values.name || ""}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        onInput={(e) =>
                        (e.target.value = e.target.value
                          // .replace(/[^A-Za-z ]/gi, "")
                          .replace(/(?:^|\s)\S/g, (match) =>
                            match.toUpperCase()
                          ))
                        }
                        maxLength={80}
                        disabled={popup.type === "update-skill" && activeInputs}
                      />
                    </div>
                    {formik.errors.name && formik.touched.name && (
                      <span className="err">{formik.errors.name}</span>
                    )}
                  </div>

                  <div className="form-control">
                    <label className="form-label">Skill Category</label>
                    <div className="form-input-box">
                      <DropDownBox
                        options={catList}
                        defaultValue="Select Category"
                        customSetter={formik.setFieldValue}
                        customFormikLabel="category_id"
                        customDropBoxStyles={{
                          border: "0.063rem solid #d2d6da",
                          borderRadius: "0.5rem",
                          color: "#454545",
                        }}
                        customTextStyle={{
                          opacity: "0.7",
                          color: "#454545",
                          fontSize: "small",
                        }}
                        incomingValue={
                          popup.type === "update-skill"
                            ? skillDetail?.categoryId
                            : null
                        }
                        disabled={
                          popup.type === "add-skill" ? true : !activeInputs
                        }
                      />
                    </div>
                    {formik.errors.category_id &&
                      formik.touched.category_id && (
                        <span className="err">{formik.errors.category_id}</span>
                      )}
                  </div>

                  <div className="form-control">
                    <label className="form-label">Description</label>
                    <div className="form-input-box">
                      <textarea
                        type="text"
                        id="desc"
                        name="desc"
                        value={formik.values.desc}
                        className="form-input"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        rows={4}
                        cols={5}
                        disabled={popup.type === "update-faq" ? activeInputs : false}
                      />
                    </div>
                    {formik.errors.desc &&
                      formik.touched.desc && (
                        <span className="err">{formik.errors.desc}</span>
                      )}
                  </div>

                  <div className="flex-between gap-1">
                    <div className="form-label">For Premium User?</div>
                    <input
                      id="is_premium"
                      name="is_premium"
                      type="checkbox"
                      checked={formik.values.is_premium}
                      onChange={(e) =>
                        formik.setFieldValue("is_premium", e.target.checked)
                      }
                      onBlur={formik.handleBlur}
                      disabled={popup.type === "update-skill" && activeInputs}
                      className="cc-inp"
                      hidden
                    />
                    <label htmlFor="is_premium">
                      <div className="custom-checkbox">
                        <div className="check-circle"></div>
                      </div>
                    </label>
                  </div>

                  <div className="flex-center">
                    {popup.type === "update-skill" && activeInputs === true ? (
                      <button
                        className="btn-outlined flex-center"
                        onClick={() => setActiveInputs(!activeInputs)}
                      >
                        Edit Category
                      </button>
                    ) : (
                      <SubmitButton
                        size="fit-content"
                        className="btn-primary"
                        type="submit"
                        isLoading={formik.isSubmitting}
                      >
                        Submit
                      </SubmitButton>
                    )}
                  </div>
                </form>
              </div>
            </div>
          ) : null}
          {popup.type === "add-multiple-skill" && (
            <BulkUpload
              sheet={skillsSheet}
              fetchApi={addBulkSkillAsync}
              handleClick={handleClick}
              templateName={"Skill Template"}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default SkillsManager;
