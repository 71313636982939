/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useState } from "react";
import ModuleLayout from "layouts/ModuleLayout";
import Loader from "components/ui/Loader";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { deleteReview, getReviewDetail } from "../redux/reviewApi";
import { unixTimeToReadableFormat } from "helper/helper";
import { StarRating } from "components/StarRating";
import NotFound from "components/ui/NotFound";
import SeprPagination from "components/ui/SeprPagination";
import ConformationPopup from "components/ui/ConformationPopup";

const Managereviews = ({ setTitle }) => {
  const [active, setActive] = useState("reviews");
  const [mobileSidebar, setMobileSidebar] = useState(false);
  const [minimizedSidebar, setMinimizedSidebar] = useState(false);
  const { id } = useParams()
  const [alertBox, setAlertBox] = useState(false);
  const [popUpData, setPopupData] = useState();
  const navigate = useNavigate()
  const [selectedIds, setSelectedIds] = useState([])
  
  const [deleteMode, setDeleteMode] = useState(false)
  const [filterListData, setFilterListData] = useState({
    page: 0,
    limit: 5,
    sort: "desc",
  })

  const dispatch = useDispatch();

  useEffect(() => {
    setTitle("Manage Reviews");
  }, [setTitle, dispatch]);

  useEffect(() => {
    if (id) {
      const data = {
        id: id,
        page: filterListData?.page,
        limit: filterListData?.limit,
        sort: filterListData?.sort,
      }
      dispatch(getReviewDetail(data))
    }
  }, [id, filterListData])

  const { reviewDetail, listMetaData, dataList, reviewLoading } = useSelector(state => state.review, shallowEqual)

  const [imageZoom, setImageZoom] = useState()

  const handleDelete = async (revid) => {
    const data = {
      send: {
        is_delete: true,
        review_id: [revid]
      },
      id: id
    }
    setPopupData({ title: "this review", data });
    setAlertBox(true);
  };

  const handleDeleteMultiple = () => {
    const data = {
      send: {
        is_delete: true,
        review_id: selectedIds
      },
      id: id
    }
    setPopupData({ title: "reviews", data });
    setAlertBox(true);
  }

  const handlePageChange = (pageNumber) => {
    setFilterListData({ ...filterListData, page: pageNumber })
  }
  const handleLimitChange = (limit) => {
    setFilterListData({ ...filterListData, limit: limit })
  }

  if (!reviewLoading && Object.keys(reviewDetail).length === 0) {
    return <NotFound />
  }

  const handleCheckboxChange = (item) => {
    if (selectedIds.includes(item.id)) {
      setSelectedIds(selectedIds.filter((id) => id !== item.id));
    } else {
      setSelectedIds([...selectedIds, item.id]);
    }
  };

  const cf = () => {
    setSelectedIds([])
    setDeleteMode(false)
  }

  return (
    <ModuleLayout
      minimizedSidebar={minimizedSidebar}
      setMinimizedSidebar={setMinimizedSidebar}
      setMobileSidebar={setMobileSidebar}
      mobileSidebar={mobileSidebar}
      setActive={setActive}
      active={active}
    >
      <Loader isLoading={reviewLoading} />

      <div className="list-container custom-box">
        <div className="form-header">
          <h2>Review Management</h2>
        </div>

        <main className="main">
          <div className="auth-form-wrapper">
            <div className="revprod-header flex gap-1">
              <img src={reviewDetail?.image?.localUrl} crossOrigin="anonymous" className="prod-img" alt="" />
              <div className="revprod-info">
                <h3 className="prod-title">{reviewDetail?.name}</h3>
                <div className="flex gap-1" style={{ alignItems: "center" }}>
                  <StarRating rating={reviewDetail?.rating} />
                  <span>({reviewDetail?.ratedBy} review)</span>
                </div>
                <button type="button" className="btn-primary mt-1" onClick={() => navigate(`/products/${reviewDetail?.id}`)}>Product Detail</button>
              </div>
            </div>
            <div className="mt-1 flex-between">
              <h3 style={{ fontWeight: "bold" }}>All Reviews</h3>
              {
                deleteMode ?
                  <div className="flex gap-2">
                    <button
                      type="button"
                      className="btn-outlined"
                      onClick={() => {
                        setSelectedIds([])
                        setDeleteMode(false)
                      }}
                    >
                      Abort
                    </button>
                    <button
                      type="button"
                      className="btn-secondary"
                      onClick={() => handleDeleteMultiple()}
                    >
                      <i className="fa-regular fa-trash"></i> Delete ({selectedIds.length})
                    </button>
                  </div> :
                  <button type="button" className="btn-outlined" onClick={() => setDeleteMode(true)}>Delete Multiple</button>
              }
            </div>
            <div className="rev-list">
              {dataList?.map((ele) =>
                <div className="rev-box mt-1" key={ele.id}>
                  <div className="flex-between">
                    <div>
                      <div className={deleteMode ? `flex-center gap-1 align-center` : ""}>
                        {
                          deleteMode &&
                          <label
                            htmlFor={`checkbox${ele.id}`}
                            className="TD_CheckBox_LB"
                          >
                            <input
                              name={"checkbox"}
                              id={`checkbox${ele.id}`}
                              type="checkbox"
                              className="checkbox-animated"
                              checked={selectedIds.includes(ele.id)}
                              onChange={() => handleCheckboxChange(ele)}
                            />
                            <div className="checkmark"></div>
                          </label>
                        }
                        <h3 className="m-0">{ele?.user?.name?.firstName} {ele?.user?.name?.lastName}</h3>
                      </div>
                      <p className="mt-1">{unixTimeToReadableFormat(ele?.createdAt)}</p>
                    </div>
                    <div className="flex-center gap-1">
                      <StarRating rating={ele?.rating} />
                      {
                        !deleteMode &&
                        <button
                          type="button"
                          className="btn-secondary"
                          onClick={() => handleDelete(ele.id)}
                        >
                          Delete
                        </button>
                      }
                    </div>
                  </div>
                  <p className="mt-1">{ele?.description}</p>
                  <div className="flex gap-1 mt-1 rev-imglist">
                    {
                      ele.images && ele?.images.length > 0 &&
                      ele?.images?.map((img, i) =>
                        <div onClick={() => setImageZoom(img.localUrl)} key={i}>
                          <img src={img?.localUrl} crossOrigin="anonymous" alt="" />
                        </div>
                      )
                    }
                  </div>
                </div>
              )}
              <div className="mt-1">
                <SeprPagination
                  metaData={listMetaData}
                  setRecords={handleLimitChange}
                  handlePageChange={handlePageChange}
                  records={filterListData.limit}
                  isLimitBox
                />
              </div>
            </div>
          </div>
        </main>
      </div>
      {imageZoom && (
        <div id="confirm-box-wrapper">
          <div className="image-container flex-center">
            <div className="event-card show_delete-component-fadeIn-animation">
              <button className="closespan" onClick={() => setImageZoom(false)}>
                <i className="fa-regular fa-x small"></i>
              </button>
              <div className="preview-image">
                <img src={imageZoom} alt="" crossOrigin="anonymous" />
              </div>
            </div>
          </div>
        </div>
      )}
      {alertBox && (
        <ConformationPopup
          customState={alertBox}
          customSetter={setAlertBox}
          api={deleteReview}
          popUpData={popUpData}
          customFunction={cf}
        />
      )}
    </ModuleLayout>
  );
};

export default Managereviews;
