import { createSlice } from "@reduxjs/toolkit";
import { getPolicyDetail, getPolicyList } from "./policiesApi";

const policiesSlice = createSlice({
  name: "cmsPolicy",
  initialState: {
    policyLoading: false,
    policyList: [],
    metaData: [],
    policyDetail: [],
  },
  reducers: {
    isPolicyLoading: (state, action) =>
      (state = {
        ...state,
        policyLoading: action.payload,
      }),
    // to reset policy details
    resetPolicyDetail: (state) => {
      state.policyDetail = [];
    },
  },

  extraReducers: (builder) => {
    // Get Policy Lists
    builder.addCase(getPolicyList.pending, (state, action) => {
      state.policyLoading = true;
    });
    builder.addCase(getPolicyList.fulfilled, (state, action) => {
      state.policyLoading = false;
      state.policyList = action?.payload?.data;
      state.metaData = action?.payload?.metadata[0];
    });
    builder.addCase(getPolicyList.rejected, (state, action) => {
      state.policyLoading = false;
      state.policyList = [];
    });
    // Update Policy Details
    builder.addCase(getPolicyDetail.pending, (state, action) => {
      state.policyLoading = true;
    });
    builder.addCase(getPolicyDetail.fulfilled, (state, action) => {
      state.policyLoading = false;
      state.policyDetail = action.payload.data;
    });
    builder.addCase(getPolicyDetail.rejected, (state, action) => {
      state.policyLoading = false;
    });
  },
});

export const { isPolicyLoading, resetPolicyDetail } = policiesSlice.actions;
export default policiesSlice.reducer;
