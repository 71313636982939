import React from "react";
import InputField from "./ui/InputField";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

const BulkUpload = ({ sheet, fetchApi, handleClick, templateName = "Template" }) => {
  const dispatch = useDispatch();

  const bulkSchema = Yup.object().shape({
    excelFile: Yup.mixed()
      .required("File is required")
      .test("fileFormat", "Invalid file format", (value) => {
        return (
          value &&
          (value.type === "application/vnd.ms-excel" ||
            value.type ===
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
        );
      }),
  });
  const bulkUpload = useFormik({
    initialValues: {
      excelFile: "",
    },
    bulkSchema,
    onSubmit: async (value, { setSubmitting, resetForm }) => {
      const data = {
        excelFile: value.excelFile,
      };
      const response = await dispatch(fetchApi(data));
      if (response?.payload) {
        setSubmitting(false);
        resetForm();
        handleClick();
      }
    },
  });

  const handleChange = (e) => {
    const selectedFile = e.currentTarget.files[0];

    if (selectedFile) {
      const docType = [
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      ];
      if (docType.includes(selectedFile.type)) {
        if (selectedFile.size > 5 * 1024 * 1024) {
          toast.warning(
            "File size exceeds 5MB limit. Please select a smaller file."
          );
          e.target.value = null;
        } else {
          bulkUpload.setFieldValue("excelFile", selectedFile);
          // e.target.value = null;
        }
      } else {
        toast.warning(
          "accepts .xlxs, .xlsm, .xlsb, .xltx, and .xls files only"
        );
        e.target.value = null;
      }
    }
  };
  const downLoadSheet = async () => {
    // const fileURL = template;
    try {
      const response = await fetch(sheet);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = `${templateName}`;
      link.click();
    } catch (error) {
      console.error("Error occurred while downloading the file:", error);
    }
  };
  return (
    <div className="bulk-upload-wrapper">
      <div className="require_data">
        {/* <h3 className="title">Preview:</h3> */}
        {/* <div className="table-wrapper">
          <div className="table_box">
            <table className="mxTable">
              <thead>
                <tr className="mxTb-row">
                  <th className="mxTb-header">
                    <div className="h-content">
                      Name *
                      <span
                        className="tooltip label flex-center"
                        data-title="Name of the Glossary/Toolkit"
                      >
                        <i className="fa-sharp fa-regular fa-circle-info"></i>
                      </span>
                    </div>
                  </th>
                  <th className="mxTb-header">
                    <div className="h-content">
                      Description *
                      <span
                        className="tooltip label flex-center"
                        data-title="Description of Toolkit"
                      >
                        <i className="fa-sharp fa-regular fa-circle-info"></i>
                      </span>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="mxTb-row ">
                  <td>Advertisement</td>
                  <td>
                    Posters, signs, television Commercials, radio spots, and
                    other media that businesses use to promote products or
                    services.
                  </td>
                </tr>
                <tr className="mxTb-row ">
                  <td>Annual return</td>
                  <td>
                    The profit or loss on an investment over a one-year period.
                  </td>
                </tr>
                <tr className="mxTb-row ">
                  <td>Asset</td>
                  <td>
                    An item with economic value, such as stock or real estate.
                  </td>
                </tr>
              </tbody>
            </table>
            <span className="sample_data_view">Sample Data</span>
          </div>
          <div className="TB_Nav flex-center gap-2">
            <i className="fa-regular fa-chevron-left"></i>
            <i className="fa-regular fa-chevron-right"></i>
            <i className="fa-regular fa-bars-sort"></i>
            <span
              style={{
                border: ".013rem solid var(--color-tertiary)",
                borderRadius: "50%",
              }}
            >
              # -Glossary
            </span>
            <span>A -Glossary</span>
            <span>B -Glossary</span>
            <span>C -Glossary</span>
          </div>
        </div> */}

        <div className="text-center mt-1">
          <button className="btn-outlined" onClick={downLoadSheet}>
            Download Excel Template
          </button>
        </div>

        <ul className="instructions-wrapper">
          <h3>Instructions:</h3>
          <li className="instruction">
            Upload the file seen in the preview here to your List sheet.
          </li>
          <li className="instruction">
            The file you are uploading should look like this.
          </li>
        </ul>

        <div>
          <p>
            <b>Note: </b> If there are any empty cells, fill them with the value
            null
          </p>
          <div className="null-box">
            <span></span>
            <span>Null</span>
            <p>Should be like this.</p>
          </div>
        </div>

        <div className="upload-file-wrapper">
          <h3>Upload File Here*</h3>
          <form className="form" onSubmit={bulkUpload.handleSubmit}>
            <div className="form-control">
              <label className="form-input-box">
                <InputField
                  type="file"
                  id="excelFile"
                  name="excelFile"
                  onChange={handleChange}
                  accept=".xls,.xlsx,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  required
                />
                <button
                  className="btn-primary"
                  type="submit"
                  style={{
                    position: "absolute",
                    top: "16%",
                    right: "2%",
                  }}
                >
                  Submit
                </button>
              </label>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default BulkUpload;
