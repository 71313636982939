import React from "react";

const CardSkelaton = () => {
  return (
    <div className="card-skalaton">
      <div className="skeleton-left flex-1">
        <div className="square circle"></div>
      </div>
      <div className="skeleton-right flex-2">
        <div className="line h17 m10"></div>
        <div className="flex-center gap-2">
          <div className="flex-1">
            <div className="line"></div>
            <div className="line h8 w50"></div>
          </div>
          <div className="flex-1">
            <div className="line  w75"></div>
            <div className="line h8  w50"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardSkelaton;
