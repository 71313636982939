/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import ModuleLayout from "layouts/ModuleLayout";
import ConformationPopup from "components/ui/ConformationPopup";
import NotFound from "components/ui/NotFound";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { deleteAddress, getAddressList } from "../redux/addressApi";
import { useNavigate } from "react-router-dom";

const Address = ({ setTitle }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    setTitle("Address");
  }, [setTitle]);

  const [active, setActive] = useState("address");
  const [mobileSidebar, setMobileSidebar] = useState(false);
  const [minimizedSidebar, setMinimizedSidebar] = useState(false);
  const { addressList } = useSelector((state) => state.address, shallowEqual);
  
  
  const [popUpData, setPopupData] = useState();
  const [alertBox, setAlertBox] = useState(false);

  useEffect(() => {
    dispatch(getAddressList());
  }, []);

  const handleDelete = async (id) => {
    const data = {
      is_delete: true,
      address_id: [id],
    };
    setPopupData({ title: "this address", data });
    setAlertBox(true);
    // const res = await dispatch(deleteAddress(data));
  };

  return (
    <ModuleLayout
      minimizedSidebar={minimizedSidebar}
      setMinimizedSidebar={setMinimizedSidebar}
      mobileSidebar={mobileSidebar}
      setMobileSidebar={setMobileSidebar}
      active={active}
      setActive={setActive}
    >
      <div className="profile-wrapper" style={{ padding: "1.6rem" }}>
        <div className="header-wrapper">
          <div className="sub-header flex-between">
            <h2>Addresses</h2>
            <button
              className="btn-primary flex-center gap-1"
              onClick={() => navigate("/address/add-new")}
            >
              <i className="fa-solid fa-feather"></i> Add Address
            </button>
          </div>
        </div>
      </div>
      {addressList?.length > 0 ? (
        <div className="address-list form-col-3">
          {addressList?.map((ele) => (
            <div className="address-card" key={ele.id}>
              <div className="flex-between gap-2" style={{ height: "100%" }}>
                <div
                  className="addr-detail form-control"
                  style={{ gap: "1.2rem" }}
                >
                  <div>
                    <h3>{ele?.name}</h3>
                    <p>{`${ele?.address?.line_one},`}</p>
                    <p>{`${ele?.address?.line_two},`}</p>
                    <p>{`${ele?.address?.city} - ${ele?.address?.pincode},`}</p>
                    <p>{`${ele?.address?.state}, ${ele?.address?.country}.`}</p>
                  </div>
                  <div className="flex gap-1">
                    <i className="fa-light fa-phone"></i>
                    <p>{ele?.phone?.value}</p>
                  </div>
                </div>
                <div className="addr-action flex flex-col gap-1">
                  <button
                    className="addr-act addr-edit"
                    onClick={() =>
                      navigate(`/address/${ele.id}`, {
                        state: { slug: ele.id, title: ele.slug },
                      })
                    }
                  >
                    <i className="fa-regular fa-pen-to-square"></i>
                  </button>
                  <button
                    className="addr-act addr-del"
                    onClick={() => handleDelete(ele?.id)}
                  >
                    <i className="fa-regular fa-trash"></i>
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <NotFound />
      )}
      {alertBox && (
        <ConformationPopup
          customState={alertBox}
          customSetter={setAlertBox}
          api={deleteAddress}
          popUpData={popUpData}
        />
      )}
    </ModuleLayout>
  );
};

export default Address;
