/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { notifications } from "helper/placeholder-data";
import { logout } from "modules/auth/redux/authApi";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { getCountryList, getProfileDetails } from "modules/settings/profile/redux/profileApi";
import { female, male } from "assets/icons";
import SubmitButton from "./ui/SubmitButton";
import Breadcrumb from "./BreadCrumbs";

const Header = ({
  setMobileSidebar,
  setMinimizedSidebar,
  minimizedSidebar,
}) => {
  const navigate = useNavigate();
  const [notificationTooltip, setNotificationTooltip] = useState(false);
  const [showprofileTooltip, setShowprofileTooltip] = useState(false);
  const { countryList } = useSelector((state) => state.profile, shallowEqual)

  const toggleProfileTooltip = () => {
    setShowprofileTooltip(!showprofileTooltip);
  };
  const toggleNotificationTooltip = () => {
    setNotificationTooltip(!notificationTooltip);
  };

  const profileref = useRef();
  const notifyref = useRef();

  useEffect(() => {
    const handleGlobalClick = (event) => {
      if (profileref.current && !profileref.current.contains(event.target)) {
        setShowprofileTooltip(false);
      }
      if (notifyref.current && !notifyref.current.contains(event.target)) {
        setNotificationTooltip(false);
      }
    };
    document.addEventListener("click", handleGlobalClick);
    return () => {
      document.removeEventListener("click", handleGlobalClick);
    };
  }, []);

  const dispatch = useDispatch();
  const { authLoading, role, profileDetail } = useSelector(
    (state) => ({
      authLoading: state.auth.authLoading,
      role: state.auth.role,
      profileDetail: state.profile.profileDetail,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (Object.keys(profileDetail).length === 0) {
      dispatch(getProfileDetails());
    }
  }, [dispatch, profileDetail]);

  const profilePic = useMemo(() => {
    if (profileDetail?.photo?.localUrl) {
      return profileDetail.photo?.localUrl;
    } else {
      return profileDetail?.gender === "male" ? male : female;
    }
  }, [profileDetail]);

  const handleLogout = async () => {
    const response = await dispatch(logout());
    if (response?.payload) {
      navigate("/login");
    }
  };

  useEffect(() => {
    if (countryList?.length < 1) {
      dispatch(getCountryList());
    }
  }, [countryList]);

  return (
    <header className="content-header">
      <nav className="navbar">
        <ul className="nav-items">
          <li className="nav-item desktop-icon">
            <button
              className="icon-btn"
              onClick={() => setMinimizedSidebar(!minimizedSidebar)}
            >
              <i className="fa-regular fa-bars-sort"></i>
            </button>
          </li>
          <li className="tablet-icon">
            <button className="icon-btn" onClick={() => setMobileSidebar(true)}>
              <i className="fa-regular fa-bars-sort"></i>
            </button>
          </li>

          <Breadcrumb />
        </ul>

        <ul className="nav-items">
          <li
            className="nav-item"
            ref={notifyref}
            onClick={toggleNotificationTooltip}
          >
            <button className="icon-btn">
              <i className="fa-light fa-bell"></i>
            </button>
          </li>
          {notificationTooltip && (
            <div className="tooltipN-container">
              <h3 className="toolpip-title">Notifications</h3>

              <div className="notification-box">
                {notifications.map((item, i) => (
                  <div key={i} className="notifiy-details">
                    <img src={item?.img} alt="profile-logo" />
                    <div className="user_info">
                      <h5 className="name">{item.title}</h5>
                      <p className="role">{item.message}</p>
                    </div>
                  </div>
                ))}
              </div>

              <button
                className="btn-outlined"
                onClick={() => navigate("/notifications")}
              >
                See all Notifications
              </button>
            </div>
          )}
          <li
            className="nav-item"
            ref={profileref}
            onClick={toggleProfileTooltip}
          >
            <button className="icon-btn pf-logo">
              <img
                src={profilePic}
                alt="profile-logo"
                className="profile-img"
                style={{
                  width: "2.2rem",
                  height: "2.2rem",
                  objectFit: "cover",
                  borderRadius: "50%",
                }}
                crossOrigin="anonymous"
              />
            </button>
          </li>

          {showprofileTooltip && (
            <div className="tooltipP-container">
              <h4 className="toolpip-title">My Profile</h4>
              <div className="profile-details">
                <img
                  src={profilePic}
                  alt=""
                  className="profile-logo"
                  crossOrigin="anonymous"
                />
                <div className="user_info">
                  <h2 className="name">{`${profileDetail?.name?.firstName} ${profileDetail?.name?.lastName}`}</h2>
                  <h5 className="role">
                    {role === 1 ? "Super Admin" : role === 2 ? "Admin" : "User"}
                  </h5>
                  <div className="mail-id">
                    <i className="fa-light fa-envelope"></i>
                    <a
                      href={`mailto:${profileDetail?.email?.value}`}
                      className="md"
                    >
                      {profileDetail?.email?.value}
                    </a>
                  </div>
                </div>
              </div>
              <hr />
              <div className="stack-details">
                <div className="stack-div">
                  <div className="stack-logo">
                    <i className="fa-regular fa-user"></i>
                  </div>
                  <div
                    className="stack-info"
                    onClick={() => navigate("/profile")}
                  >
                    <p className="stack-content">My Profile</p>
                    <p className="stack-content2">Account Settings</p>
                  </div>
                </div>
              </div>

              <SubmitButton
                className="toolpip-logout"
                onClick={() => handleLogout()}
                isLoading={authLoading}
              >
                <i className="fa-light fa-arrow-right-from-bracket large"></i>
                <span>Logout</span>
              </SubmitButton>
            </div>
          )}
        </ul>
      </nav>
    </header>
  );
};

export default Header;
