import React, { useState } from 'react'

export default function AddProducts({ Tbdata, setTbData, addPopup, setAddPopup }) {

    const TableData = [
        {
            id: 1,
            sku: "KSG92791",
            orderId: "2645212",
            outStandingqty: "1",
            available: "4",
            addressMatch: true,
        },
        {
            id: 2,
            sku: "KSG92792",
            orderId: "2645212",
            outStandingqty: "1",
            available: "4",
            addressMatch: true,
        },
        {
            id: 3,
            sku: "KSG92793",
            orderId: "2645212",
            outStandingqty: "1",
            available: "4",
            addressMatch: false,
        },
        {
            id: 4,
            sku: "KSG92794",
            orderId: "2645212",
            outStandingqty: "1",
            available: "4",
            addressMatch: true,
        },
        {
            id: 5,
            sku: "KSG92795",
            orderId: "2645212",
            outStandingqty: "1",
            available: "4",
            addressMatch: false,
        },
    ];

    const [addStyle, setAddStyle] = useState(true);
    const [selectedProduct, setSelectedProduct] = useState([])

    const handleClick = () => {
        setAddPopup(false);
        setAddStyle(!addPopup);
    };

    const handlecheckAll = () => {
        if (JSON.stringify(TableData.map((data) => data.sku)) === JSON.stringify(selectedProduct)) {
            setSelectedProduct([])
        } else {
            setSelectedProduct(TableData.map((data) => data.sku))
        }
    };

    const handleCheckboxChange = (item) => {
        if (selectedProduct.includes(item.sku)) {
            setSelectedProduct(selectedProduct.filter((sku) => sku !== item.sku));
        } else {
            setSelectedProduct([...selectedProduct, item.sku]);
        }
    };

    const handleSubmit = () => {
        setTbData(selectedProduct)
        setAddPopup(false);
        setAddStyle(!addPopup);
    }

    return (
        <div id="confirm-box-wrapper">
            <div
                className={
                    "popup-wrapper ship-pop" +
                    (addStyle
                        ? " show_delete-component-fadeIn-animation"
                        : " hide_delete-component-fadeOut-animation")
                }
            >
                <div className="popup-content que-popup">
                    <div className="popup-header">
                        <h2>
                            Add Shipment Items
                        </h2>
                        <button className="close flex-center" onClick={handleClick}>
                            <i className="fa-sharp fa-regular fa-xmark-large"></i>
                        </button>
                    </div>
                    <div className="gm-TableMain">
                        <table className="total-table">
                            <thead>
                                <tr>
                                    <th className="tt-header">
                                        <label
                                            htmlFor={`checkbox`}
                                            className="TD_CheckBox_LB"
                                        >
                                            <input
                                                name={"checkbox"}
                                                id={`checkbox`}
                                                type="checkbox"
                                                className="checkbox-animated"
                                                onChange={handlecheckAll}
                                                checked={JSON.stringify(TableData.map((data) => data.sku).sort()) === JSON.stringify(selectedProduct.sort())}
                                            />
                                            <div className="checkmark"></div>
                                        </label>
                                    </th>
                                    <th className="tt-header">SKU</th>
                                    <th className="tt-header">Order Id</th>

                                    <th className="tt-header">Outstanding Quantity</th>
                                    <th className="tt-header">Available</th>
                                    <th className="tt-header">Address Matches Shipment</th>
                                </tr>
                            </thead>
                            <tbody>
                                {TableData?.map((table, index) => (
                                    <tr key={index}>
                                        <td className="tt-data shipment">
                                            <label
                                                htmlFor={`checkbox${table.sku}`}
                                                className="TD_CheckBox_LB"
                                            >
                                                <input
                                                    name={"checkbox"}
                                                    id={`checkbox${table.sku}`}
                                                    type="checkbox"
                                                    className="checkbox-animated"
                                                    checked={selectedProduct.includes(table.sku)}
                                                    onChange={() => handleCheckboxChange(table)}
                                                />
                                                <div className="checkmark"></div>
                                            </label>
                                        </td>
                                        <td className="tt-data shipment">{table.sku}</td>
                                        <td className="tt-data shipment">{table.orderId}</td>
                                        <td className="tt-data shipment">{table.outStandingqty}</td>
                                        <td className="tt-data shipment">{table.available}</td>
                                        <td className="tt-data shipment">
                                            <span
                                                className={`material-symbols-outlined address${table.addressMatch}`}
                                            >
                                                {table.addressMatch === true ? "done" : "close"}
                                            </span>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        <div className="flex-center gap-2 mt-1">
                            <button
                                className="btn-outlined"
                                onClick={() => setAddPopup(false)}
                            >
                                Cancel
                            </button>
                            <button className="btn-primary" onClick={handleSubmit}>Submit</button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}
