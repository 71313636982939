/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import SubmitButton from "components/ui/SubmitButton";
import BulkUpload from "components/BulkUpload";
import { skillsSheet } from "assets/files";
import { useFormik } from "formik";
import * as Yup from "yup";
import { addFaq, getFaqDetail, updateFaqDetail } from "../redux/faqApi";
import DropDownBox from "components/DropDownBox";
import { getCategoryList } from "modules/catalogue/categories/redux/categoryApi";

const FaManager = ({ popup, setPopup }) => {
    const dispatch = useDispatch();
    const { categoryList } = useSelector((state) => state.CatCategory, shallowEqual);
    const { faqDetail, faqLoading } = useSelector((state) => state.faq, shallowEqual);
    
    const [addStyle, setAddStyle] = useState(true);
    const [activeInputs, setActiveInputs] = useState(true);
    const [catList, setCatList] = useState([]);

    const formik = useFormik({
        initialValues: {
            category: "",
            question: "",
            answer: "",
        },
        validationSchema: Yup.object({
            question: Yup.string()
                .trim()
                .min(3, "Question must be at least 3 characters long")
                .max(80, "Question must be less then 80 characters")
                .required("Question should not be empty"),
            answer: Yup.string()
                .trim()
                .min(3, "Answer must be at least 3 characters long")
                .max(80, "Answer must be less then 500 characters")
                .required("Answer should not be empty"),
            category: Yup.string().required("Category is required")
        }),
        onSubmit: async (values) => {

            const data = {
                send: {
                    category_id: values.category,
                    question: values.question,
                    answer: values.answer,
                },
                id: popup.id,
            };
            if (popup.type === "add-faq") {
                const response = await dispatch(addFaq(data?.send));
                if (response?.payload) {
                    setPopup({ show: false, type: "", id: "" });
                }
            }

            if (popup.type === "update-faq") {
                const response = await dispatch(updateFaqDetail(data));
                if (response?.payload) {
                    setPopup({ show: false, type: "", id: "" });
                }
            }
        },
    });

    useEffect(() => {
        document
            .getElementsByClassName("container")[0]
            .classList.add("popUp-container");
    }, []);

    const handleClick = () => {
        if (activeInputs === false) {
            setActiveInputs(true);
        } else {
            setAddStyle(!addStyle);
            chatBoxVisibility();
        }
    };

    function chatBoxVisibility() {
        if (popup.show) {
            const styleTimer = setTimeout(() => {
                setPopup({ ...popup, show: !popup.show });
                document
                    .getElementsByClassName("container")[0]
                    .classList.remove("popUp-container");
                clearTimeout(styleTimer);
            }, 200);
        }
    }

    useEffect(() => {
        if (categoryList?.length > 0) {
            const list = categoryList.map((category) => {
                const label = category?.name;
                const value = category?.id;
                return { label, value };
            });
            setCatList(list);
        }
    }, [categoryList]);

    useEffect(() => {
        dispatch(
            getCategoryList({
                page: "0",
                limit: "0",
                search: "",
                sort: "desc",
                filter: "faq",
            })
        );
    }, [dispatch, popup]);

    useEffect(() => {
        if (popup.type === "update-faq") {
            dispatch(getFaqDetail({ id: popup.id }));
        }
    }, []);

    useEffect(() => {
        if (popup.type === "update-faq" && faqDetail && !faqLoading) {
            formik.setValues({
                question: faqDetail?.question,
                answer: faqDetail?.answer,
                category: faqDetail?.categoryId
            });
        }
        return () => {
            formik.resetForm();
        };
    }, [faqDetail, popup.type, faqLoading]);

    return (
        <div id="confirm-box-wrapper">
            <div
                className={
                    "popup-wrapper" +
                    (addStyle
                        ? " show_delete-component-fadeIn-animation"
                        : " hide_delete-component-fadeOut-animation")
                }
            >
                <div
                    className={`popup-content ${popup.type === "add-multiple-faq" ? "w-32" : ""}`}
                >
                    <div className="popup-header">
                        <h2>{popup?.type?.replaceAll("-", " ")}</h2>

                        <button className="close flex-center" onClick={() => handleClick()}>
                            <i className="fa-sharp fa-regular fa-xmark-large"></i>
                        </button>
                    </div>

                    {popup.type === "add-faq" || popup.type === "update-faq" ? (
                        <div className="pc-wrapper">
                            <div className="auth-form-wrapper">
                                <form
                                    className="form"
                                    onSubmit={formik.handleSubmit}
                                // noValidate="novalidate"
                                >
                                    <div className="form-control">
                                        <label className="form-label">Select Category</label>
                                        <div className="form-input-box">
                                            <DropDownBox
                                                options={catList}
                                                size="large"
                                                defaultValue="Select Category"
                                                customSetter={formik.setFieldValue}
                                                customFormikLabel="category"
                                                customDropBoxStyles={{
                                                    border: "0.063rem solid #d2d6da",
                                                    borderRadius: "0.5rem",
                                                    color: "#262626",
                                                    fontSize: "0.875rem",
                                                }}
                                                customTextStyle={{
                                                    color: "#454545",
                                                    fontSize: "small",
                                                }}
                                                incomingValue={formik.values.category}
                                                disabled={
                                                    popup.type === "update-faq"
                                                        ? !activeInputs
                                                        : true
                                                }
                                            />
                                        </div>
                                        {formik.errors.category && formik.touched.category ? (
                                            <span className="err">{formik.errors.category}</span>
                                        ) : null}
                                    </div>

                                    <div className="form-control">
                                        <label className="form-label">Question</label>
                                        <div className="form-input-box">
                                            <input
                                                id="question"
                                                name="question"
                                                type="text"
                                                placeholder="Please enter question"
                                                value={formik.values.question || ""}
                                                className="form-input"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                onInput={(e) =>
                                                (e.target.value = e.target.value
                                                    .replace(/(?:^|\s)\S/g, (match) =>
                                                        match.toUpperCase()
                                                    ))
                                                }
                                                disabled={
                                                    popup.type === "update-faq" ? activeInputs : false
                                                }
                                                maxLength={80}
                                            />
                                        </div>
                                        {formik.errors.question && formik.touched.question && (
                                            <span className="err">{formik.errors.question}</span>
                                        )}
                                    </div>

                                    <div className="form-control">
                                        <label className="form-label">Answer</label>
                                        <div className="form-input-box">
                                            <textarea
                                                type="text"
                                                id="answer"
                                                name="answer"
                                                value={formik.values.answer}
                                                className="form-input"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                rows={4}
                                                cols={5}
                                                disabled={popup.type === "update-faq" ? activeInputs : false}
                                            />
                                        </div>
                                        {formik.errors.answer &&
                                            formik.touched.answer && (
                                                <span className="err">{formik.errors.answer}</span>
                                            )}
                                    </div>

                                    <div className="flex-center">
                                        {popup.type === "update-faq" && activeInputs === true ? (
                                            <button
                                                className="btn-outlined flex-center"
                                                onClick={() => setActiveInputs(!activeInputs)}
                                            >
                                                Edit Faq
                                            </button>
                                        ) : (
                                            <SubmitButton
                                                size="fit-content"
                                                className="btn-primary"
                                                type="submit"
                                                isLoading={formik.isSubmitting}
                                            >
                                                Submit
                                            </SubmitButton>
                                        )}
                                    </div>
                                </form>
                            </div>
                        </div>
                    ) : null}
                    {popup.type === "add-multiple-faq" && (
                        <BulkUpload
                            sheet={skillsSheet}
                            //   fetchApi={addBulkBrandAsync}
                            handleClick={handleClick}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default FaManager;
