import React, { useEffect, useState } from "react";
import CustomTable from "components/CustomTable";
import ConformationPopup from "components/ui/ConformationPopup";
import SearchField from "components/ui/SearchField";
import useFetchList from "hooks/useFetchList";
import useDisableSkelaton from "hooks/useDisableSkelaton";
import ModuleLayout from "layouts/ModuleLayout";
import { getBreadcrumbName, unixTimeToReadableFormat } from "helper/helper";
import { deletePolicy, getPolicyList } from "../redux/policiesApi";
import { useSelector, shallowEqual } from "react-redux";
import { useNavigate } from "react-router-dom";
import DropDownBox from "components/DropDownBox";
import { SortItems } from "helper/placeholder-data";
import useSelectRows from "hooks/useSelectRows";

const Policies = ({ setTitle }) => {
  const navigate = useNavigate();
  useEffect(() => {
    setTitle("Content Management | Policy Pages");
  }, [setTitle]);

  const { policyList, metaData, policyLoading } = useSelector(
    (state) => state.cmsPolicy,
    shallowEqual
  );

  const [active, setActive] = useState("cmsPages");
  const [mobileSidebar, setMobileSidebar] = useState(false);
  const [minimizedSidebar, setMinimizedSidebar] = useState(false);

  const {
    content,
    alertBox,
    setAlertBox,
    selectAllRows,
    selectedRows,
    setSelectedRows,
    toggleSelectAll,
    toggleRowSelection,
    deleteFunction,
  } = useSelectRows(policyList, "page_id");

  // Used useFetchList hook to fetch list dynamically, with search, & limit & sort
  const {
    privileges,
    limit,
    setLimit,
    sort,
    setSort,
    search,
    setSearch,
    btnNo,
    setBtnNo,
    tableIndex,
    setTableIndex,
  } = useFetchList(getPolicyList, metaData, selectAllRows, setSelectedRows);

  // used this hook to disable skelaton loading if api request is less than 300ms
  const { contentLoading } = useDisableSkelaton(policyLoading);

  // Made an array of list items that will given as prop to custom table with tabledata object.
  const listContent = policyList.map((item, i) => {
    const { id, num, slug, title, createdAt } = item;
    const crDt = unixTimeToReadableFormat(createdAt);
    const similarItems = [
      { style: "name", val: title },
      { style: "email", val: slug },
      { style: "", val: crDt },
    ];
    return [id, num, title, slug, similarItems];
  });

  const TABLEDATA = {
    loadingState: contentLoading,
    skalatonDetails: { col: 6, rows: limit },
    list: policyList,
    listHeading: ["Title", "Slug", "Created At"],
    listContent,
    selectedRows,
    selectAllRows,
    setSelectedRows,
    toggleSelectAll,
    toggleRowSelection,
    deleteFunction,
    sliceName: "cmsPolicy",
    search,
    PagAPI: getPolicyList,
    sort,
    limit,
    setLimit,
    btnNo,
    setBtnNo,
    tableIndex,
    setTableIndex,
    privileges,
    metaData,
    hasAccess: "pageManagement",
    navigateTo: update,
  };

  function update(id) {
    const template = policyList.find((template) => template.id === id);
    const breadcrumbName = getBreadcrumbName(template);
    navigate(`/content/pages/${id}`, {
      state: { slug: id, title: breadcrumbName },
    });
  }

  return (
    <ModuleLayout
      minimizedSidebar={minimizedSidebar}
      setMinimizedSidebar={setMinimizedSidebar}
      mobileSidebar={mobileSidebar}
      setMobileSidebar={setMobileSidebar}
      active={active}
      setActive={setActive}
      content={content}
      alertBox={alertBox}
    >
      <header className="list-header">
        <div className="list-title">
          <h2>Content Management | Policies, T&C</h2>
          <div className="flex-center gap-1">
            <button
              className="btn-primary flex-center gap-1"
              onClick={() => navigate("/content/pages/add-new")}
            >
              <i className="fa-solid fa-feather"></i> Add Page
            </button>

            {Object?.values(privileges["pageManagement"])?.includes("delete") &&
            selectedRows.length !== 0 ? (
              <button
                className="btn-secondary flex-center gap-1"
                onClick={() => {
                  deleteFunction("all policies");
                }}
              >
                <i className="fa-light fa-trash"></i>
                {`Delete ${selectedRows.length}`}
              </button>
            ) : null}
          </div>
        </div>
      </header>

      <div className="list-container custom-box">
        <div className="tb_controls">
          <DropDownBox
            options={SortItems}
            size="small"
            dropDownTitle="Sort"
            animateDropDownTitle
            customSetter={setSort}
            customDropBoxStyles={{
              opacity: 0.6,
              color: "#262626",
              padding: "0.6rem",
              border: "1px solid var(--color-neutral-92)",
            }}
          />
          <SearchField value={search} setValue={setSearch} />
        </div>
        <CustomTable {...TABLEDATA} />
      </div>

      {alertBox && (
        <ConformationPopup
          customState={alertBox}
          customSetter={setAlertBox}
          mainTitle=""
          subTitle=""
          api={deletePolicy}
          popUpData={content}
          customFunction={() => setSelectedRows(new Set())}
          toastMessage={"Page removed successfully"}
        />
      )}
    </ModuleLayout>
  );
};

export default Policies;
