import { logoutAllDevices } from "modules/auth/redux/authApi";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const LogoutEverywhere = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const handleLogoutEverywhere = async () => {
        const res = await dispatch(logoutAllDevices())
        if(res?.payload?.status){
          navigate("/")
        }
      }
    return (
        <div className="profile-details">
            <div className="header-wrapper">
                <h2 className="title">Logout from all devices</h2>
                <p style={{ fontSize: ".9rem" }}>This will logout your account from every devices.</p>
                <button type="button" className="btn-secondary mt-1" onClick={handleLogoutEverywhere}>Logout Everywhere</button>
            </div>
       
        </div>
    );
};

export default LogoutEverywhere;
