import { useParams } from "react-router-dom";

const ProgressPoints = () => {
  const { type } = useParams();

  const arr = [
    {
      id: 1,
      type: "walnuts",
      correct: 10,
      incorrect: 5,
      totalPoints: 150,
      data: [
        {
          id: 1,
          ques: "Lorem ipsum dolor sit amet consectetur. Fermentum vitae dui lorem auctor diam gravida volutpat. Dictum nulla nulla suscipit aliquam orci. Urna felis adipiscing curabitur phasellus non dictumst nunc aliquam.",
          ans: [
            { title: "loream ipsum", isChecked: false },
            { title: "loream ipsum", isChecked: true },
            { title: "loream ipsum", isChecked: false },
            { title: "loream ipsum", isChecked: false },
          ],
          points: 15,
        },
        {
          id: 2,
          ques: "Lorem ipsum dolor sit amet consectetur. Fermentum vitae dui lorem auctor diam gravida volutpat. Dictum nulla nulla suscipit aliquam orci. Urna felis adipiscing curabitur phasellus non dictumst nunc aliquam.",
          ans: [
            { title: "loream ipsum", isChecked: false },
            { title: "loream ipsum", isChecked: false },
            { title: "loream ipsum", isChecked: true },
            { title: "loream ipsum", isChecked: false },
          ],
          points: 15,
        },
        {
          id: 3,
          ques: "Lorem ipsum dolor sit amet consectetur. Fermentum vitae dui lorem auctor diam gravida volutpat. Dictum nulla nulla suscipit aliquam orci. Urna felis adipiscing curabitur phasellus non dictumst nunc aliquam.",
          ans: [
            { title: "loream ipsum", isChecked: false },
            { title: "loream ipsum", isChecked: false },
            { title: "loream ipsum", isChecked: false },
            { title: "loream ipsum", isChecked: true },
          ],
          points: 15,
        },
        {
          id: 4,
          ques: "Lorem ipsum dolor sit amet consectetur. Fermentum vitae dui lorem auctor diam gravida volutpat. Dictum nulla nulla suscipit aliquam orci. Urna felis adipiscing curabitur phasellus non dictumst nunc aliquam.",
          ans: [
            { title: "loream ipsum", isChecked: false },
            { title: "loream ipsum", isChecked: true },
            { title: "loream ipsum", isChecked: false },
            { title: "loream ipsum", isChecked: false },
          ],
          points: 15,
        },
        {
          id: 5,
          ques: "Lorem ipsum dolor sit amet consectetur. Fermentum vitae dui lorem auctor diam gravida volutpat. Dictum nulla nulla suscipit aliquam orci. Urna felis adipiscing curabitur phasellus non dictumst nunc aliquam.",
          ans: [
            { title: "loream ipsum", isChecked: false },
            { title: "loream ipsum", isChecked: false },
            { title: "loream ipsum", isChecked: true },
            { title: "loream ipsum", isChecked: false },
          ],
          points: 15,
        },
        {
          id: 6,
          ques: "Lorem ipsum dolor sit amet consectetur. Fermentum vitae dui lorem auctor diam gravida volutpat. Dictum nulla nulla suscipit aliquam orci. Urna felis adipiscing curabitur phasellus non dictumst nunc aliquam.",
          ans: [
            { title: "loream ipsum", isChecked: false },
            { title: "loream ipsum", isChecked: false },
            { title: "loream ipsum", isChecked: false },
            { title: "loream ipsum", isChecked: true },
          ],
          points: 15,
        },
        {
          id: 7,
          ques: "Lorem ipsum dolor sit amet consectetur. Fermentum vitae dui lorem auctor diam gravida volutpat. Dictum nulla nulla suscipit aliquam orci. Urna felis adipiscing curabitur phasellus non dictumst nunc aliquam.",
          ans: [
            { title: "loream ipsum", isChecked: false },
            { title: "loream ipsum", isChecked: true },
            { title: "loream ipsum", isChecked: false },
            { title: "loream ipsum", isChecked: false },
          ],
          points: 15,
        },
        {
          id: 8,
          ques: "Lorem ipsum dolor sit amet consectetur. Fermentum vitae dui lorem auctor diam gravida volutpat. Dictum nulla nulla suscipit aliquam orci. Urna felis adipiscing curabitur phasellus non dictumst nunc aliquam.",
          ans: [
            { title: "loream ipsum", isChecked: false },
            { title: "loream ipsum", isChecked: false },
            { title: "loream ipsum", isChecked: true },
            { title: "loream ipsum", isChecked: false },
          ],
          points: 15,
        },
        {
          id: 9,
          ques: "Lorem ipsum dolor sit amet consectetur. Fermentum vitae dui lorem auctor diam gravida volutpat. Dictum nulla nulla suscipit aliquam orci. Urna felis adipiscing curabitur phasellus non dictumst nunc aliquam.",
          ans: [
            { title: "loream ipsum", isChecked: false },
            { title: "loream ipsum", isChecked: false },
            { title: "loream ipsum", isChecked: false },
            { title: "loream ipsum", isChecked: true },
          ],
          points: 15,
        },
        {
          id: 10,
          ques: "Lorem ipsum dolor sit amet consectetur. Fermentum vitae dui lorem auctor diam gravida volutpat. Dictum nulla nulla suscipit aliquam orci. Urna felis adipiscing curabitur phasellus non dictumst nunc aliquam.",
          ans: [
            { title: "loream ipsum", isChecked: false },
            { title: "loream ipsum", isChecked: true },
            { title: "loream ipsum", isChecked: false },
            { title: "loream ipsum", isChecked: false },
          ],
          points: 15,
        },
        {
          id: 11,
          ques: "Lorem ipsum dolor sit amet consectetur. Fermentum vitae dui lorem auctor diam gravida volutpat. Dictum nulla nulla suscipit aliquam orci. Urna felis adipiscing curabitur phasellus non dictumst nunc aliquam.",
          ans: [
            { title: "loream ipsum", isChecked: false },
            { title: "loream ipsum", isChecked: false },
            { title: "loream ipsum", isChecked: true },
            { title: "loream ipsum", isChecked: false },
          ],
          points: 15,
        },
        {
          id: 12,
          ques: "Lorem ipsum dolor sit amet consectetur. Fermentum vitae dui lorem auctor diam gravida volutpat. Dictum nulla nulla suscipit aliquam orci. Urna felis adipiscing curabitur phasellus non dictumst nunc aliquam.",
          ans: [
            { title: "loream ipsum", isChecked: false },
            { title: "loream ipsum", isChecked: false },
            { title: "loream ipsum", isChecked: false },
            { title: "loream ipsum", isChecked: true },
          ],
          points: 15,
        },
        {
          id: 13,
          ques: "Lorem ipsum dolor sit amet consectetur. Fermentum vitae dui lorem auctor diam gravida volutpat. Dictum nulla nulla suscipit aliquam orci. Urna felis adipiscing curabitur phasellus non dictumst nunc aliquam.",
          ans: [
            { title: "loream ipsum", isChecked: false },
            { title: "loream ipsum", isChecked: true },
            { title: "loream ipsum", isChecked: false },
            { title: "loream ipsum", isChecked: false },
          ],
          points: 15,
        },
        {
          id: 14,
          ques: "Lorem ipsum dolor sit amet consectetur. Fermentum vitae dui lorem auctor diam gravida volutpat. Dictum nulla nulla suscipit aliquam orci. Urna felis adipiscing curabitur phasellus non dictumst nunc aliquam.",
          ans: [
            { title: "loream ipsum", isChecked: false },
            { title: "loream ipsum", isChecked: false },
            { title: "loream ipsum", isChecked: true },
            { title: "loream ipsum", isChecked: false },
          ],
          points: 15,
        },
        {
          id: 15,
          ques: "Lorem ipsum dolor sit amet consectetur. Fermentum vitae dui lorem auctor diam gravida volutpat. Dictum nulla nulla suscipit aliquam orci. Urna felis adipiscing curabitur phasellus non dictumst nunc aliquam.",
          ans: [
            { title: "loream ipsum", isChecked: false },
            { title: "loream ipsum", isChecked: false },
            { title: "loream ipsum", isChecked: false },
            { title: "loream ipsum", isChecked: true },
          ],
          points: 15,
        },
      ],
    },
    {
      id: 1,
      type: "xp",
      correct: 10,
      incorrect: 5,
      totalPoints: 150,
      data: [
        {
          id: 1,
          ques: "Lorem ipsum dolor sit amet consectetur. Fermentum vitae dui lorem auctor diam gravida volutpat. Dictum nulla nulla suscipit aliquam orci. Urna felis adipiscing curabitur phasellus non dictumst nunc aliquam.",
          ans: [
            { title: "loream ipsum", isChecked: false },
            { title: "loream ipsum", isChecked: true },
            { title: "loream ipsum", isChecked: false },
            { title: "loream ipsum", isChecked: false },
          ],
          points: 100,
        },
        {
          id: 2,
          ques: "Lorem ipsum dolor sit amet consectetur. Fermentum vitae dui lorem auctor diam gravida volutpat. Dictum nulla nulla suscipit aliquam orci. Urna felis adipiscing curabitur phasellus non dictumst nunc aliquam.",
          ans: [
            { title: "loream ipsum", isChecked: false },
            { title: "loream ipsum", isChecked: false },
            { title: "loream ipsum", isChecked: true },
            { title: "loream ipsum", isChecked: false },
          ],
          points: 100,
        },
        {
          id: 3,
          ques: "Lorem ipsum dolor sit amet consectetur. Fermentum vitae dui lorem auctor diam gravida volutpat. Dictum nulla nulla suscipit aliquam orci. Urna felis adipiscing curabitur phasellus non dictumst nunc aliquam.",
          ans: [
            { title: "loream ipsum", isChecked: false },
            { title: "loream ipsum", isChecked: false },
            { title: "loream ipsum", isChecked: false },
            { title: "loream ipsum", isChecked: true },
          ],
          points: 100,
        },
        {
          id: 4,
          ques: "Lorem ipsum dolor sit amet consectetur. Fermentum vitae dui lorem auctor diam gravida volutpat. Dictum nulla nulla suscipit aliquam orci. Urna felis adipiscing curabitur phasellus non dictumst nunc aliquam.",
          ans: [
            { title: "loream ipsum", isChecked: false },
            { title: "loream ipsum", isChecked: true },
            { title: "loream ipsum", isChecked: false },
            { title: "loream ipsum", isChecked: false },
          ],
          points: 100,
        },
        {
          id: 5,
          ques: "Lorem ipsum dolor sit amet consectetur. Fermentum vitae dui lorem auctor diam gravida volutpat. Dictum nulla nulla suscipit aliquam orci. Urna felis adipiscing curabitur phasellus non dictumst nunc aliquam.",
          ans: [
            { title: "loream ipsum", isChecked: false },
            { title: "loream ipsum", isChecked: false },
            { title: "loream ipsum", isChecked: true },
            { title: "loream ipsum", isChecked: false },
          ],
          points: 100,
        },
        {
          id: 6,
          ques: "Lorem ipsum dolor sit amet consectetur. Fermentum vitae dui lorem auctor diam gravida volutpat. Dictum nulla nulla suscipit aliquam orci. Urna felis adipiscing curabitur phasellus non dictumst nunc aliquam.",
          ans: [
            { title: "loream ipsum", isChecked: false },
            { title: "loream ipsum", isChecked: false },
            { title: "loream ipsum", isChecked: false },
            { title: "loream ipsum", isChecked: true },
          ],
          points: 100,
        },
        {
          id: 7,
          ques: "Lorem ipsum dolor sit amet consectetur. Fermentum vitae dui lorem auctor diam gravida volutpat. Dictum nulla nulla suscipit aliquam orci. Urna felis adipiscing curabitur phasellus non dictumst nunc aliquam.",
          ans: [
            { title: "loream ipsum", isChecked: false },
            { title: "loream ipsum", isChecked: true },
            { title: "loream ipsum", isChecked: false },
            { title: "loream ipsum", isChecked: false },
          ],
          points: 100,
        },
        {
          id: 8,
          ques: "Lorem ipsum dolor sit amet consectetur. Fermentum vitae dui lorem auctor diam gravida volutpat. Dictum nulla nulla suscipit aliquam orci. Urna felis adipiscing curabitur phasellus non dictumst nunc aliquam.",
          ans: [
            { title: "loream ipsum", isChecked: false },
            { title: "loream ipsum", isChecked: false },
            { title: "loream ipsum", isChecked: true },
            { title: "loream ipsum", isChecked: false },
          ],
          points: 100,
        },
        {
          id: 9,
          ques: "Lorem ipsum dolor sit amet consectetur. Fermentum vitae dui lorem auctor diam gravida volutpat. Dictum nulla nulla suscipit aliquam orci. Urna felis adipiscing curabitur phasellus non dictumst nunc aliquam.",
          ans: [
            { title: "loream ipsum", isChecked: false },
            { title: "loream ipsum", isChecked: false },
            { title: "loream ipsum", isChecked: false },
            { title: "loream ipsum", isChecked: true },
          ],
          points: 100,
        },
        {
          id: 10,
          ques: "Lorem ipsum dolor sit amet consectetur. Fermentum vitae dui lorem auctor diam gravida volutpat. Dictum nulla nulla suscipit aliquam orci. Urna felis adipiscing curabitur phasellus non dictumst nunc aliquam.",
          ans: [
            { title: "loream ipsum", isChecked: false },
            { title: "loream ipsum", isChecked: true },
            { title: "loream ipsum", isChecked: false },
            { title: "loream ipsum", isChecked: false },
          ],
          points: 100,
        },
        {
          id: 11,
          ques: "Lorem ipsum dolor sit amet consectetur. Fermentum vitae dui lorem auctor diam gravida volutpat. Dictum nulla nulla suscipit aliquam orci. Urna felis adipiscing curabitur phasellus non dictumst nunc aliquam.",
          ans: [
            { title: "loream ipsum", isChecked: false },
            { title: "loream ipsum", isChecked: false },
            { title: "loream ipsum", isChecked: true },
            { title: "loream ipsum", isChecked: false },
          ],
          points: 100,
        },
        {
          id: 12,
          ques: "Lorem ipsum dolor sit amet consectetur. Fermentum vitae dui lorem auctor diam gravida volutpat. Dictum nulla nulla suscipit aliquam orci. Urna felis adipiscing curabitur phasellus non dictumst nunc aliquam.",
          ans: [
            { title: "loream ipsum", isChecked: false },
            { title: "loream ipsum", isChecked: false },
            { title: "loream ipsum", isChecked: false },
            { title: "loream ipsum", isChecked: true },
          ],
          points: 100,
        },
        {
          id: 13,
          ques: "Lorem ipsum dolor sit amet consectetur. Fermentum vitae dui lorem auctor diam gravida volutpat. Dictum nulla nulla suscipit aliquam orci. Urna felis adipiscing curabitur phasellus non dictumst nunc aliquam.",
          ans: [
            { title: "loream ipsum", isChecked: false },
            { title: "loream ipsum", isChecked: true },
            { title: "loream ipsum", isChecked: false },
            { title: "loream ipsum", isChecked: false },
          ],
          points: 100,
        },
        {
          id: 14,
          ques: "Lorem ipsum dolor sit amet consectetur. Fermentum vitae dui lorem auctor diam gravida volutpat. Dictum nulla nulla suscipit aliquam orci. Urna felis adipiscing curabitur phasellus non dictumst nunc aliquam.",
          ans: [
            { title: "loream ipsum", isChecked: false },
            { title: "loream ipsum", isChecked: false },
            { title: "loream ipsum", isChecked: true },
            { title: "loream ipsum", isChecked: false },
          ],
          points: 100,
        },
        {
          id: 15,
          ques: "Lorem ipsum dolor sit amet consectetur. Fermentum vitae dui lorem auctor diam gravida volutpat. Dictum nulla nulla suscipit aliquam orci. Urna felis adipiscing curabitur phasellus non dictumst nunc aliquam.",
          ans: [
            { title: "loream ipsum", isChecked: false },
            { title: "loream ipsum", isChecked: false },
            { title: "loream ipsum", isChecked: false },
            { title: "loream ipsum", isChecked: true },
          ],
          points: 100,
        },
      ],
    },
    {
      id: 1,
      type: "lifes",
      incorrect: 5,
      data: [
        {
          id: 1,
          ques: "Lorem ipsum dolor sit amet consectetur. Fermentum vitae dui lorem auctor diam gravida volutpat. Dictum nulla nulla suscipit aliquam orci. Urna felis adipiscing curabitur phasellus non dictumst nunc aliquam.",
          ans: [
            { title: "loream ipsum", isChecked: false },
            { title: "loream ipsum", isChecked: true },
            { title: "loream ipsum", isChecked: false },
            { title: "loream ipsum", isChecked: false },
          ],
        },
        {
          id: 2,
          ques: "Lorem ipsum dolor sit amet consectetur. Fermentum vitae dui lorem auctor diam gravida volutpat. Dictum nulla nulla suscipit aliquam orci. Urna felis adipiscing curabitur phasellus non dictumst nunc aliquam.",
          ans: [
            { title: "loream ipsum", isChecked: false },
            { title: "loream ipsum", isChecked: false },
            { title: "loream ipsum", isChecked: true },
            { title: "loream ipsum", isChecked: false },
          ],
        },
        {
          id: 3,
          ques: "Lorem ipsum dolor sit amet consectetur. Fermentum vitae dui lorem auctor diam gravida volutpat. Dictum nulla nulla suscipit aliquam orci. Urna felis adipiscing curabitur phasellus non dictumst nunc aliquam.",
          ans: [
            { title: "loream ipsum", isChecked: false },
            { title: "loream ipsum", isChecked: false },
            { title: "loream ipsum", isChecked: false },
            { title: "loream ipsum", isChecked: true },
          ],
        },
        {
          id: 4,
          ques: "Lorem ipsum dolor sit amet consectetur. Fermentum vitae dui lorem auctor diam gravida volutpat. Dictum nulla nulla suscipit aliquam orci. Urna felis adipiscing curabitur phasellus non dictumst nunc aliquam.",
          ans: [
            { title: "loream ipsum", isChecked: false },
            { title: "loream ipsum", isChecked: true },
            { title: "loream ipsum", isChecked: false },
            { title: "loream ipsum", isChecked: false },
          ],
        },
        {
          id: 5,
          ques: "Lorem ipsum dolor sit amet consectetur. Fermentum vitae dui lorem auctor diam gravida volutpat. Dictum nulla nulla suscipit aliquam orci. Urna felis adipiscing curabitur phasellus non dictumst nunc aliquam.",
          ans: [
            { title: "loream ipsum", isChecked: false },
            { title: "loream ipsum", isChecked: false },
            { title: "loream ipsum", isChecked: true },
            { title: "loream ipsum", isChecked: false },
          ],
        },
        {
          id: 6,
          ques: "Lorem ipsum dolor sit amet consectetur. Fermentum vitae dui lorem auctor diam gravida volutpat. Dictum nulla nulla suscipit aliquam orci. Urna felis adipiscing curabitur phasellus non dictumst nunc aliquam.",
          ans: [
            { title: "loream ipsum", isChecked: false },
            { title: "loream ipsum", isChecked: false },
            { title: "loream ipsum", isChecked: false },
            { title: "loream ipsum", isChecked: true },
          ],
        },
        {
          id: 7,
          ques: "Lorem ipsum dolor sit amet consectetur. Fermentum vitae dui lorem auctor diam gravida volutpat. Dictum nulla nulla suscipit aliquam orci. Urna felis adipiscing curabitur phasellus non dictumst nunc aliquam.",
          ans: [
            { title: "loream ipsum", isChecked: false },
            { title: "loream ipsum", isChecked: true },
            { title: "loream ipsum", isChecked: false },
            { title: "loream ipsum", isChecked: false },
          ],
        },
        {
          id: 8,
          ques: "Lorem ipsum dolor sit amet consectetur. Fermentum vitae dui lorem auctor diam gravida volutpat. Dictum nulla nulla suscipit aliquam orci. Urna felis adipiscing curabitur phasellus non dictumst nunc aliquam.",
          ans: [
            { title: "loream ipsum", isChecked: false },
            { title: "loream ipsum", isChecked: false },
            { title: "loream ipsum", isChecked: true },
            { title: "loream ipsum", isChecked: false },
          ],
        },
        {
          id: 9,
          ques: "Lorem ipsum dolor sit amet consectetur. Fermentum vitae dui lorem auctor diam gravida volutpat. Dictum nulla nulla suscipit aliquam orci. Urna felis adipiscing curabitur phasellus non dictumst nunc aliquam.",
          ans: [
            { title: "loream ipsum", isChecked: false },
            { title: "loream ipsum", isChecked: false },
            { title: "loream ipsum", isChecked: false },
            { title: "loream ipsum", isChecked: true },
          ],
        },
        {
          id: 10,
          ques: "Lorem ipsum dolor sit amet consectetur. Fermentum vitae dui lorem auctor diam gravida volutpat. Dictum nulla nulla suscipit aliquam orci. Urna felis adipiscing curabitur phasellus non dictumst nunc aliquam.",
          ans: [
            { title: "loream ipsum", isChecked: false },
            { title: "loream ipsum", isChecked: true },
            { title: "loream ipsum", isChecked: false },
            { title: "loream ipsum", isChecked: false },
          ],
        },
        {
          id: 11,
          ques: "Lorem ipsum dolor sit amet consectetur. Fermentum vitae dui lorem auctor diam gravida volutpat. Dictum nulla nulla suscipit aliquam orci. Urna felis adipiscing curabitur phasellus non dictumst nunc aliquam.",
          ans: [
            { title: "loream ipsum", isChecked: false },
            { title: "loream ipsum", isChecked: false },
            { title: "loream ipsum", isChecked: true },
            { title: "loream ipsum", isChecked: false },
          ],
        },
        {
          id: 12,
          ques: "Lorem ipsum dolor sit amet consectetur. Fermentum vitae dui lorem auctor diam gravida volutpat. Dictum nulla nulla suscipit aliquam orci. Urna felis adipiscing curabitur phasellus non dictumst nunc aliquam.",
          ans: [
            { title: "loream ipsum", isChecked: false },
            { title: "loream ipsum", isChecked: false },
            { title: "loream ipsum", isChecked: false },
            { title: "loream ipsum", isChecked: true },
          ],
        },
        {
          id: 13,
          ques: "Lorem ipsum dolor sit amet consectetur. Fermentum vitae dui lorem auctor diam gravida volutpat. Dictum nulla nulla suscipit aliquam orci. Urna felis adipiscing curabitur phasellus non dictumst nunc aliquam.",
          ans: [
            { title: "loream ipsum", isChecked: false },
            { title: "loream ipsum", isChecked: true },
            { title: "loream ipsum", isChecked: false },
            { title: "loream ipsum", isChecked: false },
          ],
        },
        {
          id: 14,
          ques: "Lorem ipsum dolor sit amet consectetur. Fermentum vitae dui lorem auctor diam gravida volutpat. Dictum nulla nulla suscipit aliquam orci. Urna felis adipiscing curabitur phasellus non dictumst nunc aliquam.",
          ans: [
            { title: "loream ipsum", isChecked: false },
            { title: "loream ipsum", isChecked: false },
            { title: "loream ipsum", isChecked: true },
            { title: "loream ipsum", isChecked: false },
          ],
        },
        {
          id: 15,
          ques: "Lorem ipsum dolor sit amet consectetur. Fermentum vitae dui lorem auctor diam gravida volutpat. Dictum nulla nulla suscipit aliquam orci. Urna felis adipiscing curabitur phasellus non dictumst nunc aliquam.",
          ans: [
            { title: "loream ipsum", isChecked: false },
            { title: "loream ipsum", isChecked: false },
            { title: "loream ipsum", isChecked: false },
            { title: "loream ipsum", isChecked: true },
          ],
        },
      ],
    },
  ];

  const newArr = arr.find((val) => val.type === type);

  return (
    <div className="details-wrapper">
      <div className="details-wrapper-header">
        <div className="profile-header">
          <div className="profile-header__primary flex-center">
            <h3>Progress Points</h3>

            <div className="summary">
              {newArr?.correct && (
                <p className="corr_ques">
                  <span>Correct:</span> <span>{newArr?.correct} question</span>
                </p>
              )}

              <p className="wrong_ques">
                <span>Wrong:</span> <span>{newArr?.incorrect} question</span>
              </p>

              {newArr?.totalPoints && (
                <div className="total_points">
                  {newArr?.totalPoints}{" "}
                  <span>{newArr?.type === "xp" ? "XP" : newArr?.type}</span> Win
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="profile-body">
          {newArr?.data.map((val, i) => (
            <div key={i} className="ques-body">
              <div className="summary">
                <p>{val.ques}</p>
                <div
                  className={`total_points ${newArr?.type === "lifes" && "life"}`}
                >
                  {newArr?.type === "xp" || newArr?.type === "walnuts" ? (
                    <i className="fa-regular fa-plus"></i>
                  ) : (
                    newArr?.type === "lifes" && (
                      <>
                        <i className="fa-regular fa-minus"></i> 1{" "}
                      </>
                    )
                  )}{" "}
                  {val.points}{" "}
                  <span>
                    {newArr?.type === "xp" && "XP Points"}
                    {newArr?.type === "walnuts" && "Walnuts Points"}
                    {newArr?.type === "lifes" && "Life"}
                  </span>
                </div>
              </div>

              <div className="ans-box">
                {val.ans.map(({ title, isChecked }, i) => (
                  <p
                    key={i}
                    className={`ans ${isChecked ? "correct" : ""} ${newArr?.type === "lifes" ? "life" : ""}`}
                  >
                    {title}
                  </p>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProgressPoints;
