/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import ConformationPopup from "components/ui/ConformationPopup";
import SearchField from "components/ui/SearchField";
import useDisableSkelaton from "hooks/useDisableSkelaton";
import useFetchList from "hooks/useFetchList";
import ModuleLayout from "layouts/ModuleLayout";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { getBreadcrumbName, unixTimeToReadableFormat } from "helper/helper";
import UserTable from "../../components/UserTable";
import {
  deleteUser,
  getUserList,
  userActivateDeactivate,
} from "../redux/userApi";
import DropDownBox from "components/DropDownBox";
import { FilterGender } from "helper/placeholder-data";
import useSelectRows from "hooks/useSelectRows";

const UserList = ({ setTitle }) => {
  useEffect(() => {
    setTitle("User Management");
  }, [setTitle]);

  const { userList, metaData, userLoading } = useSelector(
    (state) => state.user,
    shallowEqual
  );

  const [active, setActive] = useState("users");
  const [mobileSidebar, setMobileSidebar] = useState(false);
  const [minimizedSidebar, setMinimizedSidebar] = useState(false);
  const [alertBox2, setAlertBox2] = useState(false);
  const [popupDataTwo, setPopupDataTwo] = useState();
  const [popupCustomWarning, setPopupCustomWarning] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    content,
    alertBox,
    setAlertBox,
    selectAllRows,
    selectedRows,
    setSelectedRows,
    toggleSelectAll,
    toggleRowSelection,
    deleteFunction,
  } = useSelectRows(userList, "user_id");

  const {
    privileges,
    limit,
    setLimit,
    sort,
    setSort,
    search,
    setSearch,
    btnNo,
    setBtnNo,
    tableIndex,
    setTableIndex,
  } = useFetchList(getUserList, metaData, selectAllRows, setSelectedRows);

  const { contentLoading } = useDisableSkelaton(userLoading);

  const listContent = userList.map((item) => {
    const { id, num, name, email, status, gender, createdAt, lastLogin } = item;
    const userName = `${name.firstName} ${name.lastName}`;
    const userStatus = status ? "active" : "inactive";
    const crDt = unixTimeToReadableFormat(createdAt);
    const lDt = lastLogin
      ? unixTimeToReadableFormat(lastLogin?.lastLogin)
      : "Not yet login!";

    const similarItems = [
      { style: "name", val: userName },
      { style: "email", val: email },
      { style: `status ${userStatus}`, val: userStatus },
      { style: "", val: gender ? gender : "Not updated yet!" },
      { style: "date", val: lDt },
      { style: "date", val: crDt },
    ];
    return [id, num, userName, similarItems, status];
  });

  const TABLEDATA = {
    loadingState: contentLoading,
    skalatonDetails: { col: 7, rows: limit },
    listHeading: [
      "Name",
      "Contact Info",
      "Status",
      "gender",
      "Last Login",
      "Created At",
    ],
    listContent,
    selectedRows,
    selectAllRows,
    setSelectedRows,
    toggleSelectAll,
    toggleRowSelection,
    deleteFunction,
    sliceName: "user",
    search,
    PagAPI: getUserList,
    sort,
    limit,
    setLimit,
    btnNo,
    setBtnNo,
    tableIndex,
    setTableIndex,
    privileges,
    hasAccess: "userManagement",
    navigateTo: update,
    setAlertBox2,
    setPopupDataTwo,
    setPopupCustomWarning,
    metaData,
  };

  function update(id) {
    const user = userList.find((user) => user.id === id);
    const breadcrumbName = getBreadcrumbName(user);
    navigate(`/users/application-users/${id}`, {
      state: { slug: id, title: breadcrumbName },
    });
  }

  const [filterVal, setFilterVal] = useState("");
  const [userType, setUserType] = useState("");

  useEffect(() => {
    dispatch(
      getUserList({
        page: 0,
        search,
        sort,
        limit,
        gender: filterVal,
        premium: userType,
      })
    );
  }, [filterVal, userType]);

  return (
    <ModuleLayout
      minimizedSidebar={minimizedSidebar}
      setMinimizedSidebar={setMinimizedSidebar}
      mobileSidebar={mobileSidebar}
      setMobileSidebar={setMobileSidebar}
      active={active}
      setActive={setActive}
      content={content}
      alertBox={alertBox}
    >
      <div className="list-container custom-box">
        <header className="list-header">
          <div className="list-title">
            <h2>App User Insights</h2>
            <div className="flex-center gap-1">
              {Object?.values(privileges["userManagement"])?.includes(
                "delete"
              ) &&  selectedRows.length > 0 ? (
                <button
                  className="btn-secondary flex-center gap-1"
                  onClick={() => {
                    deleteFunction("all templates");
                  }}
                >
                  <i className="fa-light fa-trash"></i>
                  {`Delete ${selectedRows.length}`}
                </button>
              ) : null}
            </div>
          </div>
        </header>
        <div className="tb_controls mt-1">
          <div className="flex-center gap-1">
            <DropDownBox
              options={[
                { label: "Newest", value: "desc" },
                { label: "Oldest", value: "asc" },
              ]}
              size="small"
              dropDownTitle="Sort"
              animateDropDownTitle
              customSetter={setSort}
              customDropBoxStyles={{
                opacity: 0.6,
                color: "#262626",
                padding: "0.6rem",
                border: "1px solid var(--color-neutral-92)",
              }}
            />
            <DropDownBox
              options={FilterGender}
              size="small"
              dropDownTitle="Gender"
              animateDropDownTitle
              customSetter={setFilterVal}
              customDropBoxStyles={{
                opacity: 0.6,
                color: "#262626",
                padding: "0.6rem",
                border: "1px solid var(--color-neutral-92)",
              }}
              resetButton
            />
            <DropDownBox
              options={[
                { label: "Premium", value: "true" },
                { label: "Free", value: "false" },
              ]}
              size="small"
              dropDownTitle="User Type"
              animateDropDownTitle
              customSetter={setUserType}
              customDropBoxStyles={{
                opacity: 0.6,
                color: "#262626",
                padding: "0.6rem",
                border: "1px solid var(--color-neutral-92)",
              }}
              resetButton
            />
          </div>
          <SearchField value={search} setValue={setSearch} />
        </div>
        <UserTable {...TABLEDATA} />
      </div>

      {alertBox && (
        <ConformationPopup
          customState={alertBox}
          customSetter={setAlertBox}
          api={deleteUser}
          popUpData={content}
          customFunction={() => setSelectedRows(new Set())}
        />
      )}

      {alertBox2 && (
        <ConformationPopup
          customState={alertBox2}
          customSetter={setAlertBox2}
          mainTitle={popupCustomWarning}
          subTitle={` Are you sure want to ${popupDataTwo?.data?.send?.status ? "Activate" : "Deactivate"} the user?`}
          api={userActivateDeactivate}
          popUpData={popupDataTwo}
          reData={{ page: 0, search, sort: sort, limit }}
          reFetchApi={getUserList}
          popupType="edit"
        // toastMessage="User state changed"
        />
      )}
    </ModuleLayout>
  );
};

export default UserList;
