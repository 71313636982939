/* eslint-disable react-hooks/exhaustive-deps*/
import { useEffect, useState } from "react";

const useDeleteContent = (tableData, valueType) => {
  const [content, setContent] = useState();
  const [alertBox, setAlertBox] = useState(false);
  const [selectAllRows, setSelectAllRows] = useState(false);
  const [collectAllRows, setCollectAllRows] = useState([]);

  useEffect(() => {
    const idArr = tableData?.map((item) => {
      return item?.id;
    });

    if (selectAllRows) {
      setCollectAllRows(idArr);
    } else {
      setCollectAllRows([]);
    }
  }, [selectAllRows]);

  useEffect(() => {
    if (selectAllRows && collectAllRows.length === 0) {
      setSelectAllRows(false);
      setCollectAllRows([]);
      return;
    }
  }, [collectAllRows]);

  const deleteFunction = (slug, title, limit = 5) => {
    setContent({
      data: {
        slug,
        page: 0,
        search: "",
        sort: "desc",
        limit: limit,
        send: {
          is_delete: true,
          [valueType]: selectAllRows ? collectAllRows : [slug],
        },
      },
      title,
    });
    setAlertBox(true);
  };

  return {
    content,
    alertBox,
    setAlertBox,
    selectAllRows,
    collectAllRows,
    setSelectAllRows,
    setCollectAllRows,
    deleteFunction,
  };
};

export default useDeleteContent;
