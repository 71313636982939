import { createSlice } from "@reduxjs/toolkit";
import { getPlanDetail, getPlanList } from "./planApi";

const planSlice = createSlice({
  name: "plan",
  initialState: {
    planLoading: false,
    planList: [],
    metaData: {},
    planDetail: {}
  },
  reducers: {
    isplanLoading: (state, action) =>
      (state = {
        ...state,
        planLoading: action.payload,
      }),
  },
  extraReducers: (builder) => {
    builder.addCase(getPlanList.pending, (state, action) => {
      state.planLoading = true;
    });
    builder.addCase(getPlanList.fulfilled, (state, action) => {
        
      state.planLoading = false;
      state.planList = action?.payload?.data;
      state.metaData = action?.payload?.metadata[0];
    });
    builder.addCase(getPlanList.rejected, (state, action) => {
      state.planLoading = false;
      state.planList = [];
    });

    builder.addCase(getPlanDetail.pending, (state, action) => {
      state.planLoading = true;
    });
    builder.addCase(getPlanDetail.fulfilled, (state, action) => {
      state.planLoading = false;
      state.planDetail = action?.payload?.data;
    });
    builder.addCase(getPlanDetail.rejected, (state, action) => {
      state.planLoading = false;
    });
  },
});

export const { isplanLoading } = planSlice.actions;
export default planSlice.reducer;
