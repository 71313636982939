/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps*/
import Pagination from "components/Pagination";
import TableSkelaton from "components/skelaton/TableSkelaton";
import Checkbox from "components/ui/Checkbox";
import NotFound from "components/ui/NotFound";
import React from "react";


const ReviewTable = (props) => {
  const {
    loadingState,
    skalatonDetails,
    listHeading,
    listContent,
    selectAllRows,
    setSelectAllRows,
    collectAllRows,
    setCollectAllRows,
    deleteFunction,
    sliceName,
    search,
    PagAPI,
    sort,
    limit,
    setLimit,
    btnNo,
    setBtnNo,
    tableIndex,
    setTableIndex,
    privileges,
    hasAccess,
    navigateTo,
    metaData,
  } = props;

  const styles = {
    borderRadius: selectAllRows ? { borderRadius: 0 } : null,
    dangerBackground: (val) =>
      collectAllRows?.includes(val)
        ? {
          background: "#FEFBFB",
        }
        : null,
  };

  return (
    <>
      {loadingState ? (
        <TableSkelaton col={skalatonDetails.col} row={skalatonDetails.rows} />
      ) : listContent?.length > 0 ? (
        <>
          <div className="table_container">
            <div className="table_content">
              <table className="main_table">
                <thead className="tb_head">
                  <tr className="tb_row">
                    {/* <th className="tbh_name" style={[styles.borderRadius, { width: "3rem" }]}>
                      Sr No.
                    </th> */}
                    <th className="tbh_name" style={{ ...styles.borderRadius, width: "5rem" }}>
                      Sr No.
                    </th>
                    {listHeading?.map((tbh, i) => (
                      <th className="tbh_name" key={i} style={{ width: `${i === 0 ? "9rem" : "auto"}` }}>
                        {tbh}
                      </th>
                    ))}
                    {Object.values(privileges[hasAccess]).includes(
                      "write" || "delete"
                    ) && (
                        <th
                          className="tbh_name text-center"
                          style={styles.borderRadius}
                        >
                          Action
                        </th>
                      )}
                  </tr>
                </thead>

                <tbody className="tb_body">
                  {listContent.map((content, index) => {
                    return (
                      <tr
                        key={index}
                        className="tb_row"
                        style={styles.dangerBackground(content?.[0])}
                      >
                        <td className="tb_data">
                          <div style={{ paddingLeft: "0.5rem" }}>
                            {content?.[1] + "."}
                          </div>
                        </td>

                        {content?.[4].map((simVal, i) => (
                          <td className="tb_data" key={i}>
                            {
                              simVal.tag && simVal.tag === "image" ?
                                <img src={simVal.val} crossOrigin="anonymous" width={60} alt="" /> :
                                <div className={simVal.style} title={simVal.val}>{simVal.val}</div>
                            }
                          </td>
                        ))}

                        {Object.values(privileges[hasAccess]).includes(
                          "read"
                        ) && (
                            <td className="tb_data">
                              <div className="actions">
                                {Object.values(privileges[hasAccess]).includes(
                                  "read"
                                ) && (
                                    <button
                                      className="icon-btn view"
                                      onClick={() => navigateTo(content?.[0])}
                                    >
                                      <i className="fa-regular fa-eye"></i>
                                    </button>
                                  )}
                              </div>
                            </td>
                          )}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>

          {metaData?.total >= 5 && (
            <Pagination
              sliceName={sliceName}
              search={search}
              api={PagAPI}
              sort={sort}
              limit={limit}
              setLimit={setLimit}
              tableIndex={tableIndex}
              setTableIndex={setTableIndex}
              btnNo={btnNo}
              setBtnNo={setBtnNo}
              callbackFunction={() => {
                setSelectAllRows(false);
                setCollectAllRows([]);
              }}
            />
          )}
        </>
      ) : (
        <NotFound />
      )}
    </>
  );
};

export default ReviewTable;

