import { defaultLogout } from "modules/auth/redux/authSlice";

const setupAxios = (axios, store) => {
  axios.interceptors.request.use(
    (config) => {
      const { auth } = store.getState();
      if (auth.token) {
        config.headers.Authorization = `Bearer ${auth.token}`;
      }
      config.headers["Access-Key"] = process.env.REACT_APP_ACCESS_KEY;
      return config;
    },
    (err) => Promise.reject(err)
  );

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (err) => {
      if (err.response && err.response.status === 401) {
        store.dispatch(defaultLogout());
        window.location.href = "/";
      }
      return err.response;
    }
  );
};

export default setupAxios;
