import { createSlice } from "@reduxjs/toolkit";
import { getFeedbackList } from "./feedbackApi";

const feedbackSlice = createSlice({
    name: "feedback",
    initialState: {
        feedbackLoading: false,
        feedbackList: [],
        feedListMeta: {}
    },
    reducers: {
        isFeedbackLoading: (state, action) =>
        (state = {
            ...state,
            feedbackLoading: action.payload,
        }),
    },
    extraReducers: (builder) => {
        builder.addCase(getFeedbackList.pending, (state, action) => {
            state.addressLoading = true;
        });
        builder.addCase(getFeedbackList.fulfilled, (state, action) => {
            
            state.addressLoading = false;
            state.feedbackList = action.payload?.data;
            state.feedListMeta = action.payload?.metadata?.[0];
        });
        builder.addCase(getFeedbackList.rejected, (state, action) => {
            state.feedbackList = [];
            state.addressLoading = false;
        });
    }
})

export const { isFeedbackLoading } = feedbackSlice.actions;
export default feedbackSlice.reducer;