/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import SubmitButton from "components/ui/SubmitButton";
import InputField from "components/ui/InputField";
import { changePassword } from "../redux/profileApi";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";

const ChangePass = () => {
  const dispatch = useDispatch();
  const [activeInputs, setActiveInputs] = useState(false);
  const [visibility, setVisibility] = useState({
    currPass: false,
    pass: false,
    rePass: false,
  });

  const passSchema = Yup.object({
    current_password: Yup.string()
      .required("Current password is required")
      .trim()
      .matches(
        //eslint-disable-next-line
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,20})/,
        "Password should contains at least 1 digit, 1 uppercase & 1 lowercase,1 special character,length between 8 to 20"
      ),
    password: Yup.string()
      .trim()
      .matches(
        //eslint-disable-next-line
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,20})/,
        "Password should contains at least 1 digit, 1 uppercase & 1 lowercase,1 special character,length between 8 to 20"
      )
      .required("New Password is required"),
    retype_password: Yup.string()
      .oneOf([Yup.ref("password")], "Confirm Password does not match")
      .required("Confirm Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      current_password: "",
      password: "",
      retype_password: "",
    },
    validationSchema: passSchema,
    onSubmit: async (values, { resetForm }) => {
      const data = {
        old_password: values.current_password,
        new_password: values.password,
        new_password_confirmation: values.retype_password,
      };

      const response = await dispatch(changePassword(data));
      if (response?.payload) {
        setActiveInputs(false);
        resetForm();
      }
    },
  });

  useEffect(() => {
    if (!activeInputs) {
      formik.resetForm();
    }
  }, [activeInputs]);

  return (
    <div className="profile-details">
      <div className="header-wrapper">
        <h2 className="title">Change Password</h2>
        <div className="sub-header">
          <div className="desc">
            <h3>Update Your Account Security</h3>
            <p className="txt">
              Secure your account by updating your password regularly.
            </p>
          </div>
          <div className="flex-center gap-1">
            {activeInputs && (
              <button
                className="btn-outlined cancel"
                onClick={() => {
                  setActiveInputs(!activeInputs);
                  formik.setValues({
                    current_password: "",
                    password: "",
                    retype_password: "",
                  });
                }}
                style={{ flexShrink: 0 }}
                type="button"
              >
                Discard
              </button>
            )}
            {activeInputs ? (
              <SubmitButton
                type="submit"
                onClick={() => {
                  formik.handleSubmit();
                }}
                className="btn-primary"
                size="fit-content"
                isLoading={formik.isSubmitting}
              >
                Update
              </SubmitButton>
            ) : (
              <button
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  setActiveInputs(true);
                }}
                className="btn-outlined edit flex-center gap-2"
              >
                <i className="fa-regular fa-pen-to-square"></i>
                Edit
              </button>
            )}
          </div>
        </div>
      </div>

      <div className="auth-form-wrapper">
        <form className="form" noValidate="novalidate">
          <div className="form-control">
            <label className="form-label">Current Password</label>
            <div className="form-input-box">
              <InputField
                type={visibility.currPass ? "text" : "password"}
                id="current_password"
                name="current_password"
                className="form-input"
                value={formik.values.current_password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled={!activeInputs}
                maxLength={20}
              />
              <i
                className={`${
                  !visibility.currPass
                    ? "fa-sharp fa-thin fa-eye-slash"
                    : "fa-sharp fa-thin fa-eye"
                } form-input-box__icon`}
                onClick={() =>
                  setVisibility({
                    ...visibility,
                    currPass: !visibility.currPass,
                  })
                }
              ></i>
            </div>
            {formik.errors.current_password &&
            formik.touched.current_password ? (
              <span className="err">{formik.errors.current_password}</span>
            ) : null}
          </div>
          <div className="form-control">
            <label className="form-label">New Password</label>
            <div className="form-input-box">
              <InputField
                type={visibility.pass ? "text" : "password"}
                id="password"
                name="password"
                className="form-input"
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled={!activeInputs}
                maxLength={20}
              />
              <i
                className={`${
                  !visibility.pass
                    ? "fa-sharp fa-thin fa-eye-slash"
                    : "fa-sharp fa-thin fa-eye"
                } form-input-box__icon`}
                onClick={() =>
                  setVisibility({
                    ...visibility,
                    pass: !visibility.pass,
                  })
                }
              ></i>
            </div>
            {formik.errors.password && formik.touched.password ? (
              <span className="err">{formik.errors.password}</span>
            ) : null}
          </div>

          <div className="form-control">
            <label className="form-label">Confirm Password</label>
            <div className="form-input-box">
              <InputField
                type={visibility.rePass ? "text" : "password"}
                id="retype_password"
                name="retype_password"
                className="form-input"
                value={formik.values.retype_password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled={!activeInputs}
                maxLength={20}
              />
              <i
                className={`${
                  !visibility.rePass
                    ? "fa-sharp fa-thin fa-eye-slash"
                    : "fa-sharp fa-thin fa-eye"
                } form-input-box__icon`}
                onClick={() =>
                  setVisibility({
                    ...visibility,
                    rePass: !visibility.rePass,
                  })
                }
              ></i>
            </div>
            {formik.errors.retype_password && formik.touched.retype_password ? (
              <span className="err">{formik.errors.retype_password}</span>
            ) : null}
          </div>
        </form>
      </div>
    </div>
  );
};

export default ChangePass;
