/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import CustomTable from "components/CustomTable";
import ConformationPopup from "components/ui/ConformationPopup";
import SearchField from "components/ui/SearchField";
import useDisableSkelaton from "hooks/useDisableSkelaton";
import useFetchList from "hooks/useFetchList";
import ModuleLayout from "layouts/ModuleLayout";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { unixTimeToReadableFormat } from "helper/helper";
import DropDownBox from "components/DropDownBox";
import { deleteFaq, getFaqList } from "../redux/faqApi";
import FaManager from "./FaqManager";
import { getCategoryList } from "modules/catalogue/categories/redux/categoryApi";
import { SortItems } from "helper/placeholder-data";
import useSelectRows from "hooks/useSelectRows";

const Faq = ({ setTitle }) => {
  useEffect(() => {
    setTitle("FAQ Management");
  }, [setTitle]);

  const dispatch = useDispatch();
  
  const { categoryList } = useSelector(
    (state) => state.CatCategory,
    shallowEqual
  );
  
  const [catList, setCatList] = useState([]);
  const [category, setCategory] = useState("");
  
  useEffect(() => {
    dispatch(
      getCategoryList({
        page: "0",
        limit: "0",
        search: "",
        sort: "desc",
        filter: "faq",
      })
    );
  }, []);

  const { faqList, metaData, faqLoading } = useSelector(
    (state) => state.faq,
    shallowEqual
  );

  const [popup, setPopup] = useState({ show: false, type: "", id: "" });

  const [active, setActive] = useState("faq");
  const [mobileSidebar, setMobileSidebar] = useState(false);
  const [minimizedSidebar, setMinimizedSidebar] = useState(false);
  const { contentLoading } = useDisableSkelaton(faqLoading);

  const {
    content,
    alertBox,
    setAlertBox,
    selectAllRows,
    selectedRows,
    setSelectedRows,
    toggleSelectAll,
    toggleRowSelection,
    deleteFunction,
  } = useSelectRows(faqList, "faq_id");
  
  const {
    privileges,
    limit,
    setLimit,
    sort,
    setSort,
    search,
    setSearch,
    btnNo,
    setBtnNo,
    tableIndex,
    setTableIndex,
  } = useFetchList(getFaqList, metaData, selectAllRows, setSelectedRows);

  useEffect(() => {
    const data = {
      page: 0,
      sort: sort,
      limit: limit,
      search: search,
      categoryId: category,
    };
    dispatch(getFaqList(data));
  }, [category, sort, limit, sort, search]);

  const listContent = faqList.map((item, i) => {
    const { id, num, slug, question, createdBy, createdAt } = item;
    const crDt = unixTimeToReadableFormat(createdAt);
    const similarItems = [
      { style: "name", val: question },
      { style: "email", val: slug },
      {
        style: "",
        val: `${createdBy?.name?.firstName} ${createdBy?.name?.lastName}`,
      },
      { style: "", val: crDt },
    ];
    return [id, num, question, slug, similarItems];
  });

  const TABLEDATA = {
    loadingState: contentLoading,
    skalatonDetails: { col: 4, rows: limit },
    list: faqList,
    listHeading: ["FAQ", "Slug", "Created By", "Created At"],
    listContent,
    selectedRows,
    selectAllRows,
    setSelectedRows,
    toggleSelectAll,
    toggleRowSelection,
    deleteFunction,
    sliceName: "faq",
    PagAPI: getFaqList,
    search,
    sort,
    limit,
    setLimit,
    btnNo,
    setBtnNo,
    tableIndex,
    setTableIndex,
    metaData,
    privileges,
    hasAccess: "faqManagement",
    navigateTo: update,
  };

  function update(id) {
    setPopup({ show: true, id: id, type: "update-faq" });
  }

  const handleClick = (val, id) => {
    if (val === "single") {
      setPopup({
        ...popup,
        show: true,
        type: "add-faq",
      });
    } else if (val === "update") {
      setPopup({
        ...popup,
        show: true,
        type: "update-faq",
        id: id,
      });
    } else if (val === "multiple") {
      setPopup({
        ...popup,
        show: true,
        type: "add-multiple-faq",
      });
    }
  };

  useEffect(() => {
    if (categoryList?.length > 0) {
      const list = categoryList.map((category) => {
        const label = category?.name;
        const value = category?.id;
        return { label, value };
      });
      setCatList(list);
    }
  }, [categoryList]);

  return (
    <ModuleLayout
      minimizedSidebar={minimizedSidebar}
      setMinimizedSidebar={setMinimizedSidebar}
      mobileSidebar={mobileSidebar}
      setMobileSidebar={setMobileSidebar}
      active={active}
      setActive={setActive}
      content={content}
      alertBox={alertBox}
    >
      <header className="list-header">
        <div className="list-title">
          <h2>FAQ Management</h2>
          <div className="flex-center gap-1">
            {Object.values(privileges["faqManagement"])?.includes("write") && (
              <button
                className="btn-primary"
                onClick={() => handleClick("single")}
              >
                <i className="fa-solid fa-feather"></i> Add FAQ
              </button>
            )}

            {Object?.values(privileges["faqManagement"])?.includes("delete") &&
            selectedRows.length !== 0 ? (
              <button
                className="btn-secondary flex-center gap-1"
                onClick={() => {
                  deleteFunction("all quizes");
                }}
              >
                <i className="fa-light fa-trash"></i>
                {`Delete ${selectedRows.length}`}
              </button>
            ) : null}
          </div>
        </div>
      </header>
      <div className="list-container custom-box">
        <div className="tb_controls">
          <div className="flex gap-1">
            <DropDownBox
              options={SortItems}
              size="small"
              dropDownTitle="Sort"
              animateDropDownTitle
              customSetter={setSort}
              customDropBoxStyles={{
                opacity: 0.6,
                color: "#262626",
                padding: "0.6rem",
                border: "1px solid var(--color-neutral-92)",
              }}
            />
            <DropDownBox
              options={catList}
              size="small"
              dropDownTitle="Category"
              animateDropDownTitle
              customSetter={setCategory}
              incomingValue={category}
              customDropBoxStyles={{
                opacity: 0.6,
                color: "#262626",
                padding: "0.6rem",
                border: "1px solid var(--color-neutral-92)",
              }}
              resetButton
            />
          </div>

          <SearchField value={search} setValue={setSearch} />
        </div>
        <CustomTable {...TABLEDATA} />
      </div>
      {popup.show && <FaManager popup={popup} setPopup={setPopup} />}
      {alertBox && (
        <ConformationPopup
          customState={alertBox}
          customSetter={setAlertBox}
          api={deleteFaq}
          popUpData={content}
          customFunction={() => setSelectedRows(new Set())}
        />
      )}
    </ModuleLayout>
  );
};

export default Faq;
