import React, { useEffect, useRef, useState } from "react";
import peak72 from "../assets/icons/MainLogo.svg";
import { useNavigate } from "react-router-dom";
import { logout } from "../modules/auth/redux/authApi";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { setPageDetails } from "modules/auth/redux/authSlice";
import SubmitButton from "./ui/SubmitButton";

const Sidebar = ({ active, setActive }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const activeItemRef = useRef(null);

  const [navList, setNavList] = useState(0);
  const { privileges, role, pageDetails, authLoading } = useSelector(
    (state) => state.auth,
    shallowEqual
  );

  const handleLogout = async () => {
    const response = await dispatch(logout());
    if (response?.payload) {
      navigate("/login");
    }
  };

  const resetMetaData = () => {
    if (Object.keys(pageDetails).length > 0) {
      dispatch(setPageDetails({}));
    }
  };

  useEffect(() => {
    if (activeItemRef.current) {
      const { top, bottom } = activeItemRef.current.getBoundingClientRect();
      if (top < 0 || bottom > window.innerHeight - 100) {
        activeItemRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }
  }, [activeItemRef]);

  const cataloguePrivileges = [
    "brandManagement",
    "categoryManagement",
    "skillManagement",
    "questionManagement",
    "quizManagement",
    "faqManagement",
    "glossaryManagement",
  ];

  const contentPrivileges = ["pageManagement", "templateManagement"];
  const userPrivileges = ["userManagement"];

  const isCatalogueActive =
    active === "brands" ||
    active === "category" ||
    active === "skills" ||
    active === "quizes" ||
    active === "question" ||
    active === "glossary" ||
    active === "faq";
  const isContentActive = active === "cmsPages" || active === "cmsTemplates";
  const isUserListActive = active === "roles" || active === "users";

  useEffect(() => {
    if (isCatalogueActive) {
      setNavList(1);
    } else if (isContentActive) {
      setNavList(2);
    } else if (isUserListActive) {
      setNavList(4);
    }

    // if (active === "inventory" || active === "product") {
    //   setNavList(3);
    // }
  }, [active]);

  return (
    <div className="sidebar-container">
      <div className="sidebar-header flex-center">
        <img src={peak72} alt="peak72_logo" />
      </div>

      <div className="sidebar-body">
        <h4 className="list-title">HOME</h4>
        <ul className="list-items">
          <li
            className={
              active === "dashboard" ? "list-item active" : "list-item"
            }
            onClick={() => {
              navigate("/");
              setActive("dashboard");
              resetMetaData();
              setNavList(0);
            }}
          >
            <div className="sidebar-icon">
              <i className="fa-solid fa-grid-2"></i>
            </div>
            <span className="list-text">Dashboard</span>
          </li>
        </ul>

        <h4 className="list-title">MANAGEMENT</h4>
        <ul className="list-items">
          {cataloguePrivileges.some((privilege) =>
            Object.keys(privileges).includes(privilege)
          ) &&
            Object?.values(
              privileges[
              cataloguePrivileges.find((privilege) =>
                Object.keys(privileges).includes(privilege)
              )
              ]
            )?.includes("read") && (
              <li
                ref={isCatalogueActive ? activeItemRef : null}
                className={isCatalogueActive ? "list-item active" : "list-item"}
                onClick={() => {
                  navList === 1 ? setNavList(0) : setNavList(1);
                }}
              >
                <div className="expand-li">
                  <div className="ls">
                    <div className="sidebar-icon">
                      <i className="fa-regular fa-grid-round-2-plus"></i>
                    </div>
                    <span className="list-text">Catalogue</span>
                  </div>
                  <div className="fs">
                    <div className="sidebar-icon">
                      <i
                        className={`fa-regular fa-chevron-right medium ${navList !== 1 ? "rotate-0" : "rotate-90"
                          }`}
                      ></i>
                    </div>
                  </div>
                </div>
              </li>
            )}

          {navList === 1 && (
            <ul className="nested-nav-list">
              {privileges.hasOwnProperty("brandManagement") &&
                Object?.values(privileges["brandManagement"])?.includes(
                  "read"
                ) && (
                  <li
                    ref={active === "brands" ? activeItemRef : null}
                    className={
                      active === "brands" ? "list-item active" : "list-item"
                    }
                    onClick={() => {
                      resetMetaData();
                      navigate("/catalogue/brand-management");
                      setActive("brands");
                      setNavList(0);
                    }}
                  >
                    <div className="sidebar-icon">
                      <i className="fa-duotone fa-circle small"></i>
                    </div>
                    <span className="list-text">Brand</span>
                  </li>
                )}

              {privileges.hasOwnProperty("categoryManagement") &&
                Object?.values(privileges["categoryManagement"])?.includes(
                  "read"
                ) && (
                  <li
                    ref={active === "category" ? activeItemRef : null}
                    className={
                      active === "category" ? "list-item active" : "list-item"
                    }
                    onClick={() => {
                      resetMetaData();
                      navigate("/catalogue/category-management");
                      setActive("category");
                    }}
                  >
                    <div className="sidebar-icon">
                      <i className="fa-duotone fa-circle small"></i>
                    </div>
                    <span className="list-text">Category</span>
                  </li>
                )}

              {privileges.hasOwnProperty("skillManagement") &&
                Object?.values(privileges["skillManagement"])?.includes(
                  "read"
                ) && (
                  <li
                    ref={active === "skills" ? activeItemRef : null}
                    className={
                      active === "skills" ? "list-item active" : "list-item"
                    }
                    onClick={() => {
                      resetMetaData();
                      navigate("/catalogue/skill-management");
                      setActive("skills");
                    }}
                  >
                    <div className="sidebar-icon">
                      <i className="fa-duotone fa-circle small"></i>
                    </div>
                    <span className="list-text">Skill</span>
                  </li>
                )}

              {privileges.hasOwnProperty("questionManagement") &&
                Object?.values(privileges["questionManagement"])?.includes(
                  "read"
                ) && (
                  <li
                    ref={active === "question" ? activeItemRef : null}
                    className={
                      active === "question" ? "list-item active" : "list-item"
                    }
                    onClick={() => {
                      resetMetaData();
                      navigate("/catalogue/question-management");
                      setActive("question");
                    }}
                  >
                    <div className="sidebar-icon">
                      <i className="fa-duotone fa-circle small"></i>
                    </div>
                    <span className="list-text">Question</span>
                  </li>
                )}

              {privileges.hasOwnProperty("quizManagement") &&
                Object?.values(privileges["quizManagement"])?.includes(
                  "read"
                ) && (
                  <li
                    ref={active === "quizes" ? activeItemRef : null}
                    className={
                      active === "quizes" ? "list-item active" : "list-item"
                    }
                    onClick={() => {
                      resetMetaData();
                      navigate("/catalogue/quiz-management");
                      setActive("quizes");
                    }}
                  >
                    <div className="sidebar-icon">
                      <i className="fa-duotone fa-circle small"></i>
                    </div>
                    <span className="list-text">Quiz</span>
                  </li>
                )}

              {privileges.hasOwnProperty("faqManagement") &&
                Object?.values(privileges["faqManagement"])?.includes(
                  "read"
                ) && (
                  <li
                    ref={active === "faq" ? activeItemRef : null}
                    className={
                      active === "faq" ? "list-item active" : "list-item"
                    }
                    onClick={() => {
                      resetMetaData();
                      navigate("/catalogue/faq-management");
                      setActive("faq");
                    }}
                  >
                    <div className="sidebar-icon">
                      <i className="fa-duotone fa-circle small"></i>
                    </div>
                    <span className="list-text">FAQs</span>
                  </li>
                )}

              {privileges.hasOwnProperty("glossaryManagement") &&
                Object?.values(privileges["glossaryManagement"])?.includes(
                  "read"
                ) && (
                  <li
                    ref={active === "glossary" ? activeItemRef : null}
                    className={
                      active === "glossary" ? "list-item active" : "list-item"
                    }
                    onClick={() => {
                      resetMetaData();
                      navigate("/catalogue/glossary-management");
                      setActive("glossary");
                      setNavList(0);
                    }}
                  >
                    <div className="sidebar-icon">
                      <i className="fa-duotone fa-circle small"></i>
                    </div>
                    <span className="list-text">Glossary</span>
                  </li>
                )}
            </ul>
          )}

          {contentPrivileges.some((privilege) =>
            Object.keys(privileges).includes(privilege)
          ) &&
            Object?.values(
              privileges[
              contentPrivileges.find((privilege) =>
                Object.keys(privileges).includes(privilege)
              )
              ]
            )?.includes("read") && (
              <li
                ref={
                  isContentActive
                    ? activeItemRef
                    : null
                }
                className={
                  isContentActive
                    ? "list-item active"
                    : "list-item"
                }
                onClick={() => {
                  navList === 2 ? setNavList(0) : setNavList(2);
                }}
              >
                <div className="expand-li">
                  <div className="ls">
                    <div className="sidebar-icon">
                      <i className="fa-regular fa-clipboard-list"></i>
                    </div>
                    <span className="list-text">Content</span>
                  </div>
                  <div className="fs">
                    <div className="sidebar-icon">
                      <i
                        className={`fa-regular fa-chevron-right medium ${navList !== 2 ? "rotate-0" : "rotate-90"
                          }`}
                      ></i>
                    </div>
                  </div>
                </div>
              </li>
            )}

          {navList === 2 && (
            <ul className="nested-nav-list">
              {privileges.hasOwnProperty("pageManagement") &&
                Object?.values(privileges["pageManagement"])?.includes(
                  "read"
                ) && (
                  <li
                    ref={active === "cmsPages" ? activeItemRef : null}
                    className={
                      active === "cmsPages" ? "list-item active" : "list-item"
                    }
                    onClick={() => {
                      resetMetaData();
                      navigate("/content/pages");
                      setActive("cmsPages");
                    }}
                  >
                    <div className="sidebar-icon">
                      <i className="fa-duotone fa-circle small"></i>
                    </div>
                    <span className="list-text">Pages</span>
                  </li>
                )}
              {privileges.hasOwnProperty("templateManagement") &&
                Object?.values(privileges["templateManagement"])?.includes(
                  "read"
                ) && (
                  <li
                    ref={active === "cmsTemplates" ? activeItemRef : null}
                    className={
                      active === "cmsTemplates"
                        ? "list-item active"
                        : "list-item"
                    }
                    onClick={() => {
                      resetMetaData();
                      navigate("/content/templates");
                      setActive("cmsTemplates");
                    }}
                  >
                    <div className="sidebar-icon">
                      <i className="fa-duotone fa-circle small"></i>
                    </div>
                    <span className="list-text">Templates</span>
                  </li>
                )}
            </ul>
          )}

          {privileges.hasOwnProperty("couponManagement") &&
            Object?.values(privileges["couponManagement"])?.includes(
              "read"
            ) && (
              <li
                ref={active === "coupon" ? activeItemRef : null}
                className={
                  active === "coupon" ? "list-item active" : "list-item"
                }
                onClick={() => {
                  navigate("/coupons");
                  resetMetaData();
                  setActive("coupons");
                  setNavList(0);
                }}
              >
                <div className="sidebar-icon">
                  <i className="fa-regular fa-tag"></i>
                </div>
                <span className="list-text">Coupon Management</span>
              </li>
            )}

          {privileges.hasOwnProperty("dailyQuizManagement") &&
            Object?.values(privileges["dailyQuizManagement"])?.includes(
              "read"
            ) && (
              <li
                ref={active === "dailyQuizzes" ? activeItemRef : null}
                className={
                  active === "dailyQuizzes" ? "list-item active" : "list-item"
                }
                onClick={() => {
                  navigate("/daily-quizes");
                  resetMetaData();
                  setActive("dailyQuizzes");
                  setNavList(0);
                }}
              >
                <div className="sidebar-icon">
                  <i className="fa-regular fa-block-question"></i>
                </div>
                <span className="list-text">Daily Quizzes Management</span>
              </li>
            )}

          {privileges.hasOwnProperty("emailManagement") &&
            Object?.values(privileges["emailManagement"])?.includes("read") && (
              <li
                ref={active === "email" ? activeItemRef : null}
                className={
                  active === "email" ? "list-item active" : "list-item"
                }
                onClick={() => {
                  navigate("/email");
                  setActive("email");
                  resetMetaData();
                  setNavList(0);
                }}
              >
                <div className="sidebar-icon">
                  <i className="fa-regular fa-envelope"></i>
                </div>
                <span className="list-text">Email Management</span>
              </li>
            )}

          {privileges.hasOwnProperty("feedbackManagement") &&
            Object?.values(privileges["feedbackManagement"])?.includes(
              "read"
            ) && (
              <li
                ref={active === "feedback" ? activeItemRef : null}
                className={
                  active === "feedback" ? "list-item active" : "list-item"
                }
                onClick={() => {
                  navigate("/feedback");
                  resetMetaData();
                  setActive("feedback");
                  setNavList(0);
                }}
              >
                <div className="sidebar-icon">
                  <i className="fa-regular fa-comment"></i>
                </div>
                <span className="list-text">Feedback</span>
              </li>
            )}

          {privileges.hasOwnProperty("orderManagement") &&
            Object?.values(privileges["orderManagement"])?.includes("read") && (
              <>
                <li
                  ref={active === "order" ? activeItemRef : null}
                  className={
                    active === "order" ? "list-item active" : "list-item"
                  }
                  onClick={() => {
                    navigate("/orders");
                    resetMetaData();
                    setActive("order");
                    setNavList(0);
                  }}
                >
                  <div className="sidebar-icon">
                    <i className="fa-regular fa-box-open"></i>
                  </div>
                  <span className="list-text">Orders</span>
                </li>
                {/* <li
                  ref={active === "shipment" ? activeItemRef : null}
                  className={
                    active === "shipment" ? "list-item active" : "list-item"
                  }
                  onClick={() => {
                    navigate("/shipment");
                    resetMetaData();
                    setActive("shipment");
                    setNavList(0);
                  }}
                >
                  <div className="sidebar-icon">
                    <i className="fa-light fa-truck-fast"></i>
                  </div>
                  <span className="list-text">Shipment</span>
                </li> */}
              </>
            )}

          {privileges.hasOwnProperty("leadboardManagement") &&
            Object?.values(privileges["leadboardManagement"])?.includes(
              "read"
            ) && (
              <li
                ref={active === "leadboard" ? activeItemRef : null}
                className={
                  active === "leadboard" ? "list-item active" : "list-item"
                }
                onClick={() => {
                  navigate("/leadboard");
                  resetMetaData();
                  setActive("dashboard");
                  setNavList(0);
                }}
              >
                <div className="sidebar-icon">
                  <i className="fa-regular fa-ranking-star"></i>
                </div>
                <span className="list-text">Lead Board Management</span>
              </li>
            )}

          {privileges.hasOwnProperty("notificationManagement") &&
            Object?.values(privileges["notificationManagement"])?.includes(
              "read"
            ) && (
              <li
                ref={active === "notifications" ? activeItemRef : null}
                className={
                  active === "notifications" ? "list-item active" : "list-item"
                }
                onClick={() => {
                  navigate("/notifications");
                  resetMetaData();
                  setActive("notifications");
                  setNavList(0);
                }}
              >
                <div className="sidebar-icon">
                  <i className="fa-regular fa-bell"></i>
                </div>
                <span className="list-text">Notifications Management</span>
              </li>
            )}

          {privileges.hasOwnProperty("productManagement") &&
            Object?.values(privileges["productManagement"])?.includes(
              "read"
            ) && (
              <li
                ref={active === "product" ? activeItemRef : null}
                className={
                  active === "product" ? "list-item active" : "list-item"
                }
                onClick={() => {
                  navigate("/products");
                  setActive("products");
                  resetMetaData();
                  setNavList(0);
                }}
              >
                <div className="sidebar-icon">
                  <i className="fa-regular fa-box-taped"></i>
                </div>
                <span className="list-text">Products</span>
              </li>
            )}
          {privileges.hasOwnProperty("reviewManagement") &&
            Object?.values(privileges["reviewManagement"])?.includes(
              "read"
            ) && (
              <li
                ref={active === "review" ? activeItemRef : null}
                className={
                  active === "review" ? "list-item active" : "list-item"
                }
                onClick={() => {
                  navigate("/reviews");
                  setActive("reviews");
                  resetMetaData();
                  setNavList(0);
                }}
              >
                <div className="sidebar-icon">
                  <i className="fa-sharp fa-regular fa-face-smile"></i>
                </div>
                <span className="list-text">Reviews</span>
              </li>
            )}

          {privileges.hasOwnProperty("planManagement") &&
            Object?.values(privileges["planManagement"])?.includes(
              "read"
            ) && (
              <li
                ref={active === "plans" ? activeItemRef : null}
                className={
                  active === "plans" ? "list-item active" : "list-item"
                }
                onClick={() => {
                  navigate("/plans");
                  setActive("plans");
                  resetMetaData();
                  setNavList(0);
                }}
              >
                <div className="sidebar-icon">
                <i className="fa-sharp fa-light fa-award-simple"></i>
                </div>
                <span className="list-text">Plans</span>
              </li>
            )}

          {privileges.hasOwnProperty("rewardManagement") &&
            Object?.values(privileges["rewardManagement"])?.includes(
              "read"
            ) && (
              <li
                ref={active === "rewards" ? activeItemRef : null}
                className={
                  active === "rewards" ? "list-item active" : "list-item"
                }
                onClick={() => {
                  resetMetaData();
                  navigate("/rewards");
                  setActive("rewards");
                  setNavList(0);
                }}
              >
                <div className="sidebar-icon">
                  <i className="fa-regular fa-gift"></i>
                </div>
                <span className="list-text">Reward Management</span>
              </li>
            )}

          {privileges.hasOwnProperty("scorecardManagement") &&
            Object?.values(privileges["scorecardManagement"])?.includes(
              "read"
            ) && (
              <li
                ref={active === "scores" ? activeItemRef : null}
                className={
                  active === "scores" ? "list-item active" : "list-item"
                }
                onClick={() => {
                  resetMetaData();
                  navigate("/score-card");
                  setActive("scores");
                  setNavList(0);
                }}
              >
                <div className="sidebar-icon">
                  <i className="fa-regular fa-wallet"></i>
                </div>
                <span className="list-text">Score Card Management</span>
              </li>
            )}

          {userPrivileges.some((privilege) =>
            Object.keys(privileges).includes(privilege)
          ) &&
            Object?.values(
              privileges[
              userPrivileges.find((privilege) =>
                Object.keys(privileges).includes(privilege)
              )
              ]
            )?.includes("read") && (
              <li
                ref={isUserListActive ? activeItemRef : null}
                className={isUserListActive ? "list-item active" : "list-item"}
                onClick={() => {
                  navList === 4 ? setNavList(0) : setNavList(4);
                }}
              >
                <div className="expand-li">
                  <div className="ls">
                    <div className="sidebar-icon">
                      <i className="fa-regular fa-users"></i>
                    </div>
                    <span className="list-text">Users</span>
                  </div>
                  <div className="fs">
                    <div className="sidebar-icon">
                      <i
                        className={`fa-regular fa-chevron-right medium ${navList !== 4 ? "rotate-0" : "rotate-90"
                          }`}
                      ></i>
                    </div>
                  </div>
                </div>
              </li>
            )}

          {navList === 4 && (
            <ul className="nested-nav-list">
              {privileges.hasOwnProperty("userManagement") &&
                Object?.values(privileges["userManagement"])?.includes(
                  "read"
                ) && (
                  <li
                    ref={active === "roles" ? activeItemRef : null}
                    className={
                      active === "roles" ? "list-item active" : "list-item"
                    }
                    onClick={() => {
                      resetMetaData();
                      navigate("/users/administrator-directory");
                      setActive("roles");
                    }}
                  >
                    <div className="sidebar-icon">
                      <i className="fa-duotone fa-circle small"></i>
                    </div>
                    <span className="list-text">Administrator</span>
                  </li>
                )}

              {privileges.hasOwnProperty("userManagement") &&
                Object?.values(privileges["userManagement"])?.includes(
                  "read"
                ) && (
                  <li
                    ref={active === "users" ? activeItemRef : null}
                    className={
                      active === "users" ? "list-item active" : "list-item"
                    }
                    onClick={() => {
                      resetMetaData();
                      navigate("/users/application-users");
                      setActive("users");
                    }}
                  >
                    <div className="sidebar-icon">
                      <i className="fa-duotone fa-circle small"></i>
                    </div>
                    <span className="list-text">Application Users</span>
                  </li>
                )}
            </ul>
          )}
        </ul>

        <h4 className="list-title">SETTINGS</h4>
        <ul className="list-items">
          <li
            ref={active === "profile" ? activeItemRef : null}
            className={active === "profile" ? "list-item active" : "list-item"}
            onClick={() => {
              resetMetaData();
              navigate("/profile");
              setActive("profile");
              setNavList(0);
            }}
          >
            <div className="sidebar-icon">
              <i className="fa-duotone fa-user"></i>
            </div>
            <span className="list-text">My Profile</span>
          </li>

          {role === 1 &&
            privileges.hasOwnProperty("settingManagement") &&
            Object?.values(privileges["settingManagement"])?.includes(
              "read"
            ) && (
              <li
                ref={active === "company" ? activeItemRef : null}
                className={
                  active === "company" ? "list-item active" : "list-item"
                }
                onClick={() => {
                  resetMetaData();
                  navigate("/company");
                  setActive("company");
                  setNavList(0);
                }}
              >
                <div className="sidebar-icon">
                  <i className="fa-regular fa-buildings"></i>
                </div>
                <span className="list-text">Company</span>
              </li>
            )}

          {privileges.hasOwnProperty("addressManagement") &&
            Object?.values(privileges["addressManagement"])?.includes(
              "read"
            ) && (
              <li
                ref={active === "address" ? activeItemRef : null}
                className={
                  active === "address" ? "list-item active" : "list-item"
                }
                onClick={() => {
                  resetMetaData();
                  navigate("/address");
                  setActive("address");
                }}
              >
                <div className="sidebar-icon">
                  <i className="fa-regular fa-warehouse"></i>
                </div>
                <span className="list-text">Address</span>
              </li>
            )}
        </ul>
      </div>

      <div className="sidebar-footer">
        <SubmitButton
          className="logout-btn flex-center"
          onClick={() => handleLogout()}
          isLoading={authLoading}
        >
          <div className="sidebar-icon">
            <i className="fa-light fa-arrow-right-from-bracket large"></i>
          </div>
          <span>Logout </span>
        </SubmitButton>
      </div>
    </div>
  );
};

export default Sidebar;
