import CryptoJS from "crypto-js";
import { toast } from "react-toastify";

// it accepts file = event.target.files[0]
export const convertToWebP = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      const img = new Image();

      img.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;

        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);

        canvas.toBlob((blob) => {
          const convertedFile = new File([blob], `${Date.now()}.webp`, {
            type: "image/webp",
          });
          resolve(convertedFile);
        }, "image/webp");
      };

      img.src = reader.result;
    };

    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
};

// -------->> Upload Image Function <<-------------
export const uploadImage = async (event, customSetter, customField = "file") => {
  const selectedImg = event.target.files[0];

  if (
    selectedImg?.type === "image/jpeg" ||
    selectedImg?.type === "image/webp" ||
    selectedImg?.type === "image/png" ||
    selectedImg?.type === "image/jpg"
  ) {
    if (selectedImg.size > 5 * 1024 * 1024) {
      toast.warning(
        "File size exceeds 5MB limit. Please select a smaller file."
      );
      event.target.value = null;
    } else {
      const convertedImg = await convertToWebP(selectedImg);
      customSetter(customField, convertedImg);
    }
  } else {
    toast.warning("accepts .jpeg, .jpg, .png and .webp files only");
    event.target.value = null;
  }
};

// make image file/blob from image url
export const urlToObject = async (imageUrl) => {
  const response = await fetch(imageUrl);
  // here image is url/location of image
  const blob = await response.blob();
  const file = new File([blob], "image.jpg", { type: blob.type });
  return file;
};

export const yyyyMMDDToISO = (yyyyMMDDDate) => {
  // Split the date string into year, month, and day components
  if (yyyyMMDDDate) {
    const [year, month, day] = yyyyMMDDDate?.split("-");

    // Convert date components to milliseconds, assuming UTC
    const timestamp = new Date(
      Date.UTC(parseInt(year), parseInt(month) - 1, parseInt(day))
    ).getTime();

    // Create a Date object from the milliseconds
    const date = new Date(timestamp);

    // Format the date object to ISO format
    const formattedDate = date.toISOString().replace(/T00:00:00Z/, "");
    return formattedDate;
  }
};

export const IpAddress = async () => {
  try {
    const response1 = await fetch(`${process.env.REACT_APP_IP_Address1}`);
    const data1 = await response1.json();
    const [latitude, longitude] = data1.loc.split(",");
    const Values = {
      latitude: latitude,
      longitude: longitude,
      ip: data1?.ip,
    };
    return Values;
  } catch (error) {
    try {
      const response2 = await fetch(`${process.env.REACT_APP_IP_Address2}`);
      const data2 = await response2.json();
      const Values = {
        latitude: data2?.latitude,
        longitude: data2?.longitude,
        ip: data2?.IPv4,
      };
      return Values;
    } catch (error) {
      try {
        const response3 = await fetch(`${process.env.REACT_APP_IP_Address3}`);
        const data3 = await response3.json();
        const Values = {
          latitude: data3?.latitude,
          longitude: data3?.longitude,
          ip: data3?.IPv4,
        };
        return Values;
      } catch (error) {
        try {
          const response4 = await fetch(`${process.env.REACT_APP_IP_Address4}`);
          const data4 = await response4.json();
          const Values = {
            latitude: data4?.lat,
            longitude: data4?.lon,
            ip: data4?.IPv4,
          };
          return Values;
        } catch (error) {
          const staticData = {
            latitude: "",
            longitude: "",
            ip: "00.00.00.00",
          };
          return staticData;
        }
      }
    }
  }
};

export const unixTimeToYYYYMMDD = (unixTimestamp) => {
  const date = new Date(unixTimestamp);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Pad the month with a leading zero if necessary
  const day = String(date.getDate()).padStart(2, "0"); // Pad the day with a leading zero if necessary

  // Combine the components into the desired format
  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
};

export const incrptPSW = async (password) => {
  const key = Array.from({ length: 64 }, () =>
    "0123456789abcdefghijklmnopqrstuvwxyz".charAt(
      Math.floor(Math.random() * 36)
    )
  ).join("");
  const decrypt = CryptoJS.AES.encrypt(password, key).toString();
  return { decrypt, key };
};

export const decryptPSW = (password, key) => {
  const bytes = CryptoJS.AES.decrypt(password, key);
  return bytes.toString(CryptoJS.enc.Utf8);
};

export const getDeviceId = () => {
  const today = new Date();
  const day = today.getDate();
  const month = today.getMonth() + 1;
  const year = today.getFullYear();
  const newDate = new Date(`${year}-${month}-${day}`);
  let uid = newDate.getTime();
  uid += navigator.mediaDevices?.enumerateDevices?.length;
  uid += navigator.userAgent.replace(/\D+/g, "");
  uid += window.screen.height || "";
  uid += window.screen.width || "";
  uid += window.screen.pixelDepth || "";

  return uid;
};

export const AddNumField = async (Mod, startNumbering) => {
  return new Promise((resolve, reject) => {
    try {
      const newData = Mod.data.map((item, index) => {
        return { ...item, num: startNumbering + 1 + index };
      });
      const updatedMod = { ...Mod, data: newData };
      resolve(updatedMod);
    } catch (error) {
      reject(error);
    }
  });
};

export const unixTimeToReadableFormat = (unixTimestamp) => {
  // Create a Date object from the Unix timestamp
  const date = new Date(unixTimestamp);

  // Extract the day, month, and year components
  const day = date.getDate();
  const month = date.getMonth();
  const year = date.getFullYear();

  // Convert the month number to the corresponding month name
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const monthName = monthNames[month];

  // Format the date in the desired format
  const formattedDate = `${day} ${monthName}, ${year}`;

  return formattedDate;
};

export const options = {
  // position: toast.POSITION.TOP_RIGHT,
  // autoClose: 3000,
  // icon: true,
  // closeButton: false,
};

//! animation function //
export const handleAnimation = (
  setAddStyle,
  addStyle,
  setShowComponent,
  showComponent,
  delay = 200
) => {
  setAddStyle(!addStyle);
  let styleTimer;
  if (showComponent) {
    styleTimer = setTimeout(() => {
      setShowComponent(false);
    }, delay);
  } else {
    setShowComponent(true);
  }
  return () => clearTimeout(styleTimer);
};
//! animation function ends//

// -------->> Function to get title for breadcrumb //
export function getBreadcrumbName(params) {
  let title = "";
  if (params?.name?.firstName && params?.name?.lastName) {
    title = `${params?.name?.firstName} ${params?.name?.lastName}`;
  } else if (params?.email) {
    title = params?.email;
  } else if (params?.title) {
    title = params?.title;
  } else if (params?.name) {
    title = params?.name;
  } else {
    title = "Dashboard";
  }
  return title;
}

export const localDateAndTime = (date) => {
  if (date) {
    const localDate = new Date(date);
    const dateOptions = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      timeZone: "Asia/Kolkata",
    };
    const timeOptions = {
      hour: "numeric",
      minute: "numeric",
      hour12: false,
      timeZone: "Asia/Kolkata",
    };
    const fdate = new Intl.DateTimeFormat("en-IN", dateOptions).format(
      localDate
    );
    const [day, month, year] = fdate.split("/").map(Number);
    const paddedMonth = month.toString().padStart(2, "0");
    const paddedDay = day.toString().padStart(2, "0");
    const formattedDate = `${year}-${paddedMonth}-${paddedDay}`;
    const ftime = new Intl.DateTimeFormat("en-IN", timeOptions).format(
      localDate
    );
    return {
      date: formattedDate,
      time: ftime,
    };
  }
};

// max year set for date of birth
export const currYear = new Date().getFullYear();
export const maxDate = `${currYear}-12-31`;
export const minDate = `${currYear - 100}-12-31`;
export const minBirthDate = `${currYear - 18}-12-31`;

export const numValidator = (str) => {
  str = String(str);
  let firstPeriod = false;
  let newStr = str.replace(/[^\d .+]/gi, "").replace(/[.]/g, function (match) {
    if (match === "." && !firstPeriod) {
      firstPeriod = true;
      return ".";
    }
    return "";
  });
  let [firstNum, secondNum] = newStr.split(".");
  if (secondNum) {
    secondNum = secondNum.slice(0, 2);
    return `${firstNum}.${secondNum}`;
  }
  return newStr;

};