import React, { useState } from "react";

const UserBookmarks = () => {
  const data = [
    {
      id: 1,
      category: "savings and spendings",
      ques: "Lorem ipsum dolor sit amet consectetur. Fermentum vitae dui lorem auctor diam gravida volutpat. Dictum nulla nulla suscipit aliquam orci. Urna felis adipiscing curabitur phasellus non dictumst nunc aliquam.",
      ans: [
        { title: "loream ipsum", isChecked: false },
        { title: "loream ipsum", isChecked: true },
        { title: "loream ipsum", isChecked: false },
        { title: "loream ipsum", isChecked: false },
      ],
    },
    {
      id: 2,
      category: "savings and spendings",
      ques: "Lorem ipsum dolor sit amet consectetur. Fermentum vitae dui lorem auctor diam gravida volutpat. Dictum nulla nulla suscipit aliquam orci. Urna felis adipiscing curabitur phasellus non dictumst nunc aliquam.",
      ans: [
        { title: "loream ipsum", isChecked: false },
        { title: "loream ipsum", isChecked: false },
        { title: "loream ipsum", isChecked: true },
        { title: "loream ipsum", isChecked: false },
      ],
    },
    {
      id: 3,
      category: "savings and spendings",
      ques: "Lorem ipsum dolor sit amet consectetur. Fermentum vitae dui lorem auctor diam gravida volutpat. Dictum nulla nulla suscipit aliquam orci. Urna felis adipiscing curabitur phasellus non dictumst nunc aliquam.",
      ans: [
        { title: "loream ipsum", isChecked: false },
        { title: "loream ipsum", isChecked: false },
        { title: "loream ipsum", isChecked: false },
        { title: "loream ipsum", isChecked: true },
      ],
    },
    {
      id: 4,
      category: "risk and insurance",
      ques: "Lorem ipsum dolor sit amet consectetur. Fermentum vitae dui lorem auctor diam gravida volutpat. Dictum nulla nulla suscipit aliquam orci. Urna felis adipiscing curabitur phasellus non dictumst nunc aliquam.",
      ans: [
        { title: "loream ipsum", isChecked: false },
        { title: "loream ipsum", isChecked: true },
        { title: "loream ipsum", isChecked: false },
        { title: "loream ipsum", isChecked: false },
      ],
    },
    {
      id: 5,
      category: "risk and insurance",
      ques: "Lorem ipsum dolor sit amet consectetur. Fermentum vitae dui lorem auctor diam gravida volutpat. Dictum nulla nulla suscipit aliquam orci. Urna felis adipiscing curabitur phasellus non dictumst nunc aliquam.",
      ans: [
        { title: "loream ipsum", isChecked: false },
        { title: "loream ipsum", isChecked: false },
        { title: "loream ipsum", isChecked: true },
        { title: "loream ipsum", isChecked: false },
      ],
    },
    {
      id: 6,
      category: "risk and insurance",
      ques: "Lorem ipsum dolor sit amet consectetur. Fermentum vitae dui lorem auctor diam gravida volutpat. Dictum nulla nulla suscipit aliquam orci. Urna felis adipiscing curabitur phasellus non dictumst nunc aliquam.",
      ans: [
        { title: "loream ipsum", isChecked: false },
        { title: "loream ipsum", isChecked: false },
        { title: "loream ipsum", isChecked: false },
        { title: "loream ipsum", isChecked: true },
      ],
    },
    {
      id: 7,
      category: "information and technology",
      ques: "Lorem ipsum dolor sit amet consectetur. Fermentum vitae dui lorem auctor diam gravida volutpat. Dictum nulla nulla suscipit aliquam orci. Urna felis adipiscing curabitur phasellus non dictumst nunc aliquam.",
      ans: [
        { title: "loream ipsum", isChecked: false },
        { title: "loream ipsum", isChecked: true },
        { title: "loream ipsum", isChecked: false },
        { title: "loream ipsum", isChecked: false },
      ],
    },
    {
      id: 8,
      category: "information and technology",
      ques: "Lorem ipsum dolor sit amet consectetur. Fermentum vitae dui lorem auctor diam gravida volutpat. Dictum nulla nulla suscipit aliquam orci. Urna felis adipiscing curabitur phasellus non dictumst nunc aliquam.",
      ans: [
        { title: "loream ipsum", isChecked: false },
        { title: "loream ipsum", isChecked: false },
        { title: "loream ipsum", isChecked: true },
        { title: "loream ipsum", isChecked: false },
      ],
    },
    {
      id: 9,
      category: "information and technology",
      ques: "Lorem ipsum dolor sit amet consectetur. Fermentum vitae dui lorem auctor diam gravida volutpat. Dictum nulla nulla suscipit aliquam orci. Urna felis adipiscing curabitur phasellus non dictumst nunc aliquam.",
      ans: [
        { title: "loream ipsum", isChecked: false },
        { title: "loream ipsum", isChecked: false },
        { title: "loream ipsum", isChecked: false },
        { title: "loream ipsum", isChecked: true },
      ],
    },
    {
      id: 10,
      category: "entrepreneurship",
      ques: "Lorem ipsum dolor sit amet consectetur. Fermentum vitae dui lorem auctor diam gravida volutpat. Dictum nulla nulla suscipit aliquam orci. Urna felis adipiscing curabitur phasellus non dictumst nunc aliquam.",
      ans: [
        { title: "loream ipsum", isChecked: false },
        { title: "loream ipsum", isChecked: true },
        { title: "loream ipsum", isChecked: false },
        { title: "loream ipsum", isChecked: false },
      ],
    },
    {
      id: 11,
      category: "entrepreneurship",
      ques: "Lorem ipsum dolor sit amet consectetur. Fermentum vitae dui lorem auctor diam gravida volutpat. Dictum nulla nulla suscipit aliquam orci. Urna felis adipiscing curabitur phasellus non dictumst nunc aliquam.",
      ans: [
        { title: "loream ipsum", isChecked: false },
        { title: "loream ipsum", isChecked: false },
        { title: "loream ipsum", isChecked: true },
        { title: "loream ipsum", isChecked: false },
      ],
    },
    {
      id: 12,
      category: "entrepreneurship",
      ques: "Lorem ipsum dolor sit amet consectetur. Fermentum vitae dui lorem auctor diam gravida volutpat. Dictum nulla nulla suscipit aliquam orci. Urna felis adipiscing curabitur phasellus non dictumst nunc aliquam.",
      ans: [
        { title: "loream ipsum", isChecked: false },
        { title: "loream ipsum", isChecked: false },
        { title: "loream ipsum", isChecked: false },
        { title: "loream ipsum", isChecked: true },
      ],
    },
    {
      id: 13,
      category: "entrepreneurship",
      ques: "Lorem ipsum dolor sit amet consectetur. Fermentum vitae dui lorem auctor diam gravida volutpat. Dictum nulla nulla suscipit aliquam orci. Urna felis adipiscing curabitur phasellus non dictumst nunc aliquam.",
      ans: [
        { title: "loream ipsum", isChecked: false },
        { title: "loream ipsum", isChecked: true },
        { title: "loream ipsum", isChecked: false },
        { title: "loream ipsum", isChecked: false },
      ],
    },
    {
      id: 14,
      category: "entrepreneurship",
      ques: "Lorem ipsum dolor sit amet consectetur. Fermentum vitae dui lorem auctor diam gravida volutpat. Dictum nulla nulla suscipit aliquam orci. Urna felis adipiscing curabitur phasellus non dictumst nunc aliquam.",
      ans: [
        { title: "loream ipsum", isChecked: false },
        { title: "loream ipsum", isChecked: false },
        { title: "loream ipsum", isChecked: true },
        { title: "loream ipsum", isChecked: false },
      ],
    },
    {
      id: 15,
      category: "savings and spendings",
      ques: "Lorem ipsum dolor sit amet consectetur. Fermentum vitae dui lorem auctor diam gravida volutpat. Dictum nulla nulla suscipit aliquam orci. Urna felis adipiscing curabitur phasellus non dictumst nunc aliquam.",
      ans: [
        { title: "loream ipsum", isChecked: false },
        { title: "loream ipsum", isChecked: false },
        { title: "loream ipsum", isChecked: false },
        { title: "loream ipsum", isChecked: true },
      ],
    },
  ];

  const handleUpdate = (type) => data.filter((obj) => obj.category === type);
  const categories = [...new Set(data.map((obj) => obj.category))];
  const [bookmarks, setBookmarks] = useState(handleUpdate(categories?.[0]));
  const [selectedTab, setSelectedTab] = useState(categories?.[0]);

  return (
    <div className="custom-box">
      <h3>Bookmarks</h3>

      <ul className="points-tab-buttons">
        {categories.map((list, i) => (
          <li
            key={i}
            className={`points-tab ${selectedTab === list ? "active" : ""}`}
            onClick={() => {
              setBookmarks(handleUpdate(list));
              setSelectedTab(list);
            }}
          >
            {list}
          </li>
        ))}
      </ul>

      <ul className="bookmark-details">
        {bookmarks.map((ques, i) => (
          <li className="bookmarks" key={i}>
            <p className="question">{ques?.ques}</p>
            <div className="answers">
              {ques?.ans.map((ans, j) => (
                <span
                  key={j}
                  className={`answer ${ans?.isChecked ? "correct" : ""}`}
                >
                  {ans?.title}
                </span>
              ))}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default UserBookmarks;
