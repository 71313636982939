/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps*/
import { setPageDetails } from "modules/auth/redux/authSlice";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

const useFetchList = (
  listApi,
  metaData,
  selectAllRows = false,
  setSelectedRows,
  defaultLimit = 5
) => {
  const dispatch = useDispatch();
  const { privileges, pageDetails } = useSelector(
    (state) => state.auth,
    shallowEqual
  );

  const [searchTimeout, setSearchTimeout] = useState(null);
  const [check, setCheck] = useState(false);
  const [limit, setLimit] = useState(defaultLimit);
  const [sort, setSort] = useState("desc");
  const [btnNo, setBtnNo] = useState([1, 2, 3]);
  const [tableIndex, setTableIndex] = useState(
    metaData?.page ? metaData?.page : 0
  );
  const [search, setSearch] = useState(
    setPageDetails.search ? pageDetails.search : ""
  );

  useEffect(() => {
    if (searchTimeout || check) {
      clearTimeout(searchTimeout);
    } else {
      dispatch(
        listApi({
          page:
            Object.keys(pageDetails).length > 0 &&
            pageDetails.page !== undefined
              ? pageDetails?.page
              : 0,
          limit: limit,
          sort: sort,
          search:
            Object.keys(pageDetails).length > 0 ? pageDetails?.search : "",
        })
      );
      setCheck(true);
      if (metaData?.length > 0) {
        dispatch(setPageDetails({ page: metaData?.page, search }));
      }
      return;
    }

    const timeoutId = setTimeout(async () => {
      dispatch(listApi({ page: 0, limit: limit, search, sort: sort }));
      setTableIndex(0);
      setBtnNo([1, 2, 3]);
      if (selectAllRows) {
        setSelectedRows(new Set());
      }
    }, 1000);

    setSearchTimeout(timeoutId);

    return () => clearTimeout(timeoutId);
  }, [dispatch, limit, sort, search]);

  return {
    privileges,
    searchTimeout,
    setSearchTimeout,
    check,
    setCheck,
    limit,
    setLimit,
    sort,
    setSort,
    search,
    setSearch,
    btnNo,
    setBtnNo,
    tableIndex,
    setTableIndex,
  };
};

export default useFetchList;
