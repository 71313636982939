/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  convertToWebP,
  maxDate,
  minBirthDate,
  minDate,
  unixTimeToYYYYMMDD,
  yyyyMMDDToISO,
} from "helper/helper";
import DropDownBox from "components/DropDownBox";
import { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  changeProfilePicture,
  getProfileDetails,
  reqEmailOtp,
  reqPhoneOtp,
  updateNewEmail,
  updateNewPhone,
  updateProfile,
  verifyEmail,
  verifyPhone,
} from "../redux/profileApi";
import { female, male } from "assets/icons";
import SubmitButton from "components/ui/SubmitButton";
import InputField from "components/ui/InputField";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import { send2faOtp } from "modules/auth/redux/authApi";

const GeneralDetails = () => {
  const dispatch = useDispatch();
  const { profileDetail, profileLoading } = useSelector(
    (store) => store.profile,
    shallowEqual
  );

  useEffect(() => {
    if (Object.keys(profileDetail).length === 0) dispatch(getProfileDetails());
  }, [dispatch, profileDetail]);

  console.log("profileDetail: ", profileDetail);
  const [activeInputs, setActiveInputs] = useState(false);
  const [profilePic, setProfilePic] = useState(null);
  const [emailOtpBox, setEmailOtpBox] = useState({
    status: false,
    update: false,
  });
  const [phOtpBox, setPhOtpBox] = useState({ status: false, update: false });
  const [changePhone, setChangePhone] = useState(false);
  const [changeEmail, setChangeEmail] = useState(false);
  const { countryList } = useSelector((state) => state.profile, shallowEqual);
  const [isoList, setIsoList] = useState();
  const initialValues = {
    first_name: "",
    last_name: "",
    email: "",
    country: "",
    phone_no: "",
    gender: "",
    dob: "",
    emailOtp: "",
    phoneOtp: "",
  };

  const profileSchema = Yup.object({
    first_name: Yup.string()
      .trim()
      .min(3, "Name must be at least 3 characters long")
      .max(80, "First name must be less then 80 characters")
      .matches(/^[A-Za-z ]+$/, "Please enter a valid name")
      .required("First name is required"),
    last_name: Yup.string()
      .trim()
      .min(3, "Last Name must be at least 3 characters long")
      .max(80, "Last name must be less then 80 characters")
      .matches(/^[A-Za-z ]+$/, "Please enter a valid name")
      .required("Last name is required"),
    // email: Yup.string()
    //   .trim()
    //   .email("Complete email address")
    //   .matches(
    //     /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
    //     "Enter a valid email, please!"
    //   )
    //   .required("Email address should not be empty"),
    // phone_no: Yup.string()
    //   .matches(/^[6-9]\d{9}$/, "Please enter a valid phone number")
    //   .required("Phone number is required"),
    gender: Yup.string().required("Gender is required"),
    dob: Yup.date()
      .required("Birth date is required")
      .test("age", "You must be 18 years old or above", (value) => {
        const today = new Date(); // Define 'today' as the current date
        const birthDate = new Date(value);
        const ageDifferenceInMilliseconds = today - birthDate;
        const ageInYears =
          ageDifferenceInMilliseconds / (365.25 * 24 * 60 * 60 * 1000); // Approximate calculation
        return ageInYears >= 18;
      })
      .test("age two", "User's age must be less than 100 years", (value) => {
        if (new Date(value).getFullYear() >= new Date().getFullYear() - 100) {
          return true;
        } else {
          return false;
        }
      }),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: profileSchema,
    onSubmit: async (values) => {
      const data = {
        first_name: values.first_name,
        last_name: values.last_name,
        gender: values.gender,
        // email: values.email,
        // phone: `+91${values.phone_no}`,
        date_of_birth: yyyyMMDDToISO(values.dob),
      };
      const response = await dispatch(updateProfile(data));

      if (response?.payload) {
        setActiveInputs(false);
        setEmailOtpBox({ status: false, update: false });
        setPhOtpBox({ status: false, update: false });
        setChangePhone(false);
        setChangeEmail(false);
      }
    },
  });
  console.log("dob: ", formik.values.dob);

  useEffect(() => {
    if (countryList?.length > 0) {
      const cc = countryList.map((item) => {
        const label = `<img src = "${item?.flag?.icon}" width=15 style="margin-right: 10px"/>  ${item?.dialCode} ${item.name}`;
        const value = item.isoCode;
        const key = item.dialCode;
        return { label, value, key };
      });
      setIsoList(cc);
    }
  }, [countryList]);

  useEffect(() => {
    const country = isoList?.filter(
      (ele) => ele.value === profileDetail.phone.isoCode
    )?.[0];
    formik.setValues({
      first_name: profileDetail?.name?.firstName,
      last_name: profileDetail?.name?.lastName,
      phone_no: String(
        profileDetail?.phone?.value?.slice(country?.key?.length)
      ),
      email: profileDetail?.email?.value,
      country: profileDetail?.phone?.isoCode,
      gender: profileDetail?.gender,
      dob: profileDetail?.dob && unixTimeToYYYYMMDD(profileDetail?.dob),
    });

    setProfilePic(
      profileDetail?.photo?.localUrl
        ? profileDetail?.photo?.localUrl
        : profileDetail?.gender
          ? profileDetail?.gender === "male"
            ? male
            : female
          : male
    );
  }, [profileDetail, activeInputs, isoList]);

  const handleProfilePic = async (e) => {
    const selectedImg = e.target.files[0];

    if (
      selectedImg.type === "image/jpeg" ||
      selectedImg.type === "image/webp" ||
      selectedImg.type === "image/png" ||
      selectedImg.type === "image/jpg"
    ) {
      if (selectedImg.size > 5 * 1024 * 1024) {
        toast.warning(
          "File size exceeds 5MB limit. Please select a smaller file."
        );
        e.target.value = null;
      } else {
        setProfilePic(URL.createObjectURL(selectedImg));
        const convertedImg = await convertToWebP(selectedImg);
        e.target.value = null;
        dispatch(changeProfilePicture({ picture: convertedImg }));
      }
    } else {
      toast.warning("accepts .jpeg, .jpg, .png and .webp files only");
      e.target.value = null;
    }
  };

  const handleRemove = async () => {
    const dummyImg = profileDetail?.gender
      ? profileDetail?.gender === "male"
        ? male
        : female
      : male;
    setProfilePic(dummyImg);
    // Converted the dummy image to a WebP image
    fetch(dummyImg)
      .then((res) => res.blob())
      .then(async (blob) => {
        const convertedImg = await convertToWebP(blob);
        const res = dispatch(changeProfilePicture({ picture: convertedImg }));
      });
  };

  const handleEmailOtp = async () => {
    const res = await dispatch(reqEmailOtp({ email: formik.values.email }));
    if (res.payload) {
      formik.setFieldValue("emailOtp", "");
      setEmailOtpBox({ status: true, update: false });
    }
  };

  // const handlePhoneOtp = async () => {
  //   const data = {
  //     iso_code: formik.values.country,
  //     phone: formik.values.phone_no
  //   }
  //   const res = await dispatch(reqPhoneOtp(data))
  //   if (res.payload) {
  //     formik.setFieldValue("phoneOtp", "")
  //     setPhOtpBox({ status: true, update: false })
  //   }
  // }
  const handlePhoneOtp = async () => {
    const data = {
      iso_code: formik.values.country,
      phone: formik.values.phone_no,
      email: profileDetail?.email?.value,
    };
    const res = await dispatch(send2faOtp(data));
    if (res.payload) {
      formik.setFieldValue("phoneOtp", "");
      setPhOtpBox({ status: true, update: false });
    }
  };

  const verifyEmailOtp = async () => {
    const data = {
      email: formik.values.email,
      otp: Number(formik.values.emailOtp),
    };
    const res = await dispatch(verifyEmail(data));
    if (res.payload) {
      formik.setFieldValue("emailOtp", "");
      setEmailOtpBox({ status: false, update: false });
    }
  };

  const verifyPhoneOtp = async () => {
    const contryCode = isoList?.filter(
      (ele) => ele.value === formik.values.country
    );
    const data = {
      iso_code: formik.values.country,
      phone:
        formik.values.phone_no.length === 10
          ? `${contryCode?.[0]?.key}${formik.values.phone_no}`
          : formik.values.phone_no,
      otp: Number(formik.values.phoneOtp),
    };
    const res = await dispatch(verifyPhone(data));
    if (res.payload) {
      formik.setFieldValue("emailOtp", "");
      setPhOtpBox({ status: false, update: false });
    }
  };

  const handlePhoneChange = async () => {
    const contryCode = isoList?.filter(
      (ele) => ele.value === formik.values.country
    )?.[0];

    // const data = {
    //   iso_code: formik.values.country,
    //   phone: `${contryCode?.key}${formik.values.phone_no}`,
    // }
    // const res = await dispatch(reqPhoneOtp(data))
    const data = {
      iso_code: formik.values.country,
      phone: `${contryCode?.key}${formik.values.phone_no}`,
      email: profileDetail?.email?.value,
    };
    const res = await dispatch(send2faOtp(data));

    if (res.payload) {
      formik.setFieldValue("phoneOtp", "");
      setChangePhone(false);
      setPhOtpBox({ status: true, update: true });
    }
  };

  const updatePhone = async () => {
    const contryCode = isoList?.filter(
      (ele) => ele.value === formik.values.country
    )?.[0];
    const data = {
      iso_code: formik.values.country,
      phone: `${contryCode?.key}${formik.values.phone_no}`,
      otp: Number(formik.values.phoneOtp),
    };
    const res = await dispatch(updateNewPhone(data));
    if (res.payload) {
      formik.setFieldValue("emailOtp", "");
      setPhOtpBox({ status: false, update: false });
    }
  };

  const updateEmail = async () => {
    const data = {
      email: formik.values.email,
      otp: Number(formik.values.emailOtp),
    };
    const res = await dispatch(updateNewEmail(data));
    if (res.payload) {
      formik.setFieldValue("emailOtp", "");
      setEmailOtpBox({ status: false, update: false });
      setChangeEmail(false);
    }
  };

  const handleDiscard = () => {
    setActiveInputs(!activeInputs);
    setEmailOtpBox({ status: false, update: false });
    setPhOtpBox({ status: false, update: false });
    setChangePhone(false);
    setChangeEmail(false);
  };

  return (
    <div className="profile-details">
      <div className="header-wrapper">
        <h2 className="title">My Profile</h2>
        <div className="sub-header">
          <div className="desc">
            <h3>General Details</h3>
            <p className="txt">Update your personal details here</p>
          </div>

          <div className="flex-center gap-2">
            {activeInputs && (
              <button
                className="btn-outlined cancel"
                // onClick={() => {
                //   setActiveInputs(!activeInputs);
                // }}
                type="button"
                onClick={handleDiscard}
              >
                Discard
              </button>
            )}
            {activeInputs ? (
              <SubmitButton
                type="submit"
                onClick={() => {
                  formik.handleSubmit();
                }}
                className="btn-primary"
                isLoading={profileLoading}
              >
                Update
              </SubmitButton>
            ) : (
              <button
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  setActiveInputs(true);
                }}
                className="btn-outlined edit flex-center gap-2"
              >
                <i className="fa-regular fa-pen-to-square"></i>
                Edit
              </button>
            )}
          </div>
        </div>
      </div>

      <div className="auth-form-wrapper">
        <div className="update-dp">
          <div className="image-box">
            <img
              src={profilePic}
              alt="profile-logo"
              className="profile-img"
              crossOrigin="anonymous"
            />
          </div>
          <div className="flex-center gap-2">
            <label
              htmlFor="profile_picture"
              style={!activeInputs ? { cursor: "not-allowed" } : null}
              className="profChange_label"
            >
              Change Picture
              <input
                type="file"
                accept=".jpg,.png,.jpeg,.webp"
                className="profChange_input"
                name="profile_picture"
                id="profile_picture"
                onChange={handleProfilePic}
                disabled={!activeInputs}
                hidden
              />
            </label>

            <button
              className="btn-outlined cancel"
              onClick={handleRemove}
              style={
                !activeInputs
                  ? { cursor: "not-allowed", color: "#D0202E" }
                  : { color: "#D0202E" }
              }
            >
              Remove
            </button>
          </div>
        </div>
        <form className="form" noValidate="novalidate">
          <div className="form-col-2">
            <div className="form-control">
              <label className="form-label">First Name</label>
              <div className="form-input-box">
                <InputField
                  type="text"
                  id="first_name"
                  name="first_name"
                  value={formik.values.first_name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  disabled={!activeInputs}
                  onInput={(e) => {
                    e.target.value = e.target.value
                      .replace(/[^A-Za-z ]/gi, "")
                      .replace(/(?:^|\s)\S/g, (match) => match.toUpperCase());
                  }}
                  onPaste={(e) => {
                    e.preventDefault();
                  }}
                  onCopy={(e) => {
                    e.preventDefault();
                  }}
                  onKeyDown={(e) => {
                    if (e.key === " ") {
                      e.preventDefault();
                    }
                  }}
                  maxLength={81}
                />
              </div>
              {formik.errors.first_name && formik.touched.first_name ? (
                <span className="err">{formik.errors.first_name}</span>
              ) : null}
            </div>
            <div className="form-control">
              <label className="form-label">Last Name</label>
              <div className="form-input-box">
                <InputField
                  type="text"
                  id="last_name"
                  name="last_name"
                  value={formik.values.last_name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  onInput={(e) => {
                    e.target.value = e.target.value
                      .replace(/[^A-Za-z ]/gi, "")
                      .replace(/(?:^|\s)\S/g, (match) => match.toUpperCase());
                  }}
                  maxLength={81}
                  disabled={!activeInputs}
                />
              </div>
              {formik.errors.last_name && formik.touched.last_name ? (
                <span className="err">{formik.errors.last_name}</span>
              ) : null}
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <div className="form-control">
              <label className="form-label">Email</label>

              {!changeEmail ? (
                <div className="form-input-box">
                  {emailOtpBox.status ? (
                    <>
                      <InputField
                        type="text"
                        id="emailOtp"
                        name="emailOtp"
                        className="form-input"
                        value={formik.values.emailOtp}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder="Enter Otp"
                      />
                      <div className="verf-box">
                        <button
                          type="button"
                          disabled={!activeInputs}
                          onClick={verifyEmailOtp}
                        >
                          Verify
                        </button>
                        <button
                          type="button"
                          disabled={!activeInputs}
                          onClick={() =>
                            setEmailOtpBox({ status: false, update: false })
                          }
                        >
                          Cancel
                        </button>
                      </div>
                    </>
                  ) : (
                    <>
                      <InputField
                        type="text"
                        id="email"
                        name="email"
                        className="form-input"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        disabled={!changeEmail}
                      />
                      <div className="verf-box">
                        <button
                          type="button"
                          disabled={!activeInputs}
                          onClick={() => {
                            setChangeEmail(true);
                            setEmailOtpBox({ status: false, update: false });
                          }}
                        >
                          Change
                        </button>
                        {profileDetail?.email?.isVerified ? (
                          <i className="fa-sharp fa-solid fa-circle-check"></i>
                        ) : (
                          <button
                            type="button"
                            disabled={!activeInputs}
                            onClick={handleEmailOtp}
                          >
                            Send OTP
                          </button>
                        )}
                      </div>
                    </>
                  )}
                </div>
              ) : (
                <div className="form-input-box">
                  {!emailOtpBox.status ? (
                    <>
                      <InputField
                        type="text"
                        id="email"
                        name="email"
                        className="form-input"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        disabled={!changeEmail}
                      />
                      <div className="verf-box">
                        <button
                          type="button"
                          disabled={!activeInputs}
                          onClick={handleEmailOtp}
                        >
                          Send OTP
                        </button>
                      </div>
                    </>
                  ) : (
                    <>
                      <InputField
                        type="text"
                        id="emailOtp"
                        name="emailOtp"
                        className="form-input"
                        value={formik.values.emailOtp}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder="Enter Otp"
                      />
                      <div className="verf-box">
                        <button
                          type="button"
                          disabled={!activeInputs}
                          onClick={updateEmail}
                        >
                          Verify
                        </button>
                      </div>
                    </>
                  )}
                </div>
              )}

              {/* <div className="form-input-box">
                {
                  !emailOtpBox.status ?
                    <InputField
                      type="text"
                      id="email"
                      name="email"
                      className="form-input"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={!changeEmail}
                    /> :
                    <InputField
                      type="text"
                      id="emailOtp"
                      name="emailOtp"
                      className="form-input"
                      value={formik.values.emailOtp}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder="Enter Otp"
                    />
                }
                <div className="verf-box">
                  {!emailOtpBox.status ?
                    (profileDetail?.email?.isVerified ?
                      <i className="fa-sharp fa-solid fa-circle-check"></i> :
                      <button type="button" disabled={!activeInputs} onClick={handleEmailOtp}>Send OTP</button>) :
                    <button type="button" disabled={!activeInputs} onClick={emailOtpBox.update ? updateEmail : verifyEmailOtp}>Verify</button>
                  }
                  {
                    !emailOtpBox.status &&
                    <button
                      type="button"
                      disabled={!activeInputs}
                      onClick={() => {
                        setChangeEmail(!changeEmail)
                        setEmailOtpBox({ status: true, update: true })
                      }}
                    >
                      {changeEmail ? "Cancel" : "Change"}
                    </button>
                  }
                  {
                    changeEmail &&
                    (!emailOtpBox.update && <button type="button" disabled={!activeInputs} onClick={handleEmailOtp}>Update</button>)
                  }
                </div>
              </div> */}
              {formik.errors.email && formik.touched.email ? (
                <span className="err">{formik.errors.email}</span>
              ) : null}
            </div>
            <div className="form-control">
              <label className="form-label">Phone Number</label>
              {!changePhone ? (
                <div className="form-input-box ph-input">
                  {!phOtpBox.status ? (
                    <div
                      className="form-input"
                      style={{
                        border: "1px solid #d2d6da",
                        width: "100%",
                        backgroundColor: "#ebebeb4b",
                        cursor: "not-allowed",
                        fontSize: "0.875rem",
                      }}
                    >
                      {profileDetail?.phone?.value}
                    </div>
                  ) : (
                    // <InputField
                    //   type="text"
                    //   id="phone_no"
                    //   name="phone_no"
                    //   className={`form-input ${formik.values.phone_no ? "phon_no" : ""}`}
                    //   value={formik.values.phone_no}
                    //   onChange={formik.handleChange}
                    //   onBlur={formik.handleBlur}
                    //   onInput={(e) => {
                    //     e.target.value = e.target.value
                    //       .slice(0, 10)
                    //       .replace(/\D/g, "");
                    //   }}
                    //   disabled
                    // /> :
                    <input
                      type="text"
                      id="phoneOtp"
                      name="phoneOtp"
                      className={`form-input ${formik.values.phoneOtp ? "phon_no" : ""}`}
                      value={formik.values.phoneOtp}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      maxlength="17"
                      onInput={(e) => {
                        // Allow only digits and limit to 17 characters
                        const value = e.target.value.replace(/[^\d]/g, "").slice(0, 17);
                        e.target.value = value;
                      }}
                      placeholder="Enter Otp"
                    />
                  )}
                  <div className="verf-box">
                    {!phOtpBox.status ? (
                      <>
                        <button
                          type="button"
                          disabled={!activeInputs}
                          onClick={() => setChangePhone(true)}
                        >
                          Change
                        </button>
                        {profileDetail?.phone?.isVerified ? (
                          <i className="fa-sharp fa-solid fa-circle-check"></i>
                        ) : (
                          <button
                            type="button"
                            disabled={!activeInputs}
                            onClick={handlePhoneOtp}
                          >
                            Send Otp
                          </button>
                        )}
                      </>
                    ) : (
                      <button
                        type="button"
                        disabled={!activeInputs}
                        onClick={phOtpBox.update ? updatePhone : verifyPhoneOtp}
                      >
                        Verify
                      </button>
                    )}
                  </div>
                </div>
              ) : (
                <div className="form-input-box ph-input">
                  <DropDownBox
                    options={isoList}
                    customSetter={formik.setFieldValue}
                    customFormikLabel="country"
                    incomingValue={formik.values.country}
                    showSearchBar
                    disabled={activeInputs}
                    isPhoneCode
                  />
                  <input
                    type="text"
                    id="phone_no"
                    name="phone_no"
                    className={`form-input ${formik.values.phone_no ? "phon_no" : ""}`}
                    value={formik.values.phone_no}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    // onInput={(e) => {
                    //   e.target.value = e.target.value.replace(/\D/g, "")
                    // }}
                    maxlength="15"
                    onInput={(e) => {
                      // Allow only digits and limit to 17 characters
                      const value = e.target.value.replace(/[^\d]/g, "").slice(0, 15);
                      e.target.value = value;
                    }}
                    disabled={!activeInputs}
                  />
                  <div className="verf-box">
                    <button
                      type="button"
                      disabled={!activeInputs}
                      onClick={handlePhoneChange}
                    >
                      Update
                    </button>
                  </div>
                </div>
              )}
              {formik.errors.phone_no && formik.touched.phone_no ? (
                <span className="err">{formik.errors.phone_no}</span>
              ) : null}
            </div>
          </div>
          <div className="form-col-2">
            <div className="form-control">
              <label className="form-label">Gender</label>
              <div className="form-input-box">
                <DropDownBox
                  options={[
                    { label: "Male", value: "male" },
                    {
                      label: "Female",
                      value: "female",
                    },
                    { label: "Other", value: "other" },
                  ]}
                  // dropDownTitle="Gender"
                  // animateDropDownTitle
                  defaultValue="Select Gender"
                  customSetter={formik.setFieldValue}
                  customFormikLabel="gender"
                  customDropBoxStyles={{
                    border: "0.063rem solid #d2d6da",
                    borderRadius: "0.5rem",
                    color: "#454545",
                  }}
                  customTextStyle={{
                    color: "#212229",
                  }}
                  incomingValue={formik.values.gender}
                  disabled={activeInputs}
                />
              </div>
              {formik.errors.gender && formik.touched.gender ? (
                <span className="err">{formik.errors.gender}</span>
              ) : null}
            </div>
            <div className="form-control">
              <label className="form-label">Date of Birth</label>
              <div className="form-input-box">
                <input
                  type="date"
                  id="dob"
                  name="dob"
                  className="form-input"
                  value={formik.values.dob}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  disabled={!activeInputs}
                  max={minBirthDate}
                  min={minDate}
                />
              </div>
              {formik.errors.dob && formik.touched.dob ? (
                <span className="err">{formik.errors.dob}</span>
              ) : null}
            </div>
          </div>
          {formik.errors.role && formik.touched.role ? (
            <span className="err">{formik.errors.role}</span>
          ) : null}
        </form>
      </div>
    </div>
  );
};

export default GeneralDetails;
