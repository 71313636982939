/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import DropDownBox from "components/DropDownBox";
import BulkUpload from "components/BulkUpload";
import SubmitButton from "components/ui/SubmitButton";
import { skillsSheet } from "assets/files";
import { uploadImage, urlToObject } from "helper/helper";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  addBulkCategoryAsync,
  addCategory,
  getCategoryDetail,
  updateCategoryDetail,
} from "../redux/categoryApi";
import { useFormik } from "formik";
import * as Yup from "yup";
import { clearCategoryDetail } from "../redux/categorySlice";

const CategoryManager = ({ popup, setPopup, setBtnNo, setTableIndex }) => {
  const [addStyle, setAddStyle] = useState(true);
  const [activeInputs, setActiveInputs] = useState(true);
  const [categoryImg, setCategoryImg] = useState(null);

  const dispatch = useDispatch();
  const { categoryDetail } = useSelector(
    (state) => state.CatCategory,
    shallowEqual
  );

  useEffect(() => {
    if (popup.id) {
      dispatch(getCategoryDetail({ id: popup.id }));
    }

    return () => dispatch(clearCategoryDetail());
  }, [dispatch, popup.id]);

  const CategoryImage =
    categoryDetail?.image?.bucketUrl || categoryDetail?.image?.localUrl;

  const formik = useFormik({
    initialValues: {
      name: "",
      type: "",
      file: null,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .trim()
        .min(3, "Name must be at least 3 characters long")
        .max(80, "Name must be less then 80 characters")
        // .matches(/^[A-Za-z ]+$/, "Please enter a valid name")
        .required("Name should not be empty"),
      type: Yup.string().trim().required("Type is required"),
      file: Yup.mixed().required("Category image is required"),
    }),
    onSubmit: async (values) => {
      const data = {
        send: {
          image: values.file,
          category_name: values.name.trim(),
          category_type: values.type.trim(),
        },
        id: popup.id,
      };
      if (popup.type === "add-category") {
        const response = await dispatch(addCategory(data.send));
        if (response?.payload) setPopup({ show: false, type: "", id: "" });
        setTableIndex(0);
        setBtnNo([1, 2, 3]);
      }

      if (popup.type === "update-category") {
        const response = await dispatch(updateCategoryDetail(data));
        if (response?.payload) setPopup({ show: false, type: "", id: "" });
      }
    },
  });

  useEffect(() => {
    if (popup.type === "update-category" && categoryDetail) {
      (async () => {
        const imageObject = CategoryImage && (await urlToObject(CategoryImage));
        formik.setValues({
          name: categoryDetail.name,
          type: categoryDetail.type,
          file: imageObject,
        });
        setCategoryImg(CategoryImage);
      })();
    }
  }, [categoryDetail, popup.type]);

  useEffect(() => {
    document
      .getElementsByClassName("container")[0]
      .classList.add("popUp-container");
  }, []);

  const handleClick = () => {
    setAddStyle((prev) => !prev);
    chatBoxVisibility();
  };

  function chatBoxVisibility() {
    if (popup.show) {
      const styleTimer = setTimeout(() => {
        setPopup((prev) => ({ ...prev, show: !prev.show }));
        document
          .getElementsByClassName("container")[0]
          .classList.remove("popUp-container");
        clearTimeout(styleTimer);
      }, 200);
    }
  }

  useEffect(() => {
    if (formik.values.file) {
      const img = URL.createObjectURL(formik.values.file);
      setCategoryImg(img);
    }
  }, [formik.values.file]);

  const InputProps = {
    className: "form-input",
    onChange: formik.handleChange,
    onBlur: formik.handleBlur,
    // onKeyDown: (e) => e.key === " " && e.preventDefault(),
    onInput: (e) =>
      (e.target.value = e.target.value
        // .replace(/[^A-Za-z ]/gi, "")
        .replace(/(?:^|\s)\S/g, (match) => match.toUpperCase())),
    disabled: popup.type === "update-category" ? activeInputs : false,
    maxLength: 80,
  };

  return (
    <div id="confirm-box-wrapper">
      <div
        className={
          "popup-wrapper" +
          (addStyle
            ? " show_delete-component-fadeIn-animation"
            : " hide_delete-component-fadeOut-animation")
        }
      >
        <div
          className={`popup-content ${popup.type === "add-multiple-category" ? "w-32" : ""}`}
        >
          <div className="popup-header">
            <h2>{popup.type.replaceAll("-", " ")}</h2>

            <button className="close flex-center" onClick={() => handleClick()}>
              <i className="fa-sharp fa-regular fa-xmark-large"></i>
            </button>
          </div>

          {popup.type === "add-category" || popup.type === "update-category" ? (
            <div className="pc-wrapper">
              <div className="auth-form-wrapper">
                <form
                  className="form"
                  onSubmit={formik.handleSubmit}
                  // noValidate="novalidate"
                >
                  <div className="form-control">
                    <label htmlFor="file" className="form-label">
                      {categoryImg ? (
                        <div
                          className="update-image-wrapper flex-center"
                          style={
                            activeInputs ? { cursor: "not-allowed" } : null
                          }
                        >
                          <img
                            src={categoryImg}
                            alt="brand"
                            crossOrigin="anonymous"
                          />
                        </div>
                      ) : (
                        <div className="flex-center gap-2">
                          <div className="dummy-img flex-center">
                            <i className="fa-duotone fa-solid fa-image dummy-img__icon"></i>
                          </div>
                          <span className="profChange_label">Add Icon</span>
                        </div>
                      )}
                      <input
                        id="file"
                        name="file"
                        type="file"
                        accept=".jpg, .jpeg, .png, .gif, .webp "
                        onChange={(e) => uploadImage(e, formik.setFieldValue)}
                        onBlur={formik.handleBlur}
                        disabled={
                          popup.type === "update-category" && activeInputs
                        }
                        hidden
                      />
                    </label>
                    {formik.errors.file && formik.touched.file && (
                      <span className="err">{formik.errors.file}</span>
                    )}
                  </div>

                  <div className="form-control">
                    <label className="form-label">Name</label>
                    <div className="form-input-box">
                      <input
                        id="name"
                        name="name"
                        type="text"
                        placeholder="Please enter category here..."
                        value={formik.values.name || ""}
                        {...InputProps}
                      />
                    </div>
                    {formik.errors.name && formik.touched.name && (
                      <span className="err">{formik.errors.name}</span>
                    )}
                  </div>

                  <div className="form-control">
                    <label className="form-label">Type</label>
                    <div className="form-input-box">
                      <DropDownBox
                        options={[
                          { label: "Skill", value: "skill" },
                          { label: "Product", value: "product" },
                          { label: "FAQ", value: "faq" },
                        ]}
                        size="large"
                        defaultValue="Select Type"
                        customSetter={formik.setFieldValue}
                        customFormikLabel="type"
                        customDropBoxStyles={{
                          border: "0.063rem solid #d2d6da",
                          borderRadius: "0.5rem",
                          color: "#262626",
                          fontSize: "0.875rem",
                        }}
                        customTextStyle={{
                          color: "#454545",
                          fontSize: "small",
                        }}
                        incomingValue={
                          popup.type === "update-category"
                            ? categoryDetail?.type
                            : null
                        }
                        disabled={
                          popup.type === "update-category"
                            ? !activeInputs
                            : true
                        }
                      />
                    </div>
                    {formik.errors.type && formik.touched.type ? (
                      <span className="err">{formik.errors.type}</span>
                    ) : null}
                  </div>

                  <div className="flex-center">
                    {popup.type === "update-category" &&
                    activeInputs === true ? (
                      <button
                        className="btn-outlined flex-center"
                        onClick={() => setActiveInputs(!activeInputs)}
                      >
                        Edit Category
                      </button>
                    ) : (
                      <SubmitButton
                        size="fit-content"
                        className="btn-primary"
                        type="submit"
                        isLoading={formik.isSubmitting}
                      >
                        Submit
                      </SubmitButton>
                    )}
                  </div>
                </form>
              </div>
            </div>
          ) : null}
          {popup.type === "add-multiple-category" && (
            <BulkUpload
              sheet={skillsSheet}
              fetchApi={addBulkCategoryAsync}
              handleClick={handleClick}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default CategoryManager;
