import { createSlice } from "@reduxjs/toolkit";
import { getCompanyDetail, getLocality } from "./companyApi";

const companySlice = createSlice({
    name: "company",
    initialState: {
        companyLoading: false,
        companyDetail: {},
        locality: []
    },
    reducers: {
        iscompanyToggle: (state, action) => {
            state.companyLoading = action.payload;
        },
    },

    extraReducers: (builder) => {
        builder.addCase(getCompanyDetail.pending, (state) => {
            state.companyLoading = true;
        });
        builder.addCase(getCompanyDetail.fulfilled, (state, { payload }) => {

            state.companyLoading = false;
            state.companyDetail = payload.data;
        });
        builder.addCase(getCompanyDetail.rejected, (state) => {
            state.companyLoading = false;
        });
        builder.addCase(getLocality.pending, (state) => {
            state.profileLoading = true;
        });
        builder.addCase(getLocality.fulfilled, (state, action) => {
            state.profileLoading = false;
            state.locality = action.payload.data;
        });
        builder.addCase(getLocality.rejected, (state) => {
            state.profileLoading = false;
        });
    }
})

export const { iscompanyToggle } = companySlice.actions;
export default companySlice.reducer;