/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import ModuleLayout from "layouts/ModuleLayout";
import DropDownBox from "components/DropDownBox";
import InputField from "components/ui/InputField";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getLocality } from "modules/settings/company/redux/companyApi";
import {
  addAddress,
  getAddressDetail,
  updateAddress,
} from "../redux/addressApi";
import { useFormik } from "formik";
import * as Yup from "yup";

const AddAddress = ({ setTitle }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    setTitle("Add Address");
  }, [setTitle]);

  const [active, setActive] = useState("address");
  const [mobileSidebar, setMobileSidebar] = useState(false);
  const [minimizedSidebar, setMinimizedSidebar] = useState(false);
  const [activeInputs, setActiveInputs] = useState(false);
  const [isoList, setIsoList] = useState();
  const { countryList } = useSelector((state) => state.profile, shallowEqual);
  const { pathname } = useLocation();
  const { addressDetail, addressLoading } = useSelector(
    (state) => state.address,
    shallowEqual
  );
  const { locality } = useSelector((state) => state.company, shallowEqual);
  const { id } = useParams();
  const [pinStatue, setPinStatue] = useState(true);
  const initialValues = {
    name: "",
    isoCode: id ? "" : "IN",
    phone_no: "",
    lineOne: "",
    lineTwo: "",
    city: "",
    state: "",
    country: "",
    pincode: "",
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    isoCode: Yup.string().required("ISO Code is required"),
    phone_no: Yup.string().required("Phone number is required"),
    lineOne: Yup.string().required("Address Line one is required"),
    city: Yup.string().required("City is required"),
    state: Yup.string().required("State is required"),
    country: Yup.string().required("Country is required"),
    pincode: Yup.string().required("Pincode is required"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      const code = countryList.filter(
        (ele) => ele.isoCode === values.isoCode
      )?.[0];
      const data = {
        name: values.name,
        iso_code: values.isoCode,
        phone: `${code?.dialCode}${values.phone_no}`,
        address_line_one: values.lineOne,
        address_line_two: values.lineTwo,
        city: values.city,
        state: values.state,
        country: values.country,
        pincode: values.pincode,
      };
      if (pathname === "/address/add-new") {
        const res = await dispatch(addAddress(data));
        if (res?.payload?.status) {
          resetForm();
          navigate("/address");
        }
      } else {
        const res = await dispatch(updateAddress({ id: id, data: data }));
        if (res?.payload?.status) {
          resetForm();
          navigate("/address");
        }
      }
    },
  });

  const getLocalAddress = async (e) => {
    formik.setFieldValue("pincode", e);
    if (e.length === 6) {
      const data = {
        pincode: e,
      };
      const res = await dispatch(getLocality(data));
      if (res?.error) {
        setPinStatue(false);
      } else {
        setPinStatue(true);
      }
    }
  };

  useEffect(() => {
    if (countryList?.length > 0) {
      const cc = countryList.map((item) => {
        const label = `<img src = "${item?.flag?.icon}" width=15 style="margin-right: 10px"/>  ${item?.dialCode} ${item.name}`;
        const value = item.isoCode;
        const key = item.dialCode;
        return { label, value, key };
      });
      setIsoList(cc);
    }
  }, [countryList]);

  useEffect(() => {
    if (id) {
      const data = {
        id: id,
      };
      dispatch(getAddressDetail(data));
    }
  }, []);

  useEffect(() => {
    if (Object.keys(addressDetail).length > 0 && id) {
      const iso = countryList.filter(
        (ele) => ele.isoCode === addressDetail?.phone?.isoCode
      )?.[0];
      formik.setValues({
        name: addressDetail?.name,
        phone_no: addressDetail?.phone?.value?.slice(iso?.dialCode?.length),
        lineOne: addressDetail?.address?.line_one,
        lineTwo: addressDetail?.address?.line_two,
        city: addressDetail?.address?.city,
        state: addressDetail?.address?.state,
        country: addressDetail?.address?.country,
        pincode: addressDetail?.address?.pincode,
        isoCode: addressDetail?.phone?.isoCode,
      });
    }
  }, [addressDetail, addressLoading, countryList]);

  useEffect(() => {
    if (locality) {
      formik.setValues({
        ...formik.values,
        city: locality?.[0]?.city || "",
        state: locality?.[0]?.state || "",
        country: locality?.[0]?.country || "",
      });
    }
  }, [locality]);

  useEffect(() => {
    if (!id) {
      formik.setValues({
        ...formik.values,
        city: "",
        state: "",
        country: "",
      });
    }
  }, [id]);

  return (
    <ModuleLayout
      minimizedSidebar={minimizedSidebar}
      setMinimizedSidebar={setMinimizedSidebar}
      mobileSidebar={mobileSidebar}
      setMobileSidebar={setMobileSidebar}
      active={active}
      setActive={setActive}
    >
      <div className="profile-wrapper" style={{ padding: "1.6rem" }}>
        <div className="header-wrapper">
          <div className="sub-header flex-between">
            <h2 className="title">
              {pathname === "/address/add-new" ? "Add Address" : "Address"}
            </h2>
            {pathname === "/address/add-new" ? (
              <div className="flex-center gap-2">
                <button
                  type="button"
                  onClick={() => navigate(-1)}
                  className="btn-outlined cancel"
                >
                  Back
                </button>
                <button
                  className="btn-primary"
                  type="submit"
                  onClick={formik.handleSubmit}
                >
                  Submit
                </button>{" "}
              </div>
            ) : activeInputs ? (
              <div className="flex gap-1">
                <button
                  className="btn-outlined cancel"
                  type="button"
                  onClick={() => setActiveInputs(false)}
                >
                  Cancel
                </button>
                <button
                  className="btn-primary"
                  type="submit"
                  onClick={formik.handleSubmit}
                >
                  Update
                </button>
              </div>
            ) : (
              <>
                <button
                  className="btn-primary"
                  type="button"
                  onClick={() => setActiveInputs(true)}
                >
                  Edit
                </button>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="addform-box">
        <form onSubmit={formik.handleSubmit}>
          <div className="form-col-2 mt-1">
            <div className="form-control">
              <label className="form-label">Name</label>
              <div className="form-input-box">
                <InputField
                  type="text"
                  id="name"
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  disabled={id && !activeInputs}
                  maxLength={50}
                  style={{ textTransform: "capitalize" }}
                />
              </div>
              {formik.errors.name && formik.touched.name ? (
                <span className="err">{formik.errors.name}</span>
              ) : null}
            </div>
            <div className="form-control">
              <label className="form-label">Phone</label>
              <div className="form-input-box ph-input">
                <DropDownBox
                  options={isoList}
                  customSetter={formik.setFieldValue}
                  customFormikLabel="isoCode"
                  incomingValue={formik.values.isoCode}
                  showSearchBar
                  disabled={id && activeInputs}
                  customDropBoxStyles={{
                    border: "1px solid #d2d6da",
                  }}
                isPhoneCode
                />
                <InputField
                  type="text"
                  id="phone_no"
                  name="phone_no"
                  className={`form-input ${formik.values.phone_no ? "phon_no" : ""}`}
                  value={formik.values.phone_no}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(/\D/g, "");
                  }}
                  maxLength={15}
                  disabled={id && !activeInputs}
                />
              </div>
              {formik.errors.isoCode && formik.touched.isoCode ? (
                <span className="err">{formik.errors.isoCode}</span>
              ) : null}
              {formik.errors.phone_no && formik.touched.phone_no ? (
                <span className="err">{formik.errors.phone_no}</span>
              ) : null}
            </div>
          </div>
          <div className="flex flex-col gap-1 mt-1">
            <div className="form-control">
              <label className="form-label">Address Line One</label>
              <div className="form-input-box">
                <InputField
                  type="text"
                  id="lineOne"
                  name="lineOne"
                  value={formik.values.lineOne}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  disabled={id && !activeInputs}
                  maxLength={50}
                  style={{ textTransform: "capitalize" }}
                />
              </div>
              {formik.errors.lineOne && formik.touched.lineOne ? (
                <span className="err">{formik.errors.lineOne}</span>
              ) : null}
            </div>
            <div className="form-control">
              <label className="form-label">Address Line Two</label>
              <div className="form-input-box">
                <InputField
                  type="text"
                  id="lineTwo"
                  name="lineTwo"
                  value={formik.values.lineTwo}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  disabled={id && !activeInputs}
                  maxLength={50}
                  style={{ textTransform: "capitalize" }}
                />
              </div>
              {formik.errors.lineTwo && formik.touched.lineTwo ? (
                <span className="err">{formik.errors.lineTwo}</span>
              ) : null}
            </div>
          </div>
          <div className="form-col-2 mt-1">
            <div className="form-control">
              <label className="form-label">Pincode</label>
              <div className="form-input-box">
                <InputField
                  type="text"
                  id="pincode"
                  name="pincode"
                  value={formik.values.pincode}
                  onChange={(e) => getLocalAddress(e.target.value)}
                  onBlur={formik.handleBlur}
                  disabled={id && !activeInputs}
                  onInput={(e) => {
                    e.target.value = e.target.value
                      .slice(0, 6)
                      .replace(/\D/g, "");
                  }}
                  maxLength={6}
                />
              </div>
              {formik.errors.pincode && formik.touched.pincode ? (
                <span className="err">{formik.errors.pincode}</span>
              ) : null}
            </div>
            <div className="form-control">
              <label className="form-label">City</label>
              <div className="form-input-box">
                <InputField
                  type="text"
                  id="city"
                  name="city"
                  value={formik.values.city}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  disabled={pinStatue}
                  maxLength={81}
                />
              </div>
              {formik.errors.city && formik.touched.city ? (
                <span className="err">{formik.errors.city}</span>
              ) : null}
            </div>
          </div>
          <div className="form-col-2 mt-1">
            <div className="form-control">
              <label className="form-label">State</label>
              <div className="form-input-box">
                <InputField
                  type="text"
                  id="state"
                  name="state"
                  value={formik.values.state}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  disabled={pinStatue}
                  maxLength={81}
                />
              </div>
              {formik.errors.state && formik.touched.state ? (
                <span className="err">{formik.errors.state}</span>
              ) : null}
            </div>
            <div className="form-control">
              <label className="form-label">Country</label>
              <div className="form-input-box">
                <InputField
                  type="text"
                  id="country"
                  name="country"
                  value={formik.values.country}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  disabled={pinStatue}
                  maxLength={81}
                />
              </div>
              {formik.errors.country && formik.touched.country ? (
                <span className="err">{formik.errors.country}</span>
              ) : null}
            </div>
          </div>
        </form>
      </div>
    </ModuleLayout>
  );
};

export default AddAddress;
