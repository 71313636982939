import React, { useEffect, useState } from "react";
import Sidebar from "components/Sidebar";
import Header from "components/Header";
import MobileSidebar from "components/MobileSidebar";
import MinimizeSidebar from "components/MinimizeSidebar";

const Email = ({ setTitle }) => {
  useEffect(() => {
    setTitle("Admin | Email");
  }, [setTitle]);

  const [mobileSidebar, setMobileSidebar] = useState(false);
  const [active, setActive] = useState("email");
  const [minimizedSidebar, setMinimizedSidebar] = useState(false);

  return (
    <div className="container">
      <div className="app-container">
        <div className="home-container">
          {!minimizedSidebar ? (
            <div className="main-sidebar">
              <Sidebar active={active} setActive={setActive} />
            </div>
          ) : (
            <div className="minisidebar-container">
              <MinimizeSidebar active={active} setActive={setActive} />
            </div>
          )}
          <div className={!mobileSidebar ? "mobile-sidebar" : "mobile-show"}>
            <MobileSidebar
              setMobileSidebar={setMobileSidebar}
              active={active}
              setActive={setActive}
            />
          </div>
          
          <div
            className={minimizedSidebar ? "content-wrapper extended" : "content-wrapper"}
          >
            <Header
              setMobileSidebar={setMobileSidebar}
              minimizedSidebar={minimizedSidebar}
              setMinimizedSidebar={setMinimizedSidebar}
            />
            <div className="content-body">
              <div className="home-inner-body">
                <h1>Email</h1>
              </div>
            </div>
          </div>
        </div>
        <footer></footer>
      </div>
    </div>
  );
};

export default Email;
